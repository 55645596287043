import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelfSubscription } from '../../../models/ui/ITableView';

@Component({
  selector: 'async-setup-notify',
  templateUrl: './async-setup-notify.component.html',
  styleUrls: ['./async-setup-notify.component.scss'],
})
export class AsyncSetupNotifyComponent {
  @Input() isSending!: boolean;
  @Input() subscription!: SelfSubscription | undefined;

  @Output() sendNotification = new EventEmitter<boolean>();
  @Output() cancelNotification = new EventEmitter<boolean>();

  sendAsyncNotification(): void {
    this.sendNotification.emit(true)
  }

  cancelingNotification(): void {
    this.cancelNotification.emit(true)
  }
}
