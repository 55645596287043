/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  ActionKind,
  ActionKindVariant,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { EBannerSource, EFabricationType } from '../enums/cbp-enum';
import { IBanner } from '../models/data/request/IBanner';
import { ICrosstabConfig } from '../models/data/request/ICrosstabConfig';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
} from '../models/data/request/IDataFilterItem';
import { IDimension } from '../models/data/request/IDimension';
import { IExpansion } from '../models/data/request/IExpansion';
import { ITableViewFabrication } from '../models/fabrication/ITableViewFabrication';
import { IAction } from '../models/suzy/IAction';
import { IMission } from '../models/suzy/IMission';
import {
  ITableView,
  ITableViewName,
  TableViewConfigBase,
} from '../models/ui/ITableView';
import { ITableViewConfig } from '../models/ui/ITableViewConfig';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFilters,
  IAdvancedFiltersTag,
} from '../models/ui/i-advanced-filters-config.model';
import { ICalculations } from '../models/ui/i-calculations-ui.model';
import { IClusterGroupFilterUI } from '../models/ui/i-cluster-group-filters-ui.model';
import { ICrosstabConfigUI } from '../models/ui/i-crosstab-config-ui';
import {
  DemographicsFilterUI,
  DemographicsFiltersUIBase,
} from '../models/ui/i-demographics-filter-ui.model';
import { DataUtility } from '../utilities/data-utility';
import { CustomBannerPointsActionsService } from './custom-banner-points-actions.service';
import { SuzyDataService } from './suzy-data.service';
import { SuzySDK } from './suzy-sdk';
import { TableViewsRequestsService } from './table-views-requests.service';

@Injectable({ providedIn: 'root' })
export class TableViewService {
  constructor(
    private suzySDK: SuzySDK,
    private translate: TranslateService,
    private suzyDataService: SuzyDataService,
    private cbpService: CustomBannerPointsActionsService,
    private newTableViewsEndpoints: TableViewsRequestsService
  ) {}

  public getInterfaceConfigKeyForAction(
    mission: IMission,
    action: IAction
  ): string {
    let missionKey = '';
    let actionKey = '';
    let detailKey = '';

    const { mission_kind, monadic_enabled } = mission;
    if (mission_kind === MissionKind.standard) {
      missionKey = 'standalone';
    } else {
      switch (mission_kind) {
        case MissionKind.survey:
          missionKey = 'survey';
          break;
        case MissionKind.screening:
          missionKey = 'screener';
          break;
        case MissionKind.splittesting:
          missionKey = 'monadic';
          break;
        case MissionKind.maxdiff:
          missionKey = 'maxdiff';
          break;
        case MissionKind.external_cint:
          if (monadic_enabled) {
            missionKey = 'monadic';
          } else {
            missionKey = 'survey';
          }
          break;
        case MissionKind.external_link:
          missionKey = 'external';
          break;
      }
    }

    if (!missionKey) {
      return '';
    }

    const { action_kind, action_kind_variant } = action;
    switch (action_kind) {
      case ActionKind.multiplechoice:
        if (
          this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_standard
        ) {
          actionKey = 'multiplechoice';
        } else if (
          this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_rating
        ) {
          actionKey = 'rating';
        }
        break;
      case ActionKind.openended:
        actionKey = 'openend';
        break;
      case ActionKind.gridcustom:
        actionKey = 'customgrid';
        break;
      case ActionKind.gridrankscale:
        if (action_kind_variant === ActionKindVariant.grid_rank) {
          actionKey = 'ranking';
        } else if (action_kind_variant === ActionKindVariant.grid_scale) {
          actionKey = 'scale';
        }
        break;
      case ActionKind.grid:
        if (this.getGridKind(action) === ActionKindVariant.grid_rank) {
          actionKey = 'ranking';
        } else if (this.getGridKind(action) === ActionKindVariant.grid_scale) {
          actionKey = 'scale';
        } else if (this.getGridKind(action) === ActionKindVariant.grid_open) {
          actionKey = 'customgrid';
        }
        break;
      case ActionKind.maxdiff:
        actionKey = 'maxdiff';
        break;
      case ActionKind.auto_assign:
        actionKey = 'autoassign';
        break;
      case ActionKind.turf:
        actionKey = 'turf';
        break;
    }

    if (!actionKey) {
      return '';
    }

    detailKey = 'standard';
    if (
      mission_kind === MissionKind.splittesting ||
      (mission_kind === MissionKind.external_cint && monadic_enabled)
    ) {
      switch (action_kind) {
        case ActionKind.multiplechoice:
        case ActionKind.openended:
          if (action.has_monadic) {
            detailKey = 'concept';
          } else {
            detailKey = 'comparison';
          }
          break;
        case ActionKind.gridrankscale:
          if (action_kind_variant === ActionKindVariant.grid_scale) {
            if (action.has_monadic) {
              detailKey = 'concept';
            } else {
              detailKey = 'comparison';
            }
          } else {
            detailKey = 'comparison';
          }
          break;
        case ActionKind.gridcustom:
          detailKey = 'comparison';
          break;
        case ActionKind.grid:
          if (this.getGridKind(action) === ActionKindVariant.grid_scale) {
            if (action.has_monadic) {
              detailKey = 'concept';
            } else {
              detailKey = 'comparison';
            }
          } else if (this.getGridKind(action) === ActionKindVariant.grid_rank) {
            detailKey = 'comparison';
          } else if (this.getGridKind(action) === ActionKindVariant.grid_open) {
            detailKey = 'comparison';
          }
          break;
        case ActionKind.turf:
          detailKey = 'comparison';
          break;
      }
    }

    return `${actionKey}_${missionKey}_${detailKey}`;
  }

  public getDefaultTableViews(): Observable<ITableView[]> {
    const searchParams = {
      take: 100,
    };

    /* Table View Templates */
    return this.newTableViewsEndpoints
      .getTableViewsTemplates(searchParams)
      .pipe(
        map((resp: any) => {
          if (!resp?.success || !resp?.items?.length) {
            return [];
          }

          const items = resp.items
            .filter((item) => {
              const config = (function (raw) {
                try {
                  return JSON.parse(raw);
                } catch (err) {
                  return false;
                }
              })(item.json_payload ?? '');
              return config !== false;
            })
            .map((item: any) => {
              const config = JSON.parse(item.json_payload) as ITableViewConfig;
              config.expansions?.forEach((expansion) => {
                if (expansion.name === 'base_size_row') {
                  expansion.name = 'span_base_size_row';
                } else if (expansion.name === 'total_responses_row') {
                  expansion.name = 'span_total_responses_row';
                } else if (expansion.name === 'total_responses_column') {
                  expansion.name = 'primary_total_responses_column';
                }
              });

              const tableView = {
                table_view_id: item.interface_config_global_id,
                isGlobal: true,
                priority: item.priority,
                display_name: item.display_name,
                entity: item.entity,
                key: item.key,
                config,
              } as ITableView;

              return tableView;
            });

          return items ?? [];
        })
      );
  }

  public getBrandTableViewNames(brandId: string): Observable<ITableViewName[]> {
    let skip = 0;
    const take = 100;
    const searchParams = {
      take,
      keyword: 'crosstab.v1',
      brand_id: brandId,
      skip,
      descending: false,
      order_by: 'mission_id',
    };
    const tableViewNamesDict: Array<ITableViewName> = [];

    const processResponse = (resp: any): Observable<ITableViewName[]> => {
      if (!resp?.success || !resp?.items?.length) {
        return of(tableViewNamesDict);
      }

      resp.items.forEach((x) => {
        const {
          mission_id,
          action_id,
          display_name,
          interface_config_brand_id,
        } = x;
        tableViewNamesDict.push({
          mission_id,
          action_id,
          table_view_id: interface_config_brand_id,
          display_name,
        });
      });

      if (!resp?.stepping?.more) {
        return of(tableViewNamesDict);
      }

      skip = skip + take;
      searchParams.skip = skip;
      return loadData(searchParams);
    };

    const loadData = (searchParams: any): Observable<ITableViewName[]> => {
      return this.suzySDK.InterfaceConfigBrand.find(searchParams).pipe(
        switchMap((resp: any) => {
          return processResponse(resp);
        })
      );
    };

    return loadData(searchParams);
  }

  public getTableViewsForAction(
    brandId: string,
    mission: IMission,
    action: IAction
    //defaultTableViews?: ITableView[]
  ): Observable<ITableView[]> {
    const actionConfigKey = this.getInterfaceConfigKeyForAction(
      mission,
      action
    );

    const searchParams = {
      take: 100,
      mission_id: mission.mission_id,
      action_id: action.action_id,
      order_by: 'display_name',
      is_derived_question: action.is_derived_question ?? false,
    };

    const configItems: ITableView[] = [];
    /*     if (defaultTableViews?.length) {
      const defaultsForAction =
        defaultTableViews.filter((x) => x.key === actionConfigKey) ?? [];
      defaultsForAction.forEach((item) => {
        configItems.push(item);
      });
    }
 */
    /* Table view list */

    return new Observable<Array<ITableView>>((observable) => {
      this.newTableViewsEndpoints
        .getTableViewsList(brandId, searchParams)
        .pipe(take(1))
        .subscribe({
          next: (resp: any) => {
            if (!resp?.success || !resp?.items?.length) {
              return;
            }
            resp.items
              .map((item: any) => {
                const config = JSON.parse(
                  item.json_payload
                ) as ITableViewConfig;
                config.expansions?.forEach((expansion) => {
                  if (expansion.name === 'base_size_row') {
                    expansion.name = 'span_base_size_row';
                  } else if (expansion.name === 'total_responses_row') {
                    expansion.name = 'span_total_responses_row';
                  } else if (expansion.name === 'total_responses_column') {
                    expansion.name = 'primary_total_responses_column';
                  }
                });

                return {
                  table_view_id: item.table_view_id,
                  isGlobal: !!item.template_id,
                  display_name: item.display_name,
                  entity: item.entity,
                  key: item.key,
                  brand_id: item.brand_id,
                  mission_id: item.mission_id,
                  action_id: item.action_id,
                  config,
                  action_id_primary: item.action_id_primary,
                  action_id_crosstabs: item.action_id_crosstabs,
                  edited_by: item.edited_by,
                  edited_utc: item.edited_utc,
                  organization_id: item.organization_id,
                  updated_utc: item.updated_utc,
                  requires_processing: item.requires_processing,
                  self_subscription: item.self_subscription,
                } as ITableView;
              })
              .forEach((item: ITableView) => {
                configItems.push(item);
              });
          },
          complete: () => {
            observable.next(configItems);
          },
        });
    });
  }

  public prepareTableViewConfig(uiConfig: ICrosstabConfig, isDerived: boolean): ITableViewConfig {
    const columns: IBanner[] = JSON.parse(JSON.stringify(uiConfig.columns));
    const rows: IBanner[] = JSON.parse(JSON.stringify(uiConfig.rows));
    const fabrications =
      this.getFabricationsFromDimension(uiConfig.columns, uiConfig.rows) ?? [];

    columns.forEach((banner) => {
      banner.dimensions.forEach((dimension) => {
        delete dimension.net_not_allowed;
        delete dimension.filter_not_allowed;
        delete dimension.fabricationType;
      });
    });
    rows.forEach((banner) => {
      banner.dimensions.forEach((dimension) => {
        delete dimension.net_not_allowed;
        delete dimension.filter_not_allowed;
        delete dimension.interlocked_nested_quota_fields;
        delete dimension.fabricationType;
      });
    });

    const tableViewConfig: ITableViewConfig = {
      ...uiConfig,
      format: 'crosstab.explorer.1',
      columns,
      data_filter: uiConfig.data_filter,
      rows,
      additions: uiConfig.additions,
      expansions: uiConfig.expansions,
      action_id_primary: uiConfig.action_id_primary,
      action_id_crosstabs: uiConfig.action_id_crosstabs ?? [],
      fabrications: fabrications,
      primary_action: {
        action_id: uiConfig.action_id_primary,
        brand_id: uiConfig.brand_id,
        is_derived: !!isDerived,
      },
    };

    if (!uiConfig.action_id_primary) {
      delete tableViewConfig.action_id_primary;
    }
    if (!uiConfig.action_id_crosstabs?.length) {
      delete tableViewConfig.action_id_crosstabs;
    }
    if (!tableViewConfig.additions) {
      tableViewConfig.additions = [];
    }
    if (uiConfig.orchestration?.custom_age_group) {
      tableViewConfig.orchestration = JSON.parse(
        JSON.stringify(uiConfig.orchestration)
      );
    }

    return tableViewConfig;
  }

  public getCrosstabConfigFromTableView(
    tableView: ITableViewConfig
  ): ICrosstabConfig {

    const config = {
      columns: tableView.columns,
      rows: tableView.rows,
      format: tableView.format,
      data_filter: tableView.data_filter,
      expansions: tableView.expansions,
      additions: tableView.additions,
      action_id_primary: tableView.action_id_primary ?? '',
      action_id_crosstabs: tableView.action_id_crosstabs ?? [],
      fabrications:
        this.getFabricationsFromDimension(tableView.columns, tableView.rows) ??
        [],
      primary_action: {
        action_id: tableView.action_id_primary,
        brand_id: tableView.brand_id,
      },
    } as ICrosstabConfig;
    if (!tableView.action_id_crosstabs?.length) {
      delete config.action_id_crosstabs;
    }
    if (tableView?.orchestration?.custom_age_group) {
      config.orchestration = JSON.parse(
        JSON.stringify(tableView.orchestration)
      );
    }
    return config;
  }

  public createTableView(
    brandId: string,
    item: ITableView
  ): Observable<ITableView> {
    const data: any = { ...item };

    delete data.isGlobal;
    delete data.config;
    delete data.table_view_id;
    delete data.id;
    delete data.self_subscription;
    delete data.requires_processing;

    data.json_payload = JSON.stringify(item.config);

    /* create a table view  */
    return this.newTableViewsEndpoints.createTableView(brandId, data).pipe(
      map((resp: any) => {
        item.table_view_id = resp?.item?.table_view_id ?? '';
        return item;
      })
    );
  }

  public updateTableView(brandId: string, item: ITableView): Observable<any> {
    const data: any = { ...item };
    data.interface_config_brand_id = item.id;
    delete data.isGlobal;
    delete data.config;
    delete data.id;
    data.json_payload = JSON.stringify(item.config);

    /* update a table view */
    return this.newTableViewsEndpoints
      .updateTableView(brandId, item.table_view_id, data)
      .pipe(
        catchError((err) => {
          return of({});
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map((resp: any) => {
          return item;
        })
      );
  }

  public deleteTableView(
    brandId: string,
    table_view_id: string
  ): Observable<any> {
    /* Delete a table view */
    return this.newTableViewsEndpoints
      .deleteTableView(brandId, table_view_id)
      .pipe(
        catchError(() => {
          return of({});
        }),
        map((resp: any) => {
          return resp;
        })
      );
  }

  public getBaseCrosstabConfig(): ICrosstabConfigUI {
    const crosstab = this.getCrosstabConfigFromTableView(TableViewConfigBase);
    return {
      meta: {},
      crosstab,
    } as ICrosstabConfigUI;
  }

  public isCalculateVisible(
    statTestingEnabled: boolean,
    advancedCalculationsEnabled: boolean,
    action?: IAction
  ): boolean {
    if (!action) {
      return false;
    }

    if (advancedCalculationsEnabled) {
      return true;
    }

    const { action_kind } = action;
    switch (action_kind) {
      case ActionKind.multiplechoice:
        if (
          this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_standard
        ) {
          return statTestingEnabled;
        }
        return true;
      case ActionKind.gridrankscale:
        return true;
      case ActionKind.grid:
        return (
          this.getGridKind(action) === ActionKindVariant.grid_rank ||
          this.getGridKind(action) === ActionKindVariant.grid_scale
        );
      case ActionKind.auto_assign:
        return statTestingEnabled;
    }

    return false;
  }

  public canCustomizeCalculations(
    mission?: IMission,
    action?: IAction
  ): boolean {
    if (!mission || !action) {
      return false;
    }
    if (!this.isCalculateVisible(false, false, action)) {
      return false;
    }

    const { action_kind } = action;
    if (
      action_kind === ActionKind.multiplechoice &&
      this.getMultipleChoiceKind(action) ===
        ActionKindVariant.multiplechoice_standard
    ) {
      return false;
    }

    if (action_kind === ActionKind.auto_assign) {
      return false;
    }

    if (action_kind === ActionKind.turf) {
      return false;
    }

    return true;
  }

  public canCustomizeStatTesting(
    statTestingEnabled: boolean,
    action?: IAction
  ): boolean {
    if (!statTestingEnabled || !action) {
      return false;
    }
    if (!this.isCalculateVisible(statTestingEnabled, false, action)) {
      return false;
    }

    return true;
  }

  public saveTableViewPref(actionId: string, tableViewId: string): void {
    if (!actionId.length || !tableViewId.length) {
      return;
    }
    localStorage.setItem(
      `table-view-default-${actionId}`,
      JSON.stringify({ id: tableViewId })
    );
  }

  public getSavedTableViewPref(actionId: string): string {
    if (!actionId.length) {
      return '';
    }
    const value = localStorage.getItem(
      `table-view-default-${actionId}`
    ) as string;
    if (!value) {
      return '';
    }
    const valueObj = JSON.parse(value);
    return valueObj?.table_view_id ?? '';
  }

  public removeTableViewPref(actionId: string): void {
    if (!actionId.length) {
      return;
    }
    localStorage.removeItem(`table-view-default-${actionId}`);
  }

  public tableViewHasCrosstabQuestion(tableView: ITableView): boolean {
    const { config } = tableView;
    const hasPanels =
      (config.rows ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'answer' &&
            y.field?.field === 'action_setting_id_cross'
        )
      ) ||
      (config.columns ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'answer' &&
            y.field?.field === 'action_setting_id_cross'
        )
      );

    return hasPanels;
  }

  public tableViewHasQuotas(tableView: ITableView): boolean {
    const { config } = tableView;
    const hasPanels =
      (config.rows ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'answer' &&
            (y.field?.field === 'interlocked_quota_entry' ||
              (y.field?.field ?? '').startsWith('quota_field_'))
        )
      ) ||
      (config.columns ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'answer' &&
            (y.field?.field === 'interlocked_quota_entry' ||
              (y.field?.field ?? '').startsWith('quota_field_'))
        )
      );

    return hasPanels;
  }

  public tableViewHasSegmentsPanels(tableView: ITableView): boolean {
    const { config } = tableView;

    const hasSegments =
      (config.rows ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            ((y.field?.source === 'answer' &&
              y.field?.field === 'targeted_brand_cluster_id') ||
              (y.field?.source === 'profile' &&
                y.field?.field === 'attributed_brand_cluster_group_id'))
        )
      ) ||
      (config.columns ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            ((y.field?.source === 'answer' &&
              y.field?.field === 'targeted_brand_cluster_id') ||
              (y.field?.source === 'profile' &&
                y.field?.field === 'attributed_brand_cluster_group_id'))
        )
      );

    return hasSegments;
  }

  public tableViewHasDemographics(tableView: ITableView): boolean {
    const { config } = tableView;
    const hasDemographics =
      (config.rows ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'demographic'
        )
      ) ||
      (config.columns ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'demographic'
        )
      ) ||
      config.orchestration?.custom_age_group !== undefined;

    return hasDemographics;
  }

  tableViewHasBannerPoints(tableView: ITableView, source: EBannerSource): boolean {
    const { config } = tableView;

    const rowHasSource = config.rows.some((row: any) =>
      row.dimensions.some((dim: any) => dim.field.source === source)
    );
    const columnHasSource = config.columns.some((column: any) =>
      column.dimensions.some((dim: any) => dim.field.source === source)
    );

    return rowHasSource || columnHasSource;
  }

  public tableViewHasFilters(tableView: ITableView): boolean {
    const { config } = tableView;
    const crosstabConfig = this.getCrosstabConfigFromTableView(config);
    const filtersStatus = this.crosstabConfigHasAdvancedFilters(crosstabConfig);
    const hasFilters = Object.keys(filtersStatus).some(
      (key) => filtersStatus[key]?.included
    );
    return hasFilters;
  }

  public tableViewHasNetOrMeans(tableView: ITableView): boolean {
    const { config } = tableView;
    const { expansions } = config;

    if (!expansions?.length) {
      return false;
    }

    return (
      expansions.findIndex((x) =>
        ['scale_rank.top', 'scale_rank.bottom', 'means'].some(
          (e) => e === x.name
        )
      ) >= 0
    );
  }

  public tableViewHasStatTesting(tableView: ITableView): boolean {
    const { config } = tableView;
    const { additions } = config;

    if (!additions?.length) {
      return false;
    }

    return (
      additions.findIndex((x) =>
        ['stat_test_column'].some((e) => e === x.name)
      ) >= 0
    );
  }

  public tableViewHasExternalAttributes(
    mission: IMission,
    tableView: ITableView
  ): boolean {
    if (mission.mission_kind !== MissionKind.external_link) {
      return false;
    }
    const { config } = tableView;
    const hasExternalAtrributes =
      (config.rows ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'profile' &&
            y.field?.field === 'brand_profile_question_id'
        )
      ) ||
      (config.columns ?? []).some((x) =>
        x.dimensions.some(
          (y) =>
            (y.type === 'field' || y.type === 'net') &&
            y.field?.source === 'profile' &&
            y.field?.field === 'brand_profile_question_id'
        )
      );

    return hasExternalAtrributes;
  }

  public canDuplicateTableView(
    mission: IMission,
    tableView: ITableView
  ): boolean {
    const { mission_kind } = mission;
    if (mission_kind === MissionKind.standard) {
      return false;
    }

    const { isGlobal } = tableView;
    if (isGlobal) {
      return false;
    }

    const hasFilters = this.tableViewHasFilters(tableView);
    const hasDemographics = this.tableViewHasDemographics(tableView);
    const hasSegmentsPanels = this.tableViewHasSegmentsPanels(tableView);
    const hasQuotas = this.tableViewHasQuotas(tableView);
    const hasCrosstabQuestion = this.tableViewHasCrosstabQuestion(tableView);
    const hasExternalAtrributes = this.tableViewHasExternalAttributes(
      mission,
      tableView
    );
    const hasSubpopulations = this.tableViewHasBannerPoints(tableView, EBannerSource.Fabrication);
    const hasDerivedQuestions = this.tableViewHasBannerPoints(tableView, EBannerSource.DerivedQuestion);

    const canDuplicateWithoutCalculations =
      hasFilters ||
      hasDemographics ||
      hasSegmentsPanels ||
      hasQuotas ||
      hasCrosstabQuestion ||
      hasExternalAtrributes ||
      hasSubpopulations || 
      hasDerivedQuestions;
    if (canDuplicateWithoutCalculations) {
      return true;
    }
    return (
      this.tableViewHasNetOrMeans(tableView) ||
      this.tableViewHasStatTesting(tableView)
    );
  }

  public duplicateBannerConfigs(
    sourceConfig: Array<IBanner>,
    duplicateConfig: Array<IBanner>,
    includes: {
      includeDemographics?: boolean;
      includeSegmentsPanels?: boolean;
      includeQuotas?: boolean;
      includeCrosstabQuestion?: boolean;
      includeExternalAttributes?: boolean;
      includeFabrications?: boolean;
      includeDerivedQuestions?: boolean;
    },
    destActionId: string,
    isColumns?: boolean
  ): Array<IBanner> {
    const isDuplicateDimension = (dimension: IDimension): boolean => {
      const canDuplicateDimension =
        (!!includes.includeDemographics &&
          dimension.field?.source === 'demographic') ||
        (!!includes.includeFabrications &&
          dimension.field?.source === 'fabrication') ||
        (!!includes.includeDerivedQuestions &&
          dimension.field?.source === 'derived') ||
        (!!includes.includeSegmentsPanels &&
          ((dimension.field?.source === 'answer' &&
            dimension.field?.field === 'targeted_brand_cluster_id') ||
            (dimension.field?.source === 'profile' &&
              dimension.field?.field ===
                'attributed_brand_cluster_group_id'))) ||
        (!!includes.includeQuotas &&
          dimension.field?.source === 'answer' &&
          (dimension.field?.field === 'interlocked_quota_entry' ||
            (dimension.field?.field ?? '').startsWith('quota_field_'))) ||
        (!!includes.includeCrosstabQuestion &&
          dimension.field?.source === 'answer' &&
          dimension.field?.field === 'action_setting_id_cross' &&
          dimension.field?.action_id !== destActionId) ||
        (!!includes.includeExternalAttributes &&
          dimension.field?.source === 'profile' &&
          dimension.field?.field === 'brand_profile_question_id');
      return canDuplicateDimension;
    };

    let tmpSourceConfig: Array<IBanner> = JSON.parse(
      JSON.stringify(sourceConfig)
    );
    tmpSourceConfig.forEach((x) => {
      x.dimensions = x.dimensions.filter((y) => !isDuplicateDimension(y)) ?? [];
    });

    tmpSourceConfig = tmpSourceConfig.filter(
      (x) => (x.dimensions ?? []).length
    );

    let tmpDuplicateConfig: Array<IBanner> = JSON.parse(
      JSON.stringify(duplicateConfig)
    );
    tmpDuplicateConfig.forEach((x) => {
      x.dimensions = x.dimensions.filter((y) => isDuplicateDimension(y)) ?? [];
    });

    tmpDuplicateConfig = tmpDuplicateConfig.filter(
      (x) => (x.dimensions ?? []).length
    );

    if (
      isColumns &&
      tmpSourceConfig.length > 0 &&
      tmpDuplicateConfig.length > 0
    ) {
      const sourceFirstBanner = tmpSourceConfig[0];
      const sourceFirstBannerDimensions = <IDimension[]>(
        JSON.parse(JSON.stringify(sourceFirstBanner.dimensions))
      );
      const duplicateFirstBanner = tmpDuplicateConfig[0];

      const newBannerDimDepth =
        sourceFirstBanner.dimensions.length +
        duplicateFirstBanner.dimensions.length;

      if (newBannerDimDepth <= 4) {
        duplicateFirstBanner.dimensions.forEach((dim) => {
          sourceFirstBanner.dimensions.push(dim);
        });
        tmpDuplicateConfig.splice(0, 1);
      }

      if (tmpDuplicateConfig.length && sourceFirstBannerDimensions.length) {
        tmpDuplicateConfig.forEach((banner) => {
          const newDimLen =
            sourceFirstBannerDimensions.length + banner.dimensions.length;
          if (newDimLen <= 4) {
            banner.dimensions.unshift(...sourceFirstBannerDimensions);
          }
        });
      }
    }

    const result = [...tmpSourceConfig, ...tmpDuplicateConfig];
    result.forEach((item: IBanner, ix: number) => {
      item.identifier = `${ix}`;
    });
    return result;
  }

  public getLabelForPrimaryResponse(action?: IAction): string {
    if (!action) {
      return 'builder.fieldAnswer';
    }
    const { action_kind } = action;
    switch (action_kind) {
      case ActionKind.multiplechoice:
        if (
          this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_rating
        )
          return 'builder.fieldAnswerRating';
        return 'builder.fieldAnswerMC';
      case ActionKind.gridrankscale:
      case ActionKind.gridcustom:
      case ActionKind.grid:
      case ActionKind.maxdiff:
        return 'builder.fieldAnswerRankScale';
      case ActionKind.openended:
        return 'builder.fieldAnswerOE';
      case ActionKind.turf:
        return 'builder.fieldAnswerMC';
      default:
        return 'builder.fieldAnswer';
    }
  }

  public getLabelForPrimarySelection(action?: IAction): string {
    if (!action) {
      return 'builder.fieldAnswerOption';
    }
    const { action_kind, action_kind_variant } = action;
    switch (action_kind) {
      case ActionKind.gridrankscale:
        if (action_kind_variant === ActionKindVariant.grid_rank) {
          return 'builder.fieldAnswerOptionRank';
        }
        if (action_kind_variant === ActionKindVariant.grid_scale) {
          return 'builder.fieldAnswerOptionScale';
        }
        return 'builder.fieldAnswerOption';
      case ActionKind.gridcustom:
        return 'builder.fieldAnswerOptionCG';
      case ActionKind.grid:
        if (this.getGridKind(action) === ActionKindVariant.grid_rank) {
          return 'builder.fieldAnswerOptionRank';
        }
        if (this.getGridKind(action) === ActionKindVariant.grid_scale) {
          return 'builder.fieldAnswerOptionScale';
        }
        if (this.getGridKind(action) === ActionKindVariant.grid_open) {
          return 'builder.fieldAnswerOptionCG';
        }
        return 'builder.fieldAnswerOption';
      case ActionKind.maxdiff:
        return 'builder.fieldAnswerOptionMD';
      default:
        return 'builder.fieldAnswerOption';
    }
  }

  public formatDimensionLabels(
    config: ICrosstabConfig,
    action?: IAction
  ): void {
    const updateDimensionLabel = (banner: IBanner) => {
      banner.dimensions.forEach((dim) => {
        if (
          dim.type === 'field' &&
          dim.field?.field === 'action_setting_id_root' &&
          dim.field?.source === 'answer'
        ) {
          const label = this.getLabelForPrimaryResponse(action);
          dim.label = this.translate.instant(label);
        } else if (
          dim.type === 'field' &&
          dim.field?.field === 'action_setting_id_secondary_root' &&
          dim.field?.source === 'answer'
        ) {
          const label = this.getLabelForPrimarySelection(action);
          dim.label = this.translate.instant(label);
        }
      });
      if (banner.name === 'Two') {
        banner.name = banner.dimensions
          .map((dim: IDimension) => {
            return dim.label ?? '';
          })
          .join(', ');
      }
    };
    config.rows.forEach((banner) => {
      updateDimensionLabel(banner);
    });
    config.columns.forEach((banner) => {
      updateDimensionLabel(banner);
    });
  }

  public canFilterCompletes(mission?: IMission, action?: IAction): boolean {
    if (!mission || !action) {
      return false;
    }

    const { mission_kind } = mission;
    if (
      mission_kind === MissionKind.standard ||
      mission_kind === MissionKind.external_cint ||
      mission_kind === MissionKind.external_link
    ) {
      return false;
    }

    return true;
  }

  public crosstabConfigHasAdvancedFilters(
    config: ICrosstabConfig
  ): IAdvancedFilters {
    const status: IAdvancedFilters = {};

    const partialResponses = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.partialResponses,
      EDataFilterUIAreas.customize
    );
    status.completes = {
      included: partialResponses.item?.enabled
        ? !(partialResponses.item.bool_1 ?? true)
        : false,
    };

    const respondentsFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.excludeUsers,
      EDataFilterUIAreas.customize
    );
    const respondentsList = (respondentsFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    const isRespondentsListExclude =
      respondentsFilter.item?.as_exclude ?? false;
    status.respondents = {
      included: respondentsList.length > 0,
      count: respondentsList.length,
      isExclude: isRespondentsListExclude,
    };

    const genderFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.gender,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedGenders = (genderFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.gender = {
      included: excludedGenders.length > 0,
      count: excludedGenders.length,
    };

    const ethnicityFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.ethnicity,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedEthnicities = (ethnicityFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.ethnicity = {
      included: excludedEthnicities.length > 0,
      count: excludedEthnicities.length,
    };

    const relationshipFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.relationship,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedRelationships = (
      relationshipFilter.item?.list_1 ?? []
    ).filter((x) => (x ?? '').trim().length > 0);
    status.relationship = {
      included: excludedRelationships.length > 0,
      count: excludedRelationships.length,
    };

    const stateFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.state,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedStates = (stateFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.state = {
      included: excludedStates.length > 0,
      count: excludedStates.length,
    };

    const ageGroupFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.age_group,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedAgeGroups = (ageGroupFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.age_group = {
      included: excludedAgeGroups.length > 0,
      count: excludedAgeGroups.length,
    };

    const educationFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.education,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedEducations = (educationFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.education = {
      included: excludedEducations.length > 0,
      count: excludedEducations.length,
    };

    const employmentFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.employment,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedEmployments = (employmentFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.employment = {
      included: excludedEmployments.length > 0,
      count: excludedEmployments.length,
    };

    const householdFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.household,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedHouseholds = (householdFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.household = {
      included: excludedHouseholds.length > 0,
      count: excludedHouseholds.length,
    };

    const incomeLevelFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.income_level,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedIncomeLevels = (incomeLevelFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.income_level = {
      included: excludedIncomeLevels.length > 0,
      count: excludedIncomeLevels.length,
    };

    const parentingFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.parenting,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedParentings = (parentingFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.parenting = {
      included: excludedParentings.length > 0,
      count: excludedParentings.length,
    };

    const regionFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.region,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedRegions = (regionFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    status.region = {
      included: excludedRegions.length > 0,
      count: excludedRegions.length,
    };

    const customAgeGroupFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.custom_age_group,
      EDataFilterUIAreas.demographicFilters
    );
    const excludedCustomAgeGroups = (
      customAgeGroupFilter.item?.list_1 ?? []
    ).filter((x) => (x ?? '').trim().length > 0);
    status.custom_age_group = {
      included: excludedCustomAgeGroups.length > 0,
      count: excludedCustomAgeGroups.length,
    };

    const segmentFilters = config.data_filter.items?.filter(
      (x) => x.name === EDataFilterNames.segmentTarget && x.enabled
    );

    const segmentFiltersCount =
      segmentFilters
        ?.map((x) => x.list_1?.length ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0;

    const panelFilters = config.data_filter.items?.filter(
      (x) => x.name === EDataFilterNames.panelTarget && x.enabled
    );

    const panelFiltersCount =
      panelFilters
        ?.map((x) => x.list_1?.length ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0;

    const userAttributionFilters = config.data_filter.items?.filter(
      (x) => x.name === EDataFilterNames.userAttribution && x.enabled
    );

    const userAttributionFilterCount =
      userAttributionFilters
        ?.map((x) => x.list_1?.length ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0;

    status.segmentsPanels = {
      included:
        panelFiltersCount > 0 ||
        segmentFiltersCount > 0 ||
        userAttributionFilterCount > 0,
      count:
        panelFiltersCount + segmentFiltersCount + userAttributionFilterCount,
    };

    const profilingFilters = config.data_filter.items?.filter(
      (x) => x.name === EDataFilterNames.profilingQuestion && x.enabled
    );

    const profilingFiltersCount =
      profilingFilters
        ?.map((x) => x.list_1?.length ?? 0)
        .reduce((a, b) => a + b, 0) ?? 0;

    status.profiling = {
      included: profilingFiltersCount > 0,
      count: profilingFiltersCount,
    };

    if (status.profiling.included) {
      status.profiling.attributes = profilingFilters?.map((x) => {
        return { id: x.ui_area ?? '', name: '', count: x.list_1?.length ?? 0 };
      });
    }

    const trimmedResponsesFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.includeTrimmed,
      EDataFilterUIAreas.customize
    );
    status.trimmed_responses = {
      included: trimmedResponsesFilter.item?.enabled
        ? trimmedResponsesFilter.item.bool_1 ?? false
        : false,
    };

    const cleanedResponsesFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.includeCleanedResponses,
      EDataFilterUIAreas.customize
    );
    status.cleaned_responses = {
      included: cleanedResponsesFilter.item?.enabled
        ? cleanedResponsesFilter.item.bool_1 ?? false
        : false,
    };

    return status;
  }

  public getAdvancedFiltersTags(
    filter: IAdvancedFilters,
    global?: boolean
  ): IAdvancedFiltersTag[] {
    const fields = Object.keys(filter);
    if (!fields?.length) {
      return [];
    }

    const filters: IAdvancedFiltersTag[] = [];
    fields.forEach((field) => {
      switch (field) {
        case 'completes':
          if (filter[field]?.included) {
            filters.push({
              value: 'builder.settings_completes_only',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'respondents':
          if (filter[field]?.included) {
            if (filter[field]?.isExclude === true) {
              filters.push({
                value: this.translate.instant(
                  `filters.respondentsExcludedCount`,
                  {
                    count: filter[field]?.count ?? 1,
                  }
                ),
                kind: EAdvancedFiltersTagKind.raw,
              });
            } else if (filter[field]?.isExclude === false) {
              filters.push({
                value: this.translate.instant(
                  `filters.respondentsIncludedCount`,
                  {
                    count: filter[field]?.count ?? 1,
                  }
                ),
                kind: EAdvancedFiltersTagKind.raw,
              });
            } else {
              filters.push({
                value: this.translate.instant(`filters.respondentsCount`, {
                  count: filter[field]?.count ?? 1,
                }),
                kind: EAdvancedFiltersTagKind.raw,
              });
            }
          }
          break;
        case 'gender':
        case 'ethnicity':
        case 'relationship':
        case 'state':
        case 'age_group':
        case 'education':
        case 'employment':
        case 'household':
        case 'income_level':
        case 'parenting':
        case 'region':
        case 'custom_age_group':
          if (filter[field]?.included) {
            filters.push({
              value: this.translate.instant(`filters.${field}Count`, {
                count: filter[field]?.count ?? 1,
              }),
              kind: EAdvancedFiltersTagKind.raw,
            });
          }
          break;
        case 'segmentsPanels':
          if (filter[field]?.included) {
            filters.push({
              value: this.translate.instant(`filters.segmentsPanelsCount`, {
                count: filter[field]?.count ?? 1,
              }),
              kind: EAdvancedFiltersTagKind.raw,
            });
          }
          break;
        case 'profiling':
          if (filter[field]?.included) {
            if (filter[field]?.attributes?.length) {
              (filter[field]?.attributes ?? []).forEach((attribute) => {
                filters.push({
                  value: '',
                  kind: EAdvancedFiltersTagKind.async,
                  asyncParams: {
                    key: global ? 'profilingQuestion' : 'externalAttribute',
                    value: attribute.id,
                    count: attribute.count,
                  },
                });
              });
            } else {
              filters.push({
                value: this.translate.instant(
                  global
                    ? 'filters.profilingCount'
                    : 'filters.externalAttributeCount',
                  {
                    count: filter[field]?.count ?? 1,
                  }
                ),
                kind: EAdvancedFiltersTagKind.raw,
              });
            }
          }
          break;
        case 'trimmed_responses':
          if (filter[field]?.included) {
            filters.push({
              value: 'builder.settings_include_trimmed',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'cleaned_responses':
          if (filter[field]?.included) {
            filters.push({
              value: 'builder.settings_include_cleaned_respondents',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        default:
          break;
      }
    });

    return filters;
  }

  public getDemographicsFilterFromConfig(
    config: ICrosstabConfig
  ): DemographicsFilterUI {
    const filterConfig = JSON.parse(JSON.stringify(DemographicsFiltersUIBase));
    const { data_filter } = config;
    if (!data_filter) {
      return filterConfig;
    }

    const genderFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.gender,
      EDataFilterUIAreas.demographicFilters
    );
    const genderFilterList = (genderFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (genderFilterList.length) {
      filterConfig.gender.enabled = true;
      filterConfig.gender.includedIds = genderFilterList;
    }

    const ethnicityFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.ethnicity,
      EDataFilterUIAreas.demographicFilters
    );
    const ethnicityFilterList = (ethnicityFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (ethnicityFilterList.length) {
      filterConfig.ethnicity.enabled = true;
      filterConfig.ethnicity.includedIds = ethnicityFilterList;
    }

    const relationshipFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.relationship,
      EDataFilterUIAreas.demographicFilters
    );
    const relationshipFilterList = (
      relationshipFilter.item?.list_1 ?? []
    ).filter((x) => (x ?? '').trim().length > 0);
    if (relationshipFilterList.length) {
      filterConfig.relationship.enabled = true;
      filterConfig.relationship.includedIds = relationshipFilterList;
    }

    const stateFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.state,
      EDataFilterUIAreas.demographicFilters
    );
    const stateFilterList = (stateFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (stateFilterList.length) {
      filterConfig.state.enabled = true;
      filterConfig.state.includedIds = stateFilterList;
    }

    const ageGroupFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.age_group,
      EDataFilterUIAreas.demographicFilters
    );
    const ageGroupFilterList = (ageGroupFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (ageGroupFilterList.length) {
      filterConfig.age_group.enabled = true;
      filterConfig.age_group.includedIds = ageGroupFilterList;
    }

    const educationFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.education,
      EDataFilterUIAreas.demographicFilters
    );
    const educationFilterList = (educationFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (educationFilterList.length) {
      filterConfig.education.enabled = true;
      filterConfig.education.includedIds = educationFilterList;
    }

    const employmentFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.employment,
      EDataFilterUIAreas.demographicFilters
    );
    const employmentFilterList = (employmentFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (employmentFilterList.length) {
      filterConfig.employment.enabled = true;
      filterConfig.employment.includedIds = employmentFilterList;
    }

    const householdFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.household,
      EDataFilterUIAreas.demographicFilters
    );
    const householdFilterList = (householdFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (householdFilterList.length) {
      filterConfig.household.enabled = true;
      filterConfig.household.includedIds = householdFilterList;
    }

    const incomeLevelFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.income_level,
      EDataFilterUIAreas.demographicFilters
    );
    const incomeLevelFilterList = (incomeLevelFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (incomeLevelFilterList.length) {
      filterConfig.income_level.enabled = true;
      filterConfig.income_level.includedIds = incomeLevelFilterList;
    }

    const parentingFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.parenting,
      EDataFilterUIAreas.demographicFilters
    );
    const parentingFilterList = (parentingFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (parentingFilterList.length) {
      filterConfig.parenting.enabled = true;
      filterConfig.parenting.includedIds = parentingFilterList;
    }

    const regionFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.region,
      EDataFilterUIAreas.demographicFilters
    );
    const regionFilterList = (regionFilter.item?.list_1 ?? []).filter(
      (x) => (x ?? '').trim().length > 0
    );
    if (regionFilterList.length) {
      filterConfig.region.enabled = true;
      filterConfig.region.includedIds = regionFilterList;
    }

    const customAgeGroupFilter = DataUtility.findFilterItem(
      config.data_filter,
      EDataFilterNames.custom_age_group,
      EDataFilterUIAreas.demographicFilters
    );
    const customAgeGroupFilterList = (
      customAgeGroupFilter.item?.list_1 ?? []
    ).filter((x) => (x ?? '').trim().length > 0);
    if (customAgeGroupFilterList.length) {
      filterConfig.custom_age_group.enabled = true;
      filterConfig.custom_age_group.includedIds = customAgeGroupFilterList;
    }

    return filterConfig;
  }

  public getClusterGroupsFilterFromConfig(
    config: ICrosstabConfig
  ): Observable<IClusterGroupFilterUI> {
    const segmentsGet$ = this.suzyDataService.audienceSegmentsGet(
      config.brand_id
    );
    const panelsGet$ = this.suzyDataService.audiencePanelsGet(config.brand_id);
    const segmentPanelsFilterData: IClusterGroupFilterUI = {};

    return forkJoin([segmentsGet$, panelsGet$]).pipe(
      map(([segments, panels]) => {
        if (segments?.length) {
          segments.forEach((item) => {
            const existingFilter = config.data_filter.items?.find(
              (x) =>
                (x.name === EDataFilterNames.segmentTarget ||
                  x.name === EDataFilterNames.userAttribution) &&
                x.ui_area === item.id
            );
            segmentPanelsFilterData[item.id] = {
              name: item.name,
              enabled: existingFilter !== undefined,
              includedIds: existingFilter?.list_1 ?? [],
              list: [],
            };
          });
        }

        if (panels?.length) {
          panels.forEach((item) => {
            const existingFilter = config.data_filter.items?.find(
              (x) =>
                (x.name === EDataFilterNames.panelTarget ||
                  x.name === EDataFilterNames.userAttribution) &&
                x.ui_area === item.id
            );
            segmentPanelsFilterData[item.id] = {
              name: item.name,
              enabled: existingFilter !== undefined,
              includedIds: existingFilter?.list_1 ?? [],
              list: [],
            };
          });
        }

        return segmentPanelsFilterData;
      })
    );
  }

  public getProfilingFilterFromConfig(
    config: ICrosstabConfig,
    brand_id?: string,
    mission_id?: string,
    global?: boolean
  ): Observable<IClusterGroupFilterUI> {
    const profilingQuestionsGet$ = global
      ? this.suzyDataService.getBrandProfileQuestions(
          brand_id ?? '',
          mission_id ?? ''
        )
      : this.suzyDataService.getPublicLinkAttributes(
          brand_id ?? '',
          mission_id ?? ''
        );
    const profilingQuestionsFilterData: IClusterGroupFilterUI = {};
    if (global) {
      profilingQuestionsFilterData['gender'] = {
        name: this.translate.instant('filters.gender'),
        enabled: false,
        includedIds: [],
        list: [],
      };
      profilingQuestionsFilterData['age_group'] = {
        name: this.translate.instant('filters.age_group'),
        enabled: false,
        includedIds: [],
        list: [],
      };

      const genderFilter = DataUtility.findFilterItem(
        config.data_filter,
        EDataFilterNames.gender,
        EDataFilterUIAreas.demographicFilters
      );
      const genderFilterList = (genderFilter.item?.list_1 ?? []).filter(
        (x) => (x ?? '').trim().length > 0
      );
      if (genderFilterList.length) {
        profilingQuestionsFilterData['gender'].enabled = true;
        profilingQuestionsFilterData['gender'].includedIds = genderFilterList;
      }

      const ageGroupFilter = DataUtility.findFilterItem(
        config.data_filter,
        EDataFilterNames.age_group,
        EDataFilterUIAreas.demographicFilters
      );
      const ageGroupFilterList = (ageGroupFilter.item?.list_1 ?? []).filter(
        (x) => (x ?? '').trim().length > 0
      );
      if (ageGroupFilterList.length) {
        profilingQuestionsFilterData['age_group'].enabled = true;
        profilingQuestionsFilterData['age_group'].includedIds =
          ageGroupFilterList;
      }
    }

    return profilingQuestionsGet$.pipe(
      map((items) => {
        if (items?.length) {
          items.forEach((item) => {
            const existingFilter = config.data_filter.items?.find(
              (x) =>
                x.name === EDataFilterNames.profilingQuestion &&
                x.ui_area === item.id
            );
            profilingQuestionsFilterData[item.id] = {
              name: item.name,
              enabled: existingFilter !== undefined,
              includedIds: existingFilter?.list_1 ?? [],
              list: [],
            };
          });
        }

        return profilingQuestionsFilterData;
      })
    );
  }

  public crosstabConfigHasCalculations(
    advancedCalculationsEnabled: boolean,
    config: ICrosstabConfig
  ): ICalculations {
    const result: ICalculations = {};

    const hasStatTesting =
      (config.additions ?? []).findIndex(
        (x) => x.name === 'stat_test_column'
      ) >= 0;

    if (hasStatTesting) {
      result.statTesting = {
        included: true,
      };
    }

    const hasTopN =
      (config.expansions ?? []).findIndex((x) => x.name === 'scale_rank.top') >=
      0;
    if (hasTopN) {
      const topN = (config.expansions ?? []).find(
        (x) => x.name === 'scale_rank.top'
      );
      result.topN = {
        included: true,
        param: topN?.param_1,
      };
    }

    const hasBottomN =
      (config.expansions ?? []).findIndex(
        (x) => x.name === 'scale_rank.bottom'
      ) >= 0;
    if (hasBottomN) {
      const bottomN = (config.expansions ?? []).find(
        (x) => x.name === 'scale_rank.bottom'
      );
      result.bottomN = {
        included: true,
        param: bottomN?.param_1,
      };
    }

    const hasMeans =
      (config.expansions ?? []).findIndex((x) => x.name === 'means') >= 0;

    if (hasMeans) {
      result.means = {
        included: true,
      };
    }

    if (advancedCalculationsEnabled) {
      const hasResponsesTotalColumn =
        (config.expansions ?? []).findIndex(
          (x) => x.name === 'primary_total_responses_column'
        ) >= 0;
      if (hasResponsesTotalColumn) {
        result.responsesTotalColumn = {
          included: true,
        };
      }

      const hasBaseTotalColumn =
        (config.expansions ?? []).findIndex(
          (x) => x.name === 'primary_base_size_column'
        ) >= 0;
      if (hasBaseTotalColumn) {
        result.baseTotalColumn = {
          included: true,
        };
      }

      const hasResponsesTotalRow =
        (config.expansions ?? []).findIndex(
          (x) => x.name === 'span_total_responses_row'
        ) >= 0;
      if (hasResponsesTotalRow) {
        result.responsesTotalRow = {
          included: true,
        };
      }

      const hasBaseTotalRow =
        (config.expansions ?? []).findIndex(
          (x) => x.name === 'span_base_size_row'
        ) >= 0;
      if (hasBaseTotalRow) {
        result.baseTotalRow = {
          included: true,
        };
      }

      const spamResponses = DataUtility.findFilterItem(
        config.data_filter,
        EDataFilterNames.includeSpam,
        EDataFilterUIAreas.customize
      ).item;
      const hasSpamResponses = spamResponses?.enabled && spamResponses.bool_1;

      if (hasSpamResponses) {
        result.spamResponses = {
          included: true,
        };
      }
    }

    return result;
  }

  public getCalculationsTags(filter: ICalculations): IAdvancedFiltersTag[] {
    const fields = Object.keys(filter);
    if (!fields?.length) {
      return [];
    }

    const filters: IAdvancedFiltersTag[] = [];
    fields.forEach((field) => {
      switch (field) {
        case 'statTesting':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.statTesting',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'means':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.means',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'topN':
          if (filter[field]?.included) {
            filters.push({
              value: this.translate.instant(`calculations.topN`, {
                param: filter[field]?.param ?? '',
              }),
              kind: EAdvancedFiltersTagKind.raw,
            });
          }
          break;
        case 'bottomN':
          if (filter[field]?.included) {
            filters.push({
              value: this.translate.instant(`calculations.bottomN`, {
                param: filter[field]?.param ?? '',
              }),
              kind: EAdvancedFiltersTagKind.raw,
            });
          }
          break;
        case 'responsesTotalColumn':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.responsesTotalColumnTag',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'baseTotalColumn':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.baseTotalColumnTag',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'responsesTotalRow':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.responsesTotalRowTag',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'baseTotalRow':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.baseTotalRowTag',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        case 'spamResponses':
          if (filter[field]?.included) {
            filters.push({
              value: 'calculations.spamResponsesTag',
              kind: EAdvancedFiltersTagKind.token,
            });
          }
          break;
        default:
          break;
      }
    });

    return filters;
  }

  public isStatTestingDisabledForCrosstabConfig(
    statTestingEnabled: boolean,
    config?: ICrosstabConfig,
    action?: IAction
  ): { disabled: boolean; tooltip?: string } {
    if (!config || !action) {
      return { disabled: true };
    }

    if (!this.canCustomizeStatTesting(statTestingEnabled, action)) {
      return { disabled: true };
    }

    const { action_kind } = action;
    const { columns, rows, expansions } = config;

    if (
      (action_kind === ActionKind.multiplechoice &&
        this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_standard) ||
      action_kind === ActionKind.turf
    ) {
      if (!columns?.length || !rows?.length) {
        return { disabled: true, tooltip: 'builder.statTestWarningMC' };
      }
      return { disabled: false };
    }

    if (
      action_kind === ActionKind.gridrankscale ||
      (action_kind === ActionKind.grid &&
        this.getGridKind(action) === ActionKindVariant.grid_rank) ||
      (action_kind === ActionKind.grid &&
        this.getGridKind(action) === ActionKindVariant.grid_scale) ||
      (action_kind === ActionKind.multiplechoice &&
        this.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_rating)
    ) {
      if (!columns?.length || !rows?.length) {
        return { disabled: true, tooltip: 'builder.statTestWarningRating' };
      }

      const hasSummaryMetrics = (expansions ?? []).some(
        (x) =>
          x.name === 'scale_rank.bottom' ||
          x.name === 'scale_rank.top' ||
          x.name === 'means'
      );
      if (!hasSummaryMetrics) {
        return { disabled: true, tooltip: 'builder.statTestWarningRating' };
      }
      return { disabled: false };
    }

    return { disabled: false };
  }

  public getMultipleChoiceKind(
    action: IAction
  ):
    | ActionKindVariant.multiplechoice_standard
    | ActionKindVariant.multiplechoice_rating
    | undefined {
    if (
      action.multiple_choice?.multiple_choice_kind ===
      ActionStructureMultipleChoiceKind.standard
    ) {
      return ActionKindVariant.multiplechoice_standard;
    }

    if (
      action.multiple_choice?.multiple_choice_kind ===
      ActionStructureMultipleChoiceKind.rating
    ) {
      return ActionKindVariant.multiplechoice_rating;
    }

    return;
  }

  public getGridKind(
    action: IAction
  ):
    | ActionKindVariant.grid_rank
    | ActionKindVariant.grid_scale
    | ActionKindVariant.grid_open
    | undefined {
    if (action.grid?.grid_kind === ActionStructureGridKind.rank) {
      return ActionKindVariant.grid_rank;
    }

    if (action.grid?.grid_kind === ActionStructureGridKind.scale) {
      return ActionKindVariant.grid_scale;
    }

    if (action.grid?.grid_kind === ActionStructureGridKind.open) {
      return ActionKindVariant.grid_open;
    }

    return;
  }

  public getDefaultCalculationsExpansions(): IExpansion[] {
    const expansions = <IExpansion[]>[];
    expansions.push(<IExpansion>{
      label: 'T2',
      name: 'scale_rank.top',
      param_1: '2',
      start: false,
    });
    expansions.push(<IExpansion>{
      label: 'B2',
      name: 'scale_rank.bottom',
      param_1: '2',
      start: false,
    });
    return expansions;
  }

  getFabricationsFromDimension(columns, rows): ITableViewFabrication[] {
    const tableViewFabrications: any = [];

    rows.forEach((row: any) => {
      const fabs = row.dimensions.filter(
        (dim: any) => dim.field.source === 'fabrication'
      );
      tableViewFabrications.push(...fabs);
    });

    columns.forEach((col: any) => {
      delete col.show_sub_totals;
      const fabs = col.dimensions.filter(
        (dim: any) => dim.field.source === 'fabrication'
      );
      tableViewFabrications.push(...fabs);
    });

    const mappedFabs = tableViewFabrications.map((item: any) => {
      const newItem = {
        fabrication_name: item.label,
        fabrication_id: item.field.parent_id,
        fabrication_type: item.fabricationType,
      };

      return { ...newItem };
    });

    return mappedFabs;
  }
}
