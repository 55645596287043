/* eslint-disable @typescript-eslint/no-explicit-any */
import { PercentPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3-scale-chromatic';
import {
  CalloutPlacementPositions,
  IgxCalloutPlacementPositionsCollection,
} from 'igniteui-angular-charts';
import { DataUtility } from '../../../utilities/data-utility';
const colorScale = d3.interpolateRdYlBu;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: false,
};

interface GridColItem {
  field: string;
  title: string;
  columns?: GridColItem[];
  source?: string;
}
interface GridRowItem {
  k: string;
  b: string;
  s?: string;
  name: string;
  bannerId?: string;
  bannerLabel?: string;
}

@Component({
  selector: 'data-explorer-elements-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements OnInit {
  private _gridData?: any;
  get gridData(): any {
    return this.isFlipped ? this.gridDataFlipped : this._gridData;
  }
  @Input() set gridData(data: any) {
    this.adjustGridDataForChart(data);
  }
  @Input() question = '';
  @Input() isPercent = false;
  @Input() isSingleSelect = true;
  @Input() canFlip = false;

  isFlipped!: boolean;
  gridDataFlipped!: any;
  chartData: any = [];
  chartLabels: any = [];
  cols: Array<{
    title: string;
    field: string;
    legendData?: Array<{ label: string; value: string }>;
    brushColor?: string;
  }> = [];
  chartHeight!: string;
  yAxisCategoryGap = 1.5;
  yAxisBarGapRatio = -0.5;
  colors!: string[];
  calloutPositions = new IgxCalloutPlacementPositionsCollection([
    CalloutPlacementPositions.TopLeft,
    CalloutPlacementPositions.BottomLeft,
    CalloutPlacementPositions.Top,
    CalloutPlacementPositions.Bottom,
    CalloutPlacementPositions.TopRight,
    CalloutPlacementPositions.BottomLeft,
  ]);
  intervalValue!: number;
  maximumValue!: number;

  constructor(private percentPipe: PercentPipe) {}

  ngOnInit(): void {
    if (this.canFlip) {
      this.prepareFlippedData(this.gridData);
      this.isFlipped = true;
    }
    this.buildStackedChart(this.gridData);
  }

  buildStackedChart(data: any): void {
    this.chartData = [];
    this.cols = [];
    const rowLengths = (this.gridData.rows ?? []).map(
      (row: any[]) => row.length
    );
    const rowsDepth = rowLengths?.length ? Math.max(...rowLengths) : 1;

    if (this.gridData.depth === 1) {
      data.columns.forEach((col: any, i: any) => {
        if (i > 0) {
          col.columns.forEach((colItem) => {
            if (!this.isTotalColumn(colItem)) {
              this.cols.push({
                title: colItem.title,
                field: colItem.field,
                legendData: [{ label: col.title, value: colItem.title }],
              });
            }
          });
        }
      });
    } else if (this.gridData.depth > 1) {
      this.buildNestedColumns(this.gridData.depth);
    }

    if (this.isPercent) {
      data.data.forEach((datum: any) => {
        this.cols
          .filter((x) => x.field.startsWith('c'))
          .forEach((col) => {
            datum[col.field] = datum[col.field] * 100;
          });
      });
      this.intervalValue = 10;
      this.maximumValue = 100;
    }

    // Set up Chart Labels
    const valuesArr: any = [];
    const xForSingleSelect: any = [];
    this.colors = this.interpolateColors(
      this.cols.length > 4 ? this.cols.length : 4
    );
    if (this.isSingleSelect) {
      for (let i = 0; i < data.data.length; i++) {
        valuesArr.push([]);
        xForSingleSelect.push([]);
      }

      data.data?.forEach((datum, i: number) => {
        this.cols.forEach((col) => {
          valuesArr[i].push(Math.trunc(datum[col.field]));
        });
      });

      valuesArr.forEach((val, i: number) => {
        let sum = 0;
        valuesArr[i].forEach((x) => {
          sum += x;
        });
        val.forEach((v) => {
          xForSingleSelect[i].push((v / sum) * 100);
        });
      });
    }

    data.data?.forEach((datum: any, i) => {
      let rowTitle = datum.rbd_1;
      const tmpTitles: string[] = [];
      tmpTitles.push(datum.rbd_1 ?? '');
      if (rowsDepth >= 2) {
        tmpTitles.push(datum.rbd_2 ?? '');
      }
      if (rowsDepth >= 3) {
        tmpTitles.push(datum.rbd_3 ?? '');
      }
      if (rowsDepth > 3) {
        tmpTitles.push(datum.rbd_4 ?? '');
      }
      rowTitle = tmpTitles.filter((x) => x.trim().length > 0).join(', ');
      this.chartData.push({
        name: rowTitle,
      });

      let count = 0;
      this.cols.forEach((col, j) => {
        if (i === 0) {
          col.brushColor = this.colors[j];
        }
        let x = 0;
        if (this.isSingleSelect) {
          x = xForSingleSelect[i][j] + count;
        } else {
          x = Math.trunc(datum[col.field]) + count;
          x = Math.trunc(datum[col.field]) < 10 ? x : x - 10;
        }
        if (
          (!this.isSingleSelect && Math.trunc(datum[col.field]) > 0) ||
          (this.isSingleSelect && xForSingleSelect[i][j] > 0)
        ) {
          this.chartLabels.push({
            name: col.title,
            legendData: col.legendData,
            brushColor: this.colors[j],
            x,
            y: `${i}`,
            label: this.isPercent
              ? `${this.percentPipe.transform(datum[col.field] / 100)}`
              : `${datum[col.field]}`,
          });
          if (this.isSingleSelect) {
            count += xForSingleSelect[i][j];
          } else {
            count += Math.trunc(datum[col.field]);
          }
        }
      });
    });

    this.chartHeight = `${this.calculateChartHeight()}px`;
    if (this.isSingleSelect) {
      this.intervalValue = 10;
      this.maximumValue = 100;
    } else {
      this.maximumValue = 0;
      this.intervalValue = 0;
      this.gridData.data.forEach((datum) => {
        let rowTotal = 0;
        this.cols.forEach((col) => {
          rowTotal += datum[col.field];
        });
        if (rowTotal > this.maximumValue) {
          this.maximumValue = rowTotal;
        }
      });
      this.calculateInterval();
    }
  }

  buildNestedColumns(depth: number): any {
    let title = '';

    if (depth === 2) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (!this.isTotalColumn(col3)) {
                  const colTitles = [
                    (col2.title ?? '').trim(),
                    (col3.title ?? '').trim(),
                  ].filter((x) => x !== '');
                  this.cols.push({
                    title: `${title} : ${colTitles.join(', ')}`,
                    field: col3.field,
                    legendData: [
                      { label: titles[0] ?? '', value: colTitles[0] ?? '' },
                      { label: titles[1] ?? '', value: colTitles[1] ?? '' },
                    ],
                  });
                }
              });
            }
          });
        }
      });
    }

    if (depth === 3) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (
                  col3.columns &&
                  col3.columns.length > 0 &&
                  !this.isTotalColumn(col3)
                ) {
                  col3.columns?.forEach((col4: any) => {
                    if (!this.isTotalColumn(col4)) {
                      const colTitles = [
                        (col2.title ?? '').trim(),
                        (col3.title ?? '').trim(),
                        (col4.title ?? '').trim(),
                      ].filter((x) => x !== '');
                      this.cols.push({
                        title: `${title} : ${colTitles.join(', ')}`,
                        field: col4.field,
                        legendData: [
                          { label: titles[0] ?? '', value: colTitles[0] ?? '' },
                          { label: titles[1] ?? '', value: colTitles[1] ?? '' },
                          { label: titles[2] ?? '', value: colTitles[2] ?? '' },
                        ],
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

    if (depth === 4) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (
                  col3.columns &&
                  col3.columns.length > 0 &&
                  !this.isTotalColumn(col3)
                ) {
                  col3.columns?.forEach((col4: any) => {
                    if (
                      col4.columns &&
                      col4.columns.length > 0 &&
                      !this.isTotalColumn(col4)
                    ) {
                      col4.columns?.forEach((col5: any) => {
                        if (!this.isTotalColumn(col5)) {
                          const colTitles = [
                            (col2.title ?? '').trim(),
                            (col3.title ?? '').trim(),
                            (col4.title ?? '').trim(),
                            (col5.title ?? '').trim(),
                          ].filter((x) => x !== '');
                          this.cols.push({
                            title: `${title} : ${colTitles.join(', ')}`,
                            field: col5.field,
                            legendData: [
                              {
                                label: titles[0] ?? '',
                                value: colTitles[0] ?? '',
                              },
                              {
                                label: titles[1] ?? '',
                                value: colTitles[1] ?? '',
                              },
                              {
                                label: titles[2] ?? '',
                                value: colTitles[2] ?? '',
                              },
                              {
                                label: titles[3] ?? '',
                                value: colTitles[3] ?? '',
                              },
                            ],
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  private adjustGridDataForChart(gridData: any): void {
    const { cellTemplate } = gridData;
    if (cellTemplate) {
      delete gridData.cellTemplate;
    }
    const tmpData = JSON.parse(JSON.stringify(gridData));
    const rowsToRemove = this.getBannerRowIdsToRemove(tmpData);
    rowsToRemove.forEach((id) => {
      const ix = tmpData.data.findIndex((datum) => datum.rbd_1__id === id);
      if (ix >= 0) {
        tmpData.data.splice(ix, 1);
      }
    });

    if (cellTemplate) {
      gridData.cellTemplate = cellTemplate;
    }
    this.removeColumnComparisonData(tmpData.data);
    this._gridData = tmpData;
  }

  private removeColumnComparisonData(data: any): void {
    let statLettersDataIx = -1;
    (data ?? []).forEach((datum: any, ix: number) => {
      Object.keys(datum)
        .filter((x) => x.startsWith('c'))
        .forEach((itemKey) => {
          const itemValue = datum[itemKey];
          if (
            itemValue.comparison !== undefined &&
            itemValue.value !== undefined
          ) {
            datum[itemKey] = itemValue.value;
          } else if (
            typeof itemValue === 'string' &&
            itemValue.startsWith('key_') &&
            statLettersDataIx < 0
          ) {
            statLettersDataIx = ix;
          }
        });
    });

    if (statLettersDataIx >= 0) {
      data.splice(statLettersDataIx, 1);
    }
  }

  private isTotalColumn(col: GridColItem): boolean {
    return [
      ...DataUtility.totalsColumnDimensions,
      ...DataUtility.bannerSummaryDimensions,
    ].some((x) => (col.source ?? '') === x);
  }

  private calculateChartHeight(): number {
    const xAxisLabelHeight = 46;
    const yAxisBarHeight = 32;
    const yAxisCategoryBarCount = 1;
    const yAxisCategoryBarHeight =
      yAxisBarHeight * -1 * this.yAxisBarGapRatio + yAxisBarHeight;
    const yAxisCategoryHeight =
      yAxisCategoryBarHeight * yAxisCategoryBarCount +
      yAxisCategoryBarHeight * yAxisCategoryBarCount * this.yAxisCategoryGap;
    const yAxisCategoriesCount = this.chartData.length;
    const graphHeight =
      yAxisCategoryHeight * yAxisCategoriesCount + xAxisLabelHeight;
    return graphHeight;
  }

  private interpolateColors(dataLength: number) {
    const { colorStart, colorEnd } = colorRangeInfo;
    const colorRange = colorEnd - colorStart;
    const intervalSize = colorRange / dataLength;
    let colorPoint: number;
    const colorArray: string[] = [];

    for (let i = 0; i < dataLength; i++) {
      colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }
    this.shuffleArray(colorArray);
    return colorArray;
  }

  private calculatePoint(i: number, intervalSize: number, colorRangeInfo: any) {
    const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return useEndAsStart
      ? colorEnd - i * intervalSize
      : colorStart + i * intervalSize;
  }

  private shuffleArray(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  private prepareFlippedData(gridData: any): void {
    const { rows, columns, data } = gridData;
    const rowsToRemove = this.getBannerRowIdsToRemove(gridData);
    const rowBannerIds: string[] = [];
    rows.forEach((row: GridRowItem[]) => {
      if (
        row[0].b &&
        !rowBannerIds.some((x) => x === row[0].b) &&
        !rowsToRemove.some((x) => x === row[0].s)
      ) {
        rowBannerIds.push(row[0].b);
      }
    });

    const newColumns: GridColItem[] = [];
    const newDataKeys: string[] = [];

    rowBannerIds.forEach((bannerId: string) => {
      const sourceRows = rows.filter((row) => row[0].b === bannerId);
      const newColRoot: GridColItem = {
        field: '',
        title: '',
        columns: [],
      };
      sourceRows.forEach((row: GridRowItem[]) => {
        const rowItem = row[0];
        let parentNode = newColRoot;
        const sourcesToDiscard = [
          ...DataUtility.bannerSummaryDimensions,
          ...DataUtility.tableSummaryDimensions,
        ];
        if (sourcesToDiscard.some((x) => rowItem.s === x)) {
          return;
        }
        let rowDataKey = '';

        newColRoot.title = rowItem.bannerLabel ?? '';
        row.forEach((datum: GridRowItem, datumIx: number) => {
          const isFirst = datumIx === 0;
          const isLast = datumIx + 1 === row.length;

          if (isFirst) {
            rowDataKey = `${rowItem.k.replace('r', 'c')}`;
            const existingCol = (newColRoot.columns ?? []).find(
              (x) => x.field === datum.k.replace('r', 'c')
            );
            if (!existingCol) {
              const colRoot: GridColItem = {
                field: datum.k.replace('r', 'c'),
                title: datum.name,
                source: datum.s,
              };
              if (row.length > 1) {
                colRoot.columns = [];
              }
              newColRoot.columns?.push(colRoot);
              parentNode = colRoot;
            } else {
              parentNode = existingCol;
            }
            if (isLast) {
              newDataKeys.push(`${datum.k.replace('r', 'c')}`);
            }
          } else {
            const existingCol = (parentNode.columns ?? []).find(
              (x) => x.field === datum.k.replace('r', 'c')
            );
            if (!existingCol) {
              const newColChild: GridColItem = {
                field: isLast
                  ? `${rowDataKey},${datum.k.replace('r', 'c')}`
                  : datum.k.replace('r', 'c'),
                title: datum.name,
                source: datum.s,
              };
              if (!parentNode.columns) {
                parentNode.columns = [];
              }
              parentNode.columns?.push(newColChild);
              parentNode = newColChild;
              if (isLast) {
                newDataKeys.push(`${rowDataKey},${datum.k.replace('r', 'c')}`);
              } else {
                rowDataKey = `${rowDataKey},${datum.k.replace('r', 'c')}`;
              }
            } else {
              parentNode = existingCol;
              rowDataKey = `${rowDataKey},${datum.k.replace('r', 'c')}`;
            }
          }
        });
      });
      newColumns.push(newColRoot);
    });
    newColumns.unshift({ field: '', title: '' });

    const newRows: GridRowItem[][] = [];
    columns.forEach((col: GridColItem, colIx: number) => {
      if (!colIx) {
        return;
      }
      if (!col.columns?.length) {
        newRows.push([
          {
            k: col.field.split(',').slice(-1)[0].replace('c', 'r'),
            name: col.title,
            b: colIx.toString(),
          },
        ]);
      } else {
        const getRowsFromCol = (x: GridColItem): GridRowItem[][] => {
          if (!x.columns) {
            const leafCol = {
              k: x.field.split(',').slice(-1)[0].replace('c', 'r'),
              name: x.title,
              b: colIx.toString(),
            };
            return this.isTotalColumn(x) ? [] : [[leafCol]];
          } else {
            const newRows: GridRowItem[][] = [];
            x.columns.forEach((y) => {
              const innerRows = getRowsFromCol(y);
              innerRows.forEach((inner) => {
                const tmpCol = {
                  k: x.field.split(',').slice(-1)[0].replace('c', 'r'),
                  name: x.title,
                  b: colIx.toString(),
                };
                inner.unshift(tmpCol);
                newRows.push(inner);
              });
            });
            return newRows;
          }
        };
        (col.columns ?? []).forEach((datum: GridColItem) => {
          const tmpRows = getRowsFromCol(datum);
          tmpRows.map((tmpRow) => {
            newRows.push(tmpRow);
          });
        });
      }
    });

    const newData: any[] = [];

    newRows.forEach((row) => {
      const dataKey = row
        .filter((x) => x.k !== 'rempty')
        .map((x) => x.k.replace('r', 'c'))
        .join(',');
      const dataItem = {};
      data.forEach((datum, ix) => {
        const value = datum[dataKey];
        const key = newDataKeys[ix];
        dataItem[key] = value;
      });

      if (row.findIndex((r) => r.k === 'rempty') >= 0) {
        row.splice(
          row.findIndex((r) => r.k === 'rempty'),
          1
        );
      }
      row.forEach((item, itemIx) => {
        dataItem['rbd_' + (itemIx + 1)] = row[itemIx].name;
      });

      newData.push(dataItem);
    });
    const newGridData = {
      columns: newColumns,
      rows: newRows,
      data: newData,
      depth: this._gridData?.rows?.length ? this._gridData.rows[0].length : 1,
    };

    this.gridDataFlipped = newGridData;
  }

  private calculateInterval(): void {
    let remainder = 20;
    if (this.maximumValue < 100) {
      remainder = 10;
    }

    const topUp =
      this.maximumValue % remainder > 0
        ? remainder - (this.maximumValue % remainder)
        : 0;

    this.maximumValue += topUp;
    let intervalStep = remainder;
    let numIntervals = this.maximumValue / remainder;
    if (numIntervals > 10) {
      const numTry = 50;
      for (let k = 2; k < numTry; k++) {
        intervalStep += remainder;
        numIntervals = this.maximumValue / intervalStep;
        if (numIntervals <= 10 || k + 1 === numTry) {
          this.intervalValue = intervalStep;
          k = numTry;
        }
      }
      const newTopUp =
        this.maximumValue % intervalStep > 0
          ? intervalStep - (this.maximumValue % intervalStep)
          : 0;
      this.maximumValue += newTopUp;
    } else {
      this.intervalValue = remainder;
    }
  }

  private getBannerRowIdsToRemove(gridData: any): string[] {
    const rowIds: string[] = [];
    const sourcesToRemove = [
      ...DataUtility.bannerSummaryDimensions,
      ...DataUtility.tableSummaryDimensions,
    ];
    const { rows } = gridData;
    rows.forEach((rowArray: any) => {
      rowArray.forEach((row: any) => {
        if (sourcesToRemove.some((s) => s === row.s)) {
          rowIds.push(row.id);
        }
      });
    });

    return rowIds;
  }
}
