/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryFilter } from './QueryFilter.model';
import { QueryFilterKind, BrandClusterKind } from '@asksuzy/typescript-sdk';

export interface QueryFilterCustomValue {
  id: string;
  name: string;
  parent_id: string;
  parent_name: string;
}

export class QueryFilterCustom extends QueryFilter {
  override kind!: QueryFilterKind;
  values: Array<QueryFilterCustomValue>;
  override cluster_group_id?: string;
  override cluster_group_name?: string;
  brandClusterKind: BrandClusterKind;
  static fromJsonData(filter: any): QueryFilterCustom {
    return new QueryFilterCustom(
      filter.filter_kind,
      filter.values,
      filter.cluster_group_id,
      filter.cluster_group_name,
      filter.brand_cluster_kind
    );
  }

  constructor(
    kind: QueryFilterKind,
    values: Array<QueryFilterCustomValue> = [],
    cluster_group_id?: string,
    cluster_group_name?: string,
    brandClusterKind?: any
  ) {
    super(kind, brandClusterKind);
    this.cluster_group_id = cluster_group_id;
    this.cluster_group_name = cluster_group_name;
    this.brandClusterKind = brandClusterKind;
    this.values = values;
  }

  override toJsonData(): any {
    return {
      ...super.toJsonData(),
      ...{ values: this.values },
    };
  }
}
