import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { Subject, take, takeUntil } from 'rxjs';
import { ITableViewValidation } from '../../../models/data/request/ITableViewValidation';
import { ICrosstabResult } from '../../../models/data/response/IItemResult';
import { CrosstabDataService } from '../../../services/crosstab-data.service';

interface TableViewValidatorForm {
  description: FormControl<string | null>;
  isValid: FormControl<boolean | null>;
}

@Component({
  selector: 'data-explorer-tabelview-validator',
  templateUrl: './tabelview-validator.component.html',
  styleUrls: ['./tabelview-validator.component.scss'],
})
export class TabelViewValidatorComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<void>();

  @Input() validation!: ITableViewValidation;
  @Input() dialog!: IgxDialogComponent;
  @Output() saved: EventEmitter<ICrosstabResult> =
    new EventEmitter<ICrosstabResult>();

  pageForm!: FormGroup<TableViewValidatorForm>;
  inprogress!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: CrosstabDataService
  ) {}

  ngOnInit(): void {
    this.pageForm = this.createForm();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSubmitValidation(): void {
    this.inprogress = true;

    const notes = this.pageForm.controls.description.value ?? '';
    const isValid = this.pageForm.controls.isValid.value ?? false;
    const postData: ITableViewValidation = JSON.parse(
      JSON.stringify(this.validation ?? {})
    );
    postData.metadata.is_valid = isValid;
    postData.metadata.notes = notes;

    this.pageForm.disable();

    const session = localStorage.getItem('session');
    let userId = '';
    if (session) {
      const sessionObj = JSON.parse(session);
      userId = sessionObj?.userId ?? '';
    }

    postData.metadata.user_id = userId;

    this.dataService
      .postTableValiadtion(postData)
      .pipe(takeUntil(this.unsubscribeAll), take(1))
      .subscribe((res) => {
        this.saved.emit(res);
        this.dialog.close();
        setTimeout(() => {
          this.inprogress = false;
          this.pageForm.reset();
          this.pageForm.enable();
        });
      });
  }

  private createForm(): FormGroup<TableViewValidatorForm> {
    return this.formBuilder.group({
      description: [''],
      isValid: [false],
    });
  }
}
