import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { AuthSessionService } from '../../services/auth-session.service';

@Component({
  selector: 'data-explorer-elements-testing-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribeAll: Subject<void> = new Subject<void>();
  username = '';
  password = '';
  inprogress!: boolean;
  errorMsg!: string;

  @ViewChild('errorDialog', { static: true }) errorDialog!: IgxDialogComponent;

  constructor(
    private authSessionService: AuthSessionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authSessionService.logout();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onLoginClick(): void {
    this.authSessionService
      .login(this.username, this.password)
      .pipe(
        tap(() => {
          this.inprogress = true;
        }),
        takeUntil(this.unsubscribeAll),
        take(1)
      )
      .subscribe((resp) => {
        if (resp?.success) {
          this.router.navigate(['/', 'brand']);
          return;
        }

        this.inprogress = false;
        this.errorMsg = resp?.message ?? 'Login failed';
        setTimeout(() => {
          this.password = '';
          if (this.errorDialog) {
            this.errorDialog.open();
          }
        }, 0);
      });
  }
}
