export enum EDemographicEndpoint {
  ethnicity_id = 'ethnicities',
  gender = '',
  relationship_id = 'relationships',
  state_id = 'states',
  age_group_id = 'agegroups',
  education_id = 'educations',
  employment_id = 'employments',
  household_id = 'households',
  income_level_id = 'incomelevels',
  parenting_id = 'parentings',
  region_id = 'regions',
}

export enum ECBPCategory {
  Demographics = 'demographic',
  Responses = 'answer',
  SegmentsPanels = 'segments',
  Profile = 'profile',
}

export enum EFabricationType {
  Subpopulation = 'sub_population',
  DerivedQuestions = 'derived_question',
}

export enum EFabricationEndpointType {
  Subpopulation = 'subpopulations',
  DerivedQuestions = 'derivedquestions',
}

export enum EFabricationTypeNumber {
  Subpopulation,
  DerivedQuestions,
}

export enum EFabricationName {
  Subpopulation = 'Subpopulation',
  DerivedQuestions = 'Derived Question',
}

export enum EHydrateStatus {
  Errored = -2,
  Disabled,
  Empty,
  Starting,
  Generating,
  Persisting,
  Hydrated,
  Delayed,
}

export enum EBannerSource {
  Fabrication = 'fabrication',
  DerivedQuestion = 'derived',
}
