<div class="filter-container">
  <ng-container *ngIf="!hasFilter">
    <div class="empty-filter-row">
      <div class="empty-filter-row__content">
        <span>{{ 'filters.emptyFiltersInfo' | translate }}</span>
      </div>
      <button igxButton="outlined" (click)="onFiltersClick()">
        <igx-icon fontSet="material">add</igx-icon>
        {{ 'filters.addFilters' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="hasFilter">
    <div class="active-filter-row">
      <div class="active-filter-row__label">
        <igx-icon fontSet="material">filter_list</igx-icon>
        <span>{{ 'filters.filtersApplied' | translate }}&nbsp;:</span>
      </div>
      <div class="active-filter-row__content">
        <ng-container *ngFor="let tag of filterTags">
          <ng-container [ngSwitch]="tag.kind">
            <span *ngSwitchCase="filterTagKind.token">{{
              tag.value | translate
            }}</span>
            <span *ngSwitchCase="filterTagKind.async">{{
              tag.asyncParams
                | asyncFilterTag
                  : {
                      brand_id: context?.brand_id ?? '',
                      mission_id: context?.mission?.mission_id ?? ''
                    }
                | async
            }}</span>
            <span *ngSwitchDefault>{{ tag.value }}</span>
          </ng-container>
        </ng-container>
      </div>
      <button igxButton="outlined" (click)="onFiltersClick()">
        <igx-icon family="suzy" name="edit"></igx-icon>
        {{ 'filters.editFilters' | translate }}
      </button>
    </div>
  </ng-container>
</div>

<igx-dialog #advanced_filter_dialog>
  <app-advanced-filter
    [dialog]="advanced_filter_dialog"
    [context]="context"
    [crosstabConfig]="crosstabConfig"
    [config]="advancedFiltersBinding?.data"
    (configChange)="onConfigChanged($event)"
  ></app-advanced-filter>
</igx-dialog>
