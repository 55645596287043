<div class="selected-mission-container" [ngClass]="{ 'with-export': canExportSurvey }">
    <div class="mission-info">
        <span class="input-label text-suzy-grey-800">{{ 'dataExplorer.missionSelectedTitle' | translate }}</span>
        <div class="mission-title">
            <action-icon class="mission-icon" matPrefix *ngIf="mission?.mission_id" [mission]="mission"></action-icon>
            <div>
                <div [innerHTML]="mission.search_internal_text || mission.search_text || mission.unStrippedSearchText || mission.mission_caption" [igxTooltipTarget]="infoTooltip" (click)="navigateToRoute(mission)"></div>
                    <div #infoTooltip="tooltip" igxTooltip class="tooltip">
                        <span class="tooltip-region">Action type: {{ getActionType(mission) | translate }}</span>
                        <span class="tooltip-region">Created on: {{ mission.stamp_utc | date : 'mediumDate' }}</span>
                        <span class="tooltip-region">Folder: {{ mission.brand_folder_name ?? 'None'  }}</span>
                        <span class="tooltip-region">Tags: {{ mission.brand_tags_text ?? 'None' }}</span>
                    </div>
                <span class="material-symbols-rounded">open_in_new </span>
            </div>
        </div>
    </div>
    <div class="mission-actions">
        <button class="suzy-icon-button" [disabled]="isBuilderView" (click)="removeMission()"
            [igxTooltipTarget]="closeTooltip">
            <span class="material-symbols-rounded">close</span>
        </button>
        <button *ngIf="canExportSurvey" class="export-btn" igxButton="flat" [igxToggleAction]="exportSurvey"
            [overlaySettings]="overlaySettings" [igxDropDownItemNavigation]="exportSurvey"
            [igxTooltipTarget]="exportTooltip">
            <igx-icon family="suzy" name="download"></igx-icon>
            <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
        </button>

        <igx-drop-down class="export-dropdown" #exportSurvey [allowItemsFocus]="false">
            <igx-drop-down-item #excelExport (click)="onExportSurvey('excel')">{{
                'crosstab.exportToExcel' | translate }}
                <span class="ppt-loading-spinner" *ngIf="false">
                    <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                </span></igx-drop-down-item>
            <igx-drop-down-item #PPTExport (click)="onExportSurvey('ppt')">
                {{ 'crosstab.exportToPPT' | translate }}
                <span class="ppt-loading-spinner" *ngIf="false">
                    <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                </span>
            </igx-drop-down-item>
        </igx-drop-down>

        <div #exportTooltip="tooltip" igxTooltip class="toolbar-tooltip">
            {{ 'crosstab.download' | translate }}
        </div>

    </div>
    <div #closeTooltip="tooltip" igxTooltip class="toolbar-tooltip">
        {{ 'dataExplorer.remove' | translate }}
    </div>
</div>