import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { IFabItem } from '../../../../../../models/fabrication/IFabricationResponse';
import { DragDropManager } from '../../../../drag-drop-manager';
import { IBuilder } from '../../../../i-builder';
import { EFabricationType } from '../../../../../../enums/cbp-enum';

@Component({
  selector: 'app-derived-question-selector',
  templateUrl: './derived-question-selector.component.html',
  styleUrls: ['./derived-question-selector.component.scss'],
})
export class DerivedQuestionSelectorComponent implements OnChanges {
  @Input() derivedQuestions: IFabItem[] = [];
  @Input() builder?: IBuilder;

  get dragdrop(): DragDropManager | undefined {
    return this.builder?.dragdrop;
  }

  filteredDerivedQuestions: any[] = [];
  dimensions: any[] = [];
  unsubscribe = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    const dqChanges = changes['derivedQuestions'];

    if (dqChanges?.currentValue) {
      this.dimensions = dqChanges.currentValue.map((item: IFabItem) => {
        return {
          type: 'field',
          label: item.fabrication_name,
          field: {
            source: 'derived',
            field: item.known_items[0].item_id,
            action_id: item.known_items[0].item_id,
            parent_id: item.fabrication_id,
          },
          fabricationType: EFabricationType.DerivedQuestions,
        };
      });
      this.builder?.dragdrop?.prepareNewItems(
        'derived_question',
        this.dimensions
      );
      this.filteredDerivedQuestions = this.dimensions;
    }
  }

  filterItems(searchTerm: string): void {
    if (searchTerm) {
      this.filteredDerivedQuestions = this.filteredDerivedQuestions.filter(
        (item: any) => item.label.search(new RegExp(searchTerm, 'i')) !== -1
      );
    } else {
      this.filteredDerivedQuestions = this.dimensions;

      this.builder?.dragdrop?.prepareNewItems(
        'derived_question',
        this.filteredDerivedQuestions
      );
    }
  }
}
