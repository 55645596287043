<div class="qd-container">
    <cbp-search-bar (searchTerm)="filterItems($event)"></cbp-search-bar>

    <div class="dq-table-container">
        <table cdk-table [dataSource]="dataSource" matSort>
            <ng-container cdkColumnDef="fabrication_name">
                <th cdk-header-cell *cdkHeaderCellDef mat-sort-header> Name </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small-bold"> {{element.display_name}} </td>
            </ng-container>
            <ng-container cdkColumnDef="updated_utc">
                <th cdk-header-cell *cdkHeaderCellDef mat-sort-header> Updated </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small">
                     {{ element.updated_utc | date:'medium' }} 
                </td>
            </ng-container>

            <!--             <ng-container cdkColumnDef="outcomes">
                <th cdk-header-cell *cdkHeaderCellDef> Groups </th>
                <td cdk-cell *cdkCellDef="let element">
                    <ul class="cbp-group-list">
                        <li class="body-copy-small" *ngFor="let outcome of element.outcomes">
                            {{ outcome.outcome_text }}
                        </li>
                    </ul>
                </td>
            </ng-container> -->

            <ng-container cdkColumnDef="actions">
                <th cdk-header-cell *cdkHeaderCellDef> </th>
                <td cdk-cell *cdkCellDef="let element" class="justify-end">
                    <button class="suzy-icon-button" (click)="deleteItem(element.table_view_id)">
                        <span class="material-symbols-rounded">delete</span>
                    </button>
                </td>
            </ng-container>

            <!--  <ng-container *ngIf="dataSource.data.length > 0;"> -->
            <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
            <!--     </ng-container> -->

            <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
            <tr *cdkNoDataRow>
                <td [attr.colspan]="displayedColumns.length">
                    <div class="dq-empty-state">
                        <span *ngIf="dataSource.data.length === 0 && dataSource.filteredData.length === 0"> {{ emptyText
                            }}</span>
                        <span *ngIf="dataSource.filteredData.length === 0 && dataSource.filter">
                            {{ 'customBannerPoints.formText.labels.noResults' | translate }}
                        </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>