import { Injectable } from '@angular/core';
import {
  ISegmentTrackActionData,
  ESegmentTrackActionKind,
  ISegmentTrackBulkExportData,
  ISegmentTrackApplyTableViewData,
} from '../models/ui/i-segment-track-action';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  windowObjectNotLoaded(): boolean {
    return window === undefined || window.analytics === undefined;
  }

  load(segmentToken: string): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.load(segmentToken);
  }

  trackAction(
    kind: ESegmentTrackActionKind,
    properties:
      | ISegmentTrackActionData
      | ISegmentTrackBulkExportData
      | ISegmentTrackApplyTableViewData
  ): void {
    if (this.windowObjectNotLoaded()) {
      return;
    }
    window.analytics.track(kind, properties);
  }
}
