import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrosstabDataService } from './crosstab-data.service';

@Injectable({
  providedIn: 'root',
})
export class TableViewsRequestsService {
  constructor(
    private http: HttpClient,
    private crosstab: CrosstabDataService
  ) {}

  getTableViewsList(
    brandId: string,
    userParams: { mission_id: string; action_id: string; order_by: string, is_derived_question: boolean }
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: userParams,
    });

    return this.http.get(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  createTableView(brandId: string, data: any): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  updateTableView(
    brandId: string,
    tableViewId: string,
    data: any
  ): Observable<any> {
    return this.http.put(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/${tableViewId}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  deleteTableView(brandId: string, tableViewId: string): Observable<any> {
    return this.http.delete(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/${tableViewId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  getTableViewDetails(tableViewId: string, brandId: string): Observable<any> {
    return this.http.get(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/${tableViewId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  getTableViewsTemplates(userParams: any): Observable<any> {
    const params = new HttpParams({
      fromObject: userParams,
    });

    return this.http.get(
      `${this.crosstab.apiBaseUrl}/data/tableviewtemplates`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  notifyAsync(brandId: string, tableViewId: string): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/${tableViewId}/subscription/ensure`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  cancelAsyncNotification(
    brandId: string,
    tableViewId: string
  ): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/${tableViewId}/subscription/remove`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  verifyFabricationImpact(
    brandId: string,
    fabricationId: string,
    userParams: any
  ): Observable<any> {
    // /data/:brand_id/tableviews/for_fabrication/:fabrication_id?take=XX
    const params = new HttpParams({
      fromObject: userParams,
    });

    return this.http.get(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/tableviews/for_fabrication/${fabricationId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }
}
