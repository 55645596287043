<div>
    <div class="modal-header">
        <div class="modal-header-left">
            <span class="h2 text-suzy-grey-900">{{ 'removeMission.title' | translate }}</span>
        </div>
        <button class="suzy-icon-button" (click)="close()">
            <span class="material-symbols-rounded">close</span>
        </button>
    </div>

    <div class="modal-body">
        <p class="body-copy-regular text-suzy-grey-900">{{ 'removeMission.message' | translate }}</p>
    </div>

    <div igxDialogActions class="modal-footer-right">
        <button class="suzy-secondary" (click)="close()">{{ 'removeMission.cancelBtn' | translate }}</button>
        <button class="suzy-primary" (click)="confirm(true)">{{ 'removeMission.ctaBtn' | translate }}</button>
    </div>
</div>