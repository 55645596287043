<igc-dockmanager #dockManager id="dockManager" *ngIf="config" [layout]="layout" class="builder"
  [ngClass]="{ 'no-calculations': !ui.customize.calculation }">
  <div slot="preview-row-header" class="bannersPanelHeader left">
    <igx-icon (click)="onSwapBanners()" [igxTooltipTarget]="swapBannersTooltip"
      *ngIf="config.crosstab.columns?.length || config.crosstab.rows?.length">swap_horiz</igx-icon>
    <span>{{ 'builder.previewRow' | translate }}</span>
  </div>
  <div slot="preview-column-header" class="bannersPanelHeader right">
    {{ 'builder.previewColumn' | translate }}
    <igx-icon [igxTooltipTarget]="columnInfoTooltip">info_outlined</igx-icon>
  </div>
  <div slot="preview-column" class="dockManagerContent fillContent bannersPanel right">
    <div class="columnBannerList">
      <ng-container *ngFor="let banner of config.crosstab.columns; index as bannerIndex">
        <igx-list class="columnBanner" #listDragRef>
          <div class="no-drop-container">
            <igx-icon>block</igx-icon>
          </div>
          <igx-list-item [isHeader]="true" class="bannerTitle" id="{{ banner[dragdrop!.drag_id_field] }}"
            (dragStart)="dragdrop!.banner_dragStart(false, banner)"
            (ghostCreate)="dragdrop!.banner_ghostCreate(listDragRef)" (dragEnd)="dragdrop!.banner_dragEnd(listDragRef)"
            (dropped)="dragdrop!.banner_dropped()" (enter)="dragdrop!.banner_enter($event)"
            [ghostClass]="'drag_ghost_class'" igxDrag igxDrop>
            <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
            <!-- <span class="flexFill">{{ banner.name }}</span> -->
            <button class="removeButton margin-left-auto" igxButton="icon" onclick="this.blur()"
              (click)="removeBanner('columns', config.crosstab.columns, banner)">
              <igx-icon>close</igx-icon>
            </button>
          </igx-list-item>
          <ng-container *ngFor="let dimension of banner.dimensions">
            <igx-list-item class="bannerDim" id="{{ dimension[dragdrop!.drag_id_field] }}"
              (dropped)="dragdrop!.dimension_dropped(banner, $event)" (dragStart)="
                dragdrop!.dimension_dragStart(false, dimension, banner)
              " (ghostCreate)="dragdrop!.dimension_ghostCreate(itemDragRef)"
              (dragEnd)="dragdrop!.dimension_dragEnd(itemDragRef)" (leave)="dragdrop!.dimension_leave($event)"
              (enter)="dragdrop!.dimension_enter($event, bannerIndex, false)" [ghostClass]="'drag_ghost_class'" igxDrag
              igxDrop #itemDragRef>
              <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
              <span class="flexFill dim-label" [ngClass]="{
                  'has-icon':
                    dimension.type === 'net' ||
                    (dimension.type === 'field' && dimension.selection)
                }" [showDelay]="750" [hideDelay]="250" [igxTooltipTarget]="dimensionInfoTooltip" (tooltipShow)="
                  dimensionInfoTooltip.context = {
                    tooltip: dimension.label
                  }
                ">{{ dimension.label }}</span>
              <button *ngIf="dimension.type === 'net'" class="netButton filtered" igxButton="icon" onclick="this.blur()"
                (click)="
                  netDimensionOpen(
                    null,
                    config.crosstab.rows,
                    banner,
                    banner.dimensions,
                    dimension
                  )
                ">
                <igx-icon>merge</igx-icon>
              </button>
              <button *ngIf="dimension.type === 'field' && dimension.selection" class="filterButton filtered"
                igxButton="icon" onclick="this.blur()" (click)="
                  filterDimensionOpen(
                    null,
                    config.crosstab.columns,
                    banner,
                    banner.dimensions,
                    dimension
                  )
                ">
                <igx-icon>visibility_off</igx-icon>
              </button>
              <button class="button" igxButton="icon" [igxToggleAction]="dropdown"
                [igxDropDownItemNavigation]="dropdown" [overlaySettings]="optionsMenuOverlaySettings">
                <igx-icon>arrow_drop_down</igx-icon>
              </button>
              <igx-drop-down #dropdown>
                <igx-drop-down-item class="dim-action-menu-item" [selected]="false" (click)="
                    renameDimensionOpen(
                      dropdown,
                      config.crosstab.columns,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>drive_file_rename_outline</igx-icon><span>{{ 'builder.rename' | translate
                      }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-divider *ngIf="
                    !dimension.filter_not_allowed || !dimension.net_not_allowed
                  "></igx-divider>
                <igx-drop-down-item class="dim-action-menu-item" *ngIf="!dimension.net_not_allowed" [selected]="false"
                  (click)="
                    netDimensionOpen(
                      dropdown,
                      config.crosstab.columns,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>merge</igx-icon><span>{{ 'builder.net' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-drop-down-item class="dim-action-menu-item"
                  *ngIf="dimension.field?.field === 'custom_age_group_id'" [selected]="false"
                  (click)="onEditAgeRanges('dimension', dropdown)">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>merge</igx-icon><span>{{ 'builder.customizeAgeRanges' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-drop-down-item class="dim-action-menu-item" *ngIf="!dimension.filter_not_allowed"
                  [selected]="false" (click)="
                    filterDimensionOpen(
                      dropdown,
                      config.crosstab.columns,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>visibility_off</igx-icon><span>{{ 'builder.filter' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-divider></igx-divider>
                <igx-drop-down-item class="dim-action-menu-item delete-button" [selected]="false" (click)="
                    removeDimension(
                      'columns',
                      config.crosstab.columns,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>close</igx-icon><span>{{ 'builder.delete' | translate }}</span>
                  </div>
                </igx-drop-down-item>
              </igx-drop-down>
            </igx-list-item>
          </ng-container>
        </igx-list>

      </ng-container>
      <igx-list *ngIf="ui.show_new_banner || ui.show_relocate_banner_columns" class="columnBanner newBanner"
        (dropped)="dragdrop!.newbanner_dropped(false, $event)" (leave)="dragdrop!.newbanner_leave($event)"
        (enter)="dragdrop!.newbanner_enter($event)" igxDrop>
        <div class="no-drop-container">
          <igx-icon>block</igx-icon>
        </div>
        <igx-list-item [isHeader]="true" id="new_banner">
          <!-- <span class="flexFill">{{ 'builder.newBanner' | translate }}</span> -->
        </igx-list-item>
      </igx-list>
    </div>
  </div>

  <div slot="preview-row" class="dockManagerContent fillContent bannersPanel left">
    <div class="rowBannerList">
      <ng-container *ngFor="let banner of config.crosstab.rows; index as bannerIndex">
        <igx-list class="rowBanner" #listDragRef>
          <div class="no-drop-container">
            <igx-icon>block</igx-icon>
          </div>
          <igx-list-item class="bannerTitle" [isHeader]="true" id="{{ banner[dragdrop!.drag_id_field] }}"
            (dragStart)="dragdrop!.banner_dragStart(true, banner)"
            (ghostCreate)="dragdrop!.banner_ghostCreate(listDragRef)" (dropped)="dragdrop!.banner_dropped()"
            (dragEnd)="dragdrop!.banner_dragEnd(listDragRef)" (enter)="dragdrop!.banner_enter($event)"
            [ghostClass]="'drag_ghost_class'" igxDrag igxDrop>
            <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
            <!-- <span class="flexFill">{{ banner.name }}</span> -->
            <button class="removeButton margin-left-auto" igxButton="icon" onclick="this.blur()"
              (click)="removeBanner('rows', config.crosstab.rows, banner)">
              <igx-icon>close</igx-icon>
            </button>
          </igx-list-item>
          <ng-container *ngFor="let dimension of banner.dimensions">
            <igx-list-item class="bannerDim" id="{{ dimension[dragdrop!.drag_id_field] }}"
              (dropped)="dragdrop!.dimension_dropped(banner, $event)" (dragStart)="
                dragdrop!.dimension_dragStart(true, dimension, banner)
              " (ghostCreate)="dragdrop!.dimension_ghostCreate(itemDragRef)"
              (dragEnd)="dragdrop!.dimension_dragEnd(itemDragRef)" (leave)="dragdrop!.dimension_leave($event)"
              (enter)="dragdrop!.dimension_enter($event, bannerIndex, true)" [ghostClass]="'drag_ghost_class'" igxDrag
              igxDrop #itemDragRef>
              <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
              <span class="flexFill dim-label" [ngClass]="{
                  'has-icon':
                    dimension.type === 'net' ||
                    (dimension.type === 'field' && dimension.selection)
                }" [igxTooltipTarget]="dimensionInfoTooltip" [showDelay]="750" [hideDelay]="250" (tooltipShow)="
                  dimensionInfoTooltip.context = {
                    tooltip: dimension.label
                  }
                ">{{ dimension.label }}</span>

              <button *ngIf="dimension.type === 'net'" class="netButton filtered" igxButton="icon" onclick="this.blur()"
                (click)="
                  netDimensionOpen(
                    null,
                    config.crosstab.rows,
                    banner,
                    banner.dimensions,
                    dimension,
                    true
                  )
                ">
                <igx-icon>merge</igx-icon>
              </button>
              <button *ngIf="dimension.type === 'field' && dimension.selection" class="filterButton filtered"
                igxButton="icon" onclick="this.blur()" (click)="
                  filterDimensionOpen(
                    null,
                    config.crosstab.rows,
                    banner,
                    banner.dimensions,
                    dimension
                  )
                ">
                <igx-icon>visibility_off</igx-icon>
              </button>

              <button class="button" igxButton="icon" [igxToggleAction]="dropdown"
                [igxDropDownItemNavigation]="dropdown" [overlaySettings]="optionsMenuOverlaySettings">
                <igx-icon>keyboard_arrow_down</igx-icon>
              </button>
              <igx-drop-down #dropdown>
                <igx-drop-down-item class="dim-action-menu-item" [selected]="false" (click)="
                    renameDimensionOpen(
                      dropdown,
                      config.crosstab.rows,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon>drive_file_rename_outline</igx-icon><span>{{ 'builder.rename' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-divider *ngIf="
                    !dimension.filter_not_allowed || !dimension.net_not_allowed
                  "></igx-divider>
                <igx-drop-down-item *ngIf="!dimension.net_not_allowed" class="dim-action-menu-item" [selected]="false"
                  (click)="
                    netDimensionOpen(
                      dropdown,
                      config.crosstab.rows,
                      banner,
                      banner.dimensions,
                      dimension,
                      true
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon>merge</igx-icon><span>{{ 'builder.net' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-drop-down-item class="dim-action-menu-item"
                  *ngIf="dimension.field?.field === 'custom_age_group_id'" [selected]="false"
                  (click)="onEditAgeRanges('dimension', dropdown)">
                  <div class="action-menu-line">
                    <igx-icon igxPrefix>merge</igx-icon><span>{{ 'builder.customizeAgeRanges' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-drop-down-item class="dim-action-menu-item" *ngIf="!dimension.filter_not_allowed"
                  [selected]="false" (click)="
                    filterDimensionOpen(
                      dropdown,
                      config.crosstab.rows,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon>visibility_off</igx-icon><span>{{ 'builder.filter' | translate }}</span>
                  </div>
                </igx-drop-down-item>
                <igx-divider></igx-divider>
                <igx-drop-down-item class="dim-action-menu-item delete-button" [selected]="false" (click)="
                    removeDimension(
                      'rows',
                      config.crosstab.rows,
                      banner,
                      banner.dimensions,
                      dimension
                    )
                  ">
                  <div class="action-menu-line">
                    <igx-icon>close</igx-icon><span>{{ 'builder.delete' | translate }}</span>
                  </div>
                </igx-drop-down-item>
              </igx-drop-down>
            </igx-list-item>
          </ng-container>
        </igx-list>
      </ng-container>
      <igx-list *ngIf="ui.show_new_banner || ui.show_relocate_banner_rows" class="rowBanner newBanner"
        (dropped)="dragdrop!.newbanner_dropped(true, $event)" (leave)="dragdrop!.newbanner_leave($event)"
        (enter)="dragdrop!.newbanner_enter($event)" igxDrop>
        <igx-list-item [isHeader]="true" id="new_banner">
          <!-- <span class="flexFill">{{ 'builder.newBanner' | translate }}</span> -->
        </igx-list-item>
      </igx-list>
    </div>
  </div>
  <ng-container *ngFor="let provider of dimensionProvidersEnabled">
    <div slot="{{ provider.code }}" class="dockManagerContent fillContent toolPanel"
      (pointerdown)="$event.preventDefault()">
      <div *ngIf="provider.loading" class="loading">
        {{ 'builder.loadingDimension' | translate: { '0': provider.name } }}
      </div>
      <div *ngIf="
          provider.loaded && provider.instructions && provider.dimensions.length
        " class="loading">
        <span>{{ provider.instructions | translate }}</span>
        <span class="link" *ngIf="provider.instructionsLink && provider.instructionsLinkUrl"
          (click)="onNavigateToExternalUrl(provider.instructionsLinkUrl)">{{ provider.instructionsLink | translate
          }}</span>
      </div>
      <div *ngIf="provider.loaded && provider.dimensions.length === 0" class="loading">
        {{
        provider.noDimensionMessage ?? 'builder.noDimensionData'
        | translate: { '0': provider.name }
        }}
      </div>
      <div *ngIf="provider.loaded" class="dragDimensionList">
        <!-- dimensions items list -->
        <ng-container *ngFor="let dimension of provider.dimensions">
          <div class="dragDimension" [ngClass]="{ wide: provider.code === 'globalprofiling' }"
            [ghostClass]="'drag_ghost_class'" id="{{ dimension[dragdrop!.drag_id_field] }}"
            (dragStart)="onNewDimensionDragStart()" (dragEnd)="dragdrop!.newdimension_dragEnd()" [dragTolerance]="2"
            igxDrag>
            <div>
              <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
              <span class="dragDimensionName dim-label">{{
                dimension.label
                }}</span>
            </div>
            <div class="fabrication-actions">
              <button class="suzy-icon-button" *ngIf="provider.code === 'subpopulations'"
                (click)="editSubppulaton(dimension)">
                <igx-icon family="suzy" name="edit"></igx-icon>
              </button>
              <!-- <button class="suzy-icon-button" *ngIf="provider.code === 'subpopulations'"
                (click)="deleteSubppulaton(dimension)">
                <span class="material-symbols-rounded">delete</span>
              </button> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="provider.code === 'demographics-standard'">
          <app-custom-age-ranges #custom_age_ranges [builder]="builder"
            [crosstabConfig]="config"></app-custom-age-ranges>
        </ng-container>
        <ng-container *ngIf="provider.code === 'subpopulations'">
          <button class="gray-fit-button" (click)="navigateToCreateSP()">
            <igx-icon>add</igx-icon>
            {{ 'customBannerPoints.subPopulations.addSubpopulation' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div slot="crosstab-question" class="dockManagerContent fillContent toolPanel"
    (pointerdown)="$event.preventDefault()">
    <div class="loading">
      <span>{{ 'builder.crosstabQuestionTabInstructions' | translate }}</span>
    </div>
    <app-crosstab-question [builder]="builder" [context]="context" [crosstabConfig]="config"
      #crosstab_question></app-crosstab-question>
  </div>
</igc-dockmanager>
<span class="section-title">{{ 'builder.tableOptions' | translate }}</span>
<app-builder-calculations *ngIf="ui.customize.calculation" #calculations [context]="context" [crosstabConfig]="config"
  (crosstabConfigChange)="onCalculationsApplied($event)"></app-builder-calculations>
<app-builder-filters #global_filters [context]="context" [crosstabConfig]="config" [allowedFilters]="ui.allowed_filters"
  (crosstabConfigChange)="onFiltersApplied($event)"></app-builder-filters>

<igx-dialog style="padding:32px;" #confirm_builder_dialog [message]="ui.confirm_dialog.message">
  <igx-dialog-title>
    <div class="dialog-container">
      <h2 class="h2 text-suzy-grey-900">{{ ui.confirm_dialog.title }}</h2>
    </div>
  </igx-dialog-title>
  <div igxDialogActions class="dialog-container dialog-actions">
    <button class="suzy-secondary" (click)="dialog_callback(false, confirm_builder_dialog)">
      {{ ui.confirm_dialog.left }}
    </button>
    <button class="suzy-primary" (click)="dialog_callback(true, confirm_builder_dialog)">
      {{ ui.confirm_dialog.right }}
    </button>
  </div>
</igx-dialog>

<igx-dialog #dimension_filter_dialog>
  <app-dimension-filter [data]="dimension_filter_binding?.data" [crosstabConfig]="config"
    [dialog]="dimension_filter_dialog"></app-dimension-filter>
</igx-dialog>

<div #columnInfoTooltip="tooltip" igxTooltip class="tooltip">
  {{ 'builder.columnInfoTooltip' | translate }}
</div>

<div #swapBannersTooltip="tooltip" igxTooltip class="tooltip">
  {{ 'builder.swapBannersTooltip' | translate }}
</div>

<div #dimensionInfoTooltip="tooltip" igxTooltip class="tooltip">
  {{ dimensionInfoTooltip.context?.tooltip ?? '' }}
</div>

<igx-dialog #age_range_editor_dialog>
  <app-age-range-editor #age_range_editor *ngIf="rangeEditorVisible" [dialog]="age_range_editor_dialog"
    [config]="config?.crosstab" [ageGroupClassification]="ageGroupClassification"
    [source]="ageRangeEditorSource"></app-age-range-editor>
</igx-dialog>