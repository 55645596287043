import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { EFabricationEndpointType } from '../../../enums/cbp-enum';
import { IFabrication } from '../../../models/fabrication/IFabrication';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { IFabItemDetail } from '../../../models/fabrication/IFabricationDetail';

@Component({
  selector: 'cbp-fabrication-form',
  templateUrl: './cbp-fabrication-form.component.html',
  styleUrls: ['./cbp-fabrication-form.component.scss'],
})
export class CbpFabricationFormComponent {
  isSaving = false;
  type!: EFabricationEndpointType;
  bannerDimensions!: any;
  brandId = '';
  fabricationToEdit?: IFabItemDetail | null;
  toClone = false;

  @Output() navigateAway = new EventEmitter<boolean>(false);

  constructor(
    @Inject(DATA_TOKEN) public formInputs: ICBPFormInputs,
    private cbpService: CustomBannerPointsService,
    public cbpActions: CustomBannerPointsActionsService
  ) {
    this.type = this.formInputs.type ?? EFabricationEndpointType.Subpopulation;
    this.brandId = this.formInputs.uiData.brand_id;
    this.bannerDimensions = this.formInputs.bannerDimensions;
    this.toClone = this.formInputs.toClone ?? false;

    if (this.formInputs.toEdit)
      this.fabricationToEdit = this.preparePayload(this.formInputs.toEdit);
  }

  handleFormValue(fabrication: IFabrication): void {
    this.cbpActions.createOrEditFabrication(
      this.brandId,
      this.type,
      fabrication,
      !!this.fabricationToEdit && !this.toClone
    );
   }

  handleNavigateAway(evt: boolean): void {
    this.navigateAway.emit(evt);
  }

  preparePayload(fabrication: IFabItemDetail): IFabItemDetail {
    if (this.toClone) {
      const readyToClone: any = structuredClone(fabrication);
      readyToClone.fabrication_id = null;
      readyToClone.fabrication_name = `Copy of ${fabrication.fabrication_name}`;
      readyToClone.json_payload = this.prepareJsonPayload(fabrication.json_payload);

      return readyToClone;
    }
    
    return fabrication;
  }

  prepareJsonPayload(jsonPayload: string): string {
    const payload = JSON.parse(jsonPayload);
    payload.items[0].title = `Copy of ${payload.items[0].title}`;
    payload.items[0].item_id = null;
    payload.fabrication_id = null;
    payload.items[0].outcomes.map((outcome) => {
      outcome.outcome_id = null;

      return outcome;
      
    })
    payload.items[0].remainder.outcome.outcome_id = null;

    return JSON.stringify(payload);
  }
}
