export const environment = {
  production: false,
  suzyUrl: 'https://api.asksuzy.io/client',
  dataExplorerAPI: {
    baseURL: 'https://app-dataexplorer-i3-prod-edge.azurewebsites.net/api',
    validationURL: 'https://dataexplorer-eng.asksuzy.io/staging-func',
    tokenURL:
      'https://app-tokenapi-prod-eastus.azurewebsites.net/api/auth/GenerateToken?intent=prod_testing',
  },
  features: {
    validationEnabled: true,
    qualityEnabled: true,
    statTestingEnabled: true,
    advancedCalculationsEnabled: true,
    preparationEnabled: true,
    trimmedResponsesEnabled: true,
    publicLinkManageDataEnabled: true,
    manageDataUrl: 'https://app.suzy.com/',
    brandFoldersEnabled: true,
  },
  segmentToken: 'qpmvOcYGcbvDpL2BVut2RVdJSEW6Txru',
};
