<div class="suzy-modal">
    <ng-container *ngIf="data | async as itemDetail; else elseTemplate">
        <div class="modal-header">
            <div class="modal-header-left">
                <div class="h2 text-suzy-grey-800">{{ itemDetail.item.fabrication_name }}</div>
                <span class="body-copy-small text-suzy-grey-700">
                    <span>{{itemDetail.item.fabrication_description }}</span>
                </span>
            </div>

            <button class="suzy-icon-button" (click)="close()">
                <span class="material-symbols-rounded">close</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="info-table">
                <div class="info-table__header">
                    <span>
                        Groups
                    </span>
                </div>
                <div class="info-table__row">
                    <div class="info-table__row-content">
                        <span>{{ getGroupsFromJsonPayload(itemDetail.item.json_payload) }}</span>
                    </div>
                </div>
            </div>

            <div class="info-table">
                <div class="info-table__row bg-color-suzy-grey-100">
                    <div class="info-table__row-content">
                        <span>
                            Created
                        </span>
                    </div>
                    <div class="info-table__row-content">
                        <span>
                            Updated
                        </span>
                    </div>
                    <div class="info-table__row-content">
                        <span>
                            Updated By
                        </span>
                    </div>
                </div>

                <div class="info-table__row">
                    <div class="info-table__row-content">
                        <span>{{itemDetail.item.created_utc | date}}</span>
                    </div>
                    <div class="info-table__row-content">
                        <span>{{itemDetail.item.updated_utc | date}}</span>
                    </div>
                    <div class="info-table__row-content">
                        <span *ngIf="itemDetail.item.edited_by">{{ itemDetail.item.edited_by.full_name | textOrDefault:
                            '-' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="loader-container">
            <div>
                <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
                <p>{{ 'customBannerPoints.formText.labels.loadingData' | translate }}</p>
            </div>
        </div>
    </ng-template>
</div>