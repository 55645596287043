import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITableInfo } from '../../../models/ui/i-table-info.model';
import {
  IChangeCheckboxEventArgs,
  IgxDialogComponent,
} from '@infragistics/igniteui-angular';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFilters,
} from '../../../models/ui/i-advanced-filters-config.model';
import { ICalculations } from '../../../models/ui/i-calculations-ui.model';

@Component({
  selector: 'data-explorer-table-information',
  templateUrl: './table-information.component.html',
  styleUrls: ['./table-information.component.scss'],
})
export class TabelInformationComponent implements OnInit {
  @Input()
  public dialog: IgxDialogComponent | undefined;

  @Input()
  public data!: ITableInfo;

  @Output() refreshData: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  public hasFilters!: boolean;
  public hasCalculations!: boolean;
  public filterTagKind = EAdvancedFiltersTagKind;
  public forceRefreshActions = new Array<string>();

  ngOnInit(): void {
    this.hasFilters = this.checkHasFilters(this.data.filterStatus);
    this.hasCalculations = this.checkHasCalculations(
      this.data.calculationsStatus
    );
  }

  onClose(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  onQuestionsUsedSelectionChanged(
    event: IChangeCheckboxEventArgs,
    actionId: string
  ): void {
    if (event.checked) {
      this.forceRefreshActions.push(actionId);
    } else {
      this.forceRefreshActions = this.forceRefreshActions.filter(
        (x) => x !== actionId
      );
    }
  }

  onRefreshQuestionDataClicked(): void {
    if (this.forceRefreshActions.length) {
      this.refreshData.emit(this.forceRefreshActions);
    }
    this.onClose();
  }

  private checkHasFilters(filter: IAdvancedFilters): boolean {
    return Object.keys(filter).some((x) => filter[x]?.included);
  }

  private checkHasCalculations(calculations: ICalculations): boolean {
    return Object.keys(calculations).some((x) => calculations[x]?.included);
  }
}
