import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { IBuilderContext } from '../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFilters,
  IAdvancedFiltersConfig,
  IAdvancedFiltersTag,
} from '../../../../models/ui/i-advanced-filters-config.model';
import { CompletesFilterComponent } from '../../providers/filters/completes/completes-filter.component';
import { RespondentsFilterComponent } from '../../providers/filters/respondents/respondents-filter.component';
import { DemographicsFilterComponent } from '../../providers/filters/demographics/demographics-filter.component';
import { DemographicsFilterUI } from '../../../../models/ui/i-demographics-filter-ui.model';
import { IClusterGroupFilterUI } from '../../../../models/ui/i-cluster-group-filters-ui.model';
import { ICrosstabConfig } from '../../../../models/data/request/ICrosstabConfig';
import { TableViewService } from '../../../../services/table-view.service';
import { ClusterGroupsFilterComponent } from '../../providers/filters/cluster-groups/cluster-groups-filter.component';
import { ProfilingFilterComponent } from '../../providers/filters/profiling/profiling-filter.component';
import { TirmmedResponsesFilterComponent } from '../../providers/filters/trimmed-responses/trimmed-responses-filter.component';
import { CleanedResponsesFilterComponent } from '../../providers/filters/cleaned-responses/cleaned-responses-filter.component';

@Component({
  selector: 'app-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent implements OnInit {
  inprogress!: boolean;
  loading!: boolean;
  demographicsFilterBinding?: { data?: DemographicsFilterUI } = {
    data: undefined,
  };
  segmentsPanelsFilterBinding?: { data?: IClusterGroupFilterUI } = {
    data: undefined,
  };
  profilingFilterBinding?: { data?: IClusterGroupFilterUI } = {
    data: undefined,
  };
  filterStatus!: IAdvancedFilters;
  filterTags!: IAdvancedFiltersTag[];
  filterTagKind = EAdvancedFiltersTagKind;
  hasFilter!: boolean;
  missionHasTrimmedResponses!: boolean;
  missionHasCleanedResponses!: boolean;
  profilingSectionTitle!: string;

  @Input()
  public dialog: IgxDialogComponent | undefined;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() configChange = new EventEmitter<ICrosstabConfigUI>();

  private _config?: IAdvancedFiltersConfig;
  get config(): IAdvancedFiltersConfig | undefined {
    return this._config;
  }
  @Input() set config(value: IAdvancedFiltersConfig | undefined) {
    this.initialize(value);
    this._config = value;
  }

  @ViewChild('completes_filter', {
    read: CompletesFilterComponent,
    static: false,
  })
  private completesFilter!: CompletesFilterComponent;

  @ViewChild('respondents_filter', {
    read: RespondentsFilterComponent,
    static: false,
  })
  private respondentsFilter!: RespondentsFilterComponent;

  @ViewChild('demographics_filter', {
    read: DemographicsFilterComponent,
    static: false,
  })
  private demographicsFilter!: DemographicsFilterComponent;

  @ViewChild('segments_panels_filter', {
    read: ClusterGroupsFilterComponent,
    static: false,
  })
  private segmentsPanelsFilter!: ClusterGroupsFilterComponent;

  @ViewChild('profiling_filter', {
    read: ProfilingFilterComponent,
    static: false,
  })
  private profilingFilter!: ProfilingFilterComponent;

  @ViewChild('trimmed_responses_filter', {
    read: TirmmedResponsesFilterComponent,
    static: false,
  })
  private trimmedResponsesFilter!: TirmmedResponsesFilterComponent;

  @ViewChild('cleaned_responses_filter', {
    read: CleanedResponsesFilterComponent,
    static: false,
  })
  private cleanedResponsesFilter!: CleanedResponsesFilterComponent;

  constructor(private tableViewService: TableViewService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const { mission } = this.context;
    if (mission?.mission_trim_details?.length) {
      const { mission_trim_details } = mission;
      this.missionHasTrimmedResponses =
        mission_trim_details[0].response_trim_count > 0;
    }
    this.missionHasCleanedResponses =
      this.context.missionHasCleanedResponses ?? false;

    this.profilingSectionTitle = this.context?.global
      ? 'builder.dimensionGlobalProfiling'
      : 'builder.dimensionExternalAttributes';
  }

  onClose(save?: boolean): void {
    if (!this.dialog || !this.context || !this.crosstabConfig) {
      return;
    }

    if (!save) {
      this.dialog.close();
      return;
    }

    this.completesFilter?.prepareSave(this.context, this.crosstabConfig);
    this.demographicsFilter?.prepareSave(this.context, this.crosstabConfig);
    this.segmentsPanelsFilter?.prepareSave(this.context, this.crosstabConfig);
    this.profilingFilter?.prepareSave(this.context, this.crosstabConfig);
    this.respondentsFilter?.prepareSave(this.context, this.crosstabConfig);
    this.trimmedResponsesFilter?.prepareSave(this.context, this.crosstabConfig);
    this.cleanedResponsesFilter?.prepareSave(this.context, this.crosstabConfig);

    this.inprogress = true;
    this.configChange.emit(this.crosstabConfig);

    setTimeout(() => {
      this.onClose();
      this.inprogress = false;
    }, 100);
  }

  onFilterChanged(
    updatedConfig: ICrosstabConfig,
    changeKind?:
      | 'completes'
      | 'demographics'
      | 'segments_panels'
      | 'respondents'
      | 'profiling'
      | 'trimmed_responses'
      | 'cleaned_responses'
  ): void {
    if (changeKind) {
      const filterStatusForChange =
        this.tableViewService.crosstabConfigHasAdvancedFilters(updatedConfig);
      switch (changeKind) {
        case 'completes':
          this.filterStatus.completes = filterStatusForChange.completes;
          break;
        case 'segments_panels':
          this.filterStatus.segmentsPanels =
            filterStatusForChange.segmentsPanels;
          break;
        case 'profiling':
          this.filterStatus.profiling = filterStatusForChange.profiling;
          this.filterStatus.age_group = filterStatusForChange.age_group;
          this.filterStatus.gender = filterStatusForChange.gender;
          break;
        case 'respondents':
          this.filterStatus.respondents = filterStatusForChange.respondents;
          break;
        case 'demographics':
          this.filterStatus.age_group = filterStatusForChange.age_group;
          this.filterStatus.education = filterStatusForChange.education;
          this.filterStatus.employment = filterStatusForChange.employment;
          this.filterStatus.ethnicity = filterStatusForChange.ethnicity;
          this.filterStatus.gender = filterStatusForChange.gender;
          this.filterStatus.household = filterStatusForChange.household;
          this.filterStatus.income_level = filterStatusForChange.income_level;
          this.filterStatus.parenting = filterStatusForChange.parenting;
          this.filterStatus.region = filterStatusForChange.region;
          this.filterStatus.relationship = filterStatusForChange.relationship;
          this.filterStatus.state = filterStatusForChange.state;
          this.filterStatus.custom_age_group =
            filterStatusForChange.custom_age_group;
          break;
        case 'trimmed_responses':
          this.filterStatus.trimmed_responses =
            filterStatusForChange.trimmed_responses;
          break;
        case 'cleaned_responses':
          this.filterStatus.cleaned_responses =
            filterStatusForChange.cleaned_responses;
          break;
        default:
          break;
      }
    } else {
      this.filterStatus =
        this.tableViewService.crosstabConfigHasAdvancedFilters(updatedConfig);
    }

    this.filterTags = this.tableViewService.getAdvancedFiltersTags(
      this.filterStatus,
      this.context?.global
    );
    this.hasFilter = this.checkHasFilters(this.filterStatus);
  }

  onResetFilters(): void {
    this.demographicsFilter?.reset();
    this.segmentsPanelsFilter?.reset();
    this.profilingFilter?.reset();
    this.respondentsFilter?.reset();
    this.completesFilter?.reset();
    this.trimmedResponsesFilter?.reset();
    this.cleanedResponsesFilter?.reset();
  }

  private initialize(filterConfig: IAdvancedFiltersConfig | undefined): void {
    if (!filterConfig || !this.crosstabConfig) {
      return;
    }

    if (filterConfig.loading) {
      this.loading = true;
      return;
    }

    this.loading = false;
    this.onFilterChanged(this.crosstabConfig.crosstab);

    setTimeout(() => {
      this.demographicsFilterBinding = {
        data: filterConfig.demographicsFilterData,
      };

      if (filterConfig.allowedFilters?.segmentsPanels) {
        this.segmentsPanelsFilterBinding = {
          data: filterConfig.segmentsPanelsFilterData,
        };
      }

      if (filterConfig.allowedFilters?.profiling) {
        this.profilingFilterBinding = {
          data: filterConfig.profilingFilterData,
        };
      }
    }, 0);
  }

  private checkHasFilters(filter: IAdvancedFilters): boolean {
    return Object.keys(filter).some((x) => filter[x]?.included);
  }
}
