/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ActionKind,
  ActionKindVariant,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import {
  ConnectedPositioningStrategy,
  HorizontalAlignment,
  IgxDialogComponent,
  IgxDropDownComponent,
  NoOpScrollStrategy,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';
import {
  IgcContentPane,
  IgcDockManagerComponent,
  IgcDockManagerLayout,
  IgcDockManagerPaneType,
  IgcSplitPaneOrientation,
} from '@infragistics/igniteui-dockmanager';
import { TranslateService } from '@ngx-translate/core';
import { delay, forkJoin, take } from 'rxjs';
import { IBanner } from '../../models/data/request/IBanner';
import { ICrosstabConfig } from '../../models/data/request/ICrosstabConfig';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
} from '../../models/data/request/IDataFilterItem';
import { IDimension } from '../../models/data/request/IDimension';
import { IOrchestrationAgeGroupClassification } from '../../models/data/request/IOrchestrationAgeGroupClassification';
import { ICrosstabConfigUI } from '../../models/ui/i-crosstab-config-ui';
import { CustomBannerPointsActionsService } from '../../services/custom-banner-points-actions.service';
import { SuzyDataService } from '../../services/suzy-data.service';
import { TableViewService } from '../../services/table-view.service';
import { DataUtility } from '../../utilities/data-utility';
import { CalculationsComponent } from './calculations/calculations.component';
import { DragDropManager } from './drag-drop-manager';
import { FiltersComponent } from './filters/filters.component';
import { IBuilder } from './i-builder';
import { IBuilderContext } from './i-builder-context';
import { IBuilderUI } from './i-builder-ui';
import { AgeRangeEditorComponent } from './modals/age-range-editor/age-range-editor.component';
import { IDimensionFilterData } from './modals/dimension-filter/i-dimension-filter-data';
import { CrosstabQuestionComponent } from './providers/advanced/crosstab-question/crosstab-question.component';
import { CustomAgeRangesComponent } from './providers/advanced/custom-age-ranges/custom-age-ranges.component';
import { DemographicDimensionProvider } from './providers/dimensions/demographic-dimension-provider';
import { ExternalAttributesDimensionProvider } from './providers/dimensions/external-attributes-dimension-provider';
import { GlobalDimensionProvider } from './providers/dimensions/global-dimension-provider';
import { QuestionDimensionProvider } from './providers/dimensions/question-dimension-provider';
import { SegmentPanelDimensionProvider } from './providers/dimensions/segment-panel-dimension-provider';
import { SubpopulationsDimensionProvider } from './providers/dimensions/subpopulations-dimension.provider';
import { TargetingDimensionProvider } from './providers/dimensions/targeting-dimension-provider';
import { IDimensionProvider } from './providers/i-dimension-provider';
import { CustomBannerPointsService } from '../../services/custom-banner-points.service';

@Component({
  selector: 'data-explorer-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss'],
})
export class BuilderComponent implements IBuilder {
  actionKind = ActionKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  @ViewChild('confirm_builder_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private confirm_dialog!: IgxDialogComponent;

  @ViewChild('dimension_filter_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private dimension_filter_dialog!: IgxDialogComponent;

  @ViewChild('crosstab_question', {
    read: CrosstabQuestionComponent,
    static: false,
  })
  private crosstab_question!: CrosstabQuestionComponent;

  @Input()
  public context?: IBuilderContext = { brand_id: '', global: false };

  private _config?: ICrosstabConfigUI;
  public get config(): ICrosstabConfigUI | undefined {
    return this._config;
  }

  @Input()
  public set config(value: ICrosstabConfigUI | undefined) {
    this.initialize(value);
    this._config = value;
  }

  @ViewChild('age_range_editor_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private age_range_editor_dialog!: IgxDialogComponent;

  @ViewChild('age_range_editor', {
    read: AgeRangeEditorComponent,
    static: false,
  })
  private age_range_editor!: AgeRangeEditorComponent;

  @ViewChild('custom_age_ranges', {
    read: CustomAgeRangesComponent,
    static: false,
  })
  private custom_age_ranges!: CustomAgeRangesComponent;

  @ViewChild('global_filters', {
    read: FiltersComponent,
    static: false,
  })
  private global_filters!: FiltersComponent;

  @ViewChild('calculations', {
    read: CalculationsComponent,
    static: false,
  })
  private calculations!: CalculationsComponent;

  @ViewChild('dockManager', { static: false })
  private dockManager!: ElementRef<IgcDockManagerComponent>;

  public dragdrop?: DragDropManager;
  public layout?: IgcDockManagerLayout;

  public dimension_filter_binding?: { data?: IDimensionFilterData } = {
    data: undefined,
  };

  public dimensionProviders: Array<IDimensionProvider>;
  public dimensionProvidersEnabled: Array<IDimensionProvider>;

  public ui: IBuilderUI = {
    show_new_banner: false,
    show_relocate_banner_columns: false,
    show_relocate_banner_rows: false,
    confirm_dialog: {
      message: 'are you sure?', // these are localized when used
      title: 'confirm?',
      left: 'cancel',
      right: 'okay',
      callback: null,
    },
    customize: {},
    allowed_filters: {},
  };

  public builder: IBuilder;
  public rangeEditorVisible!: boolean;
  public ageGroupClassification!:
    | IOrchestrationAgeGroupClassification
    | undefined;
  public ageRangeEditorSource!: 'builder' | 'dimension';

  public optionsMenuOverlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  @Output() readonly openSubpopulationForm = new EventEmitter<boolean>(false);
  @Output() readonly editTBSubpopulation = new EventEmitter<string>();

  constructor(
    private renderer: Renderer2,
    private translate: TranslateService,
    private suzy: SuzyDataService,
    private tableViewService: TableViewService,
    private cbpActions: CustomBannerPointsActionsService,
    private cbpService: CustomBannerPointsService
  ) {
    this.builder = this;

    this.dimensionProviders = [
      new ExternalAttributesDimensionProvider(this.translate, this.suzy),
      new TargetingDimensionProvider(this.translate, this.suzy),
      new DemographicDimensionProvider(this.translate),
      new GlobalDimensionProvider(this.translate, this.suzy),
      new SegmentPanelDimensionProvider(this.translate, this.suzy),
      new SubpopulationsDimensionProvider(
        this.translate,
        this.cbpActions,
        this.cbpService
      ),
    ];
    this.dimensionProvidersEnabled = [];
  }

  initialize(value?: ICrosstabConfigUI) {
    if (!value || !this.context) {
      return;
    }

    this.dimensionProviders.push(
      new QuestionDimensionProvider(this.translate, this.tableViewService)
    );

    // Prepare Drag n Drop
    this.initializeDragDrop(value.crosstab);
    this.setAllowedActionsForBannerDimensions(value.crosstab?.columns);
    this.setAllowedActionsForBannerDimensions(value.crosstab?.rows);

    // Initialize Dimension Providers
    const dimensionProvidersEnabled: Array<IDimensionProvider> = [];
    for (const provider of this.dimensionProviders) {
      const initialized = provider.initialize(
        this.context,
        this.builder,
        value
      );
      if (initialized) {
        provider.enabled = true;
        this.dragdrop?.prepareNewItems(provider.code, provider.dimensions);
        dimensionProvidersEnabled.push(provider);
      } else {
        provider.enabled = false;
      }
    }
    this.dimensionProvidersEnabled = dimensionProvidersEnabled;

    // Initialize Layouts
    this.initializeLayout();
    this.ui.allowed_filters.demographics =
      !this.context?.global &&
      this.context.mission?.mission_kind !== MissionKind.external_link;
    this.ui.allowed_filters.completes =
      this.tableViewService.canFilterCompletes(
        this.context?.mission,
        this.context?.action
      );
    this.ui.allowed_filters.respondents = this.context?.qualityEnabled ?? false;
    this.ui.allowed_filters.trimmedResponses =
      this.context?.trimmedResponsesEnabled ?? false;
    this.ui.allowed_filters.subpopulations = true; // TODO: Assign context

    if (
      !this.context?.global &&
      this.context.mission?.mission_kind !== MissionKind.external_link
    ) {
      forkJoin([
        this.suzy.audienceSegmentsGet(this.context?.brand_id).pipe(take(1)),
        this.suzy.audiencePanelsGet(this.context?.brand_id).pipe(take(1)),
      ]).subscribe(([segmentsItems, panelsItems]) => {
        this.ui.allowed_filters.segmentsPanels =
          [...(segmentsItems ?? []), ...(panelsItems ?? [])].length > 0;
      });
      this.ui.allowed_filters.profiling = false;
    } else {
      this.ui.allowed_filters.segmentsPanels = false;
      this.ui.allowed_filters.profiling = true;
    }

    this.ui.customize.quality = this.context?.qualityEnabled ?? false;
    this.ui.customize.calculation = this.tableViewService.isCalculateVisible(
      this.context?.statTestingEnabled ?? false,
      this.context?.advancedCalculationsEnabled ?? false,
      this.context?.action
    );

    this.ui.customize.statTesting = this.context?.statTestingEnabled ?? false;

    this.ageGroupClassification =
      value.crosstab?.orchestration?.custom_age_group;
  }

  initializeLayout() {
    const panes: Array<IgcContentPane> = [];
    let activePane: IgcContentPane;

    for (const provider of this.dimensionProviders) {
      if (provider.enabled) {
        if (provider.code === 'questions') {
          panes.push({
            type: IgcDockManagerPaneType.contentPane,
            contentId: 'crosstab-question',
            header: this.translate.instant('builder.crosstabQuestionTab'),
            allowClose: false,
            allowMaximize: false,
            allowPinning: false,
            allowDocking: false,
            allowFloating: false,
          });
        }

        const tmpPane: IgcContentPane = {
          type: IgcDockManagerPaneType.contentPane,
          contentId: provider.code,
          header: provider.name,
          allowClose: false,
          allowMaximize: false,
          allowPinning: false,
          allowDocking: false,
          allowFloating: false,
        };
        if (provider.code === 'demographics-standard') {
          activePane = tmpPane;
        }
        panes.push(tmpPane);
      }
    }

    const targetingTab = panes.find((pane) => pane.contentId === 'targeting');
    if (targetingTab) {
      activePane = targetingTab;
    }

    this.layout = {
      rootPane: {
        type: IgcDockManagerPaneType.splitPane,
        orientation: IgcSplitPaneOrientation.vertical,
        floatingResizable: false,
        panes: [
          {
            type: IgcDockManagerPaneType.documentHost,
            size: 285,
            rootPane: {
              type: IgcDockManagerPaneType.splitPane,
              orientation: IgcSplitPaneOrientation.vertical,
              panes: [
                {
                  type: IgcDockManagerPaneType.tabGroupPane,
                  panes: panes,
                },
              ],
            },
          },
          {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,
            size: 465,
            panes: [
              {
                type: IgcDockManagerPaneType.contentPane,
                headerId: 'preview-row-header',
                contentId: 'preview-row',
                header: this.translate.instant('builder.previewRow'),
                size: 25,
                allowClose: false,
                allowMaximize: false,
                allowPinning: false,
                allowDocking: false,
                allowFloating: false,
              },
              {
                type: IgcDockManagerPaneType.contentPane,
                headerId: 'preview-column-header',
                contentId: 'preview-column',
                header: this.translate.instant('builder.previewColumn'),
                size: 75,
                allowClose: false,
                allowMaximize: false,
                allowPinning: false,
                allowDocking: false,
                allowFloating: false,
              },
            ],
          },
        ],
      },
      floatingPanes: [],
    };

    setTimeout(() => {
      requestAnimationFrame(() => {
        const dockManager = document.getElementById(
          'dockManager'
        ) as IgcDockManagerComponent;
        dockManager.activePane = activePane;
      });
    }, 100);
  }

  initializeDragDrop(config: ICrosstabConfig) {
    if (!this.dragdrop) {
      this.dragdrop = new DragDropManager(this.renderer, this);
    }
    this.dragdrop.prepareItems(true, config.rows);
    this.dragdrop.prepareItems(false, config.columns);
  }

  prepareSave() {
    if (!this.config) {
      return;
    }

    this.crosstab_question.prepareSave(this.context!, this.config);

    this.ensureUniqueBanners();
    this.updateBannerNames();
  }

  ensureUniqueBanners(): void {
    if (!this.config) {
      return;
    }

    this.config.crosstab.rows.forEach((r, i) => {
      r.identifier = `${i}`;
    });

    this.config.crosstab.columns.forEach((c, i) => {
      c.identifier = `${i}`;
    });
  }

  dissever() {
    if (this.dragdrop) {
      this.dragdrop.cleanItems();
    }
  }

  removeDimension(
    srouce: 'rows' | 'columns',
    banners: Array<IBanner>,
    banner: IBanner,
    dimensions: Array<IDimension>,
    dimension: IDimension,
    prompt: boolean = true
  ) {
    if (prompt) {
      const message = this.translate.instant('builder.confirm_delete_format', {
        '0': dimension.label,
      });
      this.confirm_dialog_show(message, () =>
        this.removeDimension(
          srouce,
          banners,
          banner,
          dimensions,
          dimension,
          false
        )
      );
      return;
    }
    this.dragdrop?.resetDropDisabledState();
    const index = dimensions.findIndex((x) => x === dimension);
    if (index < 0) {
      return;
    }
    const removedDimensions = dimensions.splice(index, 1);
    if (dimensions.length == 0) {
      this.removeBanner(srouce, banners, banner, false);
    } else if (
      removedDimensions?.length &&
      removedDimensions[0].field?.field === 'action_setting_id_cross' &&
      removedDimensions[0].field?.source === 'answer'
    ) {
      delete this.config?.crosstab?.action_id_crosstabs;
    }
  }

  removeBanner(
    source: 'rows' | 'columns',
    banners: Array<IBanner>,
    banner: IBanner,
    prompt: boolean = true
  ) {
    if (prompt) {
      const message = this.translate.instant('builder.confirm_delete_format', {
        '0': banner.name,
      });
      this.confirm_dialog_show(message, () =>
        this.removeBanner(source, banners, banner, false)
      );
      return;
    }
    this.dragdrop?.resetDropDisabledState();
    const index = banners.findIndex((x) => x === banner);
    if (index < 0) {
      return;
    }
    const removedBanners = banners.splice(index, 1);
    if (
      removedBanners?.length &&
      removedBanners[0].dimensions.some(
        (x) =>
          x.field?.field === 'action_setting_id_cross' &&
          x.field?.source === 'answer'
      )
    ) {
      delete this.config?.crosstab?.action_id_crosstabs;
    }
    if (source === 'columns' && !banners.length) {
      this.calculations.onColumnBannersRemoved();
    }
    setTimeout(() => {
      if (this.config?.crosstab) {
        this.initializeDragDrop(this.config.crosstab);
      }
    });
  }

  addDimensionToNewRowBanner(label: string, dimensions: IDimension[]): boolean {
    if (!this.config || dimensions.length == 0) {
      return false;
    }
    this.config.crosstab.rows.push({
      name: label,
      dimensions: dimensions,
    });
    this.initializeDragDrop(this.config.crosstab);
    return true;
  }

  addDimensionToNewColumnBanner(
    label: string,
    dimensions: IDimension[]
  ): boolean {
    if (!this.config || dimensions.length == 0) {
      return false;
    }
    this.config.crosstab.columns.push({
      name: label,
      dimensions: dimensions,
    });
    this.initializeDragDrop(this.config.crosstab);
    return true;
  }

  addBanner(banner: IBanner, isRow: boolean): void {
    if (!this.config) {
      return;
    }
    this.dragdrop?.resetDropDisabledState();
    if (isRow) {
      this.config.crosstab.rows.push(banner);
    } else {
      this.config.crosstab.columns.push(banner);
    }
    this.initializeDragDrop(this.config.crosstab);
  }

  renameDimensionOpen(
    dropdown: IgxDropDownComponent | null,
    banners: Array<IBanner>,
    banner: IBanner,
    dimensions: Array<IDimension>,
    dimension: IDimension
  ): void {
    this.dimension_filter_binding = {
      data: {
        dimension: JSON.parse(JSON.stringify(dimension)),
        type: dimension.type,
        source: dimension,
        renaming: true,
        brand_id: this.config!.crosstab.brand_id,
      },
    };
    dropdown?.clearSelection();
    setTimeout(() => {
      this.dimension_filter_dialog.open();
    }, 0);
  }

  netDimensionOpen(
    dropdown: IgxDropDownComponent | null,
    banners: Array<IBanner>,
    banner: IBanner,
    dimensions: Array<IDimension>,
    dimension: IDimension,
    isRow?: boolean
  ) {
    this.dimension_filter_binding = {
      data: {
        dimension: JSON.parse(JSON.stringify(dimension)),
        type: 'net',
        source: dimension,
        renaming: false,
        brand_id: this.context?.brand_id ?? '',
        action_id_crosstabs: this.config!.crosstab.action_id_crosstabs,
        action_id_primary: this.config!.crosstab.action_id_primary
          ? this.config?.crosstab.action_id_primary
          : this.context?.action?.action_id ?? '',
        age_classification_id:
          this.context?.mission?.quota?.age_classification_id ??
          this.suzy.standardAgeClassificationId,
        region_classification_id:
          this.context?.mission?.quota?.region_classification_id ??
          this.suzy.standardRegionClassificationId,
        is_row: isRow ?? false,
        mission: this.context?.mission,
      },
    };

    dropdown?.clearSelection();
    setTimeout(() => {
      this.dimension_filter_dialog.open();
    }, 0);
  }

  filterDimensionOpen(
    dropdown: IgxDropDownComponent | null,
    banners: Array<IBanner>,
    banner: IBanner,
    dimensions: Array<IDimension>,
    dimension: IDimension
  ): void {
    this.dimension_filter_binding = {
      data: {
        dimension: JSON.parse(JSON.stringify(dimension)),
        type: 'field',
        source: dimension,
        renaming: false,
        brand_id: this.context?.brand_id ?? '',
        action_id_crosstabs: this.config!.crosstab.action_id_crosstabs,
        action_id_primary: this.config!.crosstab.action_id_primary
          ? this.config?.crosstab.action_id_primary
          : this.context?.action?.action_id ?? '',
        age_classification_id:
          this.context?.mission?.quota?.age_classification_id ??
          this.suzy.standardAgeClassificationId,
        region_classification_id:
          this.context?.mission?.quota?.region_classification_id ??
          this.suzy.standardRegionClassificationId,
        custom_age_group:
          this.config?.crosstab?.orchestration?.custom_age_group,
        mission: this.context?.mission,
      },
    };

    dropdown?.clearSelection();
    setTimeout(() => {
      this.dimension_filter_dialog.open();
    }, 0);
  }

  notify_dialog_show(message: string) {
    this.ui.confirm_dialog.title = this.translate.instant(
      'builder.confirm_title'
    );
    this.ui.confirm_dialog.left = '';
    this.ui.confirm_dialog.right = this.translate.instant(
      'builder.confirm_right'
    );

    this.ui.confirm_dialog.message = message;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.ui.confirm_dialog.callback = () => {};
    this.confirm_dialog.open();
  }

  confirm_dialog_show(message: string, callback: () => void) {
    this.ui.confirm_dialog.title = this.translate.instant(
      'builder.confirm_title'
    );
    this.ui.confirm_dialog.left = this.translate.instant(
      'builder.confirm_left'
    );
    this.ui.confirm_dialog.right = this.translate.instant(
      'builder.confirm_right'
    );

    this.ui.confirm_dialog.message = message;
    this.ui.confirm_dialog.callback = callback;
    this.confirm_dialog.open();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  dialog_callback(accept: boolean, dialog: IgxDialogComponent) {
    if (dialog) {
      dialog.close();
    }

    if (!accept) {
      return;
    }

    if (this.ui.confirm_dialog.callback) {
      this.ui.confirm_dialog.callback();
    }
  }

  setAllowedActionsForBannerDimensions(banners: Array<IBanner>): void {
    if (!banners?.length) {
      return;
    }

    const { action_kind, action_kind_variant } = this.context?.action ?? {
      action_kind: undefined,
      action_kind_variant: undefined,
    };
    const notAllowedFields = [
      'action_setting_id_cross',
      'monadic_variant_id_root',
      'interlocked_quota_entry',
    ];
    banners.forEach((banner) => {
      banner.dimensions.forEach((dimension) => {
        let isNetAllowed = true;
        let isFilterAllowed = true;
        if (
          dimension.field?.source === 'answer' &&
          notAllowedFields.findIndex((x) => x === dimension.field?.field) >= 0
        ) {
          isNetAllowed = false;
          isFilterAllowed = false;
        } else if (
          dimension.field?.source === 'answer' &&
          [
            ActionKindVariant.grid_rank,
            ActionKindVariant.grid_scale,
            ActionKindVariant.grid_open,
          ].findIndex((x) => x === action_kind_variant) >= 0
        ) {
          if (dimension.field?.field === 'action_setting_id_root') {
            isNetAllowed = false;
          } else if (
            dimension.field?.field === 'action_setting_id_secondary_root'
          ) {
            isNetAllowed = action_kind_variant !== ActionKindVariant.grid_open;
          }
        } else if (
          dimension.field?.source === 'answer' &&
          action_kind === ActionKind.grid
        ) {
          if (dimension.field?.field === 'action_setting_id_root') {
            isNetAllowed = false;
          } else if (
            dimension.field?.field === 'action_setting_id_secondary_root'
          ) {
            isNetAllowed =
              this.context?.action?.grid?.grid_kind !==
              ActionStructureGridKind.open;
          }
        } else if (
          dimension.field?.source === 'answer' &&
          dimension.field?.field === 'action_setting_id_root' &&
          [
            ActionKind.multiplechoice,
            ActionKind.auto_assign,
            ActionKind.turf,
          ].findIndex((x) => x === action_kind) < 0
        ) {
          isNetAllowed = false;
          isFilterAllowed = false;
        } else if (
          dimension.field?.source === 'demographic' &&
          dimension.field?.field === 'custom_age_group_id'
        ) {
          isNetAllowed = false;
          isFilterAllowed = true;
        }

        dimension.net_not_allowed = !isNetAllowed;
        dimension.filter_not_allowed = !isFilterAllowed;
      });
    });
  }

  onFiltersApplied(newConfig: ICrosstabConfigUI): void {
    if (!this.config) {
      return;
    }
    this.config.crosstab.data_filter = { ...newConfig.crosstab.data_filter };
  }

  updateBannerNames(): void {
    if (!this.config) {
      return;
    }

    (this.config.crosstab.rows ?? []).forEach((rowBanner) => {
      const dimensionLabels = (rowBanner.dimensions ?? []).map(
        (dim) => dim.label
      );
      if (dimensionLabels?.length) {
        rowBanner.name = dimensionLabels.join(', ');
      }
    });

    (this.config.crosstab.columns ?? []).forEach((colBanner) => {
      const dimensionLabels = (colBanner.dimensions ?? []).map(
        (dim) => dim.label
      );
      if (dimensionLabels?.length) {
        colBanner.name = dimensionLabels.join(', ');
      }
    });
  }

  onEditAgeRanges(
    source: 'builder' | 'dimension',
    dropdown?: IgxDropDownComponent
  ): void {
    this.ageRangeEditorSource = source;
    this.rangeEditorVisible = true;

    const closeAgeRangeEditor = (
      customAgeGroup?: IOrchestrationAgeGroupClassification
    ) => {
      this.ageGroupClassification = customAgeGroup;
      if (customAgeGroup) {
        this.custom_age_ranges?.updateConfig(customAgeGroup);
      }
      this.rangeEditorVisible = false;
    };

    const removeHideShow = (banner: IBanner) => {
      banner.dimensions.forEach((dim) => {
        if (
          dim.field?.source === 'demographic' &&
          dim.field?.field === 'custom_age_group_id' &&
          dim.selection
        ) {
          delete dim.selection;
        }
      });
    };

    setTimeout(() => {
      this.age_range_editor_dialog.open();
    }, 0);

    this.age_range_editor_dialog.closed
      .pipe(take(1), delay(0))
      .subscribe(() => {
        if (
          !this.config?.crosstab ||
          !this.age_range_editor ||
          !this.age_range_editor.ageGroupConfig.age_group_classification_name ||
          !this.age_range_editor.ageGroupConfig.age_groups.length
        ) {
          closeAgeRangeEditor();
          return;
        }

        if (!this.config.crosstab.orchestration?.custom_age_group) {
          this.config.crosstab.orchestration = {
            custom_age_group: this.age_range_editor.ageGroupConfig,
          };
          closeAgeRangeEditor(
            this.config.crosstab.orchestration.custom_age_group
          );
          return;
        }

        if (!this.age_range_editor.ageGroupConfigUpdated) {
          this.config.crosstab.orchestration.custom_age_group.age_group_classification_name =
            this.age_range_editor.ageGroupConfig.age_group_classification_name;
          closeAgeRangeEditor(
            this.config.crosstab.orchestration.custom_age_group
          );
          return;
        }

        this.config.crosstab.orchestration.custom_age_group = {
          age_group_classification_id: DataUtility.generateGUID(),
          age_group_classification_name:
            this.age_range_editor.ageGroupConfig.age_group_classification_name,
          age_groups: this.age_range_editor.ageGroupConfig.age_groups,
        };

        (this.config.crosstab.columns ?? []).forEach((banner) => {
          removeHideShow(banner);
        });

        (this.config.crosstab.rows ?? []).forEach((banner) => {
          removeHideShow(banner);
        });

        closeAgeRangeEditor(
          this.config.crosstab.orchestration.custom_age_group
        );
        if (this.global_filters) {
          this.global_filters.customAgeRangesUpdated();
        }
      });

    if (dropdown) {
      dropdown.clearSelection();
    }
  }

  onCalculationsApplied(newConfig: ICrosstabConfigUI): void {
    if (!this.config) {
      return;
    }
    this.config.crosstab.expansions = newConfig.crosstab.expansions?.length
      ? [...newConfig.crosstab.expansions]
      : [];
    this.config.crosstab.additions = newConfig.crosstab.additions?.length
      ? [...newConfig.crosstab.additions]
      : [];

    const newConfigSpamResponses = DataUtility.findFilterItem(
      newConfig.crosstab.data_filter,
      EDataFilterNames.includeSpam,
      EDataFilterUIAreas.customize
    );
    const configSpamResponses = DataUtility.findFilterItem(
      this.config.crosstab.data_filter,
      EDataFilterNames.includeSpam,
      EDataFilterUIAreas.customize
    );

    if (newConfigSpamResponses.item) {
      if (
        this.config.crosstab.data_filter.items?.length &&
        configSpamResponses.ix >= 0
      ) {
        this.config.crosstab.data_filter.items[configSpamResponses.ix] = {
          ...newConfigSpamResponses.item,
        };
      } else if (this.config.crosstab.data_filter.items) {
        this.config.crosstab.data_filter.items?.push(
          newConfigSpamResponses.item
        );
      }
    }
  }

  configHasTotalColumn(): boolean {
    return (this.config?.crosstab.expansions ?? []).some((x) =>
      DataUtility.totalsColumnDimensions.some((y) => y === x.name)
    );
  }

  onNewDimensionDragStart(): void {
    this.dragdrop!.newdimension_dragStart();
    const builderContainer = document.getElementById('builderContainer');
    if (builderContainer && this.dockManager?.nativeElement) {
      const tabsRect = this.dockManager.nativeElement.getBoundingClientRect();
      if (tabsRect.top > 20) {
        const newTop =
          this.dockManager.nativeElement.offsetTop +
          (builderContainer?.offsetTop ?? 0);
        const psScroll = document.getElementById('content-ps-scroll');
        if (psScroll) {
          psScroll.scrollTop = newTop + 48;
        } else {
          window.scroll(0, newTop > 0 ? newTop : 0);
        }
      }
    }
  }

  onSwapBanners(): void {
    if (!this.config?.crosstab) {
      return;
    }

    let tmpRows: IBanner[] = [];
    let tmpColumns: IBanner[] = [];
    if (this.config.crosstab.rows) {
      tmpRows = this.config.crosstab.rows;
    }
    if (this.config.crosstab.columns) {
      tmpColumns = this.config.crosstab.columns;
    }

    this.config.crosstab.columns = tmpRows;
    this.config.crosstab.rows = tmpColumns;
    this.initializeDragDrop(this.config.crosstab);
  }

  onNavigateToExternalUrl(url: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }

  navigateToCreateSP(): void {
    this.openSubpopulationForm.emit(true);
  }

  editSubppulaton(dimension: any): void {
    const dimensionId = dimension.field.parent_id;
    this.editTBSubpopulation.emit(dimensionId);
  }
}
