<div class="suzy-modal">
  <div class="modal-header">
    <div class="modal-header-left">
      <span class="h2 text-suzy-grey-900">{{ 'tableInformation.title' | translate }}</span>
      <span class="body-copy-caption text-suzy-grey-700">{{'crosstab.lastUpdated' | translate}} {{ data.updated_utc | date: 'medium' }}</span>
    </div>
    <button class="suzy-icon-button" (click)="onClose()"><span class="material-symbols-rounded">close</span></button>
  </div>
  <div class="modal-body">
    <mat-tab-group selectedIndex="0" class="suzy-mat-tab-left-aligned" animationDuration="0ms">
      <mat-tab label="{{ 'tableInformation.tabGeneral' | translate }}">
        <div class="info-table">
          <div class="info-table__header">
            <span>
              {{ 'tableInformation.headerDataType' | translate }}
            </span>
          </div>
          <div class="info-table__row">
            <div class="info-table__row-content">
              <span>{{ 'tableInformation.showing' | translate }}</span>
            </div>
            <div class="info-table__row-content">
              <span *ngIf="data.isPercentView">{{
                'tableInformation.columnPercentage' | translate
                }}</span>
              <span *ngIf="!data.isPercentView">{{
                'tableInformation.counts' | translate
                }}</span>
            </div>
          </div>
        </div>

        <div class="info-table">
          <div class="info-table__header">
            <span>
              {{ 'tableInformation.headerTableOptions' | translate }}
            </span>
          </div>
          <div class="info-table__row">
            <div class="info-table__row-content">
              <span>{{ 'tableInformation.filters' | translate }}</span>
            </div>
            <div class="info-table__row-content">
              <span *ngIf="!hasFilters">{{
                'tableInformation.none' | translate
                }}</span>
              <ng-container *ngIf="hasFilters">
                <ng-container *ngFor="let tag of data.filterTags">
                  <ng-container [ngSwitch]="tag.kind">
                    <span *ngSwitchCase="filterTagKind.token">{{
                      tag.value | translate
                      }}</span>
                    <span *ngSwitchCase="filterTagKind.async">{{
                      tag.asyncParams
                      | asyncFilterTag
                      : {
                      brand_id: data.brandId,
                      mission_id: data.missionId
                      }
                      | async
                      }}</span>
                    <span *ngSwitchDefault>{{ tag.value }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="info-table__row">
            <div class="info-table__row-content">
              <span>{{ 'tableInformation.calculations' | translate }}</span>
            </div>
            <div class="info-table__row-content">
              <span *ngIf="!hasCalculations">{{
                'tableInformation.none' | translate
                }}</span>
              <ng-container *ngIf="hasCalculations">
                <ng-container *ngFor="let tag of data.calculationsTags">
                  <ng-container [ngSwitch]="tag.kind">
                    <span *ngSwitchCase="filterTagKind.token">{{
                      tag.value | translate
                      }}</span>
                    <span *ngSwitchDefault>{{ tag.value }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>