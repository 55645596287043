import { IPrimaryAction } from '../data/request/ICrosstabConfig';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
} from '../data/request/IDataFilterItem';
import { ITableViewConfig } from './ITableViewConfig';

export interface ITableView {
  id: string;
  isGlobal: boolean;
  priority?: number;
  display_name: string;
  entity: string;
  key: string;
  brand_id?: string;
  mission_id?: string;
  action_id?: string;
  config: ITableViewConfig;
  action_id_primary?: string;
  action_id_crosstabs?: string[];
  table_view_id: string;
  organization_id?: string;
  updated_utc?: string;
  edited_by?: Editedby;
  edited_utc?: string;
  requires_processing?: boolean;
  self_subscription?: SelfSubscription;
  primary_action?: IPrimaryAction;
}

export interface SelfSubscription {
  tableview_subscription_id: string;
  organization_id: string;
  table_view_id: string;
  user_id: string;
  email_address_masked: string;
  updated_utc: string;
}

interface Editedby {
  user_id: string;
  full_name: string;
}

export interface ITableViewName {
  mission_id: string;
  action_id: string;
  table_view_id: string;
  display_name: string;
}

export const TableViewConfigBase: ITableViewConfig = {
  format: 'crosstab.explorer.1',
  data_filter: {
    items: [
      {
        ui_area: EDataFilterUIAreas.customize,
        name: EDataFilterNames.partialResponses,
        enabled: true,
        bool_1: true,
      },
      {
        ui_area: EDataFilterUIAreas.customize,
        name: EDataFilterNames.excludeUsers,
        enabled: true,
        list_1: [],
        as_exclude: true,
      },
      {
        ui_area: EDataFilterUIAreas.customize,
        name: EDataFilterNames.includeSpam,
        enabled: true,
        bool_1: false,
      },
      {
        ui_area: EDataFilterUIAreas.customize,
        name: EDataFilterNames.includeTrimmed,
        enabled: true,
        bool_1: false,
      },
    ],
  },
  additions: [
    {
      name: 'respondent_percentage_column',
      param_1: 'additions',
      param_2: '2',
    },
  ],
  expansions: [
    {
      name: 'span_total_responses_row',
      label: 'Total Number Of Responses',
      start: false,
    },
    {
      name: 'span_base_size_row',
      label: 'Base Total',
      start: false,
    },
    {
      name: 'primary_total_responses_column',
      label: 'Total',
      start: true,
    },
  ],
  columns: [],
  rows: [
    {
      name: 'Primary',
      dimensions: [
        {
          type: 'field',
          label: 'Primary Response',
          field: {
            source: 'answer',
            field: 'action_setting_id_root',
            action_id: 'primary',
          },
        },
      ],
    },
  ],
};
