/* eslint-disable @typescript-eslint/no-inferrable-types, @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AxisLabelsLocation,
  IgxBarSeriesComponent,
  IgxCalloutLayerComponent,
  IgxCategoryYAxisComponent,
  IgxDataChartComponent,
  IgxNumericXAxisComponent,
  SeriesOutlineMode,
} from 'igniteui-angular-charts';
import * as d3 from 'd3-scale-chromatic';
import { HorizontalAlignment, VerticalAlignment } from 'igniteui-angular-core';
import {
  IgxDialogComponent,
  SortingDirection,
} from '@infragistics/igniteui-angular';
import { DataUtility } from '../../../utilities/data-utility';
import { IColumnInfo } from '../../../models/ui/i-column-info';
import { PercentPipe } from '@angular/common';
import { delay, take } from 'rxjs';

const colorScale = d3.interpolateRdYlBu;
const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: false,
};
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  private chartUseNarrowBarsTreshold = 10000;
  private chartHeightWarningTreshold = 25000;
  private _gridData?: any;
  private chartHeight!: string;
  private yAxisCategoryGap = 0.15;
  private yAxisBarGapRatio = -0.5;
  private colors!: string[];
  private chartLabels: any = [];
  chartType!: string;
  chartData: any = [];
  chartSizeWarning?: boolean;

  cols: Array<{
    title: string;
    field: string;
    legendData?: Array<{ label: string; value: string }>;
    brushColor?: string;
  }> = [];

  get gridData(): any {
    return this._gridData;
  }

  @Input() set gridData(data: any) {
    this.adjustGridDataForChart(data);
  }

  @Input() question: string = '';
  @Input() isPercent: boolean = false;
  @Input() isSingleChart: boolean = false;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('chart', { static: true })
  private chart!: IgxDataChartComponent;

  @ViewChild('chartYAxis', { static: true })
  private yAxis!: IgxCategoryYAxisComponent;

  @ViewChild('chartXAxis', { static: true })
  private xAxis!: IgxNumericXAxisComponent;

  @ViewChildren('barValuesLayer')
  private barValuesLayer!: QueryList<IgxCalloutLayerComponent>;

  @ViewChildren('barSeries') barSeries!: QueryList<IgxBarSeriesComponent>;

  @ViewChild('chart_warning_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private chart_warning_dialog!: IgxDialogComponent;

  constructor(private percentPipe: PercentPipe) {}

  ngOnInit(): void {
    if (this.isSingleChart) {
      this.chartType = 'single';
      this.buildSingleChart(this.gridData);
    } else {
      this.chartType = 'multiple';
      this.buildMultipleChart(this.gridData);
    }
    this.prepareChart();
  }

  buildSingleChart(gridData: any): void {
    this.chartData = [];
    const rowLengths = (this.gridData.rows ?? []).map(
      (row: any[]) => row.length
    );
    const rowsDepth = rowLengths?.length ? Math.max(...rowLengths) : 1;

    const refCol = gridData.columns[gridData.columns.length - 1];
    const dataKey = refCol.columns[0].field;

    gridData.data?.forEach((datum: any, i: any) => {
      let name = datum.rbd_1;
      if (rowsDepth > 3) {
        name = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${datum.rbd_3 ?? ''}, ${
          datum.rbd_4 ?? ''
        }`;
      } else if (rowsDepth === 3) {
        name = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${datum.rbd_3 ?? ''}`;
      } else if (rowsDepth === 2) {
        name = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}`;
      }

      this.chartData.push({
        name,
        x: this.isPercent ? datum[dataKey] * 100 : datum[dataKey],
        y: i,
        count: this.isPercent ? datum[dataKey] * 100 : datum[dataKey],
        label: this.isPercent
          ? `${this.percentPipe.transform(datum[dataKey])}`
          : `${datum[dataKey]}`,
      });
    });
  }

  buildMultipleChart(data: any): void {
    this.chartData = [];
    this.cols = [];
    const rowLengths = (this.gridData.rows ?? []).map(
      (row: any[]) => row.length
    );
    const rowsDepth = rowLengths?.length ? Math.max(...rowLengths) : 1;

    // Get Each Demographic Field
    if (this.gridData.depth === 1) {
      data.columns.forEach((col: any, i: any) => {
        if (i > 0) {
          col.columns.forEach((colItem) => {
            if (!this.isTotalColumn(colItem)) {
              this.cols.push({
                title: colItem.title,
                field: colItem.field,
                legendData: [{ label: col.title, value: colItem.title }],
              });
            }
          });
        }
      });
    } else if (this.gridData.depth > 1) {
      this.buildNestedColumns(this.gridData.depth);
    }
    if (this.isPercent) {
      this.gridData.data.forEach((datum: any) => {
        const percentAppliedFields: string[] = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.cols
          .filter((x) => x.field.startsWith('c'))
          .forEach((col, j) => {
            if (!percentAppliedFields.some((x) => x === `${col.field}`)) {
              datum[col.field] = datum[col.field] * 100;
              percentAppliedFields.push(`${col.field}`);
            }
          });
      });
    }

    // Set up Chart Data
    this.cols.forEach((col, i) => {
      const arr: any = [];
      this.gridData.data?.forEach((datum: any, j: number) => {
        if (i === 0) {
          let rowTitle = datum.rbd_1;
          if (rowsDepth > 3) {
            rowTitle = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${
              datum.rbd_3 ?? ''
            }, ${datum.rbd_4 ?? ''}`;

            this.chartData.push({
              name: `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${
                datum.rbd_3 ?? ''
              }, ${datum.rbd_4 ?? ''}`,
            });
          } else if (rowsDepth === 3) {
            rowTitle = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${
              datum.rbd_3 ?? ''
            }`;

            this.chartData.push({
              name: `${datum.rbd_1}, ${datum.rbd_2 ?? ''}, ${
                datum.rbd_3 ?? ''
              }`,
            });
          } else if (rowsDepth === 2) {
            rowTitle = `${datum.rbd_1}, ${datum.rbd_2 ?? ''}`;
          }
          this.chartData.push({
            name: rowTitle,
          });
        }

        arr.push({
          name: datum.rbd_1,
          x: Math.trunc(datum[col.field]),
          y: j,
          label: this.isPercent
            ? `${Math.trunc(datum[col.field]).toFixed()}%`
            : `${Math.trunc(datum[col.field])}`,
        });
      });
      this.chartLabels.push(arr);
    });
  }

  buildNestedColumns(depth: number): any {
    let title: string = '';

    if (depth === 2) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (!this.isTotalColumn(col3)) {
                  const colTitles = [
                    (col2.title ?? '').trim(),
                    (col3.title ?? '').trim(),
                  ].filter((x) => x !== '');
                  this.cols.push({
                    title: `${title} : ${colTitles.join(', ')}`,
                    field: col3.field,
                    legendData: [
                      { label: titles[0] ?? '', value: colTitles[0] ?? '' },
                      { label: titles[1] ?? '', value: colTitles[1] ?? '' },
                    ],
                  });
                }
              });
            }
          });
        }
      });
    }

    if (depth === 3) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (
                  col3.columns &&
                  col3.columns.length > 0 &&
                  !this.isTotalColumn(col3)
                ) {
                  col3.columns?.forEach((col4: any) => {
                    if (!this.isTotalColumn(col4)) {
                      const colTitles = [
                        (col2.title ?? '').trim(),
                        (col3.title ?? '').trim(),
                        (col4.title ?? '').trim(),
                      ].filter((x) => x !== '');
                      this.cols.push({
                        title: `${title} : ${colTitles.join(', ')}`,
                        field: col4.field,
                        legendData: [
                          { label: titles[0] ?? '', value: colTitles[0] ?? '' },
                          { label: titles[1] ?? '', value: colTitles[1] ?? '' },
                          { label: titles[2] ?? '', value: colTitles[2] ?? '' },
                        ],
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

    if (depth === 4) {
      this.gridData.columns.forEach((col1: any, i: number) => {
        if (
          i > 0 &&
          col1.columns &&
          col1.columns.length > 0 &&
          !this.isTotalColumn(col1)
        ) {
          title = col1.title;
          const titles = title.split(',').map((x) => x.trim());
          col1.columns?.forEach((col2: any) => {
            if (
              col2.columns &&
              col2.columns.length > 0 &&
              !this.isTotalColumn(col2)
            ) {
              col2.columns?.forEach((col3: any) => {
                if (
                  col3.columns &&
                  col3.columns.length > 0 &&
                  !this.isTotalColumn(col3)
                ) {
                  col3.columns?.forEach((col4: any) => {
                    if (!this.isTotalColumn(col4)) {
                      col4.columns?.forEach((col5: any) => {
                        if (!this.isTotalColumn(col5)) {
                          const colTitles = [
                            (col2.title ?? '').trim(),
                            (col3.title ?? '').trim(),
                            (col4.title ?? '').trim(),
                            (col5.title ?? '').trim(),
                          ].filter((x) => x !== '');
                          this.cols.push({
                            title: `${title} : ${colTitles.join(', ')}`,
                            field: col5.field,
                            legendData: [
                              {
                                label: titles[0] ?? '',
                                value: colTitles[0] ?? '',
                              },
                              {
                                label: titles[1] ?? '',
                                value: colTitles[1] ?? '',
                              },
                              {
                                label: titles[2] ?? '',
                                value: colTitles[2] ?? '',
                              },
                              {
                                label: titles[3] ?? '',
                                value: colTitles[3] ?? '',
                              },
                            ],
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  private adjustGridDataForChart(gridData: any): void {
    const { cellTemplate } = gridData;
    if (cellTemplate) {
      delete gridData.cellTemplate;
    }
    const tmpData = JSON.parse(JSON.stringify(gridData));
    const rowsToRemove = this.getBannerRowIdsToRemove(tmpData);
    rowsToRemove.forEach((id) => {
      const ix = tmpData.data.findIndex((datum) => datum.rbd_1__id === id);
      if (ix >= 0) {
        tmpData.data.splice(ix, 1);
      }
    });
    if (cellTemplate) {
      gridData.cellTemplate = cellTemplate;
    }

    this.removeColumnComparisonData(tmpData.data);
    this.applySort(tmpData);
    this._gridData = tmpData;
  }

  private removeColumnComparisonData(data: any): void {
    let statLettersDataIx = -1;
    (data ?? []).forEach((datum: any, ix: number) => {
      Object.keys(datum)
        .filter((x) => x.startsWith('c'))
        .forEach((itemKey) => {
          const itemValue = datum[itemKey];
          if (
            itemValue.statLetter !== undefined &&
            itemValue.value !== undefined
          ) {
            datum[itemKey] = itemValue.value;
          } else if (
            typeof itemValue === 'string' &&
            itemValue.startsWith('key_') &&
            statLettersDataIx < 0
          ) {
            statLettersDataIx = ix;
          }
        });
    });

    if (statLettersDataIx >= 0) {
      data.splice(statLettersDataIx, 1);
    }
  }

  private prepareChart(): void {
    this.colors = this.interpolateColors(
      this.cols.length > 4 ? this.cols.length : 4
    );
    let chartHeight = this.calculateChartHeight();
    let isNarrowBars = false;
    if (chartHeight > this.chartUseNarrowBarsTreshold) {
      isNarrowBars = true;
      chartHeight = this.calculateChartHeight(isNarrowBars);
    }

    if (chartHeight > this.chartHeightWarningTreshold) {
      this.displayChartSizeWarning();
      return;
    }

    this.chartHeight = `${chartHeight}px`;

    this.chart.isVerticalZoomEnabled = false;
    this.chart.isHorizontalZoomEnabled = false;
    this.chart.height = this.chartHeight;

    this.yAxis.gap = this.yAxisCategoryGap;
    this.yAxis.maximumGap = this.yAxisCategoryGap;
    this.yAxis.overlap = this.yAxisBarGapRatio;
    this.yAxis.isInverted = true;
    this.yAxis.label = 'name';
    this.yAxis.tickLength = 0;
    this.yAxis.tickStrokeThickness = 0;
    this.yAxis.tickStroke = 'transparent';
    this.yAxis.strokeThickness = 0;
    this.yAxis.stroke = 'transparent';
    this.yAxis.majorStrokeThickness = 1;
    this.yAxis.majorStroke = 'rgba(0,0,0,0.1)';
    this.yAxis.minorStrokeThickness = 1;
    this.yAxis.minorStroke = 'rgba(0,0,0,0.05)';
    this.yAxis.interval = 1;
    this.yAxis.labelHorizontalAlignment = HorizontalAlignment.Right;
    this.yAxis.labelRightMargin = 16;
    this.yAxis.labelTextStyle = `400 14px 'Inter', 'Helvetica Neue', sans-serif`;
    this.yAxis.name = 'yAxis';
    this.yAxis.dataSource = this.chartData;

    this.xAxis.name = 'xAxis';
    this.xAxis.labelTextStyle = `600 14px 'Inter', 'Helvetica Neue', sans-serif`;
    this.xAxis.labelFormat = this.isPercent ? '{0}%' : '{0}';
    this.xAxis.stroke = 'transparent';
    this.xAxis.strokeThickness = 0;
    this.xAxis.tickStroke = 'transparent';
    this.xAxis.tickStrokeThickness = 0;
    this.xAxis.majorStrokeThickness = 1;
    this.xAxis.majorStroke = 'rgba(0,0,0,0.1)';
    this.xAxis.minorStrokeThickness = 0;
    this.xAxis.minorStroke = 'transparent';
    this.xAxis.labelLocation = AxisLabelsLocation.OutsideBottom;
    this.xAxis.labelTopMargin = 12;
    this.xAxis.labelBottomMargin = 8;
    this.xAxis.labelVerticalAlignment = VerticalAlignment.Center;
    if (this.isPercent) {
      this.xAxis.maximumValue = 100;
      this.xAxis.minimumValue = 0;
      this.xAxis.interval = 20;
    }

    setTimeout(() => {
      const dataSource = this.chartType === 'single' ? [''] : this.cols;
      dataSource.forEach((col: any, ix: number) => {
        const barSeriesItem = this.barSeries.find(
          (element, elementIx) => elementIx === ix
        );
        if (!barSeriesItem) {
          return;
        }

        barSeriesItem.xAxis = this.xAxis;
        barSeriesItem.yAxis = this.yAxis;
        barSeriesItem.isTransitionInEnabled = true;
        barSeriesItem.isHighlightingEnabled = false;
        barSeriesItem.name = `BarSeries${ix}`;
        barSeriesItem.showDefaultTooltip = false;
        barSeriesItem.outlineMode = SeriesOutlineMode.Collapsed;
        if (this.chartType === 'multiple') {
          barSeriesItem.dataSource = this.gridData.data;
          barSeriesItem.valueMemberPath = col.field;
          barSeriesItem.brush = this.colors[ix];
          barSeriesItem.title = col.title;
          col.brushColor = this.colors[ix];
        } else {
          barSeriesItem.dataSource = this.chartData;
          barSeriesItem.valueMemberPath = 'count';
          barSeriesItem.title = this.chartData[ix].name;
          barSeriesItem.brush = 'rgba(69, 3, 187, 1)';
        }

        const barValuesLayerItem = this.barValuesLayer.find(
          (element, elementIx) => elementIx === ix
        );
        if (!barValuesLayerItem) {
          return;
        }

        barValuesLayerItem.labelMemberPath = 'label';
        barValuesLayerItem.yMemberPath = 'y';
        barValuesLayerItem.targetSeries = barSeriesItem;
        barValuesLayerItem.isCustomCalloutStyleEnabled = true;
        barValuesLayerItem.textStyle = `400 ${
          isNarrowBars ? '10' : '12'
        }px 'Inter', 'Helvetica Neue', sans-serif`;
        barValuesLayerItem.calloutTextColor = '#373737';
        barValuesLayerItem.calloutBackground = 'transparent';
        barValuesLayerItem.calloutLeaderBrush = 'transparent';
        barValuesLayerItem.calloutPositionPadding = 8;
        barValuesLayerItem.name = `CalloutLayer${ix}`;
        barValuesLayerItem.showDefaultTooltip = false;

        if (this.chartType === 'multiple') {
          barValuesLayerItem.dataSource = this.chartLabels[ix];
          barValuesLayerItem.xMemberPath = 'x';
        } else {
          barValuesLayerItem.dataSource = this.chartData;
          barValuesLayerItem.xMemberPath = 'count';
        }
      });
    }, 0);
  }

  private calculateChartHeight(isNarrow?: boolean): number {
    if (this.chartType === 'single' || this.cols.length < 3) {
      this.yAxisCategoryGap = 0.6;
      this.yAxisBarGapRatio = -1;
    }
    const xAxisLabelHeight = 46;
    const yAxisBarHeight = isNarrow ? 10 : 20;
    const yAxisCategoryBarCount =
      this.chartType === 'single' ? 1 : this.cols.length;
    const yAxisCategoryBarHeight =
      yAxisBarHeight * -1 * this.yAxisBarGapRatio + yAxisBarHeight;
    const yAxisCategoryHeight =
      yAxisCategoryBarHeight * yAxisCategoryBarCount +
      yAxisCategoryBarHeight * yAxisCategoryBarCount * this.yAxisCategoryGap;
    const yAxisCategoriesCount = this.chartData.length;
    const graphHeight =
      yAxisCategoryHeight * yAxisCategoriesCount + xAxisLabelHeight;
    return graphHeight;
  }

  private interpolateColors(dataLength: number) {
    const { colorStart, colorEnd } = colorRangeInfo;
    const colorRange = colorEnd - colorStart;
    const intervalSize = colorRange / dataLength;
    let colorPoint: number;
    const colorArray: string[] = [];

    for (let i = 0; i < dataLength; i++) {
      colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }
    this.shuffleArray(colorArray);
    return colorArray;
  }

  private calculatePoint(i: number, intervalSize: number, colorRangeInfo: any) {
    const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return useEndAsStart
      ? colorEnd - i * intervalSize
      : colorStart + i * intervalSize;
  }

  private shuffleArray(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  private isTotalColumn(col: IColumnInfo): boolean {
    return [
      ...DataUtility.totalsColumnDimensions,
      ...DataUtility.bannerSummaryDimensions,
    ].some((x) => (col.source ?? '') === x);
  }

  private applySort(data: any): void {
    if (
      !data.appliedSort.length ||
      data.appliedSort[0].dir === SortingDirection.None
    ) {
      return;
    }
    const { fieldName, dir } = data.appliedSort[0];
    const cmpFunc = (a: any, b: any) => {
      return DataUtility.sortCompareAnchorTotals(
        a[fieldName],
        b[fieldName],
        dir === SortingDirection.Desc ? 'desc' : 'asc',
        a,
        b
      );
    };
    data.data.sort(cmpFunc);
  }

  private getBannerRowIdsToRemove(gridData: any): string[] {
    const rowIds: string[] = [];
    const sourcesToRemove = [
      ...DataUtility.bannerSummaryDimensions,
      ...DataUtility.tableSummaryDimensions,
    ];

    const { rows } = gridData;
    rows.forEach((rowArray: any) => {
      rowArray.forEach((row: any) => {
        if (sourcesToRemove.some((s) => s === row.s)) {
          rowIds.push(row.id);
        }
      });
    });

    return rowIds;
  }

  identify(index: number, item: any): string {
    return item.field;
  }

  displayChartSizeWarning(): void {
    this.chartSizeWarning = true;
    setTimeout(() => {
      this.chart_warning_dialog.open();
    }, 0);
  }

  onWarningClose(): void {
    this.chart_warning_dialog.close();
    this.chart_warning_dialog.closed.pipe(take(1), delay(0)).subscribe(() => {
      this.chartSizeWarning = false;
      this.back.emit();
    });
  }
}
