<div class="age-range-modal" [ngClass]="{ 'has-warnings': hasWarnings }">
  <div class="modal-header">
    <div class="modal-header-left">
      <span class="h2 text-suzy-grey-900">{{ 'ageRanges.modalTitle' | translate }}</span>
    </div>
    <button class="suzy-icon-button" (click)="onClose()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="pageForm">
      <div class="age-range-row">
        <div class="age-range-row-controls full-width">
          <span class="label">{{ 'ageRanges.label' | translate }}</span>
        </div>
      </div>
      <div class="age-range-row">
        <div class="age-range-row-controls full-width">
          <div class="age-input-field">
            <igx-input-group type="border">
              <input igxInput name="label" maxlength="100" formControlName="label" type="text" autocomplete="off" />
            </igx-input-group>
          </div>
        </div>
      </div>
      <igx-divider></igx-divider>
      <div class="age-range-row">
        <div class="age-range-row-controls">
          <span class="label">{{ 'ageRanges.minAge' | translate }}</span>
          <span class="label">{{ 'ageRanges.maxAge' | translate }}</span>
        </div>
      </div>
      <div class="age-range-row" formArrayName="ranges"
        *ngFor="let rangeField of ageRangesFormArray.controls; let rowIx = index">
        <div class="age-range-row-controls" [formGroupName]="rowIx">
          <div class="age-input-field">
            <igx-input-group type="border">
              <input igxInput name="minAge" maxlength="3" formControlName="minAge" type="number" min="0" max="999"
                autocomplete="off" (keypress)="numericOnly($event)" />
            </igx-input-group>
            <button igxButton="flat" (click)="onUpdateRangeValue('up', 'min', rowIx)">
              <igx-icon>keyboard_arrow_up</igx-icon>
            </button>
            <button igxButton="flat" (click)="onUpdateRangeValue('down', 'min', rowIx)">
              <igx-icon>keyboard_arrow_down</igx-icon>
            </button>
          </div>
          <div class="age-input-field">
            <igx-input-group type="border">
              <input igxInput name="maxAge" maxlength="3" formControlName="maxAge" type="number" min="0" max="999"
                autocomplete="off" (keypress)="numericOnly($event)" />
            </igx-input-group>
            <button igxButton="flat" (click)="onUpdateRangeValue('up', 'max', rowIx)">
              <igx-icon>keyboard_arrow_up</igx-icon>
            </button>
            <button igxButton="flat" (click)="onUpdateRangeValue('down', 'max', rowIx)">
              <igx-icon>keyboard_arrow_down</igx-icon>
            </button>
          </div>
          <button *ngIf="rowIx" class="suzy-icon-button" (click)="onRemoveAgeRangeRow(rowIx)">
            <span class="material-symbols-rounded">delete</span>
          </button>
        </div>
        <div class="error-container">
          <span class="age-range-row-error" *ngIf="rangeField.hasError('rangeMinMax')">
            {{
            'ageRanges.rangeMinMaxWarning'
            | translate: { minValue: minAge, maxValue: maxAge }
            }}</span>
          <span class="age-range-row-error" *ngIf="
              !rangeField.hasError('rangeMinMax') &&
              rangeField.hasError('rangeInvalid')
            ">
            {{ 'ageRanges.rangeInvalidWarning' | translate }}</span>
          <span class="age-range-row-error" *ngIf="
              !(
                rangeField.hasError('rangeMinMax') ||
                rangeField.hasError('rangeInvalid')
              ) && rangeField.hasError('rangeOverlap')
            ">
            {{ 'ageRanges.rangeOverlapWarning' | translate }}</span>
        </div>
      </div>
      <div class="age-range-row">
        <button class="suzy-tertiary" (click)="onAddNewRangeRow()">
          <igx-icon fontSet="material">add</igx-icon>
          {{ 'ageRanges.addRange' | translate }}
        </button>
      </div>
    </form>

    <div class="warnings" *ngIf="hasWarnings">
      <div class="warning-box">
        <igx-icon>warning</igx-icon>
        <span>{{ 'ageRanges.' + warningMsg | translate }}</span>
      </div>
    </div>
  </div>

  <div class="modal-footer-right">
      <button class="suzy-secondary" (click)="onClose()">
        {{ 'builder.cancel' | translate }}
      </button>
      <button class="suzy-primary" (click)="onClose(true)" [disabled]="!pageForm.valid">
        {{ 'builder.apply' | translate }}
      </button>
  </div>
</div>