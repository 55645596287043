import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringGenerator'
})
export class StringGeneratorPipe implements PipeTransform {

  transform(prefix: string, ...args: number[]): unknown {
    const values = args.join('-');
    return `${prefix}-${values}`;
  }

}
