/* eslint-disable @typescript-eslint/no-explicit-any */
import { MissionTargetKind } from '@asksuzy/typescript-sdk';
import { QueryFilter } from './QueryFilter.model';
import * as queryFilterFactory from '../../services/query-filter-factory';

export class MissionTarget {
  kind: MissionTargetKind;
  priority: number;
  queryFiltersIsOr: boolean;
  queryFilters: Array<QueryFilter>;
  groupId: string | null;
  groupName?: string;
  groupIsOr?: boolean;

  static fromJsonData(target: any): MissionTarget {
    const missionTarget = new MissionTarget({
      kind: target.mission_target_kind,
      priority: target.priority,
      queryFiltersIsOr:
        target.is_or !== undefined ? target.is_or : target.query_filters_is_or,
      queryFilters: target.query_filters.map(
        queryFilterFactory.createQueryFilter
      ),
      groupId: target.group_id,
      groupName: target.group_name,
      groupIsOr: target.group_is_or,
    });

    return missionTarget;
  }

  constructor({
    kind = 0,
    priority = 0,
    queryFiltersIsOr = true,
    queryFilters = [] as QueryFilter[],
    groupId = null as string | null,
    groupName = '1',
    groupIsOr = true,
  } = {}) {
    this.kind = kind;
    this.priority = priority;
    this.queryFiltersIsOr = queryFiltersIsOr;
    this.queryFilters = queryFilters;
    this.groupId = groupId;
    this.groupName = groupName;
    this.groupIsOr = groupIsOr;
  }

  toJsonData(): any {
    return {
      mission_target_kind: this.kind,
      priority: this.priority,
      query_filters_is_or: this.queryFiltersIsOr,
      query_filters: this.queryFilters.map((queryFilter) =>
        queryFilter.toJsonData()
      ),
      group_id: this.groupId,
      group_name: this.groupName,
      group_is_or: this.groupIsOr,
    };
  }

  isEmpty(): boolean {
    return this.queryFilters.length === 0;
  }
}
