/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, catchError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class SdkAuthInterceptor implements HttpInterceptor {
  private readonly noAuthUrls = [
    '/brands/self',
    '/auth/login',
    'https://apim-dataexplorer-prod-eastus.azure-api.net',
    'https://app-tokenapi-prod-eastus.azurewebsites.net',
  ];
  constructor(private router: Router) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        const isNoAuthUrl = this.noAuthUrls.some(
          (x) =>
            req.url.startsWith(`${environment.suzyUrl}${x}`) ||
            req.url.startsWith(x)
        );
        const httpError: HttpErrorResponse = error as HttpErrorResponse;
        if (httpError.status === 401 && !isNoAuthUrl) {
          this.router.navigate(['/', 'login']);
        }
        return throwError(() => error);
      })
    );
  }
}
