import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  IComboSearchInputEventArgs,
  IgxSimpleComboComponent,
} from '@infragistics/igniteui-angular';
import {
  debounceTime,
  distinctUntilChanged,
  noop,
  Observable,
  of,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { ESortOrder } from '../../../enums/sort-orders.enum';
import { IMission } from '../../../models/suzy/IMission';

@Component({
  selector: 'selected-mission-dropdown',
  templateUrl: './selected-mission-dropdown.component.html',
  styleUrls: ['./selected-mission-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectedMissionDropdownComponent),
      multi: true,
    },
  ],
})
export class SelectedMissionDropdownComponent
  implements OnChanges, AfterViewInit, ControlValueAccessor, OnDestroy
{
  @Input() missions: IMission[] = [];
  @Input() searching!: Observable<boolean>;

  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() cssClass!: string;

  @Output() readonly missionSelected = new EventEmitter<IMission>();
  @Output() readonly searchMissionsByTerm = new EventEmitter<string>();

  @ViewChild(IgxSimpleComboComponent, {
    read: IgxSimpleComboComponent,
    static: true,
  })
  public simpleCombo!: IgxSimpleComboComponent;

  sortedMissions: IMission[] = [];
  previousMissions: IMission[] = [];

  searchTerm$ = new Subject<string>();
  unsubscribe = new Subject<void>();

  control = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: (value: unknown) => void = noop;
  onTouch: () => void = noop;

  ngOnChanges(changes: SimpleChanges): void {
    const missionChanges = changes['missions'];

    if (missionChanges?.currentValue) {
      this.sortedMissions = missionChanges?.currentValue;
      this.sortMissionNames(ESortOrder.Default, 'search_internal_text');
    }
  }

  ngAfterViewInit(): void {
    this.searchTerm$
      .asObservable()
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((value) => {
          this.searchMissionsByTerm.emit(value);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          this.onChange(value);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onMissionSelected(evt: any): void {
    const missionId = evt.newSelection;

    const mission = this.sortedMissions.find(
      (mission) => mission.mission_id === missionId
    );
    this.missionSelected.emit(mission);
  }

  onSearchInputChanged(searchTerm: IComboSearchInputEventArgs): void {
    this.searchTerm$.next(searchTerm.searchText);
  }

  sortMissionNames(order: ESortOrder, sortKey: string): void {
    const opts: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
    const LOCALE = 'en';
    this.previousMissions = this.sortedMissions.slice();

    for (const item of this.sortedMissions) {
      if (item.brand_folder_name) {
        item.folder_name$ = of(
          item.brand_folder_name[0].name ?? 'Multiple Folders'
        );
      } else {
        item.folder_name$ = of('None');
      }
    }

    for (const item of this.sortedMissions) {
      if (item.brand_tags) {
        item.brand_tags_text ?? 'Multiple Tags';
      } else {
        item.brand_tags_text = 'None';
      }
    }
    if (order === ESortOrder.Default) {
      this.sortedMissions = this.previousMissions.slice();
    }

    if (order === ESortOrder.Desc) {
      this.sortedMissions.sort((prev: any, next: any) =>
        new Intl.Collator(LOCALE, opts).compare(next[sortKey], prev[sortKey])
      );
    }

    if (order === ESortOrder.Asc) {
      this.sortedMissions.sort((prev: any, next: any) =>
        new Intl.Collator(LOCALE, opts).compare(prev[sortKey], next[sortKey])
      );
    }
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(value: string): void {
    if (value) {
      this.control.patchValue({ value });
      this.simpleCombo.select(value);
    } else {
      this.control.reset();
    }
  }
}
