import { MissionKind } from '@asksuzy/typescript-sdk';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs';
import { EFabricationEndpointType, EFabricationType } from '../../../../enums/cbp-enum';
import { IFabItem } from '../../../../models/fabrication/IFabricationResponse';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { CustomBannerPointsActionsService } from '../../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../../services/custom-banner-points.service';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';

export class SubpopulationsDimensionProvider implements IDimensionProvider {
  code = 'subpopulations';
  name!: string;
  dimensions: IBuilderDimension[] = [];
  loading = false;
  loaded = false;
  enabled = false;

  constructor(
    private translate: TranslateService,
    private cbpActions: CustomBannerPointsActionsService,
    private cbpService: CustomBannerPointsService
  ) {
    this.name = this.translate.instant('builder.dimensionSubpopulations');
  }

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (
      context.global ||
      context.mission?.mission_kind === MissionKind.external_link
    ) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }

    this.loaded = true;

    this.cbpService
      .getFabricationsByType(
        context.brand_id,
        EFabricationEndpointType.Subpopulation,
        context.mission?.mission_id
      )
      .pipe(
        tap((fabs) => this.cbpActions.builderSubpopulations.next(fabs.items)),
        take(1)
      )
      .subscribe();

    this.cbpActions.builderSubpopulations$.subscribe((fabrications) => {
      this.dimensions = fabrications.map((item: IFabItem) => {
        return {
          type: 'field',
          label: item.fabrication_name,
          field: {
            source: 'fabrication',
            field: item.known_items[0].item_id,
            action_id: 'primary',
            parent_id: item.fabrication_id,
          },
          fabricationType: EFabricationType.Subpopulation
        };
      });
      builder.dragdrop?.prepareNewItems(this.code, this.dimensions);
    });

    return true;
  }
}
