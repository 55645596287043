<div class="calculations-container">
  <ng-container *ngIf="!hasCalculations">
    <div class="empty-calculations-row">
      <div class="empty-calculations-row__content">
        <span>{{ 'calculations.emptCalculationInfo' | translate }}</span>
      </div>
      <button igxButton="outlined" (click)="onCalculationsClick()">
        <igx-icon fontSet="material">add</igx-icon>
        {{ 'calculations.addCalculation' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="hasCalculations">
    <div class="active-calculations-row">
      <div class="active-calculations-row__label">
        <igx-icon family="suzy" name="calculate-filled"></igx-icon>
        <span>{{ 'calculations.calculationsApplied' | translate }}&nbsp;:</span>
      </div>
      <div class="active-calculations-row__content">
        <ng-container *ngFor="let tag of calculationsTags">
          <ng-container [ngSwitch]="tag.kind">
            <span *ngSwitchCase="tagKind.token">{{
              tag.value | translate
            }}</span>
            <span *ngSwitchDefault>{{ tag.value }}</span>
          </ng-container>
        </ng-container>
      </div>
      <button igxButton="outlined" (click)="onCalculationsClick()">
        <igx-icon family="suzy" name="edit"></igx-icon>
        {{ 'calculations.editCalculations' | translate }}
      </button>
    </div>
  </ng-container>
</div>

<igx-dialog #calculations_editor_dialog>
  <app-calculations-editor
    *ngIf="editorVisible"
    [dialog]="calculations_editor_dialog"
    [context]="context"
    [crosstabConfig]="editorConfig"
    (configChange)="onConfigChanged($event)"
  ></app-calculations-editor>
</igx-dialog>
