<div class="questions-list">
    <div class="questions-list-header">
        <span class="h4 text-suzy-grey-800">{{ 'dataExplorer.questions' | translate }}</span>
    </div>
    <ng-container *ngIf="loading; else elseQuestionList">
        <div class="loading">
            <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
        </div>
    </ng-container>
    <ng-template #elseQuestionList>
        <div class="list-items">
            <ul>
                <li *ngFor="let item of actions" (click)="selectAction(item)"
                    [ngClass]="{'selected' : item.action_id === activeItem}" class="text-truncate" #tooltip="matTooltip" [matTooltip]="item.strippedSearchText">
                    <div class="action-sticker">
                        <action-icon class="small-icon" [mission]="item"></action-icon>
                        <span class="eyebrow text-suzy-white">{{ item.question_number }}</span>
                    </div>
                    <span class="body-copy-small text-suzy-grey-800 text-cut-off">{{ item.strippedSearchText }}</span>
                </li>
            </ul>
        </div>
    </ng-template>

</div>