<div class="title">
  <h3
    [innerHTML]="
      question.length > 250 ? (question | slice: 0:250) + '...' : question
    "
  ></h3>
</div>

<igx-data-chart
  isHorizontalZoomEnabled="false"
  isVerticalZoomEnabled="false"
  [height]="chartHeight"
  name="Chart"
  #chart
>
  <igx-category-y-axis
    [dataSource]="chartData"
    [gap]="yAxisCategoryGap"
    [maximumGap]="yAxisCategoryGap"
    [overlap]="yAxisBarGapRatio"
    tickLength="0"
    tickStrokeThickness="0"
    strokeThickness="0"
    majorStrokeThickness="1"
    majorStroke="rgba(0,0,0,0.1)"
    minorStrokeThickness="1"
    minorStroke="rgba(0,0,0,0.05)"
    interval="1"
    [labelHorizontalAlignment]="2"
    labelRightMargin="16"
    labelTextStyle="400 14px 'Inter', 'Helvetica Neue', sans-serif"
    isInverted="true"
    label="name"
    name="yAxis"
    #yAxis
  ></igx-category-y-axis>

  <igx-numeric-x-axis
    minimumValue="0"
    [maximumValue]="maximumValue"
    [labelFormat]="isPercent ? '{0}%' : '{0}'"
    labelTextStyle="600 14px 'Inter', 'Helvetica Neue', sans-serif"
    strokeThickness="0"
    tickStrokeThickness="0"
    majorStrokeThickness="1"
    majorStroke="rgba(0,0,0,0.1)"
    minorStrokeThickness="0"
    [labelLocation]="1"
    labelTopMargin="12"
    labelBottomMargin="8"
    [labelVerticalAlignment]="1"
    [interval]="intervalValue"
    name="xAxis"
    #xAxis
  ></igx-numeric-x-axis>

  <ng-container *ngIf="isSingleSelect">
    <igx-stacked-100-bar-series
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [dataSource]="chartData"
      [outlineMode]="2"
      showDefaultTooltip="false"
      isHighlightingEnabled="false"
      isTransitionInEnabled="false"
      name="Stacked100BarSeries"
      #stacked100BarSeries
    >
      <igx-stacked-fragment-series
        *ngFor="let col of cols; index as i"
        [name]="'FragmentSeries' + i"
        [title]="col.title"
        [dataSource]="gridData.data"
        [valueMemberPath]="col.field"
        finalValueAnnotationsVisible="true"
        [brush]="col.brushColor ?? ''"
      ></igx-stacked-fragment-series>
    </igx-stacked-100-bar-series>
    <igx-callout-layer
      [dataSource]="chartLabels"
      [name]="'CalloutLayer'"
      xMemberPath="x"
      yMemberPath="y"
      labelMemberPath="label"
      textStyle="400 12px 'Inter', 'Helvetica Neue', sans-serif"
      calloutBackground="transparent"
      calloutTextColor="#373737"
      calloutLeaderBrush="rgba(0,0,0,0.1)"
      [allowedPositions]="calloutPositions"
      calloutPositionPadding="24"
      [calloutCollisionMode]="0"
      showDefaultTooltip="false"
      [tooltipTemplate]="tooltipBarItem"
    >
    </igx-callout-layer>
  </ng-container>

  <ng-container *ngIf="!isSingleSelect">
    <igx-stacked-bar-series
      [xAxis]="xAxis"
      [yAxis]="yAxis"
      [dataSource]="chartData"
      [outlineMode]="2"
      showDefaultTooltip="false"
      isHighlightingEnabled="false"
      isTransitionInEnabled="false"
      name="Stacked100BarSeries"
      #stacked100BarSeries
    >
      <igx-stacked-fragment-series
        *ngFor="let col of cols; index as i"
        [name]="'FragmentSeries' + i"
        [title]="col.title"
        [dataSource]="gridData.data"
        [valueMemberPath]="col.field"
        finalValueAnnotationsVisible="true"
        [brush]="col.brushColor ?? ''"
        #fragmentSeries
      ></igx-stacked-fragment-series>
    </igx-stacked-bar-series>
    <igx-callout-layer
      [dataSource]="chartLabels"
      [name]="'CalloutLayer'"
      xMemberPath="x"
      yMemberPath="y"
      labelMemberPath="label"
      textStyle="400 12px 'Inter', 'Helvetica Neue', sans-serif"
      calloutBackground="transparent"
      calloutTextColor="#373737"
      calloutLeaderBrush="rgba(0,0,0,0.1)"
      [allowedPositions]="calloutPositions"
      calloutPositionPadding="24"
      [calloutCollisionMode]="0"
      showDefaultTooltip="false"
      [tooltipTemplate]="tooltipBarItem"
    ></igx-callout-layer>
  </ng-container>
</igx-data-chart>

<div class="custom-legend">
  <div class="legend-cell" *ngFor="let col of cols">
    <span
      class="color"
      [ngStyle]="{ 'background-color': col.brushColor }"
    ></span>
    <div class="legend-rows">
      <div class="legend-row" *ngFor="let row of col.legendData">
        <span
          *ngIf="row.label"
          class="label"
          [innerHTML]="row.label + ' :'"
        ></span>
        <span *ngIf="row.value" class="name" [innerHTML]="row.value"></span>
      </div>
    </div>
  </div>
</div>

<ng-template let-series="series" let-item="item" #tooltipBarItem>
  <div class="tooltip-container">
    <ng-container *ngFor="let legend of item.legendData">
      <span class="title" *ngIf="legend.label">
        <b>{{ legend.label }}</b
        >: {{ legend.value }}
      </span>
    </ng-container>
    <div class="value-container">
      <span
        class="circle"
        [ngStyle]="{ backgroundColor: item.brushColor }"
      ></span>
      <span class="value">{{ item.label }}</span>
    </div>
  </div>
</ng-template>
