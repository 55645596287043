import { Component, Input } from '@angular/core';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind
} from '@asksuzy/typescript-sdk';

@Component({
  selector: 'action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
})
export class ActionIconComponent {
  @Input() mission: any;
  @Input() action!: ActionKind;

  missionKind = MissionKind;
  actionKind = ActionKind;
  gridKind = ActionStructureGridKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  hidePhoto: any;
}
