import { Pipe, PipeTransform } from '@angular/core';
import { PlainTextPipe } from './plain-text.pipe';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string, props?: { plain: boolean }): string {
    if (value === undefined) {
      return '';
    }

    const plainTextPipe = new PlainTextPipe();
    let strippedText = plainTextPipe.transform(value);

    if (!props?.plain) {
      // eslint-disable-next-line no-useless-escape
      strippedText = strippedText.replace(/[\ |\u00A0]([\ |\u00A0]+)/g, (x) => {
        let result = x[0];
        for (let i = 1; i < x.length; i++) {
          result += '&nbsp;';
        }
        return result;
      });
    }

    return strippedText;
  }
}
