<div *ngIf="data">
  <div class="modal-header">
    <div class="modal-header-left">
      <igx-dialog-title *ngIf="dialogKind !== dialogKinds.rename">
        <span class="h2 text-suzy-grey-900">{{ dialogTitle | translate }}</span>
        <span class="body-copy-small text-suzy-grey-800">{{ dialogSubTitle | translate }}</span>
      </igx-dialog-title>
      <igx-dialog-title *ngIf="dialogKind === dialogKinds.rename">
        <span class="h2 text-suzy-grey-900">{{ dialogTitle | translate }}</span>
      </igx-dialog-title>
    </div>
    <button class="suzy-icon-button" (click)="closeSelf(false)">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="ui.loading" class="loading-container">
      <igx-circular-bar
        [animate]="false"
        [indeterminate]="true"
        [textVisibility]="false"
      ></igx-circular-bar>
      <span>{{ 'builderFilter.loading' | translate }}</span>
    </div>
    <ng-container *ngIf="!ui.loading">
      <div class="input-container">
        <span>{{ inputLabel | translate }}</span>
        <igx-input-group type="border">
          <input
            igxInput
            name="net_label"
            type="text"
            [(ngModel)]="data.dimension.label"
            [placeholder]="inputLabel | translate"
          />
        </igx-input-group>
      </div>
      <div class="options-container" *ngIf="dialogKind !== dialogKinds.rename">
        <div class="filter-box" *ngIf="filter_options.raw.length > 5">
          <igx-input-group>
            <input
              igxInput
              name="option_filter"
              type="text"
              [ngModel]="filter_options.keyword"
              (ngModelChange)="filterOptionsDelayed($event)"
            />
            <label igxLabel for="option_filter">{{
              'builderFilter.filterItems' | translate
            }}</label>
          </igx-input-group>
        </div>
        <div class="options-box">
          <igx-checkbox
            class="select-all"
            [readonly]="true"
            [(ngModel)]="masterCheckbox.checked"
            [indeterminate]="masterCheckbox.indeterminate"
            (click)="toggleAllFilterOptions()"
          >
            {{ 'crosstab.selectAll' | translate }}</igx-checkbox
          >
          <igx-divider></igx-divider>
          <igx-list>
            <igx-list-item
              *ngFor="let item of filter_options.filtered"
              (click)="item.include = !item.include"
            >
              <igx-checkbox [disableRipple]="true" [ngModel]="item.include">{{
                item.name
              }}</igx-checkbox>
            </igx-list-item>
            <ng-template igxEmptyList>
              <p class="empty">
                {{ 'builderFilter.noOptionsFound' | translate }}
              </p>
            </ng-template>
          </igx-list>
        </div>
      </div>
    </ng-container>
  </div>

  <div igxDialogActions class="modal-footer-right">
    <button class="suzy-secondary" (click)="closeSelf(false)">
      {{ 'builderFilter.cancel' | translate }}
    </button>
    <button *ngIf="ui.removeable" class="suzy-secondary" (click)="removeFilter()">
      {{ 'builderFilter.removeFilter' | translate }}
    </button>
    <div class="save">
      <button class="suzy-primary" (click)="closeSelf(true)">
        {{ 'builderFilter.apply' | translate }}
      </button>
    </div>
  </div>
</div>
