import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Subject,
  debounceTime,
  takeUntil,
  tap
} from 'rxjs';

@Component({
  selector: 'cbp-search-bar',
  templateUrl: './cbp-search-bar.component.html',
  styleUrls: ['./cbp-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CbpSearchBarComponent implements OnInit, OnDestroy {
  searchForm!: FormGroup;

  private unsubscribe = new Subject<void>();

  @Output() readonly searchTerm = new EventEmitter<string>();

  constructor(private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  ngOnInit(): void {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        tap((form) => this.searchTerm.emit(form.search)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  clearSearch(): void {
    this.searchForm.get('search')?.reset();
  }
}
