import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter,
} from '@angular/core';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { IBuilderContext } from '../../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { DemographicsFilterUI } from '../../../../../models/ui/i-demographics-filter-ui.model';
import { IDPair } from '../../../../../models/suzy/IDPair';
import {
  EDEmographicsFilterNames,
  EDataFilterUIAreas,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SuzyDataService } from '../../../../../services/suzy-data.service';
import { IDemographicsFilterItem } from '../../../../../models/ui/i-demographics-filter-ui.model';
import { ISelectionEventArgs } from '@infragistics/igniteui-angular';
import { DataUtility } from '../../../../../utilities/data-utility';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';

@Component({
  selector: 'app-demographics-filter',
  templateUrl: './demographics-filter.component.html',
  styleUrls: ['./demographics-filter.component.scss'],
})
export class DemographicsFilterComponent
  implements OnInit, OnDestroy, IAdvancedProvider
{
  private readonly uiArea: string = EDataFilterUIAreas.demographicFilters;
  private unsubscribeAll = new Subject<void>();
  private fieldValueCache: { [id: string]: Array<IDPair> } = {};
  private _data?: DemographicsFilterUI;
  private ageClassificationId?: string;
  private regionClassificationId?: string;
  private allDemographicsOptions: Array<{
    key: EDEmographicsFilterNames;
    label: string;
  }> = [];

  demographicsOptions: Array<{ key: EDEmographicsFilterNames; label: string }> =
    [];
  selectedDemographicOption?: EDEmographicsFilterNames;
  selectedDemographics?: EDEmographicsFilterNames[];
  selectListVisible!: boolean;
  addButtonVisible!: boolean;
  customAgeRangeName?: string;
  filterNames = EDEmographicsFilterNames;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Input() set data(value: DemographicsFilterUI | undefined) {
    this.initialize(value);
    this._data = value;
  }

  get data(): DemographicsFilterUI | undefined {
    return this._data;
  }

  @Output() filterChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private suzy: SuzyDataService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const deomgraphicsKeys = Object.keys(
      EDEmographicsFilterNames
    ) as EDEmographicsFilterNames[];
    this.allDemographicsOptions = deomgraphicsKeys.map((key) => {
      return { key, label: `filters.${key}` };
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSelectDemographicsChange(e: ISelectionEventArgs): void {
    if (!this.data) {
      return;
    }
    const selected = e.newSelection.value as EDEmographicsFilterNames;
    const found = (Object.keys(this.data) as EDEmographicsFilterNames[]).find(
      (x) => x === selected
    );

    if (!found || this.data[found].enabled) {
      return;
    }

    const selectedFilterItem = this.data[selected];

    const addToFilter = (
      selectedFilterItem: IDemographicsFilterItem,
      list?: IDPair[]
    ) => {
      selectedFilterItem.enabled = true;
      if (list?.length) {
        selectedFilterItem.list = list;
      }
      selectedFilterItem.loaded = true;
      selectedFilterItem.list.forEach((x) => (x.include = true));
      selectedFilterItem.allIncluded = true;
      selectedFilterItem.includedIds = selectedFilterItem.list.map((x) => x.id);
      this.selectedDemographics?.push(found);
      this.onFilterChanged();
    };

    if (!selectedFilterItem.loaded) {
      this.loadDemographicsValuesCached(selected, this.data[selected])
        .pipe(takeUntil(this.unsubscribeAll), take(1))
        .subscribe((list) => {
          addToFilter(selectedFilterItem, list);
        });
    } else {
      addToFilter(selectedFilterItem);
    }

    setTimeout(() => {
      this.demographicsOptions = this.demographicsOptions.filter(
        (x) => x.key !== found
      );
      this.selectedDemographicOption = undefined;
      this.selectListVisible = false;
      this.addButtonVisible = true;
    });
  }

  onRemoveDemographic(
    key: EDEmographicsFilterNames,
    skipEvent?: boolean
  ): void {
    if (!this.data || !this.data[key]) {
      return;
    }

    this.data[key].enabled = false;
    this.data[key].allIncluded = true;
    this.data[key].list.forEach((x) => (x.include = true));
    this.data[key].includedIds = this.data[key].list.map((x) => x.id);
    this.selectedDemographics =
      this.selectedDemographics?.filter((x) => x !== key) ?? [];

    let allOptions = <
      Array<{
        key: EDEmographicsFilterNames;
        label: string;
      }>
    >JSON.parse(JSON.stringify(this.allDemographicsOptions));

    if (!this.selectedDemographics.length) {
      setTimeout(() => {
        this.selectedDemographicOption = undefined;
        this.selectListVisible = true;
        this.addButtonVisible = false;
      });
    } else {
      allOptions = allOptions.filter(
        (x) => !(this.selectedDemographics ?? []).some((y) => y === x.key)
      );
    }
    this.demographicsOptions = allOptions;
    this.demographicsOptions.sort((a, b) => a.key.localeCompare(b.key));

    if (!skipEvent) {
      this.onFilterChanged();
    }
  }

  onAddButtonClick(): void {
    this.selectListVisible = true;
    this.addButtonVisible = false;
  }

  onChangeOption(key: EDEmographicsFilterNames): void {
    if (!this.data) {
      return;
    }

    this.data[key].includedIds = this.data[key].list
      .filter((x) => x.include)
      .map((x) => x.id);
    this.data[key].allIncluded = !this.data[key].list.some((x) => !x.include);
    this.onFilterChanged();
  }

  onToggleAllOptions(key: EDEmographicsFilterNames): void {
    if (!this.data) {
      return;
    }

    if (this.data[key].allIncluded) {
      this.data[key].list.forEach((x) => (x.include = false));
      this.data[key].includedIds = [];
      this.data[key].allIncluded = false;
      this.onFilterChanged();
      return;
    }

    if (!this.data[key].allIncluded) {
      this.data[key].list.forEach((x) => (x.include = true));
      this.data[key].includedIds = this.data[key].list.map((x) => x.id);
      this.data[key].allIncluded = true;
      this.onFilterChanged();
      return;
    }
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.data) {
      return;
    }

    const keys = Object.keys(this.data) as EDEmographicsFilterNames[];
    const items = this.data;
    keys.forEach((key) => {
      const value = items[key];
      const found = DataUtility.findFilterItem(
        crosstabConfig.crosstab.data_filter,
        key,
        this.uiArea
      );

      const isFiltered =
        (value?.enabled ?? false) && (value?.includedIds ?? []).length > 0;
      if (!isFiltered && found.ix >= 0) {
        crosstabConfig.crosstab.data_filter.items?.splice(found.ix, 1);
        return;
      }

      if (isFiltered) {
        const filterItem: IDataFilterItem = {
          ui_area: this.uiArea,
          name: key,
          enabled: true,
          list_1: value?.includedIds ?? [],
          as_exclude: false,
        };

        if (!crosstabConfig.crosstab.data_filter.items?.length) {
          crosstabConfig.crosstab.data_filter.items = [];
        }

        if (found.ix >= 0) {
          crosstabConfig.crosstab.data_filter.items[found.ix] = {
            ...filterItem,
          };
        } else {
          crosstabConfig.crosstab.data_filter.items.push(filterItem);
        }
      }
    });
  }

  reset(): void {
    if (!this.selectedDemographics?.length) {
      return;
    }
    const selectedIds = [...this.selectedDemographics];
    selectedIds.forEach((item) => {
      this.onRemoveDemographic(item);
    });
    this.onFilterChanged();
  }

  private initialize(filterData: DemographicsFilterUI | undefined): void {
    if (!filterData || !this.context) {
      return;
    }

    this.selectedDemographicOption = undefined;
    this.demographicsOptions = [];
    this.selectedDemographics = [];
    this.ageClassificationId =
      this.context.mission?.quota?.age_classification_id;
    this.regionClassificationId =
      this.context.mission?.quota?.region_classification_id;

    const keys = Object.keys(filterData) as EDEmographicsFilterNames[];
    keys.forEach((key) => {
      const value = filterData[key];
      if (!value.enabled) {
        const option = this.allDemographicsOptions.find((x) => x.key === key);
        if (option) {
          this.demographicsOptions.push({ ...option });
        }
      } else {
        this.selectedDemographics?.push(key);
        this.loadDemographicsValuesCached(key, filterData[key])
          .pipe(takeUntil(this.unsubscribeAll), take(1))
          .subscribe((list) => {
            value.list = list;
            value.loaded = true;
            value.list.forEach(
              (x) => (x.include = value.includedIds?.some((y) => y === x.id))
            );
            value.allIncluded = !value.list.some((x) => !x.include);
          });
      }
    });

    this.selectListVisible = !this.selectedDemographics.length;
    this.addButtonVisible = this.selectedDemographics.length > 0;
    this.demographicsOptions.sort((a, b) => a.key.localeCompare(b.key));
    this.customAgeRangeName =
      this.crosstabConfig?.crosstab?.orchestration?.custom_age_group
        ?.age_group_classification_name ?? 'None';

    this._data = { ...filterData };
  }

  private onFilterChanged(): void {
    if (!this.context || !this.crosstabConfig || !this.data) {
      return;
    }

    const tmpConfig = JSON.parse(
      JSON.stringify(this.crosstabConfig)
    ) as ICrosstabConfigUI;
    this.prepareSave(this.context, tmpConfig);
    this.filterChanged.emit(tmpConfig.crosstab);
  }

  private loadDemographicsValuesCached(
    demographicsKey: EDEmographicsFilterNames,
    item: IDemographicsFilterItem
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      switch (demographicsKey) {
        case EDEmographicsFilterNames.gender:
          observable.next([
            {
              id: '1',
              name: 'Female',
            },
            {
              id: '2',
              name: 'Male',
            },
          ]);
          break;
        case EDEmographicsFilterNames.ethnicity:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .ethnicityGetValues(this.context?.brand_id ?? '')
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.relationship:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .relationshipGetValues(this.context?.brand_id ?? '')
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.state:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .stateGetValues(this.context?.brand_id ?? '')
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.education:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .educationLevelGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.employment:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .employmentStatusGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.household:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .householdSizeGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.income_level:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .incomeLevelGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.parenting:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .parentingStatusGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.age_group:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .ageGroupGetValues(this.ageClassificationId)
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.region:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .regionGetValues(this.regionClassificationId)
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
        case EDEmographicsFilterNames.custom_age_group:
          if (!this.crosstabConfig?.crosstab?.orchestration?.custom_age_group) {
            observable.next(<Array<IDPair>>[]);
          } else {
            const { custom_age_group } =
              this.crosstabConfig.crosstab.orchestration;
            const ageGroups = custom_age_group.age_groups.map((ageGroup) => {
              return {
                id: ageGroup.age_group_id,
                name: ageGroup.age_group_name,
              } as IDPair;
            });
            item.loaded = true;
            observable.next(ageGroups);
          }

          break;
      }
    });
  }
}
