<div class="loading-element">
    <svg width="150" height="41" viewBox="0 0 150 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32.0308 8.37891C32.2816 8.5232 32.4899 8.73209 32.6344 8.9819H32.6386C32.7853 9.23386 32.8618 9.51813 32.8618 9.80885V19.2478C32.8618 19.3231 32.8214 19.3964 32.7555 19.433C32.6897 19.4696 32.6089 19.4717 32.543 19.433L30.7407 18.395C30.3837 18.1904 30.0883 17.8932 29.8821 17.5379C29.676 17.1804 29.5676 16.7755 29.5676 16.3642V11.3077C29.5676 11.1397 29.5229 10.9782 29.4401 10.8339C29.3572 10.6896 29.236 10.569 29.0915 10.4851L18.0229 4.08478C17.5384 3.80482 16.99 3.65838 16.4289 3.65838C15.8679 3.65838 15.3195 3.80482 14.835 4.08478L3.76426 10.4851C3.61974 10.569 3.50072 10.6896 3.41784 10.8339C3.33495 10.9782 3.29244 11.1419 3.29244 11.3099V15.352L22.6773 26.6021C22.7432 26.6408 22.7836 26.7119 22.7836 26.7894C22.7836 26.8669 22.7432 26.938 22.6773 26.9768L21.2109 27.8231C20.7518 28.088 20.2332 28.228 19.704 28.228C19.1748 28.228 18.6563 28.088 18.1972 27.8231L0.833466 17.7381C0.584805 17.5939 0.376525 17.3871 0.232005 17.1351C0.087484 16.8832 0.010973 16.5989 0.010973 16.3082V9.8067C0.010973 9.51813 0.0853587 9.23386 0.229879 8.98405C0.3744 8.73209 0.58268 8.5232 0.833466 8.37891L14.0295 0.725281C14.7627 0.301037 15.5916 0.0792236 16.4374 0.0792236C17.2833 0.0792236 18.1122 0.301037 18.8454 0.725281L32.0308 8.37891Z"
            fill="#6A33D4" />
        <path
            d="M32.0326 23.2684C32.2812 23.4126 32.4895 23.6215 32.634 23.8713L32.6319 23.8692C32.7764 24.119 32.8551 24.4033 32.8551 24.694V31.1912C32.8551 31.4819 32.7786 31.7662 32.634 32.0181C32.4895 32.2679 32.2834 32.4768 32.0326 32.6211L18.8344 40.2726C18.1033 40.6968 17.2723 40.9208 16.4265 40.9208C15.5806 40.9208 14.7496 40.6968 14.0185 40.2726L0.824618 32.619C0.573832 32.4747 0.365552 32.2679 0.221032 32.016C0.076511 31.7662 0 31.4798 0 31.189V21.7523C0 21.6747 0.0403808 21.6058 0.10414 21.5692C0.170024 21.5326 0.250786 21.5305 0.31667 21.5692L2.11893 22.6072C2.47385 22.8118 2.76927 23.1068 2.97543 23.4643C3.18158 23.8218 3.28997 24.2267 3.28997 24.638V29.6945C3.28997 29.8603 3.33248 30.0218 3.41536 30.1661C3.49825 30.3104 3.61727 30.431 3.76179 30.515L14.824 36.9174C15.3086 37.1974 15.859 37.3438 16.418 37.3438C16.9791 37.3438 17.5274 37.1974 18.012 36.9174L29.0805 30.5171C29.2251 30.4331 29.3462 30.3125 29.4291 30.1683C29.512 30.024 29.5566 29.8625 29.5566 29.6966V25.6502L10.1696 14.4001C10.1037 14.3614 10.0633 14.2903 10.0633 14.2128C10.0633 14.1353 10.1058 14.0642 10.1717 14.0254L11.6573 13.1791C12.1164 12.9142 12.6349 12.7742 13.1641 12.7742C13.6933 12.7742 14.2119 12.9142 14.671 13.1791L32.0326 23.2684Z"
            fill="#6A33D4" />
        <path
            d="M43.9527 28.9364L43.976 28.9154C44.6465 28.1675 45.5646 27.6588 46.5785 27.4777C47.5924 27.2965 48.6413 27.4498 49.5501 27.9143C51.0639 28.6692 52.7482 29.0548 54.456 29.0409C57.1612 29.0409 58.8689 27.8493 58.8689 26.0469V25.9772C58.8689 24.2792 57.8714 23.3641 53.2435 22.3374C47.9428 21.1133 44.9502 19.6175 44.9502 15.2275V15.1578C44.9502 11.0744 48.5128 8.24997 53.4584 8.24997C56.7477 8.18958 59.9552 9.25107 62.4993 11.244C61.9083 12.0384 61.0509 12.6144 60.0697 12.8815C59.0885 13.1463 58.042 13.0859 57.1028 12.7073C55.9277 12.2242 54.6639 11.9687 53.386 11.9594C50.8209 11.9594 49.3234 13.2183 49.3234 14.7839V14.8489C49.3234 16.6908 50.4658 17.5038 55.2456 18.5932C60.5159 19.8219 63.2586 21.6406 63.2586 25.5591V25.6381C63.2586 30.0954 59.6142 32.7503 54.3578 32.7503C50.5126 32.7712 46.8004 31.4101 43.955 28.9364H43.9527Z"
            fill="#27292C" />
        <path
            d="M69.8309 22.1306V8.24752C70.999 8.24752 72.118 8.69582 72.945 9.49019C73.7697 10.2846 74.2346 11.3623 74.2346 12.4865V21.9796C74.2346 26.4579 76.6361 28.8689 80.5749 28.8689C84.5136 28.8689 86.8731 26.5996 86.8731 22.1515V8.27075H91.2791V21.9332C91.2791 29.1337 87.0507 32.7525 80.5001 32.7525C73.9495 32.7525 69.8309 29.1244 69.8309 22.1306Z"
            fill="#27292C" />
        <path
            d="M97.8514 29.5843L113.31 12.0661H102.421C101.886 12.0661 101.353 11.9686 100.858 11.7758C100.363 11.583 99.9119 11.3019 99.5335 10.9489C99.155 10.5935 98.8536 10.1731 98.6481 9.70853C98.4425 9.24398 98.3374 8.74924 98.3397 8.24752H119.3V11.4552L103.841 28.9316H115.218C115.753 28.9316 116.286 29.0291 116.781 29.2219C117.276 29.4147 117.727 29.6958 118.106 30.0488C118.484 30.4042 118.786 30.8246 118.991 31.2892C119.197 31.7514 119.302 32.2485 119.3 32.7502H97.8514V29.582V29.5843Z"
            fill="#27292C" />
        <path
            d="M135.735 23.0968L125.872 8.24752H131.019L137.976 19.0971L145.005 8.24752H150L140.132 22.9854V28.4136C140.132 28.9827 140.017 29.5471 139.798 30.0744C139.576 30.6016 139.253 31.0801 138.845 31.482C138.436 31.8861 137.95 32.2043 137.417 32.4227C136.882 32.641 136.31 32.7525 135.733 32.7525L135.738 23.0968H135.735Z"
            fill="#27292C" />
        <path d="M145.44 29.784V29.5308H147.224V29.784H146.477V31.8954H146.19V29.784H145.44Z" fill="#27292C" />
        <path
            d="M148.012 29.5308H147.671V31.8954H147.94V30.0976H147.963L148.706 31.8954H148.965L149.708 30.0976H149.731V31.8954H150V29.5308H149.657L148.848 31.4935H148.82L148.012 29.5308Z"
            fill="#27292C" />
    </svg>


    <igx-linear-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-linear-bar>
    <p>{{ 'customBannerPoints.formText.labels.loadingData' | translate }}</p>
</div>