<form [formGroup]="criteriaForm">
    <div class="form-field-container" formGroupName="field">
        <div class="field-flex-container"
            [ngClass]="{'field-columns-container': currentRoute === libRoutes.CBPDerivedQuestion && categorySelected === categories.Responses }">
            <div class="input-container">
                <label class="label text-suzy-grey-800"
                    for="source">{{'customBannerPoints.formText.labels.groups.category'
                    |
                    translate}}</label>
                <!-- Category -->
                <igx-simple-combo class="suzy-dropdown" #categoryCombo formControlName="source" [data]="CATEGORY_TYPES"
                    displayKey="name" valueKey="value" type="border" (selectionChanging)="categoryChange($event)"
                    [placeholder]="'customBannerPoints.formText.labels.groups.categoryPlaceholder' | translate">
                    <ng-template igxComboItem let-display let-key="valueKey">
                        <span>{{
                            'customBannerPoints.formText.categories.'
                            + display.value |
                            translate
                            }}</span>
                    </ng-template>
                </igx-simple-combo>
                <igx-hint class="error-message"
                    *ngIf="criteriaForm.get('field')?.get('source')?.invalid && (criteriaForm.get('field')?.get('source')?.dirty || criteriaForm.get('field')?.get('source')?.touched) && criteriaForm.get('field')?.get('source')?.errors?.['required']">
                    <igx-icon>info</igx-icon> {{'customBannerPoints.formText.validations.logicBlock.category'
                    |
                    translate
                    }}
                </igx-hint>
            </div>

            <div class="input-container"
                *ngIf="currentRoute === libRoutes.CBPDerivedQuestion && categorySelected === categories.Responses;">
                <label class="label text-suzy-grey-800" for="mission_id">
                    {{'customBannerPoints.derivedQuestions.form.surveyLabel' | translate}}
                </label>
                <selected-mission-dropdown formControlName="mission_id" [missions]="brandMissions"
                    [searchingMissions]="searchingMissions | async" [cssClass]="'form-search-container'"
                    (searchMissionsByTerm)="searchMissions($event)"
                    (missionSelected)="getSelectedMissionActions($event)"></selected-mission-dropdown>
                <igx-hint class="error-message"
                    *ngIf="criteriaForm.get('field')?.get('mission')?.invalid && (criteriaForm.get('field')?.get('mission')?.dirty || criteriaForm.get('field')?.get('mission')?.touched)">
                    <igx-icon>info</igx-icon>
                    {{'customBannerPoints.formText.validations.logicBlock.categoryFirst'
                    |
                    translate
                    }}
                </igx-hint>
                <igx-hint class="error-message"
                    *ngIf="criteriaForm.get('field')?.get('mission')?.valid && criteriaForm.get('field')?.get('mission')?.errors?.['required']">
                    <igx-icon>info</igx-icon>
                    {{'customBannerPoints.formText.validations.logicBlock.attribute'
                    |
                    translate
                    }}
                </igx-hint>

            </div>

            <div class="input-container">
                <label class="label text-suzy-grey-800" for="field">
                    <ng-container *ngIf="categorySelected === categories.Responses; else elseTemplate">
                        <ng-container *ngIf="currentRoute === libRoutes.CBPDerivedQuestion; else elseLabel">
                            {{'customBannerPoints.formText.labels.groups.question' | translate}}
                        </ng-container>
                        <ng-template #elseLabel>
                            {{'customBannerPoints.formText.labels.groups.question' | translate}}
                        </ng-template>
                    </ng-container>
                    <ng-template #elseTemplate>
                        {{'customBannerPoints.formText.labels.groups.attribute' | translate}}
                    </ng-template>
                </label>
                <!-- Question -->
                <igx-simple-combo class="suzy-dropdown" #attrCombo formControlName="field" [data]="attributeList"
                    type="border" [displayKey]="'label'" valueKey="_drag_id"
                    (selectionChanging)="attributeChange($event)"
                    [placeholder]="(categorySelected === categories.Responses ? 'customBannerPoints.formText.labels.groups.questionPlaceholder' : 'customBannerPoints.formText.labels.groups.attributePlaceholder') | translate">
                    <ng-template igxComboItem let-display let-key="valueKey">
                        <span>{{ display.label }}</span>
                    </ng-template>
                </igx-simple-combo>
                <igx-hint class="error-message"
                    *ngIf="criteriaForm.get('field')?.get('source')?.invalid && (criteriaForm.get('field')?.get('field')?.dirty || criteriaForm.get('field')?.get('field')?.touched)">
                    <igx-icon>info</igx-icon>
                    {{'customBannerPoints.formText.validations.logicBlock.categoryFirst'
                    |
                    translate
                    }}
                </igx-hint>
            </div>
        </div>

        <!-- ActionKind Grid -->
        <div class="rows-columns-container">
            <!-- rows/columns -->
            <div class="radio-group-container" *ngIf="formContainsRowsColumnsControl()">
                <span class="text-suzy-grey-800">
                    {{ 'customBannerPoints.formText.labels.grid.attributeSource' | translate }}
                </span>
                <igx-radio-group formControlName="rows_columns" (change)="updateRowsColumns($event)">
                    <igx-radio *ngFor="let option of ROWSCOLUMNS" [value]="option.value">
                        {{ option.name }}
                    </igx-radio>
                </igx-radio-group>
                <igx-hint class="error-message"
                    *ngIf="criteriaForm.get('field')?.get('rows_columns')?.invalid && (criteriaForm.get('field')?.get('rows_columns')?.dirty || criteriaForm.get('field')?.get('rows_columns')?.touched)">
                    <igx-icon>info</igx-icon>
                    {{'customBannerPoints.formText.validations.grid.attributeSourceError' | translate }}
                </igx-hint>
            </div>

            <!-- attribute_source -->
            <div class="input-container" *ngIf="formContainsAttributeSourceControl()">
                <label class="label text-suzy-grey-800"
                    for="attribute_source">{{'customBannerPoints.formText.labels.grid.attribute'
                    |
                    translate}}</label>
                <igx-simple-combo class="suzy-dropdown" [disabled]="attributeSourceHasValue"
                    formControlName="attribute_source" [data]="attributeSources" type="border" [displayKey]="'name'"
                    valueKey="id"
                    [placeholder]="'customBannerPoints.formText.labels.grid.attributePlaceholder' | translate"
                    (selectionChanging)="attributeSourceChange($event)">
                    <ng-template igxComboItem let-display let-key="valueKey">
                        <span>{{ display.name }}</span>
                    </ng-template>
                </igx-simple-combo>
                <igx-hint class="error-message"
                    *ngIf="attributeSourceHasValue === false && criteriaForm.get('field')?.get('attribute_source')?.invalid && (criteriaForm.get('field')?.get('attribute_source')?.dirty || criteriaForm.get('field')?.get('attribute_source')?.touched)">
                    <igx-icon>info</igx-icon>
                    {{'customBannerPoints.formText.validations.grid.attributeSource' | translate }}
                </igx-hint>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isLoading; else elseParameters">
        <div class="loader-container">
            <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
            <p>{{ 'customBannerPoints.formText.labels.loadingAttributes' | translate }}</p>
        </div>
    </ng-container>
    <ng-template #elseParameters>
        <ng-container *ngIf="parameters.controls.length > 0; else noParameters">

            <div class="operator-container my-16">
                <span class="body-copy-medium text-suzy-grey-900"> {{
                    'customBannerPoints.formText.headers.ruleOne' | translate }}</span>
                <igx-select formControlName="ui_operator" type="border" class="operator-select" [placeholder]="'customBannerPoints.formText.labels.groups.containsOperatorPlaceholder'
                |
                translate" (selectionChanging)="selectedOperator($event)">
                    <igx-select-item *ngFor="let item of operators" [value]="item.name">
                        {{item.name}}
                    </igx-select-item>
                </igx-select>
                <!-- at_least -->
                <ng-container *ngIf="showAtLeastField(operatorText)">

                    <mat-form-field class="suzy-text-input number-field" floatLabel="never">
                        <input matInput type="number" formControlName="at_least" />
                        <!-- <mat-error align="start" class="error-message"
                            *ngIf="fabricationItemForm.get('title')?.invalid && (fabricationItemForm.get('title')?.dirty || fabricationItemForm.get('title')?.touched) && fabricationItemForm.get('title')?.errors?.['cantValidateName']">
                            <igx-icon>info</igx-icon> {{translations!.validations.cantValidateName |
                            translate
                            }}
                        </mat-error> -->
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="showAtMostField(operatorText)">
                    <span *ngIf="ruleTwo" class="body-copy-medium text-suzy-grey-900">{{
                        'customBannerPoints.formText.headers.and' | translate }}</span>
                    <span *ngIf="!ruleTwo" class="body-copy-medium text-suzy-grey-900">{{
                        'customBannerPoints.formText.headers.to' | translate }}</span>
                    <!-- at_most -->
                    <mat-form-field class="suzy-text-input number-field" floatLabel="never">
                        <input matInput type="number" formControlName="at_most" />

                        <!-- <mat-error align="start" class="error-message"
                            *ngIf="fabricationItemForm.get('title')?.invalid && (fabricationItemForm.get('title')?.dirty || fabricationItemForm.get('title')?.touched) && fabricationItemForm.get('title')?.errors?.['cantValidateName']">
                            <igx-icon>info</igx-icon> {{translations!.validations.cantValidateName |
                            translate
                            }}
                        </mat-error> -->
                    </mat-form-field>

                </ng-container>
                <span *ngIf="ruleTwo" class="body-copy-medium text-suzy-grey-900">{{
                    'customBannerPoints.formText.headers.ruleTwo' | translate }}</span>
                <span *ngIf="!ruleTwo" class="body-copy-medium text-suzy-grey-900">{{
                    'customBannerPoints.formText.headers.ruleThree' | translate }}</span>
            </div>
            <igx-banner
                *ngIf="criteriaForm.get('field')?.get('attribute_source')?.valid && criteriaForm.get('parameters')?.hasError('markItems')">
                <igx-icon>info</igx-icon>
                {{'customBannerPoints.formText.validations.logicBlock.parameters'
                |
                translate
                }}
            </igx-banner>

            <!-- Parameters / Checkboxes / Scale UI -->
            <ng-container *ngIf="itIsRankScale(); else elseCheckboxes">
                <ng-container formArrayName="parameters">
                    <div class="rankscale-wrapper">
                        <div class="rankscale-group">

                            <ng-container [formGroupName]="paramIdx"
                                *ngFor="let param of parameters.controls let paramIdx = index">
                                <input [id]="id + paramIdx" type="checkbox" formControlName="checked"
                                    class="rankscale-btn" />
                                <label [for]="id + paramIdx">{{ param.value.display }}</label>
                            </ng-container>
                        </div>
                        <div class="rankscale-legend">
                            <span>{{scaleRankLessLegend}}</span>
                            <span>{{scaleRankMostLegend}}</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #elseCheckboxes>
                <div class="parameter-list">
                    <ng-container formArrayName="parameters">
                        <ng-container [formGroupName]="paramIdx"
                            *ngFor="let param of parameters.controls let paramIdx = index">
                            <igx-checkbox formControlName="checked">
                                <ng-container
                                    *ngIf="criteriaForm.get('field')?.get('rows_columns')?.invalid; else normalCheckboxes">
                                    <span class="text-suzy-grey-400">--</span>
                                </ng-container>
                                <ng-template #normalCheckboxes>{{ param.value.display }}</ng-template>
                            </igx-checkbox>

                        </ng-container>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #noParameters>
            <igx-banner *ngIf="criteriaForm.get('field')?.get('field')?.value">
                <igx-icon>info</igx-icon>
                {{'customBannerPoints.formText.validations.noParameters' | translate }}
            </igx-banner>
        </ng-template>
    </ng-template>
</form>