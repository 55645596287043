import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take, takeUntil, tap } from 'rxjs';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { TableViewService } from '../../../services/table-view.service';
import { CbpConfirmActionDialogComponent } from '../../custom-banner-points/cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';

@Component({
  selector: 'manage-tables-portal',
  templateUrl: './manage-tables-portal.component.html',
  styleUrls: ['./manage-tables-portal.component.scss'],
})
export class ManageTablesPortalComponent implements OnInit, OnDestroy {
  brandId = '';
  mission: any;
  action: any;
  tableView!: any[];
  unsubscribe = new Subject<void>();

  constructor(
    private dialog: Dialog,
    private translate: TranslateService,
    private tableViews: TableViewService,
    @Inject(DATA_TOKEN) public formInputs: ICBPFormInputs
  ) {
    if (this.formInputs && this.formInputs.uiData) {
      this.brandId = formInputs.uiData?.brand_id ?? '';
      this.mission = formInputs.uiData?.mission ?? '';
      this.action = formInputs.uiData?.action ?? '';
    }
  }

  ngOnInit(): void {
    this.getTableViews();
  }

  getTableViews() {
    this.tableViews
      .getTableViewsForAction(this.brandId, this.mission, this.action)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        // Return tables views except first default table view
        this.tableView = res.slice(1);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  confirmDelete(tvId: string): void {
    this.dialog.open(CbpConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.title'
        ),
        ctaBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.ctaBtn'
        ),
        cancelBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.cancelBtn'
        ),
        callback: () => this.deleteTableView(tvId),
      },
    });
  }

  deleteTableView(tvId: string): void {
    this.tableViews
      .deleteTableView(this.brandId, tvId)
      .pipe(
        tap(() => this.getTableViews()),
        takeUntil(this.unsubscribe),
        take(1)
      )
      .subscribe();
  }
}
