/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TranslateService } from '@ngx-translate/core';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { SuzyDataService } from '../../../../services/suzy-data.service';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';
import { DemographicDimensionProvider } from './demographic-dimension-provider';
import { MissionKind } from '@asksuzy/typescript-sdk';

export class GlobalDimensionProvider implements IDimensionProvider {
  constructor(
    private translate: TranslateService,
    private suzy: SuzyDataService
  ) {
    this.name = this.translate.instant('builder.dimensionGlobalProfiling');
  }

  code: string = 'globalprofiling';
  name: string;
  instructions = 'builder.profilingTabInstructions';
  dimensions: Array<IBuilderDimension> = [];
  loading: boolean = false;
  loaded: boolean = false;
  enabled: boolean = false;
  error?: string;

  brand_id?: string;
  mission_id?: string;

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (
      !context.global ||
      context.mission?.mission_kind === MissionKind.external_link
    ) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }
    if (this.brand_id != context.brand_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    if (this.mission_id != context.mission?.mission_id) {
      this.dimensions = [];
      this.loaded = false;
    }

    this.brand_id = context.brand_id;
    this.mission_id = context.mission?.mission_id;
    this.error = undefined;

    if (!this.loaded && this.brand_id && this.mission_id && context.mission) {
      this.loading = true;
      const { mission, brand_id } = context;

      const demographics = DemographicDimensionProvider.DIMENSIONS();
      const genderDim = demographics.find(
        (x) => x.field?.field === 'gender'
      ) as IBuilderDimension;
      const ageGroupDim = demographics.find(
        (x) => x.field?.field === 'age_group_id'
      ) as IBuilderDimension;
      this.dimensions = [genderDim, ageGroupDim];

      this.suzy
        .getBrandProfileQuestions(brand_id, mission.mission_id)
        .subscribe({
          next: (data) => {
            this.loading = false;
            const newDimensions = data.map((item) => {
              return {
                type: 'field',
                label: item.name,
                field: {
                  source: 'profile',
                  field: 'brand_profile_question_id',
                  identifier: item.id,
                  action_id: 'primary',
                },
              } as IBuilderDimension;
            });
            this.dimensions.push(...newDimensions);
            builder.dragdrop?.prepareNewItems(this.code, this.dimensions);
            this.loaded = true;
          },
          error: (error) => {
            this.loading = false;
            this.loaded = false;
            this.error = error;
          },
        });
    }
    return true;
  }
}
