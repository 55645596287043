import { ICrosstabDataSet } from '../models/data/response/ICrosstabDataSet';
import { IColumnInfo } from '../models/ui/i-column-info';
import {
  IStatTestingCell,
  IStatTestingCellMergeData,
} from '../models/ui/i-stat-testing-cell.model';

export class StatTestingDataUtility {
  public static getStatTestingMergeData(
    refData: ICrosstabDataSet,
    columns: IColumnInfo[],
    columnKeysMap: Array<{ sourceKey: string; destKey: string }>,
    rowKeysMap: Array<{ sourceKey: string; destKey: string }>,
    statLetterRowsRef: Array<{
      bannerId: string;
      statLetterRowIx: number;
      groupKey: string;
      statLetters: string[];
    }>
  ): IStatTestingCellMergeData[] {
    const refDataRows = refData.rows;
    const refDataValues = refData.data;

    const mergeData = new Array<IStatTestingCellMergeData>();
    const colsToProcess = new Array<{
      colIx: number;
      dataKeys: Array<{ key: string; mappedKey: string }>;
    }>();

    const getCellKeys = (
      col: IColumnInfo
    ): Array<{ key: string; mappedKey: string }> => {
      if (col.columns?.length) {
        const fieldsArray = col.columns.map((childCol) => {
          return getCellKeys(childCol);
        });
        const keys = new Array<{ key: string; mappedKey: string }>();
        fieldsArray.forEach((fields) => {
          keys.push(...fields);
        });
        return keys;
      }

      const mappedKey = this.getMappedKey(col.field, columnKeysMap);
      return [{ key: col.field, mappedKey }];
    };

    columns.forEach((colGroup, colIx) => {
      if (colIx === 0) {
        return;
      }
      colsToProcess.push({ colIx, dataKeys: getCellKeys(colGroup) });
    });

    colsToProcess.forEach((colToProcess) => {
      const { colIx, dataKeys } = colToProcess;
      dataKeys.forEach((dataKey) => {
        const { key, mappedKey } = dataKey;
        refDataRows.forEach((rows, rowIx) => {
          if (refDataValues.length < rowIx) {
            return;
          }
          if (
            !Object.keys(refDataValues[rowIx]).some((refKey) => refKey === key)
          ) {
            return;
          }

          const rowKey = rows.map((row) => row.k).join(',');
          const mappedRowKey = this.getMappedKey(rowKey, rowKeysMap);
          const cellValue = `${refDataValues[rowIx][key]}`;
          const statTestingParts = cellValue.split('_');
          if (statTestingParts.length !== 2) {
            return;
          }

          const statTestingGroupKey = statTestingParts[0];
          const statTestingLetters = statTestingParts[1];
          const rowBannerId = rows[0].bannerId ?? '';
          const statLetterRowRef = statLetterRowsRef.find(
            (x) =>
              x.bannerId === rowBannerId && x.groupKey === statTestingGroupKey
          );
          if (!statLetterRowRef) {
            return;
          }
          const isStatLetterRowCell =
            rowIx === statLetterRowRef.statLetterRowIx;

          const fieldData: IStatTestingCellMergeData = {
            colIx,
            columnKey: mappedKey,
            rowIx: rowIx,
            rowKey: mappedRowKey,
            statLetter: statTestingLetters,
            statLetterGroup: statTestingGroupKey,
            statLetters: [],
            kind: isStatLetterRowCell ? 'ref' : 'value',
          };
          if (!isStatLetterRowCell) {
            let lettersRef = `${statTestingLetters.toLocaleLowerCase()}`;
            statLetterRowRef.statLetters.forEach((statLetter) => {
              if (lettersRef.indexOf(statLetter) >= 0) {
                lettersRef = lettersRef.replace(statLetter, '');
                fieldData.statLetters.push(statLetter);
              }
            });
          }
          mergeData.push(fieldData);
        });
      });
    });

    return mergeData;
  }

  public static getStatLetterRowsMap(
    rowBannerIds: string[],
    crosstabData: ICrosstabDataSet
  ): {
    bannerId: string;
    statLetterRowIx: number;
    groupKey: string;
    statLetters: string[];
  }[] {
    const refMap = [] as {
      bannerId: string;
      statLetterRowIx: number;
      groupKey: string;
      statLetters: string[];
    }[];

    rowBannerIds.forEach((rowBannerId) => {
      const statLettersRowIx = crosstabData.rows.findIndex((rows) =>
        rows.some(
          (row) =>
            row.s === 'stat_test_column' &&
            row.i === 'a' &&
            row.bannerId === rowBannerId
        )
      );
      if (statLettersRowIx < 0) {
        return;
      }

      if (crosstabData.data?.length < statLettersRowIx) {
        return;
      }

      const statLettersRowData = crosstabData.data[statLettersRowIx];
      const dataFieldKeys = Object.keys(statLettersRowData).filter((key) =>
        key.startsWith('c')
      );

      dataFieldKeys.forEach((fieldKey) => {
        const statLetterRef = `${statLettersRowData[fieldKey]}`;
        const statLetterRefParts = statLetterRef.split('_');
        if (statLetterRefParts.length !== 2) {
          return;
        }
        const groupKey = statLetterRefParts[0];
        const statLetter = statLetterRefParts[1];
        let ref = refMap.find(
          (x) => x.bannerId === rowBannerId && x.groupKey === groupKey
        );
        if (!ref) {
          ref = {
            bannerId: rowBannerId,
            statLetterRowIx: statLettersRowIx,
            groupKey: groupKey,
            statLetters: [statLetter],
          };
          refMap.push(ref);
        } else {
          if (!ref.statLetters.some((x) => x === statLetter)) {
            ref.statLetters.push(statLetter);
          }
        }
        ref.statLetters.sort((a, b) => {
          const diff = b.length - a.length;
          if (diff === 0) {
            return a.localeCompare(b);
          } else {
            return diff;
          }
        });
      });
    });

    return refMap;
  }

  public static isStatLetterCell(object: any): object is IStatTestingCell {
    return 'statLetterGroup' in object;
  }

  private static getMappedKey(
    keyToMap: string,
    map: Array<{ sourceKey: string; destKey: string }>
  ): string {
    const destKeys = [] as string[];
    const sourceKeys = keyToMap.split(',');
    sourceKeys.forEach((sourceKey) => {
      const destKey = map.find((x) => x.sourceKey === sourceKey)?.destKey;
      if (destKey) {
        destKeys.push(destKey);
      }
    });
    if (destKeys.length === sourceKeys.length) {
      return destKeys.join(',');
    }
    return '';
  }
}
