import { AbstractControl, ValidationErrors } from '@angular/forms';

export function atLeastOneValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!!control && !control.value.some((res: any) => res.checked)) {
    return { markItems: true };
  }
  return null;
}
