import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalText',
})
export class ConditionalTextPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return value === 'true' ? args[0] : args[1];
  }
}
