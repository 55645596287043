export interface ISegmentTrackActionData {
  tableview_id: string;
  tableview_name: string;
  primary_mission_id: string;
  primary_mission_kind: string;
  primary_mission_name: string;
  brand_id: string;
  brand_name: string;
  primary_action_id: string;
  primary_action_kind: string;
  primary_action_text: string;
  data_export_kind_name?: 'excel' | 'powerpoint';
}

export interface ISegmentTrackBulkExportData {
  data_export_kind_name: 'excel' | 'powerpoint';
  primary_mission_id: string;
  primary_mission_kind: string;
  primary_mission_name: string;
  brand_id: string;
  brand_name: string;
  action_ids_included: string[];
  action_kinds_included: string[];
  action_text_included: string[];
  tableview_ids_included: string[];
  tableview_names_included: string[];
}

export interface ISegmentTrackApplyTableViewData {
  primary_mission_id: string;
  primary_mission_kind: string;
  primary_mission_name: string;
  brand_id: string;
  brand_name: string;
  source_action_id: string;
  source_action_kind: string;
  source_action_text: string;
  source_tableview_id: string;
  source_tableview_name: string;
  applied_attributes: string[];
  destination_action_ids: string[];
  destination_action_kinds: string[];
  destination_action_text: string[];
}

export enum ESegmentTrackActionKind {
  tableViewDeleteClicked = 'Data Explorer Table View Delete Clicked',
  tableViewDuplicated = 'Data Explorer Table View Duplicated',
  tableInfoModalClicked = 'Data Explorer Table Info Clicked',
  chartClicked = 'Data Explorer Chart Clicked',
  chartDownloaded = 'Data Explorer Chart Downloaded',
  individualExport = 'Data Explorer Individual Export',
  bulkExport = 'Data Explorer Bulk Export Clicked',
  applyTableView = 'Data Explorer Apply Table View Clicked',
  QueueRedLane = 'Async Processing: Client Opt-in',
  QueueYellowLane = '(Temp) Keep Me Updated - DE Try Queue',
  AsyncNotifyMe = 'Data Explorer Notify Me Clicked'
}
