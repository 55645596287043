<div class="search-bar" #searchBar>
  <igx-icon fontSet="material">search</igx-icon>
  <igx-input-group *ngIf="!selectedMission">
    <input
      #searchInput
      igxInput
      name="searchText"
      type="text"
      [placeholder]="'questionSelector.search' | translate"
      [(ngModel)]="searchText"
      (ngModelChange)="filterMissionsDelayed($event)"
      (focus)="toggleSearch()"
    />
  </igx-input-group>
  <span
    *ngIf="selectedMission"
    [innerHTML]="selectedMission.search_internal_text"
  ></span>
  <igx-icon fontSet="material" *ngIf="!global && !externalLink"
    >keyboard_arrow_down</igx-icon
  >
</div>
<button
  *ngIf="selectedMission && !global && !externalLink"
  igxButton="flat"
  [igxTooltipTarget]="removeTooltip"
  [showDelay]="0"
  [hideDelay]="0"
  (click)="onRemoveSelectedMission()"
>
  <igx-icon fontSet="material">delete_outline</igx-icon>
</button>

<igx-list class="crosstab-mission-search" #searchResults *ngIf="isSearchActive">
  <ng-container *ngIf="!loading">
    <igx-list-item
      *ngFor="let mission of filteredMissions"
      (click)="onMissionSelected(mission, true)"
    >
      <div class="mission-icon">
        <ng-container
          *ngIf="
            mission.mission_kind !== missionKind.screening &&
            mission.mission_kind !== missionKind.survey &&
            mission.mission_kind !== missionKind.splittesting &&
            mission.mission_kind !== missionKind.maxdiff &&
            mission.mission_kind !== missionKind.focusgroup &&
            mission.mission_kind !== missionKind.external_cint
          "
        >
          <ng-container
            *ngIf="
              mission.first_action.action_kind === actionKind.multiplechoice
            "
          >
            <igx-icon
              fontSet="material"
              *ngIf="
                mission.first_action.multiple_choice.multiple_choice_kind ===
                multipleChoiceKind.standard
              "
              >radio_button_checked</igx-icon
            >
            <igx-icon
              fontSet="material"
              *ngIf="
                mission.first_action.multiple_choice.multiple_choice_kind ===
                multipleChoiceKind.monadic
              "
              >linear_scale</igx-icon
            >
          </ng-container>
          <ng-container
            *ngIf="
              mission.first_action.action_kind === actionKind.grid ||
              mission.first_action.action_kind === actionKind.gridcustom ||
              mission.first_action.action_kind === actionKind.gridrankscale
            "
          >
            <igx-icon
              family="suzy"
              name="grid_rank"
              *ngIf="mission.first_action.grid.grid_kind === gridKind.rank"
            ></igx-icon>
            <igx-icon
              family="suzy"
              name="grid_scale"
              *ngIf="mission.first_action.grid.grid_kind === gridKind.scale"
            ></igx-icon>
            <igx-icon
              family="material"
              *ngIf="mission.first_action.grid.grid_kind === gridKind.open"
              >grid_on</igx-icon
            >
          </ng-container>
          <igx-icon
            fontSet="material"
            *ngIf="mission.first_action.action_kind === actionKind.openended"
            >sort</igx-icon
          >
          <igx-icon
            fontSet="material"
            *ngIf="
              mission.first_action.action_kind === actionKind.photoacquisition
            "
            >photo_camera</igx-icon
          >
          <igx-icon
            fontSet="material"
            *ngIf="
              mission.first_action.action_kind === actionKind.openended_video
            "
            >play_circle_filled</igx-icon
          >
          <igx-icon
            family="suzy"
            name="turf"
            class="no-color-fill"
            *ngIf="mission.first_action.action_kind === actionKind.turf"
          ></igx-icon>
          <span class="priority-number" *ngIf="mission.priority"
            >{{ 'questionSelector.questionCharacter' | translate
            }}{{ mission.priority }}</span
          >
        </ng-container>
        <igx-icon
          fontSet="material"
          *ngIf="mission.mission_kind === missionKind.screening"
          >filter_list</igx-icon
        >
        <igx-icon
          fontSet="material"
          *ngIf="
            mission.mission_kind === missionKind.survey ||
            (mission.mission_kind === missionKind.external_cint &&
              !mission.monadic_enabled)
          "
          >thumbs_up_down</igx-icon
        >
        <igx-icon
          fontSet="material"
          *ngIf="
            mission.mission_kind === missionKind.splittesting ||
            (mission.mission_kind === missionKind.external_cint &&
              mission.monadic_enabled)
          "
          class="rotate-90"
          >call_split</igx-icon
        >
        <igx-icon
          fontSet="material"
          *ngIf="mission.mission_kind === missionKind.focusgroup"
          >forum</igx-icon
        >
        <igx-icon
          family="suzy"
          name="maxdiff"
          *ngIf="mission.mission_kind === missionKind.maxdiff"
        ></igx-icon>
      </div>
      <div class="mission-text">
        <span [innerHTML]="mission.search_internal_text || mission.search_text || mission.unStrippedSearchText || mission.mission_caption"></span>
      </div>

      <div
        class="mission-info"
        [igxTooltipTarget]="missionTooltip"
        (tooltipShow)="
          missionTooltip.context = {
            stamp_utc: mission.stamp_utc,
            brand_tags_display: mission.brand_tags_text,
            show_brand_folder: showBrandFolderInTooltip,
            brand_folders_ids: mission.brand_folders_ids,
            folder_name: mission.folder_name$
          }
        "
      >
        <igx-icon fontSet="material">info_outline</igx-icon>
      </div>
    </igx-list-item>
  </ng-container>
  <ng-template igxEmptyList>
    <ng-container *ngIf="!loading; else loadingSpinner">
      <span>{{ 'questionSelector.noResults' | translate }}</span>
    </ng-container>
  </ng-template>
</igx-list>

<div #removeTooltip="tooltip" igxTooltip class="toolbar-tooltip">
  {{ 'builder.remove' | translate }}
</div>

<div #missionTooltip="tooltip" igxTooltip class="toolbar-tooltip">
  <div class="mission-tooltip" *ngIf="missionTooltip.context">
    <span *ngIf="missionTooltip.context.stamp_utc">
      {{ 'builder.createdOn' | translate }}:
      {{ missionTooltip.context.stamp_utc | date: 'mediumDate' }}
    </span>
    <span
      *ngIf="
        missionTooltip.context.stamp_utc &&
        !missionTooltip.context.brand_tags_display
      "
    >
      {{ 'builder.brandTags' | translate }}:
      {{ 'builder.noBrandTags' | translate }}
    </span>
    <span *ngIf="missionTooltip.context.brand_tags_display">
      {{ 'builder.brandTags' | translate }}:
      {{ missionTooltip.context.brand_tags_display }}
    </span>
    <ng-container *ngIf="missionTooltip.context.show_brand_folder">
      <span
        *ngIf="
          missionTooltip.context.stamp_utc &&
          !(missionTooltip.context.brand_folders_ids?.length ?? 0)
        "
        >{{ 'builder.brandFolder' | translate }}:
        {{ 'builder.noBrandTags' | translate }}
      </span>
      <span
        *ngIf="
          missionTooltip.context.stamp_utc &&
          (missionTooltip.context.brand_folders_ids?.length ?? 0) > 0 &&
          missionTooltip.context.folder_name !== undefined
        "
        >{{ 'builder.brandFolder' | translate }}:
        {{ missionTooltip.context.folder_name | async }}
      </span>
    </ng-container>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="loading" *ngIf="loading">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
    ></igx-circular-bar>
  </div>
</ng-template>
