import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { IBuilderContext } from '../../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IExpansion } from '../../../../../models/data/request/IExpansion';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calculations-column-total',
  templateUrl: './column-total.component.html',
  styleUrls: ['./column-total.component.scss'],
})
export class ColumnTotalComponent implements OnInit, IAdvancedProvider {
  private expansionName!:
    | 'primary_total_responses_column'
    | 'primary_base_size_column'
    | 'span_total_responses_row'
    | 'span_base_size_row';
  label!: string;
  included!: boolean;
  expansion?: IExpansion;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Input()
  public kind!:
    | 'base_column'
    | 'responses_column'
    | 'base_row'
    | 'responses_row';

  @Output() calculationsChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private translate: TranslateService) {}
  ngOnInit(): void {
    switch (this.kind) {
      case 'base_column':
        this.expansionName = 'primary_base_size_column';
        break;
      case 'base_row':
        this.expansionName = 'span_base_size_row';
        break;
      case 'responses_column':
        this.expansionName = 'primary_total_responses_column';
        break;
      case 'responses_row':
        this.expansionName = 'span_total_responses_row';
    }
    this.label = `calculations.${this.kind}Include`;
    const found = this.findExpansion(this.kind, this.crosstabConfig);
    this.expansion = found.item;
    this.included = found.ix >= 0;
  }

  onSelectionChange(included: boolean): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.included = included;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
    setTimeout(() => {
      this.expansion = this.findExpansion(this.kind, this.crosstabConfig)?.item;
    }, 0);
  }

  reset(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.included = false;
    this.expansion = undefined;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.included) {
      const found = this.findExpansion(this.kind, crosstabConfig);
      if (found.ix >= 0) {
        crosstabConfig.crosstab.expansions?.splice(found.ix, 1);
      }
      return;
    }

    if (!this.expansion) {
      this.expansion = {
        name: this.expansionName,
        label: this.translate.instant(`calculations.${this.kind}Total`),
        start: this.kind === 'base_column' || this.kind === 'responses_column',
      };
    }

    if (!crosstabConfig.crosstab.expansions?.length) {
      crosstabConfig.crosstab.expansions = [this.expansion];
    }
    const found = this.findExpansion(this.kind, crosstabConfig);
    if (found.item) {
      crosstabConfig.crosstab.expansions[found.ix] = { ...this.expansion };
    } else {
      crosstabConfig.crosstab.expansions.push(this.expansion);
    }
  }

  private findExpansion(
    kind: 'base_column' | 'responses_column' | 'base_row' | 'responses_row',
    crosstabConfig?: ICrosstabConfigUI
  ): {
    ix: number;
    item?: IExpansion;
  } {
    if (!crosstabConfig) {
      return { ix: -1 };
    }
    let expansionName:
      | 'primary_total_responses_column'
      | 'primary_base_size_column'
      | 'span_total_responses_row'
      | 'span_base_size_row';
    switch (kind) {
      case 'base_column':
        expansionName = 'primary_base_size_column';
        break;
      case 'base_row':
        expansionName = 'span_base_size_row';
        break;
      case 'responses_column':
        expansionName = 'primary_total_responses_column';
        break;
      case 'responses_row':
        expansionName = 'span_total_responses_row';
    }

    const ix = (crosstabConfig.crosstab.expansions ?? []).findIndex(
      (x) => x.name === expansionName
    );
    if (ix >= 0 && crosstabConfig.crosstab.expansions?.length) {
      return { ix, item: crosstabConfig.crosstab.expansions[ix] };
    }
    return { ix: -1 };
  }
}
