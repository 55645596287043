import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'crosstab-may-process-dialog',
  templateUrl: './crosstab-may-process-dialog.component.html',
  styleUrls: ['./crosstab-may-process-dialog.component.scss'],
})
export class CrosstabMayProcessDialogComponent {
  @Input() dialog: IgxDialogComponent | undefined;

  @Output() tryTableView = new EventEmitter<boolean>(false);
  @Output() triggerAppcuesEvent = new EventEmitter<boolean>(false);
  @Output() dontTry = new EventEmitter<boolean>(false);

  close(): void {
    if (this.dialog) {
      this.dialog.close();
      this.dontTry.emit(true);
    }
  }

  triggerEvent(): void {
    this.triggerAppcuesEvent.emit(true);
  }

  try(): void {
    this.tryTableView.emit(true);
  }
}
