import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConnectedPositioningStrategy,
  HorizontalAlignment,
  NoOpScrollStrategy,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';
import { IFabItem } from '../../../models/fabrication/IFabricationResponse';

@Component({
  selector: 'manage-tables-grid',
  templateUrl: './manage-tables-grid.component.html',
  styleUrls: ['./manage-tables-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageTablesGridComponent implements OnChanges {
  @Input() data: IFabItem[] | null = null;
  @Input() emptyText!: string;

  @Output() delete = new EventEmitter<string>();
  @Output() openDetail = new EventEmitter<string>();
  @Output() edit = new EventEmitter<string>();
  @Output() clone = new EventEmitter<string>();

  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource(undefined);
  displayedColumns = ['fabrication_name', 'updated_utc', 'actions'];

  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  ngOnChanges(changes: SimpleChanges): void {
    const dataChanges = changes['data'];

    if (dataChanges?.currentValue) {
      this.dataSource = new MatTableDataSource(dataChanges.currentValue);
      this.dataSource.sort = this.sort;
    }
  }

  filterItems(searchTerm: string): void {
    this.dataSource.filter = searchTerm;
  }

  deleteItem(item: string): void {
    this.delete.emit(item);
  }

  openItem(item: string): void {
    this.openDetail.emit(item);
  }

  editItem(item: string): void {
    this.edit.emit(item);
  }

  cloneItem(item: string): void {
    this.clone.emit(item);
  }
}
