<ng-container *ngIf="brandId">
    <igx-combo
      #missionSearchCombo
      [data]="missions.data"
      [(ngModel)]="missionComboSelection"
      (selectionChanging)="onMissionSelected($event)"
      (searchInputUpdate)="onSearchInputChanged($event)"
      displayKey="search_text"
      valueKey="mission_id"
      density="compact"
      placeholder="Select Mission"
      [disabled]="!missions.loaded"
      class="mission-search-combo"
      searchPlaceholder="Search"
    >
      <ng-template igxComboEmpty>
        <div class="combo--empty" *ngIf="missions.loading">Loading...</div>
        <div class="combo--empty" *ngIf="!missions.loading">No surveys found</div>
      </ng-template>
    </igx-combo>
    <igx-list *ngIf="!!selectedMissionId" density="compact">
      <igx-list-item [isHeader]="true">Actions</igx-list-item>
      <igx-list-item
        igxRipple
        igxRippleTarget=".igx-list__item-content"
        #item
        *ngFor="let action of actions.data"
        class="action-item"
        [class.selected-action]="action.action_id === selectedActionId"
        (click)="$event.stopImmediatePropagation(); onSelectAction(action)"
      >
        <span>{{ action.search_text }}</span>
      </igx-list-item>
    </igx-list>
    <div
      style="
        display: flex;
        height: 32px;
        padding: 0 16px;
        border-top: 1px solid #e0e0e0;
        align-items: center;
      "
      *ngIf="!!selectedMissionId"
    >
      <span style="font-size: 12px; cursor: pointer" (click)="pptDownload.emit()"
        >Download PPT</span
      >
      <span>&nbsp;|&nbsp;</span>
      <span
        style="font-size: 12px; cursor: pointer"
        (click)="excelDownload.emit()"
        >Download Excel</span
      >
    </div>
    <igx-linear-bar
      *ngIf="missions.loading || actions.loading"
      [indeterminate]="true"
    ></igx-linear-bar>
    <div class="testing-area"></div>
</ng-container>
