<form class="suzy-input-container">
    <mat-form-field class="suzy-searchable-input-alt" floatLabel="never" appearance="legacy">
        <mat-label>{{'customBannerPoints.derivedQuestions.form.surveyPlaceholder' | translate}}</mat-label>
        <input matInput [formControl]="control" type="text" [matAutocomplete]="auto"
            (input)="onSearchInputChanged($event)">
<!--         <button *ngIf="control.value" matSuffix (click)="clearValue($event)" type="button" class="suzy-icon-button">
            <mat-icon>close</mat-icon>
        </button> -->
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="markAsSelected($event)">
            <ng-container *ngIf="searchingMissions; else elseList">
                <div class="loading-mission">
                    <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                    <span>Loading Questions</span>
                </div>
            </ng-container>
            <ng-template #elseList>
                <mat-option disabled>
                    <igx-banner>
                        <igx-icon>info</igx-icon>
                        {{'customBannerPoints.derivedQuestions.form.surveyField.optionMessage' | translate }}
                    </igx-banner>
                </mat-option>
                <ng-container *ngFor="let mission of sortedMissions">
                    <div class="node-container" (click)="toggleItem(mission.item.mission_id)">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{mission.isOpen ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                        <div class="item-container">
                            <action-dropdown-item [item]="mission.item"></action-dropdown-item>
                        </div>
                    </div>
                    <ng-container *ngIf="mission.children && mission.isOpen">
                        <div class="loading-mission" *ngIf="mission.isLoading">
                            <igx-circular-bar [animate]="false" [indeterminate]="true"
                                [textVisibility]="false"></igx-circular-bar>
                            <span>{{'customBannerPoints.derivedQuestions.form.surveyField.loading' | translate }}</span>
                        </div>
                        <mat-option *ngFor="let question of mission.children" [value]="question">
                            <div class="question-item">
                                <span>{{question.question_number}}</span> - <span>{{question.strippedSearchText}}</span>
                            </div>
                        </mat-option>
                    </ng-container>
                </ng-container>
            </ng-template>

        </mat-autocomplete>
    </mat-form-field>
</form>
