<div class="header-container">
  <div class="content">
    <igx-select placeholder="Select brand" displayDensity="cosy" [(ngModel)]="brandId"
      (selectionChanging)="onBrandSelected($event)">
      <label igxLabel>Brand</label>
      <igx-select-item [value]="brand.brand_id" *ngFor="let brand of brands">{{
        brand.name
        }}</igx-select-item>
    </igx-select>
    <div>
      <button igxButton="outlined" igxRipple displayDensity="cosy" class="suzy" (click)="showTestingSide()">
        Show Testing Sidebar
      </button>
      <button igxButton="outlined" igxRipple displayDensity="cosy" class="suzy" (click)="onLogoutClick()">
        Logout
      </button>
    </div>
  </div>
</div>

<div [ngClass]="{
    'builder-view': builderVisible,
    'chart-expanded': isChartExpanded
  }">
  <!-- Testing sidebar -->
<!--   <div class="action-select-col">
    <ng-container *ngIf="brandId">
      <data-explorer-elements-testing-sidenav [brandId]="brandId" (missionSelected)="onMissionSelected($event)"
        (actionSelected)="onActionSelected($event)" (pptDownload)="onDownloadAsPPT()"
        (excelDownload)="onDownloadAsExcel()"></data-explorer-elements-testing-sidenav>
    </ng-container>
  </div> -->

  <!-- DE web component from lib -->
  <div *ngIf="brandId" class="explorer-col">
    <data-explorer #explorer [config]="config" [brandId]="brandId" [question]="question" [mission]="selectedMission"
      [action]="selectedAction" (toggleBuilder)="onBuilderToggle($event)" (expandChart)="onExpandChart($event)"
      (manageTableViews)="onManageTableViews()" (exportSurvey)="onExportSurveyClick($event)">
    </data-explorer>
  </div>
</div>