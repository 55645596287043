<div class="dq-selector-container">
    <cbp-search-bar class="search-bar" (searchTerm)="filterItems($event)"></cbp-search-bar>
    <div class="dq-dimensions-container">
        <div *ngFor="let item of filteredDerivedQuestions ; let dqIdx = index" class="dragDimension"
            [id]="item._drag_id" (dragStart)="dragdrop!.newdimension_dragStart()"
            (dragEnd)="dragdrop!.newdimension_dragEnd()" [ghostClass]="'drag_ghost_class'" igxDrag>
            <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
            <!-- <ng-container [ngTemplateOutlet]="actionIcon" [ngTemplateOutletContext]="{ action: item.action }"></ng-container> -->
            <span class="dragDimensionName dim-label" [innerHTML]="item.label">
            </span>
        </div>
    </div>
</div>