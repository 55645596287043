<div class="question-inline-container">
  <igx-select class="search-bar" [(ngModel)]="selectedType" [placeholder]="'Select question type'">
    <igx-select-item *ngFor="let item of questionTypes" [value]="item.id">
      {{item.label}}
    </igx-select-item>
  </igx-select> 
  <ng-container *ngIf="selectedType === 1; else elseDQ">
    <div class="action-selector-container">
      <app-question-selector *ngIf="mission" [brandId]="brandId" [global]="global" [externalLink]="externalLink"
        [mission]="mission" [showBrandFolderInTooltip]="showBrandFolderInTooltip"
        (missionSelected)="onMissionSelected($event)"></app-question-selector>
      <div *ngIf="!loading; else loadingSpinner" class="question-tiles-container">
        <div *ngFor="let item of dimensions;" class="dragDimension" id="{{ item.dimension[dragdrop!.drag_id_field] }}"
          (dragStart)="dragdrop!.newdimension_dragStart()" (dragEnd)="dragdrop!.newdimension_dragEnd()"
          [ghostClass]="'drag_ghost_class'" igxDrag>
          <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
          <ng-container [ngTemplateOutlet]="actionIcon"
            [ngTemplateOutletContext]="{ action: item.action }"></ng-container>
          <span class="dragDimensionName dim-label" [innerHTML]="item.action.search_text | async">
          </span>
        </div>
      </div>
    </div>
 </ng-container>
   <ng-template #elseDQ>
    <app-derived-question-selector [builder]="builder"
      [derivedQuestions]="derivedQuestions"></app-derived-question-selector>
  </ng-template>
</div>



<ng-template #loadingSpinner>
  <div class="loading" *ngIf="loading">
    <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
  </div>
</ng-template>

<ng-template #actionIcon let-action="action">
  <div class="action-icon">
    <ng-container *ngIf="action.action_kind === actionKind.multiplechoice">
      <igx-icon fontSet="material" *ngIf="
          action.multiple_choice.multiple_choice_kind ===
          multipleChoiceKind.standard
        ">radio_button_checked</igx-icon>
      <igx-icon fontSet="material" *ngIf="
          action.multiple_choice.multiple_choice_kind ===
          multipleChoiceKind.monadic
        ">linear_scale</igx-icon>
    </ng-container>
    <igx-icon fontSet="material" *ngIf="action.action_kind === actionKind.openended">sort</igx-icon>
    <igx-icon fontSet="material" *ngIf="action.action_kind === actionKind.openended_video">play_circle_filled</igx-icon>
    <igx-icon fontSet="material" *ngIf="action.action_kind === actionKind.photoacquisition">photo_camera</igx-icon>
    <igx-icon family="suzy" name="maxdiff" *ngIf="action.action_kind === actionKind.maxdiff"></igx-icon>
    <igx-icon family="material" *ngIf="action.action_kind === actionKind.auto_assign">people</igx-icon>
    <ng-container *ngIf="
        action.action_kind === actionKind.grid ||
        action.action_kind === actionKind.gridcustom ||
        action.action_kind === actionKind.gridrankscale
      ">
      <igx-icon family="suzy" name="grid_rank" *ngIf="action.grid.grid_kind === gridKind.rank"></igx-icon>
      <igx-icon family="suzy" name="grid_scale" *ngIf="action.grid.grid_kind === gridKind.scale"></igx-icon>
      <igx-icon family="material" *ngIf="action.grid.grid_kind === gridKind.open">grid_on</igx-icon>
    </ng-container>
    <igx-icon family="suzy" name="turf" class="no-color-fill" *ngIf="action.action_kind === actionKind.turf"></igx-icon>
  </div>
</ng-template>