/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ISortingStrategy,
  SortingDirection,
} from '@infragistics/igniteui-angular';
import { DataUtility } from '../../utilities/data-utility';

export class GridColumnCustomSort implements ISortingStrategy {
  private isGroupRowsSorting!: boolean;
  constructor(isGroup?: boolean) {
    this.isGroupRowsSorting = isGroup ?? false;
  }
  public sort(
    data: any[],
    fieldName: string,
    dir: SortingDirection,
    ignoreCase: boolean
  ) {
    if (this.isGroupRowsSorting) {
      return data.sort((a: any, b: any) => {
        return 0;
      });
    }
    const cmpFunc = (a: any, b: any) => {
      return this.compareObjects(a[fieldName], b[fieldName], dir, a, b);
    };
    return data.sort(cmpFunc);
  }

  protected compareObjects(
    obj1: string,
    obj2: string,
    dir: SortingDirection,
    data1: any,
    data2: any
  ) {
    return DataUtility.sortCompareAnchorTotals(
      obj1,
      obj2,
      dir === SortingDirection.Desc ? 'desc' : 'asc',
      data1,
      data2
    );
  }
}
