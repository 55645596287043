/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, Subject } from 'rxjs';
import { IAction } from '../models/suzy/IAction';
import { ActionKind, MissionKind } from '@asksuzy/typescript-sdk';
import { SuzySDK } from './suzy-sdk';

@Injectable({
  providedIn: 'root',
})
export class PipingTokenService {
  static Pattern = /`([^:]+):([^:]+):([^`]+)`/gi;

  private labelCache = {};
  private newtworkCache: Array<any> = [];

  private readonly cacheTime = 10 * 60 * 1000; // 10 minutes
  private readonly maxConcurentCalls = 2;
  private readonly refresh$ = new Subject<void>();

  constructor(private sdk: SuzySDK) {}

  getLabel$(
    brandId: string,
    missionId: string,
    actionId: string,
    callerMissionId?: string
  ): Observable<string> {
    callerMissionId = callerMissionId ?? missionId;

    // check cache
    let label = this.get(callerMissionId, actionId);
    if (label) {
      return of(label);
    }

    let netwarkCall = this.newtworkCache.find(
      (x: any) => x.actionId === actionId && x.missionId === callerMissionId
    );
    if (netwarkCall) {
      return netwarkCall.subject;
    }

    const execute = () => {
      for (let i = 0; i < this.newtworkCache.length; i++) {
        if (i >= this.maxConcurentCalls) {
          break;
        }
        this.newtworkCache[i].run();
      }
    };

    const remove = (call: any) => {
      const index = this.newtworkCache.indexOf(call);
      if (index !== -1) {
        this.newtworkCache.splice(index, 1);
      }

      call.running = false;
      execute();
    };

    netwarkCall = {
      actionId,
      missionId: callerMissionId,
      running: false,
      subject: new Subject<string>(),
      run: () => {
        if (netwarkCall.running) {
          return;
        }
        netwarkCall.running = true;

        // labels can contain actionId and previous action was already deleted
        label = this.get(callerMissionId ?? missionId, actionId);
        if (label) {
          netwarkCall.complete(label);

          return;
        }

        let missions$: Observable<any> = of({ items: [] });
        if (missionId !== callerMissionId) {
          missions$ = this.sdk.Mission.getMissionTargetingPipingMissions({
            brand_id: brandId,
            mission_id: callerMissionId ?? missionId,
          });
        }

        const action$: Observable<any> = this.sdk.Action.getAction({
          brand_id: brandId,
          action_id: actionId,
        });
        const missionSteps$: Observable<any> =
          this.sdk.Step.getPrimaryForMission({
            brand_id: brandId,
            mission_id: missionId,
            skip: 0,
            take: 100,
          });

        forkJoin([missions$, action$, missionSteps$])
          .pipe(
            map(
              ([missionResponse, actionResponse, stepsResponse]: [
                any,
                any,
                any
              ]) => {
                return [
                  missionResponse.items,
                  actionResponse.item,
                  stepsResponse.items,
                ];
              }
            )
          )
          .subscribe(
            ([missions, action, steps]) => {
              const sourceAction = this.getSourceAction(action, steps);
              const isMaxdiffAutoAssign =
                action.action_kind === ActionKind.auto_assign &&
                !!action.parent_step_id;
              const maxdiffLowHighLabel = isMaxdiffAutoAssign
                ? this.getMaxdiffLowHighLabel(action, sourceAction)
                : '';
              const priority = this.getActionPriority(sourceAction, steps);

              label = this.getPipeLabel({
                actionKind: sourceAction.action_kind,
                priority,
                chainedActionCount:
                  (sourceAction as any).maxdiff?.chained_action_count ??
                  undefined,
                maxdiffLowHighLabel: maxdiffLowHighLabel,
              });

              // if piping from target mission's action then get target info
              if (callerMissionId !== missionId) {
                const target = missions.find(
                  (x: any) => x.mission_id === missionId
                );

                if (target) {
                  label = this.getPipeLabel(
                    {
                      actionKind: sourceAction.action_kind,
                      priority,
                      chainedActionCount:
                        (sourceAction as any).maxdiff?.chained_action_count ??
                        undefined,
                      maxdiffLowHighLabel: maxdiffLowHighLabel,
                    },
                    {
                      missionKind: target.mission_kind,
                      priority: missions.indexOf(target) + 1,
                    }
                  );
                }
              }

              this.set(callerMissionId ?? missionId, actionId, label);
              netwarkCall.complete(label);
            },
            (err: any) => {
              netwarkCall.subject.error(err);
              remove(netwarkCall);
            }
          );
      },
      complete: (actionLabel: string) => {
        netwarkCall.subject.next(actionLabel);
        netwarkCall.subject.complete();
        remove(netwarkCall);
      },
    };

    this.newtworkCache.push(netwarkCall);
    execute();

    return netwarkCall.subject;
  }

  getPipeLabel(
    action: {
      actionKind?: ActionKind;
      priority?: number;
      chainedActionCount?: number;
      maxdiffLowHighLabel?: string;
    },
    mission?: { missionKind?: MissionKind; priority?: number }
  ): string {
    if (!action) {
      return 'Q1';
    }
    if (!mission) {
      if (action.actionKind !== ActionKind.maxdiff)
        return `Q${action.priority || 1}`;

      let label = `Q${action.priority || 1}`;
      if (
        action.priority !== undefined &&
        action.chainedActionCount !== undefined &&
        action.chainedActionCount > 1
      ) {
        label = `Q${action.priority}-Q${
          action.priority + (action.chainedActionCount - 1)
        }`;
      }
      if (action.maxdiffLowHighLabel) {
        label += `-${action.maxdiffLowHighLabel}`;
      }
      return label;
    }

    let missionLabel = '';
    let actionLabel = '';
    action.priority = action.priority || 1;

    switch (mission.missionKind) {
      case MissionKind.survey:
      case MissionKind.screening:
      case MissionKind.maxdiff:
      default:
        missionLabel = `S${mission.priority || ''}:`;
    }

    switch (action.actionKind) {
      case ActionKind.multiplechoice:
        actionLabel = `MC${action.priority}`;
        break;
      case ActionKind.openended:
        actionLabel = `OE${action.priority}`;
        break;
      case ActionKind.photoacquisition:
        actionLabel = `PA${action.priority}`;
        break;
      case ActionKind.gridrankscale:
        actionLabel = `G${action.priority}`;
        break;
      case ActionKind.gridcustom:
        actionLabel = `CG${action.priority}`;
        break;
      case ActionKind.maxdiff:
        actionLabel = `Q${action.priority || 1}`;
        if (
          action.chainedActionCount !== undefined &&
          action.chainedActionCount > 1
        ) {
          actionLabel = `Q${action.priority}-Q${
            action.priority + (action.chainedActionCount - 1)
          }`;
        }
        if (action.maxdiffLowHighLabel) {
          actionLabel += `-${action.maxdiffLowHighLabel}`;
        }
        break;
      default:
        actionLabel = `Q${action.priority}`;
    }

    return `${missionLabel}${actionLabel}`;
  }

  clearCache(): void {
    this.labelCache = {};
  }

  onRefresh$(): Subject<void> {
    return this.refresh$;
  }

  refreshLabels(): void {
    this.clearCache();
    this.refresh$.next();
  }

  private get(missionId: string, actionId: string): string | undefined {
    const item = this.labelCache[`${missionId}_${actionId}`];
    if (!item) {
      return undefined;
    }

    if (new Date().getTime() - item.time > this.cacheTime) {
      delete this.labelCache[`${missionId}_${actionId}`];

      return undefined;
    }

    return item.label;
  }

  private set(missionId: string, actionId: string, label: string): void {
    this.labelCache[`${missionId}_${actionId}`] = {
      label,
      time: new Date().getTime(),
    };
  }

  private getSourceAction(action: IAction, steps: any[]): IAction {
    if (
      action.action_kind === ActionKind.auto_assign &&
      !!action.parent_step_id
    ) {
      const maxdiffParent = (steps ?? []).find(
        (x) => x.step_id === action.parent_step_id && !x.parent_step_id
      )?.first_action;
      return maxdiffParent ?? action;
    }

    return action;
  }

  private getMaxdiffLowHighLabel(
    autoAssignAction: IAction,
    parentAction: IAction
  ): string {
    const { maxdiff } = parentAction as any;
    const { source_metric_id } = (autoAssignAction as any).auto_assign;
    if (source_metric_id === maxdiff.high_value_answer_id) {
      return maxdiff.high_value_ui_text;
    } else if (source_metric_id === maxdiff.low_value_answer_id) {
      return maxdiff.low_value_ui_text;
    }
    return '';
  }

  private getActionPriority(action: IAction, steps: any[]): number {
    let priority = action.step_priority ?? 0;
    const hasMaxdiffActions = (steps ?? []).some(
      (x) => x.first_action?.action_kind === ActionKind.maxdiff
    );
    const legalCount = (steps ?? []).filter(
      (x) => x.first_action?.is_legal_consent
    ).length;

    if (hasMaxdiffActions) {
      const actionStepIx = (steps ?? []).findIndex(
        (x) => x.first_action.action_id === action.action_id
      );
      if (actionStepIx > 0) {
        const stepsBeforeSelf = steps.slice(0, actionStepIx) ?? [];
        const filteredSteps =
          stepsBeforeSelf.filter(
            (x) =>
              x.parent_step_id &&
              x.first_action.action_kind === ActionKind.auto_assign
          ) ?? [];
        priority -= filteredSteps.length;
      }
    } else if (legalCount) {
      priority -= legalCount;
    }
    return priority;
  }
}
