<div class="qd-container">
    <cbp-search-bar (searchTerm)="filterItems($event)"></cbp-search-bar>

    <div class="dq-table-container">
        <table cdk-table [dataSource]="dataSource" matSort>
            <ng-container cdkColumnDef="fabrication_name">
                <th cdk-header-cell *cdkHeaderCellDef mat-sort-header> Name </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small-bold">
                    <div class="dq-actions">
                        <igx-circular-bar *ngIf="refreshing && element.fabrication_id === idToRefresh"
                            style="width: 20px; height: 20px;" [animate]="false" [indeterminate]="true"
                            [textVisibility]="false"></igx-circular-bar>
                        {{element.fabrication_name}}
                    </div>
                </td>
            </ng-container>
            <ng-container cdkColumnDef="hydrate_status" *ngIf="type === fabricationType.DerivedQuestions">
                <th cdk-header-cell *cdkHeaderCellDef mat-sort-header> Status </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small">
                    <ng-container *ngIf="status[element.hydrate_status] === 'Hydrated'">Prepared</ng-container>
                    <ng-container *ngIf="status[element.hydrate_status] !== 'Hydrated'">Still preparing</ng-container>
                    <!-- {{ status[element.hydrate_status] }} -->
                </td>
            </ng-container>
            <ng-container cdkColumnDef="updated_utc">
                <th cdk-header-cell *cdkHeaderCellDef mat-sort-header> Last Updated </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small">
                    {{element.last_hydrated_utc | date: 'medium' }}
                </td>
            </ng-container>
            <ng-container cdkColumnDef="edited_by">
                <th cdk-header-cell *cdkHeaderCellDef> Updated By </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small">
                    <span *ngIf="element.edited_by">{{ element.edited_by.full_name | textOrDefault: '-' }}</span>
                </td>
            </ng-container>

            <ng-container cdkColumnDef="known_items">
                <th cdk-header-cell *cdkHeaderCellDef> Groups </th>
                <td cdk-cell *cdkCellDef="let element; let i = index">
                    <ul class="cbp-group-list">
                        <ng-container *ngFor="let item of element.known_items;">
                            <li class="list-group-item body-copy-small"
                                *ngFor="let outcome of item.outcomes; let itemIdx = index"
                                [hidden]="!showMoreState[i] && itemIdx >= 3">
                                {{ outcome.outcome_text }}
                            </li>
                            <button *ngIf="item.outcomes.length > 3" class="show-more-btn text-link-small"
                                (click)="toggleShowMore(i)">
                                {{ showMoreState[i] ? 'Show Less' : 'Show More' }}
                            </button>
                        </ng-container>
                    </ul>

                </td>
            </ng-container>

            <ng-container cdkColumnDef="actions">
                <th cdk-header-cell *cdkHeaderCellDef> </th>
                <td cdk-cell *cdkCellDef="let element" class="body-copy-small">
                    <button class="suzy-icon-button" [igxToggleAction]="cbpAction"
                        [igxDropDownItemNavigation]="cbpAction" [overlaySettings]="overlaySettings">
                        <igx-icon fontSet="material">more_vert</igx-icon>
                    </button>

                    <igx-drop-down class="export-dropdown" #cbpAction [allowItemsFocus]="false">
                        <igx-drop-down-item (click)="openItem(element.fabrication_id)">{{
                            'customBannerPoints.formText.ctas.openDetail' | translate }} </igx-drop-down-item>
                        <igx-drop-down-item (click)="editItem(element.fabrication_id)">{{
                            'customBannerPoints.formText.ctas.edit' | translate }} </igx-drop-down-item>
                        <igx-drop-down-item (click)="cloneItem(element.fabrication_id)">{{
                            'customBannerPoints.formText.ctas.clone' | translate }} </igx-drop-down-item>
                        <igx-drop-down-item *ngIf="element.fabrication_type === fabricationType.DerivedQuestions"
                            (click)="refreshItem(element.fabrication_id)">{{
                            'customBannerPoints.formText.ctas.refresh' | translate }} </igx-drop-down-item>
                        <igx-drop-down-item (click)="deleteItem(element.fabrication_id)">
                            {{ 'customBannerPoints.formText.ctas.archive' | translate }}
                        </igx-drop-down-item>
                    </igx-drop-down>
                </td>
            </ng-container>

            <!--  <ng-container *ngIf="dataSource.data.length > 0;"> -->
            <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
            <!--     </ng-container> -->

            <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
            <tr *cdkNoDataRow>
                <td [attr.colspan]="displayedColumns.length">
                    <div class="dq-empty-state">
                        <span *ngIf="dataSource.data.length === 0 && dataSource.filteredData.length === 0"> {{ emptyText
                            }}</span>
                        <span *ngIf="dataSource.filteredData.length === 0 && dataSource.filter">
                            {{ 'customBannerPoints.formText.labels.noResults' | translate }}
                        </span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>