import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, debounceTime, noop, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'cbp-conditional-selector-field',
  templateUrl: './cbp-conditional-selector-field.component.html',
  styleUrls: ['./cbp-conditional-selector-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CbpConditionalSelectorFieldComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CbpConditionalSelectorFieldComponent
  implements ControlValueAccessor, OnInit
{
  @Input() radioOptions: any[] = [];
  @Input() id!: string;

  readonly form: FormGroup = new FormGroup<{
    value: FormControl<string | null>;
  }>({
    value: new FormControl<string>(''),
  });

  private unsubscribe = new Subject<void>();

  onChange: (value: unknown) => void = noop;
  onTouch: () => void = noop;

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(200),
        tap(({ value }) => {
          this.onChange(value);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value: string): void {
    if (value) {
      this.form.patchValue({ value });
    } else {
      this.form.reset();
    }
  }
}
