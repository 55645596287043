<ng-container *ngIf="!hasCustomAgeGroup">
  <button class="gray-fit-button" (click)="onAddCustomRangesClick()">
    <igx-icon>add</igx-icon>
    <span>{{ 'ageRanges.addRangesBtn' | translate }}</span>
  </button>
</ng-container>

<ng-container *ngIf="hasCustomAgeGroup">
  <div class="dragDimension" [ghostClass]="'drag_ghost_class'" id="{{ dimension[dragdrop!.drag_id_field] }}"
    (dragStart)="dragdrop!.newdimension_dragStart()" (dragEnd)="dragdrop!.newdimension_dragEnd()" igxDrag>
    <igx-icon class="dragger" igxDragHandle>drag_indicator</igx-icon>
    <span class="dragDimensionName dim-label">{{ dimension.label }}</span>
    <igx-icon class="arrow" family="suzy" name="edit" (click)="onEditCustomRangesClick()"
      [igxTooltipTarget]="customAgeGroupEditTooltip" [showDelay]="0" [hideDelay]="0"></igx-icon>
  </div>
</ng-container>

<div #customAgeGroupEditTooltip="tooltip" igxTooltip class="tooltip">
  {{ 'ageRanges.editTooltip' | translate }}
</div>