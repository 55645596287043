import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textOrDefault'
})
export class TextOrDefaultPipe implements PipeTransform {
  transform(value: string, defaultString: string): unknown {
    return typeof value ==='string' && value.trim() ? value : defaultString;
  }

}
