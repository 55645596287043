import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PipeKind } from '@asksuzy/typescript-sdk';

interface Prop {
  caller?: string;
  view?: string;
  secure?: boolean;
  hideIcon?: boolean;
  plain?: boolean;
  filter?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterData?: any;
  filterActionId?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  selectCallback?: Function;
  tooltip?: string;
}

@Pipe({
  name: 'markdown2html',
})
export class Markdown2HtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string, props?: Prop): any {
    if (value === undefined || value === null) {
      return '';
    }

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    // Use `em` for IE and `i` otherwise
    const italicToken =
      msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) ? 'i' : 'em';
    // Use `strong` for IE and `b` otherwise
    const boldToken =
      msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)
        ? 'strong'
        : 'b';

    props = props || {};
    let pipingFilter = '';
    if (props.filterData) {
      props.filterData.unshift({
        answer_id: undefined,
        answer_text: 'Combined View',
      });

      const filterData = JSON.stringify(props.filterData).replace(
        // eslint-disable-next-line no-useless-escape
        /[\/\(\)\']/g,
        '&apos;'
      );
      pipingFilter = `<app-pipe-dropdown-element class="app-pipe-dropdown-element"
      actionId="$2" missionId="$1" missionCallerId="${props.caller}" filterActionId='${props.filterActionId}' filterData='${filterData}'
      ></app-pipe-dropdown-element>`;
    }

    const pipingToken = `<app-pipe-token-element class="app-pipe-token-element" contenteditable="false" data-mission-id="$1" data-action-id="$2" data-pipe-kind="$3"${
      props.caller ? ` data-caller-id="${props.caller}"` : ''
    }${props.view ? ` data-view-id="${props.view}"` : ''}${
      props.hideIcon ? ' data-hide-icon="true"' : ''
    }${props.plain ? ' data-plain="true"' : ''}${
      props.tooltip ? ` data-tooltip="${props.tooltip}"` : ''
    }
    ></app-pipe-token-element>`;

    const replacement = props.filter ? pipingFilter : pipingToken;

    let html = this.stripHtmlTags(value)
      .replace(/\\\*/gi, '--asterisk--')
      .replace(/\\`/gi, '--backtick--')
      // eslint-disable-next-line no-useless-escape
      .replace(/\*{2}([^\*].*?)\*{2}/gis, `<${boldToken}>$1</${boldToken}>`)
      .replace(/\*(.*?)\*/gis, `<${italicToken}>$1</${italicToken}>`)
      .replace(/--asterisk--/gi, '*')
      .replace(/--backtick--/gi, '`')
      // eslint-disable-next-line no-useless-escape
      .replace(/[\ |\u00A0]([\ |\u00A0]+)/g, (x) => {
        let result = x[0];
        for (let i = 1; i < x.length; i++) {
          result += '&nbsp;';
        }

        return result;
      });

    html = this.replacePipingToken(html, replacement);

    if (props.secure) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    return html;
  }

  private stripHtmlTags(value: string): string {
    const parsed = new DOMParser().parseFromString(value, 'text/html');
    return parsed.body.textContent ?? '';
  }

  private replacePipingToken(markdown: string, replacement: string): string {
    const pipingTokenPattern = /`([^:`]+):([^:]+):([^:`]+)`/gi;
    const pipingTokenChunksPattern = /([^:`]+)/gi;
    const guidPattern =
      // eslint-disable-next-line no-useless-escape
      /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/;
    const pipeKinds = [
      PipeKind.selected_responses,
      PipeKind.non_selected_responses,
    ];

    const matches = markdown.match(pipingTokenPattern);
    if (!matches) {
      return markdown;
    }

    matches.map((match) => {
      const chunks = match.match(pipingTokenChunksPattern);
      if (!chunks || chunks.length !== 3) {
        return;
      }
      if (!guidPattern.test(chunks[0])) {
        return;
      }
      if (!guidPattern.test(chunks[1])) {
        return;
      }
      if (pipeKinds.findIndex((t) => t.toString() === chunks[2]) < 0) {
        return;
      }

      const transformed = match.replace(pipingTokenPattern, replacement);
      markdown = markdown.replace(match, transformed);
    });

    return markdown;
  }
}
