import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionKind, MissionKind } from '@asksuzy/typescript-sdk';
import {
  IComboSearchInputEventArgs,
  IComboSelectionChangingEventArgs,
  IgxComboComponent,
} from '@infragistics/igniteui-angular';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';
import { IAction, ILoadable, IMission } from '../../../data';
import { SuzyDataService } from '../../../services/suzy-data.service';

@Component({
  selector: 'data-explorer-elements-testing-sidenav',
  templateUrl: './explorer-sidenav.component.html',
  styleUrls: ['./explorer-sidenav.component.scss'],
})
export class ExplorerSidenavComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribeAll: Subject<void> = new Subject<void>();
  private brandChanged: Subject<void> = new Subject<void>();
  private searchTermChanged: Subject<void> = new Subject<void>();
  private unsubscribeSearch: Subject<void> = new Subject<void>();
  private requested = {
    complete: false,
    action_id: '' as string | null,
    mission_id: '' as string | null,
  };

  @Input() brandId!: string;
  @Output() missionSelected: EventEmitter<IMission> =
    new EventEmitter<IMission>();
  @Output() actionSelected: EventEmitter<IAction> = new EventEmitter<IAction>();
  @Output() pptDownload: EventEmitter<void> = new EventEmitter<void>();
  @Output() excelDownload: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('missionSearchCombo', { static: false })
  missionSearchComboElement!: IgxComboComponent;

  missions!: ILoadable<IMission>;
  actions!: ILoadable<IAction>;
  missionComboSelection: string[] = [];
  selectedMissionId!: string;
  selectedActionId!: string;
  isGlobal!: boolean;
  missionSearchText!: string;
  warnings: Array<string> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private suzyDataService: SuzyDataService
  ) {
    this.onBrandChanged();
  }

  ngOnInit(): void {
    this.requested.action_id =
      this.activatedRoute.snapshot.queryParamMap.get('a') ?? '';
    this.requested.mission_id =
      this.activatedRoute.snapshot.queryParamMap.get('m') ?? '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['brandId']) {
      return;
    }

    setTimeout(() => {
      this.onBrandChanged();
      if (!changes['brandId'].isFirstChange() && this.requested.mission_id) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.brandChanged.complete();
  }

  onMissionSelected(e: IComboSelectionChangingEventArgs): void {
    if (e.added.length) {
      e.newSelection = e.added;
    }

    this.actions.loaded = false;
    this.actions.data = [];
    this.actions.filter = '';

    const { newSelection } = e;
    if (!newSelection.length) {
      this.selectedMissionId = '';
      this.actions.loading = false;
      this.router.navigate(['/', 'brand', this.brandId, 'insights', 'data-explorer'], {
        //relativeTo: this.activatedRoute,
        /* queryParams: {
          b: this.brandId,
        }, */
      });
      this.missionSelected.emit();
      this.actionSelected.emit();
      return;
    }

    this.selectedMissionId = newSelection[0];
    const mission = this.missions.data.find(
      (x) => x.mission_id === newSelection[0]
    );
    this.missionSelected.emit(mission);
    this.actions.loading = true;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        m: newSelection ?? '',
      },
      queryParamsHandling: 'merge',
    });

    if (this.missionSearchComboElement) {
      this.missionSearchComboElement.close();
    }
  }

  onSearchInputChanged(e: IComboSearchInputEventArgs): void {
    this.missionSearchText = e.searchText;
    this.searchTermChanged.next();
  }

  onSelectAction(action: IAction): void {
    if (this.selectedActionId === action?.action_id) {
      return;
    }
    this.selectedActionId = action.action_id;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        a: this.selectedActionId,
      },
      queryParamsHandling: 'merge',
    });
    this.actionSelected.emit(action);
  }

  private onBrandChanged(): void {
    this.missions = {
      loaded: false,
      loading: true,
      data: [],
      filter: '',
    };
    this.actions = {
      loaded: false,
      loading: false,
      data: [],
      filter: '',
    };
    this.selectedMissionId = '';
    this.selectedActionId = '';
    this.warnings = [];
    this.brandChanged.next();
    this.missionSelected.emit();
    this.actionSelected.emit();

    if (!this.brandId) {
      return;
    }

    this.loadBrandProperties()
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.brandChanged),
        take(1)
      )
      .subscribe(() => {
        this.loadMissions();

        this.missionSelected
          .asObservable()
          .pipe(
            takeUntil(this.unsubscribeAll),
            takeUntil(this.brandChanged),
            filter((mission) => !!mission?.mission_id)
          )
          .subscribe((mission) => {
            this.loadActionsForMission(mission.mission_id);
          });

        this.searchTermChanged
          .asObservable()
          .pipe(
            takeUntil(this.unsubscribeAll),
            takeUntil(this.brandChanged),
            debounceTime(150)
          )
          .subscribe(() => {
            this.missions.loading = true;
            this.unsubscribeSearch.next();
            setTimeout(() => {
              this.loadMissions();
            }, 0);
          });
      });
  }

  private loadBrandProperties(): Observable<void> {
    return this.suzyDataService
      .brandGetPropertyValue(this.brandId, 'global')
      .pipe(
        map((propertyData) => {
          this.isGlobal = propertyData === 'true';
          return;
        })
      );
  }

  private loadMissions(): void {
    const searchText = this.missionSearchText ?? '';
    this.suzyDataService
      .missionsFind(this.brandId, this.isGlobal, searchText)
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.brandChanged),
        takeUntil(this.unsubscribeSearch),
        take(1)
      )
      .subscribe({
        next: (items) => {
          if (this.requested.mission_id && !this.requested.complete) {
            const autoSelectMission = items.find(
              (x) => x.mission_id === this.requested.mission_id
            );

            if (!autoSelectMission) {
              this.suzyDataService
                .getMission(this.brandId, this.requested.mission_id)
                .pipe(
                  takeUntil(this.unsubscribeAll),
                  takeUntil(this.brandChanged),
                  finalize(() => {
                    setTimeout(() => {
                      this.missions.loading = false;
                      this.missions.loaded = true;
                      if (!this.requested.action_id) {
                        this.requested.complete = true;
                      }
                    });
                  })
                )
                .subscribe((data) => {
                  if (data?.brand_id !== this.brandId) {
                    this.setMissionsData(items);
                    return;
                  }
                  items.push(data);
                  this.setMissionsData(items);
                  this.selectedMissionId = data.mission_id;
                  this.missionComboSelection = [this.selectedMissionId];
                  this.missionSelected.emit(data);
                });
              return;
            }

            this.selectedMissionId = autoSelectMission.mission_id;
            this.missionComboSelection = [this.selectedMissionId];
            if (!this.requested.action_id) {
              this.requested.complete = true;
            }
            this.missionSelected.emit(autoSelectMission);
          }

          this.setMissionsData(items);
          this.missions.loading = false;
          this.missions.loaded = true;
        },
        error: (error) => {
          console.log('Error loading surveys:', error);
          this.missions.loading = false;
          this.warnings.push('Error loading Surveys');
        },
      });
  }

  private setMissionsData(allMissions: Array<IMission>): void {
    this.missions.data = allMissions
      .filter(
        (item) =>
          item.mission_kind !== MissionKind.focusgroup &&
          item.first_action?.action_kind !== ActionKind.openended_video &&
          item.first_action?.action !== ActionKind.photoacquisition
      )
      .sort((a, b) => a.search_text.localeCompare(b.search_text));
  }

  private loadActionsForMission(missionId: string): void {
    this.actions = {
      loaded: false,
      loading: true,
      data: [],
      filter: '',
    };

    this.suzyDataService
      .getActionsForMission(this.brandId, missionId)
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.brandChanged),
        takeUntil(this.searchTermChanged)
      )
      .subscribe((actions) => {
        this.actions.data = actions ?? [];
        this.actions.loaded = true;
        this.actions.loading = false;

        if (this.requested.action_id && !this.requested.complete) {
          const autoSelectAction = this.actions.data.find(
            (x) => x.action_id === this.requested.action_id
          );
          if (autoSelectAction) {
            this.selectedActionId = autoSelectAction.action_id;
            this.actionSelected.emit(autoSelectAction);
          }
          this.requested.complete = true;
        }
      });
  }
}
