/* eslint-disable @typescript-eslint/no-explicit-any */
import * as toCamelCase from 'to-camel-case';
import { QueryFilterKind, BrandClusterKind } from '@asksuzy/typescript-sdk';

export class QueryFilter {
  kind: QueryFilterKind;
  parent_id?: string;
  hasParent?: boolean;
  question?: any;
  answered?: boolean;
  action_settings?: any;
  action_settings_is_or?: any;
  is_not!: boolean;

  cluster_group_id?: string;
  cluster_group_name?: string;
  brand_cluster_kind?: BrandClusterKind;

  constructor(kind: QueryFilterKind, brand_cluster_kind?: BrandClusterKind) {
    this.kind = kind;
    this.brand_cluster_kind = brand_cluster_kind;
  }

  toJsonData(): any {
    return {
      brand_cluster_kind: this.brand_cluster_kind,
      filter_kind: this.kind,
      cluster_group_id: this.cluster_group_id,
      cluster_group_name: this.cluster_group_name,
    };
  }

  isEmpty(): boolean {
    return false;
  }

  getNameToken(): string {
    return `queryFilters.${toCamelCase(QueryFilterKind[this.kind])}`;
  }
}
