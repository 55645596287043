import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { IBuilderContext } from '../../../i-builder-context';
import { IAdvancedProvider } from '../../i-advanced-provider';

@Component({
  selector: 'app-subpopulations-filters',
  templateUrl: './subpopulations-filters.component.html',
  styleUrls: ['./subpopulations-filters.component.scss'],
})
export class SubpopulationsFiltersComponent implements IAdvancedProvider {
  @Input() subpopulationsData: any[] = [
    {
      name: 'Pasta Luvers',
      included: false,
    },
    {
      name: 'NY Dog Moms',
      included: false,
    },
  ];

  selectedSubpopulationOption!: string;
  subpopulationsForm!: FormGroup;
  selectVisible = true;

  constructor(private formBuilder: FormBuilder) {
    this.subpopulationsForm = this.formBuilder.group({
      subpopulations: this.formBuilder.array([]),
    });
  }

  get subpopulations(): FormArray {
    return this.subpopulationsForm.get('subpopulations') as FormArray;
  }

  newSubpopulation(): FormGroup {
    return this.formBuilder.group({
      name: [null],
      included: [null],
    });
  }

  addSubpopulation(): void {
    this.subpopulations.push(this.newSubpopulation(), { emitEvent: false });
  }

  removeSubpopulation(optionIndex: number): void {
    this.subpopulations.removeAt(optionIndex);
  }

  trackByFn(item: any): number {
    return item.id;
  }

  setVisibility(isVisible: boolean): void {
    this.selectVisible = isVisible;
  }

  addOption(evt: any) {
    this.addSubpopulation();
    this.subpopulations.at(-1).patchValue(evt);
    this.setVisibility(false);
    this.selectedSubpopulationOption = '';
  }

  prepareSave(_context: IBuilderContext, _crosstabConfig: any): void {
    /* throw new Error('Method not implemented.'); */
  }
}
