import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { IOrchestrationAgeGroupClassification } from '../../../../models/data/request/IOrchestrationAgeGroupClassification';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IOrchestrationAgeGroup } from '../../../../models/data/request/IOrchestrationAgeGroup';
import { DataUtility } from '../../../../utilities/data-utility';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
} from '../../../../models/data/request/IDataFilterItem';
import {
  AgeRangeRowControl,
  ageRangeValidator,
} from '../../../../utilities/age-range-validator';
import { ICrosstabConfig } from '../../../../models/data/request/ICrosstabConfig';

interface AgeRangeForm {
  label: FormControl<string | null>;
  ranges: FormArray<FormGroup<AgeRangeRowControl | null>>;
}

@Component({
  selector: 'app-age-range-editor',
  templateUrl: './age-range-editor.component.html',
  styleUrls: ['./age-range-editor.component.scss'],
})
export class AgeRangeEditorComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<void>();
  private rangesControl!: FormArray<FormGroup<AgeRangeRowControl | null>>;

  @Input()
  public dialog: IgxDialogComponent | undefined;

  @Input()
  public ageGroupClassification?: IOrchestrationAgeGroupClassification;

  @Input()
  public config?: ICrosstabConfig;

  @Input()
  public source?: 'dimension' | 'builder';

  get ageRangesFormArray(): FormArray<FormGroup<AgeRangeRowControl | null>> {
    return this.pageForm.controls.ranges;
  }

  minAge = 13;
  maxAge = 99;
  pageForm!: FormGroup<AgeRangeForm>;
  ageGroupConfig!: IOrchestrationAgeGroupClassification;
  ageGroupConfigUpdated!: boolean;
  rangeErrorKind: 'rangeMinMax' | 'rangeInvalid' | undefined;
  hasWarnings!: boolean;
  warningMsg:
    | 'customAgeUpdateWarning1'
    | 'customAgeUpdateWarning2'
    | 'customAgeUpdateWarning3'
    | undefined;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();

    if (this.ageGroupClassification?.age_groups) {
      this.ageGroupConfig = <IOrchestrationAgeGroupClassification>(
        JSON.parse(JSON.stringify(this.ageGroupClassification))
      );
      const { age_groups } = this.ageGroupClassification;
      age_groups.forEach((range) => {
        this.rangesControl.push(this.createRangeItem(range));
      });
    } else {
      this.ageGroupConfig = {
        age_group_classification_id: DataUtility.generateGUID(),
        age_group_classification_name: '',
        age_groups: [],
      };
      this.rangesControl.push(this.createRangeItem());
    }

    this.checkWarnings();
    this.awaitFormChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onAddNewRangeRow(): void {
    this.rangesControl.push(this.createRangeItem());
  }

  onRemoveAgeRangeRow(rowIx: number): void {
    this.rangesControl.removeAt(rowIx);
  }

  onClose(save?: boolean): void {
    if (!this.dialog) {
      return;
    }

    if (!save) {
      this.dialog.close();
      return;
    }

    this.prepareSave();

    if (this.ageGroupConfigUpdated) {
      this.ageGroupConfig.age_groups.forEach((x, ix) => {
        x.age_group_id = DataUtility.generateGUID();
        x.priority = ix + 1;
      });
    }

    setTimeout(() => {
      this.onClose();
    }, 100);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  numericOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  onUpdateRangeValue(
    dir: 'up' | 'down',
    kind: 'min' | 'max',
    rowIx: number
  ): void {
    if (this.ageRangesFormArray?.controls?.length < rowIx) {
      return;
    }

    const tmpGroup = <FormGroup<AgeRangeRowControl | null>>(
      this.ageRangesFormArray.controls[rowIx]
    );

    if (!tmpGroup?.controls) {
      return;
    }

    const updateValue = (
      currentValue: string,
      dir: 'up' | 'down'
    ): number | undefined => {
      if (isNaN(parseInt(currentValue))) {
        return;
      }
      let newValue = parseInt(currentValue);
      newValue = dir === 'up' ? newValue + 1 : newValue - 1;
      if (newValue > this.maxAge) {
        return;
      }
      if (newValue < this.minAge) {
        return;
      }
      return newValue;
    };

    if (kind === 'min') {
      const currentValue = tmpGroup.controls.minAge.value ?? '';
      const newValue = updateValue(currentValue, dir);
      if (newValue) {
        tmpGroup.controls.minAge.setValue(`${newValue}`);
      }
    } else {
      const currentValue = tmpGroup.controls.maxAge.value ?? '';
      const newValue = updateValue(currentValue, dir);
      if (newValue) {
        tmpGroup.controls.maxAge.setValue(`${newValue}`);
      }
    }
  }

  private createForm(): void {
    this.rangesControl = this.formBuilder.array<
      FormGroup<AgeRangeRowControl | null>
    >([]);

    const labelControlValidator = Validators.compose([Validators.required]);
    const labelControl = new FormControl<string | null>(
      this.ageGroupClassification?.age_group_classification_name ?? '',
      labelControlValidator
    );

    this.pageForm = this.formBuilder.group<AgeRangeForm>({
      label: labelControl,
      ranges: this.rangesControl,
    });
  }

  private createRangeItem(
    range?: IOrchestrationAgeGroup
  ): FormGroup<AgeRangeRowControl | null> {
    const rangeItem =
      range ??
      <IOrchestrationAgeGroup>{
        age_group_id: DataUtility.generateGUID(),
        age_group_name: '',
        min_age: this.ageRangesFormArray?.length ? 0 : this.minAge,
        max_age: this.ageRangesFormArray?.length ? 0 : this.maxAge,
      };

    if (this.ageRangesFormArray.length === 0 && rangeItem.age_group_id === '') {
      rangeItem.age_group_name = `${this.minAge} - ${this.maxAge}`;
      rangeItem.min_age = this.minAge;
      rangeItem.max_age = this.maxAge;
      rangeItem.priority = 1;
    }

    const validators = Validators.compose([
      Validators.required,
      Validators.pattern(/^[0-9]\d*$/),
    ]);

    const ageGroupId = new FormControl<string | null>(
      rangeItem.age_group_id ?? ''
    );
    const ageGroupName = new FormControl<string>(
      rangeItem.age_group_name ?? ''
    );
    const minAge = new FormControl<string>(
      rangeItem.min_age ? `${rangeItem.min_age}` : '',
      validators
    );
    const maxAge = new FormControl<string>(
      rangeItem.max_age ? `${rangeItem.max_age}` : '',
      validators
    );

    const tmpFormGroup = this.formBuilder.group<AgeRangeRowControl>({
      ageGroupId,
      ageGroupName,
      minAge,
      maxAge,
    });

    tmpFormGroup.setValidators(ageRangeValidator(this.minAge, this.maxAge));

    return <FormGroup<AgeRangeRowControl | null>>tmpFormGroup;
  }

  private awaitFormChanges(): void {
    this.ageRangesFormArray.statusChanges
      .pipe(takeUntil(this.unsubscribeAll), debounceTime(300))
      .subscribe(() => {
        this.ageRangesFormArray.controls.forEach((group) => {
          if (group.hasError('rangeOverlap')) {
            group.controls?.minAge.updateValueAndValidity({
              emitEvent: false,
            });
            group.controls?.maxAge.updateValueAndValidity({
              emitEvent: false,
            });
          }
        });
      });
  }

  private prepareSave() {
    const ageRanges =
      this.ageRangesFormArray.controls.map((t, ix) => {
        const group = t as FormGroup<AgeRangeRowControl | null>;
        return <IOrchestrationAgeGroup>{
          age_group_id: group.controls?.ageGroupId.value ?? '',
          age_group_name: `${group.controls?.minAge.value} - ${group.controls?.maxAge.value}`,
          min_age: parseInt(group.controls?.minAge.value ?? '0'),
          max_age: parseInt(group.controls?.maxAge.value ?? '0'),
          priority: ix + 1,
        };
      }) ?? [];

    this.ageGroupConfig.age_group_classification_name =
      this.pageForm.controls.label.value ?? '';
    this.ageGroupConfigUpdated = this.isRangeUpdated(ageRanges);
    if (this.ageGroupConfigUpdated) {
      this.ageGroupConfig.age_groups = ageRanges;
    }
  }

  private isRangeUpdated(newRange: Array<IOrchestrationAgeGroup>): boolean {
    const initialRange = this.ageGroupClassification?.age_groups ?? [];
    if (initialRange.length !== newRange.length) {
      return true;
    }

    if (
      initialRange.some((rangeToCheck) => {
        return (
          newRange.findIndex(
            (t) =>
              t.min_age === rangeToCheck.min_age &&
              t.max_age === rangeToCheck.max_age
          ) < 0
        );
      })
    ) {
      return true;
    }
    return false;
  }

  private checkWarnings(): void {
    if (!this.config) {
      return;
    }
    const { columns, rows, data_filter } = this.config;
    const colBannersWithCustomAge = columns?.filter((x) =>
      x.dimensions.some(
        (y) =>
          y.field?.source === 'demographic' &&
          y.field?.field === 'custom_age_group_id'
      )
    );
    const rowBannersWithCustomAge = rows?.filter((x) =>
      x.dimensions.some(
        (y) =>
          y.field?.source === 'demographic' &&
          y.field?.field === 'custom_age_group_id'
      )
    );

    const countBannersWithCustomAge =
      (colBannersWithCustomAge ?? []).length +
      (rowBannersWithCustomAge ?? []).length;

    let hasCustomAgeFilters = false;
    if (data_filter) {
      const customAgeGroupFilter = DataUtility.findFilterItem(
        this.config.data_filter,
        EDataFilterNames.custom_age_group,
        EDataFilterUIAreas.demographicFilters
      );

      hasCustomAgeFilters = customAgeGroupFilter.ix >= 0;
    }

    if (hasCustomAgeFilters && !countBannersWithCustomAge) {
      this.warningMsg = 'customAgeUpdateWarning3';
    } else if (hasCustomAgeFilters && countBannersWithCustomAge > 0) {
      this.warningMsg = 'customAgeUpdateWarning2';
    } else if (this.source === 'builder' && countBannersWithCustomAge > 0) {
      this.warningMsg = 'customAgeUpdateWarning1';
    } else if (this.source === 'dimension' && countBannersWithCustomAge > 1) {
      this.warningMsg = 'customAgeUpdateWarning1';
    }

    this.hasWarnings = this.warningMsg !== undefined;
  }
}
