import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { IFabricationDetail } from '../../../models/fabrication/IFabricationDetail';
import { Observable } from 'rxjs';

@Component({
  selector: 'cbp-fabrication-detail-dialog',
  templateUrl: './cbp-fabrication-detail-dialog.component.html',
  styleUrls: ['./cbp-fabrication-detail-dialog.component.scss'],
})
export class CbpFabricationDetailDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: Observable<IFabricationDetail>
  ) {}

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getGroupsFromJsonPayload(payload: string): void {
    const parsed = JSON.parse(payload);
    const leftovers = parsed.items[0].remainder;

    const outcomes = parsed.items[0].outcomes.map(
      (outcome) => outcome.outcome_text
    );

    if (leftovers.include) {
      outcomes.push(leftovers.outcome.outcome_text);
    }

    return outcomes.toLocaleString().replaceAll(',', ', ');
  }
}
