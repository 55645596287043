<form [formGroup]="searchForm" class="cbp-search-container">
    <div class="suzy-input-container">
        <mat-form-field class="suzy-searchable-input-alt" floatLabel="never" appearance="legacy">
            <mat-label>Search</mat-label>
            <input style="width:94%" matInput #input name="searchBar" type="text" formControlName="search" autocomplete="off"
                placeholder="Search">
            <span matTextSuffix *ngIf="input.value.length > 0" (click)="clearSearch()"><igx-icon class="clear-search">clear</igx-icon></span>
        </mat-form-field>
    </div>
</form>
