export interface IDataFilterItem {
  name: string;
  enabled: boolean;
  as_exclude?: boolean;
  bool_1?: boolean;
  list_1?: string[];
  ui_area?: string;
  action_id?: string;
  string_1?: string;
}

export enum EDataFilterUIAreas {
  customize = 'customize',
  filters = 'filters',
  demographicFilters = 'demographic_filters',
}

export enum EDataFilterNames {
  partialResponses = 'partial_responses',
  excludeUsers = 'exclude_users',
  includeSpam = 'spam_responses',
  includeTrimmed = 'trimmed_responses',
  includeCleanedResponses = 'cleaned_responses',
  ethnicity = 'ethnicity',
  gender = 'gender',
  relationship = 'relationship',
  state = 'state',
  age_group = 'age_group',
  education = 'education',
  employment = 'employment',
  household = 'household',
  income_level = 'income_level',
  parenting = 'parenting',
  region = 'region',
  custom_age_group = 'custom_age_group',
  panelTarget = 'panel_target',
  segmentTarget = 'segment_target',
  userAttribution = 'user_attribution',
  profilingQuestion = 'brand_profile_question',
}

export enum EDEmographicsFilterNames {
  ethnicity = 'ethnicity',
  gender = 'gender',
  relationship = 'relationship',
  state = 'state',
  age_group = 'age_group',
  education = 'education',
  employment = 'employment',
  household = 'household',
  income_level = 'income_level',
  parenting = 'parenting',
  region = 'region',
  custom_age_group = 'custom_age_group',
}
