/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Renderer2 } from '@angular/core';
import {
  IDropBaseEventArgs,
  IgxListComponent,
  IgxListItemComponent,
} from '@infragistics/igniteui-angular';
import { IBanner } from '../../models/data/request/IBanner';
import { IDimension } from '../../models/data/request/IDimension';
import { BuilderComponent } from './builder.component';
import { IBuilderDimension } from './i-builder-dimension';

export class DragDropManager {
  constructor(private renderer: Renderer2, builder: BuilderComponent) {
    this.builder = builder;
    this.row_banners = [];
    this.column_banners = [];
  }

  public drag_id_field: string = '_drag_id';
  private dimension_id_prefix: string = 'd_';
  private new_dimension_id_prefix: string = 'n_';
  private counter: number = -1;

  private row_banners: Array<IBanner>;
  private column_banners: Array<IBanner>;

  private drag_source: string = 'new';
  private banner_drag_id?: string;
  private dimension_drag_id?: string;
  private dimension_owner_id?: string;
  private builder: BuilderComponent;
  private rowDropDisabled: boolean = false;
  private readonly maxCountFields = 4;

  private new_dimensions: {
    [id: string]: { [id: string]: IBuilderDimension };
  } = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private disabledDropContainers: Array<any> = [];
  private disabledParents: Array<HTMLElement> = [];
  private disabledGrandParents: Array<HTMLElement> = [];

  /**
   * @description Note: This augments the entity by adding drag information
   */
  public prepareNewItems(
    area: string,
    dimensions: Array<IBuilderDimension>
  ): void {
    if (!dimensions || dimensions.length == 0) {
      return;
    }
    const new_dimensions = {};
    for (const dimension of dimensions) {
      this.counter++;
      const key = this.new_dimension_id_prefix + this.counter;
      dimension[this.drag_id_field] = key;
      new_dimensions[key] = dimension;
    }
    this.new_dimensions[area] = new_dimensions;
  }

  /**
   * @description Note: This augments the entity by adding drag information
   */
  public prepareItems(is_row: boolean, banners: Array<IBanner>): void {
    const banner_id_prefix = this.getBannerIdPrefix(is_row ? 'row' : 'column');

    for (const banner of banners) {
      this.counter++;
      banner[this.drag_id_field] = banner_id_prefix + this.counter;
      for (const dimension of banner.dimensions) {
        this.counter++;
        dimension[this.drag_id_field] = this.dimension_id_prefix + this.counter;
      }
    }
    if (is_row) {
      this.row_banners = banners;
    } else {
      this.column_banners = banners;
    }
  }

  public resetNewItem(dimension: IBuilderDimension): void {
    this.counter++;
    dimension[this.drag_id_field] = this.dimension_id_prefix + this.counter;
  }

  /**
   * @description Note: This augments the entity by removing drag information
   */
  public cleanItems(): void {
    for (const banner of this.row_banners) {
      delete banner[this.drag_id_field];
      for (const dimension of banner.dimensions) {
        delete dimension[this.drag_id_field];
      }
    }
    for (const banner of this.column_banners) {
      delete banner[this.drag_id_field];
      for (const dimension of banner.dimensions) {
        delete dimension[this.drag_id_field];
      }
    }
  }

  public newbanner_enter(ev: IDropBaseEventArgs): void {
    const prefixes: Array<string> = [
      this.dimension_id_prefix,
      this.new_dimension_id_prefix,
      this.getBannerIdPrefix('row'),
      this.getBannerIdPrefix('column'),
    ];
    const dragId = <string>ev.drag.element.nativeElement.id ?? '';
    const parentElement = ev.owner.element.nativeElement;
    if (!prefixes.some((x) => dragId.startsWith(x))) {
      return;
    }
    if (parentElement) {
      this.renderer.addClass(parentElement, 'drop-create');
    }
  }

  public newbanner_dropped(is_row: boolean, ev: IDropBaseEventArgs) {
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
    }, 0);
    const dimension_drag_id = ev.drag.element.nativeElement.id;
    let columnSource: IBanner | null = null;
    let dragIndex = -1;

    const bannerPrefixes: Array<string> = [
      this.getBannerIdPrefix('row'),
      this.getBannerIdPrefix('column'),
    ];
    if (bannerPrefixes.some((x) => dimension_drag_id.startsWith(x))) {
      this.relocateBanner(dimension_drag_id, is_row ? 'columns' : 'rows');
      return;
    }

    if (dimension_drag_id.startsWith(this.new_dimension_id_prefix)) {
      const newDimensions: IDimension[] = [];
      let label: string = '';
      for (const area in this.new_dimensions) {
        const new_dimensions = this.new_dimensions[area];
        const found = new_dimensions[dimension_drag_id];
        if (found) {
          label = found.label ? found.label : '';
          if (found.createDropInstances) {
            const dimensions = found.createDropInstances(
              found,
              this.builder.config?.meta
            );
            if (dimensions) {
              for (const dimension of dimensions) {
                this.resetNewItem(dimension);
                newDimensions.push(dimension);
              }
            }
          } else {
            const newDimension = JSON.parse(JSON.stringify(found));
            this.resetNewItem(newDimension!);
            newDimensions.push(newDimension);
          }
          break;
        }
      }
      if (newDimensions.length) {
        if (is_row) {
          this.builder.addDimensionToNewRowBanner(label, newDimensions);
        } else {
          this.builder.addDimensionToNewColumnBanner(label, newDimensions);
        }
      }
      return;
    }

    let sourceBanners: Array<IBanner> | null = null;
    let bannerSource: 'rows' | 'columns' | undefined;

    const allBanners = [
      this.builder.config!.crosstab.rows,
      this.builder.config!.crosstab.columns,
    ];
    for (const banners of allBanners) {
      for (const banner of banners) {
        for (let i = 0; i < banner.dimensions.length; i++) {
          const dimension = banner.dimensions[i];
          if (dimension[this.drag_id_field] === dimension_drag_id) {
            const isRowBanner =
              this.row_banners.findIndex(
                (x) => x[this.drag_id_field] === banner[this.drag_id_field]
              ) >= 0;
            sourceBanners = banners;
            columnSource = banner;
            bannerSource = isRowBanner ? 'rows' : 'columns';
            dragIndex = i;
            break;
          }
        }
        if (dragIndex >= 0) {
          break;
        }
      }
      if (dragIndex >= 0) {
        break;
      }
    }
    if (!columnSource || dragIndex == -1 || !bannerSource) {
      return;
    }

    // jumping lists
    const temp = columnSource.dimensions[dragIndex];
    columnSource.dimensions.splice(dragIndex, 1);

    if (is_row) {
      this.builder.addDimensionToNewRowBanner(temp.label!, [temp]);
    } else {
      this.builder.addDimensionToNewColumnBanner(temp.label!, [temp]);
    }

    if (sourceBanners && columnSource.dimensions.length == 0) {
      this.builder.removeBanner(
        bannerSource,
        sourceBanners,
        columnSource,
        false
      );
    }
  }

  public newbanner_leave(ev: IDropBaseEventArgs) {
    const parentElement = ev.owner.element?.nativeElement;
    if (parentElement) {
      this.renderer.removeClass(parentElement, 'drop-create');
    }
  }

  public banner_dropped() {
    this.banner_drag_id = undefined;
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
    });
  }

  public banner_enter(ev: IDropBaseEventArgs): void {
    if (!this.banner_drag_id) {
      return;
    }

    const banner_id_prefix = this.getBannerIdPrefix(this.drag_source);

    if (!ev.owner.element.nativeElement.id.startsWith(banner_id_prefix)) {
      return;
    }
    const banner_drop_id = ev.owner.element.nativeElement.id;
    if (this.banner_drag_id === banner_drop_id) {
      return;
    }
    let banners: Array<IBanner> | null = null;
    if (this.drag_source == 'row') {
      banners = this.row_banners;
    } else {
      if (this.drag_source !== 'column') {
        this.drag_source = 'column';
      }
      banners = this.column_banners;
    }
    if (!banners) {
      return;
    }
    const dragIndex = banners.findIndex(
      (x) => x[this.drag_id_field] === this.banner_drag_id
    );
    const dropIndex = banners.findIndex(
      (x) => x[this.drag_id_field] === banner_drop_id
    );

    const temp = banners[dragIndex];
    banners.splice(dragIndex, 1);
    banners.splice(dropIndex, 0, temp);
  }

  public banner_dragStart(is_row: boolean, banner: IBanner): void {
    this.banner_drag_id = banner[this.drag_id_field];
    this.drag_source = is_row ? 'row' : 'column';
    if (this.drag_source === 'row') {
      this.builder.ui.show_relocate_banner_columns = true;
      this.builder.ui.show_relocate_banner_rows = false;
      this.builder.ui.show_new_banner = false;
    } else {
      this.builder.ui.show_relocate_banner_rows = true;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_new_banner = false;
    }
  }

  public banner_dragEnd(dragRef: IgxListComponent) {
    dragRef.element.nativeElement.style.visibility = 'visible';
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
    });
  }

  public banner_ghostCreate(dragRef: IgxListComponent) {
    dragRef.element.nativeElement.style.visibility = 'hidden';
  }

  public dimension_dropped(banner: IBanner, ev: IDropBaseEventArgs) {
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
    }, 0);
    this.dimension_drag_id = undefined;
    const parentElement = ev.owner.element?.nativeElement?.parentElement;
    if (parentElement) {
      this.renderer.removeClass(parentElement, 'drop-active');
    }

    const dimension_drop_id = ev.owner.element.nativeElement.id;
    const dimension_drag_id = ev.drag.element.nativeElement.id;

    const columnDestination = banner;
    let columnSource: IBanner | null = null;

    const dropIndex = columnDestination.dimensions.findIndex(
      (x) => x[this.drag_id_field] === dimension_drop_id
    );
    let dragIndex = -1;

    if (
      ev.drag.element.nativeElement.id.startsWith(this.new_dimension_id_prefix)
    ) {
      const newDimensions: IDimension[] = [];
      for (const area in this.new_dimensions) {
        const new_dimensions = this.new_dimensions[area];
        const found = new_dimensions[ev.drag.element.nativeElement.id];
        if (found) {
          if (found.createDropInstances) {
            const dimensions = found.createDropInstances(
              found,
              this.builder.config?.meta
            );
            if (dimensions) {
              for (const dimension of dimensions) {
                this.resetNewItem(dimension);
                newDimensions.push(dimension);
              }
            }
          } else {
            const newDimension = JSON.parse(JSON.stringify(found));
            this.resetNewItem(newDimension!);
            newDimensions.push(newDimension);
          }
          break;
        }
      }
      if (newDimensions.length) {
        columnDestination.dimensions.splice(dropIndex, 0, ...newDimensions);
      }
      return;
    }

    let sourceBanners: Array<IBanner> | null = null;
    const allBanners = [this.row_banners, this.column_banners];
    let bannerSource: 'rows' | 'columns' | undefined;
    for (const banners of allBanners) {
      for (const banner of banners) {
        for (let i = 0; i < banner.dimensions.length; i++) {
          const dimension = banner.dimensions[i];
          if (dimension[this.drag_id_field] === dimension_drag_id) {
            const isRowBanner =
              this.row_banners.findIndex(
                (x) => x[this.drag_id_field] === banner[this.drag_id_field]
              ) >= 0;
            sourceBanners = banners;
            columnSource = banner;
            bannerSource = isRowBanner ? 'rows' : 'columns';
            dragIndex = i;
            break;
          }
        }
        if (dragIndex >= 0) {
          break;
        }
      }
    }

    if (!columnSource || columnDestination == columnSource) {
      return;
    }

    // jumping lists, otherwise it would have already sorted
    const temp = columnSource.dimensions[dragIndex];
    columnSource.dimensions.splice(dragIndex, 1);
    columnDestination.dimensions.splice(dropIndex, 0, temp);

    if (sourceBanners && columnSource.dimensions.length == 0 && bannerSource) {
      this.builder.removeBanner(
        bannerSource,
        sourceBanners,
        columnSource,
        false
      );
    }
  }

  public dimension_enter(
    ev: IDropBaseEventArgs,
    bannerIndex: number,
    isRow: boolean
  ): void {
    if (!this.dimension_drag_id || !this.dimension_owner_id) {
      return;
    }

    const dropParentElement = ev.owner.element?.nativeElement?.parentElement;
    const dragDimensionId = ev.drag.element?.nativeElement?.id;
    const dragSource = this.drag_source;
    if (!dragDimensionId) {
      return;
    }
    const dragBannerId = this.dimension_owner_id;
    let dragBanner: IBanner | undefined;

    const dropBannerIx = bannerIndex;
    let dropBanner: IBanner | undefined = undefined;
    const dropDimensionId = ev.owner.element?.nativeElement?.id;

    const isNewDimension = dragSource === 'new';
    let countNewFields = 1;

    if (!isNewDimension && !['row', 'column'].some((x) => x === dragSource)) {
      //existing dimension with an invalid drag source!,
      return;
    }

    if (dragDimensionId === dropDimensionId) {
      //drag and drop dimensions ids are same!
      return;
    }

    const dropBanners: Array<IBanner> = isRow
      ? this.row_banners
      : this.column_banners;
    if (!dropBanners?.length || dropBanners.length < bannerIndex) {
      //drop area banners can not be empty!
      return;
    }

    dropBanner = dropBanners[dropBannerIx];

    if (!isNewDimension) {
      const dragBanners =
        dragSource === 'row' ? this.row_banners : this.column_banners;
      dragBanner = dragBanners.find(
        (x) => x[this.drag_id_field] === dragBannerId
      );
      if (!dragBanner) {
        //existing dimension with an invalid drag banner!
        return;
      }
    }

    //Case 1: Re-order inside a banner
    if (
      !isNewDimension &&
      dragBanner![this.drag_id_field] === dropBanner[this.drag_id_field]
    ) {
      const dragIndex = dropBanner.dimensions.findIndex(
        (x) => x[this.drag_id_field] === dragDimensionId
      );

      const dropIndex =
        dropBanner.dimensions.findIndex(
          (x) => x[this.drag_id_field] === dropDimensionId
        ) ?? -1;

      const temp = dropBanner.dimensions[dragIndex];
      dropBanner.dimensions.splice(dragIndex, 1);
      dropBanner.dimensions.splice(dropIndex, 0, temp);
      return;
    }

    //Case 2: New dimension to an existing banner
    //Case 3: Move from one banner to another banner
    const dragDimension = isNewDimension
      ? this.getNewDimensionById(dragDimensionId)
      : dragBanner?.dimensions.find(
          (x) => x[this.drag_id_field] === dragDimensionId
        );
    if (!dragDimension) {
      //drag dimension is undefined!
      return;
    }

    if ((dragDimension.interlocked_nested_quota_fields?.length ?? 0) > 0) {
      countNewFields =
        dragDimension.interlocked_nested_quota_fields?.length ?? 1;
    }
    const maxDimCountReached =
      dropBanner.dimensions.length > this.maxCountFields - countNewFields;
    if (maxDimCountReached) {
      this.addDropDisabledState(ev, bannerIndex, isRow);
      return;
    }

    const dimExistsIndDropBanner = this.dimensionExistInBanner(
      dragDimension,
      dropBanner?.dimensions
    );
    if (dimExistsIndDropBanner) {
      this.addDropDisabledState(ev, bannerIndex, isRow);
      return;
    }

    if (dropParentElement) {
      this.renderer.addClass(dropParentElement, 'drop-active');
    }
  }

  public dimension_leave(ev: IDropBaseEventArgs) {
    const parentElement = ev.owner.element?.nativeElement?.parentElement;
    if (parentElement) {
      this.renderer.removeClass(parentElement, 'drop-active');
    }
  }

  public dimension_dragStart(
    is_row: boolean,
    dimension: IDimension,
    banner: IBanner
  ): void {
    this.dimension_drag_id = dimension[this.drag_id_field];
    this.dimension_owner_id = banner[this.drag_id_field];
    this.builder.ui.show_new_banner = true;
    this.drag_source = is_row ? 'row' : 'column';
  }

  public dimension_dragEnd(dragRef: IgxListItemComponent) {
    dragRef.element.style.visibility = 'inherit';
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
      this.removeDropDisabledState();
    });
  }

  public dimension_ghostCreate(dragRef: IgxListItemComponent) {
    dragRef.element.style.visibility = 'hidden';
  }

  newdimension_dragStart() {
    this.dimension_drag_id = '_new';
    this.dimension_owner_id = '_new';
    this.builder.ui.show_new_banner = true;
    this.drag_source = 'new';
  }

  newdimension_dragEnd() {
    setTimeout(() => {
      this.builder.ui.show_new_banner = false;
      this.builder.ui.show_relocate_banner_columns = false;
      this.builder.ui.show_relocate_banner_rows = false;
      this.removeDropDisabledState();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addDropDisabledState(ev: any, bannerIndex: number, isRow: boolean): void {
    const parentElement = ev.owner.element?.nativeElement?.parentElement;
    const grandParentElement = ev.owner.element?.nativeElement?.offsetParent;

    if (!parentElement || !grandParentElement) {
      return;
    }

    const classToSearch = isRow ? 'rowBanner' : 'columnBanner';
    if (!grandParentElement.classList.contains(classToSearch)) {
      return;
    }

    this.renderer.addClass(parentElement, 'drop-disabled');
    this.renderer.addClass(grandParentElement, 'drop-disabled');
    ev.owner.droppable = false;
    ev.cancel = true;
    this.rowDropDisabled = true;
    this.disabledDropContainers.push(ev.owner);
    this.disabledParents.push(parentElement);
    this.disabledGrandParents.push(grandParentElement);
  }

  removeDropDisabledState(ev?: IDropBaseEventArgs): void {
    if (this.disabledDropContainers?.length > 0) {
      this.disabledDropContainers.forEach((parent) => {
        parent.droppable = true;
      });
      this.disabledDropContainers = [];
    }

    if (this.disabledParents?.length > 0) {
      this.disabledParents.forEach((parent) => {
        this.renderer.removeClass(parent, 'drop-disabled');
      });
      this.disabledParents = [];
    }
    if (this.disabledGrandParents?.length > 0) {
      this.disabledGrandParents.forEach((parent) => {
        this.renderer.removeClass(parent, 'drop-disabled');
      });
      this.disabledGrandParents = [];
    }
    if (ev) {
      ev.owner.droppable = true;
    }
  }

  resetDropDisabledState(): void {
    this.removeDropDisabledState();
    this.disabledGrandParents = [];
    this.disabledParents = [];
    this.disabledDropContainers = [];
    this.rowDropDisabled = false;
  }

  isUnevenRowBanners(): boolean {
    const rowDimensionLength = this.row_banners[0]?.dimensions.length;
    let isUneven = false;
    this.row_banners.forEach((row) => {
      if (row.dimensions.length !== rowDimensionLength) {
        isUneven = true;
      }
    });
    return isUneven;
  }

  isSameDimExistsInRowAndCol(): boolean {
    const rowBannerDimensions = (this.row_banners ?? []).map((rowBanner) => {
      return rowBanner.dimensions;
    });

    if (!rowBannerDimensions.length) {
      return false;
    }

    const colBannerDimensions = (this.column_banners ?? []).map((colBanner) => {
      return colBanner.dimensions;
    });
    if (!colBannerDimensions.length) {
      return false;
    }

    const rowBannerDimensionsFlat = rowBannerDimensions.reduce(
      (flatten, arr) => [...flatten, ...arr]
    );
    const columnBannerDimensionsFlat = colBannerDimensions.reduce(
      (flatten, arr) => [...flatten, ...arr]
    );

    return rowBannerDimensionsFlat.some((rowDim) =>
      columnBannerDimensionsFlat.some((colDim) => {
        return (
          rowDim.field?.field === colDim.field?.field &&
          rowDim.field?.source === colDim.field?.source &&
          rowDim.field?.action_id === colDim.field?.action_id
        );
      })
    );
  }

  hasRowBanners(): boolean {
    return (this.row_banners ?? []).length > 0;
  }

  hasColumnBanners(): boolean {
    return (this.column_banners ?? []).length > 0;
  }

  private getBannerIdPrefix(drag_source: string): string {
    if (drag_source == 'row') {
      return 'rb_';
    } else if (drag_source == 'column') {
      return 'cb_';
    } else {
      return 'nb_';
    }
  }

  private getNewDimensionById(id: string): IBuilderDimension | undefined {
    const areas = Object.keys(this.new_dimensions);
    let newDimension: IBuilderDimension | undefined = undefined;

    areas.forEach((area) => {
      if (newDimension) {
        return;
      }

      if (!this.new_dimensions[area]) {
        return;
      }

      const areaDimensions = this.new_dimensions[area];
      const found = Object.keys(areaDimensions).find((x) => x === id);
      if (found) {
        newDimension = this.new_dimensions[area][found];
      }
    });

    return newDimension;
  }

  private dimensionExistInBanner(
    dimension: IBuilderDimension,
    bannerDimensions: IBuilderDimension[]
  ): boolean {
    const checkDimExists = (dimToCheck: IBuilderDimension): boolean => {
      const result = bannerDimensions.some(
        (dim) =>
          dim.field?.field === dimToCheck.field?.field &&
          dim.field?.source === dimToCheck.field?.source &&
          (dimToCheck.field?.field === 'action_setting_id_cross' ||
            dim.field?.action_id === dimToCheck.field?.action_id) &&
          dim.field?.identifier === dimToCheck.field?.identifier
      );
      return result;
    };

    if (dimension.interlocked_nested_quota_fields?.length) {
      let exists = false;
      dimension.interlocked_nested_quota_fields.forEach((dimToCheck) => {
        if (!exists) {
          exists = checkDimExists(dimToCheck);
        }
      });
      return exists;
    }

    return checkDimExists(dimension);
  }

  private relocateBanner(
    dragBannerId: string,
    relocateFrom: 'rows' | 'columns'
  ): void {
    const sourceBanners: IBanner[] | undefined =
      relocateFrom === 'rows' ? this.row_banners : this.column_banners;

    const banner: IBanner | undefined = (sourceBanners ?? []).find(
      (banner) => banner && banner[this.drag_id_field] === dragBannerId
    );

    if (!banner) {
      return;
    }

    const newBanner = <IBanner>JSON.parse(JSON.stringify(banner));
    this.builder.removeBanner(relocateFrom, sourceBanners, banner, false);
    setTimeout(() => {
      this.builder.addBanner(newBanner, relocateFrom === 'columns');
    }, 100);
  }
}
