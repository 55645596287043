import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IBuilderContext } from '../../../i-builder-context';
import { DataUtility } from '../../../../../utilities/data-utility';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { MissionKind } from '@asksuzy/typescript-sdk';

@Component({
  selector: 'app-respondents-filter',
  templateUrl: './respondents-filter.component.html',
  styleUrls: ['./respondents-filter.component.scss'],
})
export class RespondentsFilterComponent implements OnInit, OnDestroy {
  private readonly name: string = EDataFilterNames.excludeUsers;
  private readonly uiArea: string = EDataFilterUIAreas.customize;
  private readonly inputChanged = new Subject<void>();
  private inputChangedSubscription?: Subscription;

  userIdsInput = '';
  isExlcude!: boolean;
  isExternalLinkMission!: boolean;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() filterChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    this.isExternalLinkMission =
      this.context?.mission?.mission_kind === MissionKind.external_link;

    const found = DataUtility.findFilterItem(
      this.crosstabConfig.crosstab.data_filter,
      this.name,
      this.uiArea
    );

    if (found.item?.enabled && (found.item?.list_1?.length ?? 0) > 0) {
      this.userIdsInput = (found.item?.list_1 ?? ([] as string[])).join('\n');
      this.isExlcude = found.item?.as_exclude === true;
    } else {
      this.isExlcude = true;
    }

    this.inputChangedSubscription = this.inputChanged
      .pipe(debounceTime(300))
      .subscribe(() => this.onFilterChanged());
  }

  ngOnDestroy(): void {
    this.inputChanged.complete();
    this.inputChangedSubscription?.unsubscribe();
  }

  onUserIdsChanged(): void {
    this.inputChanged.next();
  }

  onExcludeTypeChanged(isExlcude: boolean): void {
    this.isExlcude = isExlcude;
    this.inputChanged.next();
  }

  reset(): void {
    this.userIdsInput = '';
    this.isExlcude = true;
    this.inputChanged.next();
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    const filterItem: IDataFilterItem = {
      ui_area: this.uiArea,
      name: this.name,
      enabled: true,
      list_1: this.userIdsInput
        .split(/\r?\n/)
        .filter((x) => x.trim().length > 0),
      as_exclude: this.isExlcude,
    };

    if (!crosstabConfig.crosstab.data_filter.items?.length) {
      crosstabConfig.crosstab.data_filter.items = [filterItem];
    }

    const found = DataUtility.findFilterItem(
      crosstabConfig.crosstab.data_filter,
      this.name,
      this.uiArea
    );
    if (found.ix >= 0) {
      crosstabConfig.crosstab.data_filter.items[found.ix] = { ...filterItem };
    } else {
      crosstabConfig.crosstab.data_filter.items.push(filterItem);
    }
  }

  private onFilterChanged(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const tmpConfig = JSON.parse(
      JSON.stringify(this.crosstabConfig)
    ) as ICrosstabConfigUI;
    this.prepareSave(this.context, tmpConfig);
    this.filterChanged.emit(tmpConfig.crosstab);
  }
}
