/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TranslateService } from '@ngx-translate/core';
import { IDimension } from '../../../../models/data/request/IDimension';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { SuzyDataService } from '../../../../services/suzy-data.service';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';
import { MissionTargeting } from '../../../../models/suzy/MissionTargeting.model';
import { catchError, forkJoin, map, of } from 'rxjs';
import { IDPair } from '../../../../models/suzy/IDPair';
import { MissionKind, QuotaEntity } from '@asksuzy/typescript-sdk';

export class SegmentPanelDimensionProvider implements IDimensionProvider {
  private targeting?: MissionTargeting;
  constructor(
    private translate: TranslateService,
    private suzy: SuzyDataService
  ) {
    this.name = this.translate.instant('builder.dimensionSegmentsPanels');
  }

  noDimensionMessage: string = 'builder.noSegmentDimensionsData';
  code: string = 'segments_panels';
  name: string;
  dimensions: Array<IBuilderDimension> = [];
  loading: boolean = false;
  loaded: boolean = false;
  enabled: boolean = false;
  error?: string;
  instructions = 'builder.segmentsPanelsTabInstructions';

  brand_id?: string;

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (
      context?.global ||
      context?.mission?.mission_kind === MissionKind.external_link
    ) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }
    if (this.brand_id != context.brand_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    this.brand_id = context.brand_id;
    this.error = undefined;

    if (!this.loaded && this.brand_id) {
      this.loading = true;
      const { mission } = context;
      const { targets, targeting, quota } = mission ?? {
        target: undefined,
        targeting: undefined,
        quota: undefined,
      };

      if (!targeting && targets?.length) {
        this.targeting = MissionTargeting.fromJsonData(targets);
      } else {
        this.targeting = targeting;
      }

      const targetedGroups = (this.targeting?.clusterCustomQueryFilters ?? [])
        .map((x) => x.cluster_group_id ?? '')
        .filter((x) => x !== '');

      const clusterGroupQuotaFields = (quota?.quota_fields ?? []).filter(
        (quotaField) => quotaField.quota_entity === QuotaEntity.cluster_group
      );
      const quotedGroups = (clusterGroupQuotaFields ?? []).map(
        (quotaField) => quotaField.quota_custom_field
      );

      const groupsToFilterOut = [...quotedGroups, ...targetedGroups];

      const getSegments$ = this.suzy.audienceSegmentsGet(this.brand_id).pipe(
        catchError(() => {
          return of([] as IDPair[]);
        }),
        map((data) => {
          const nonTargetedGroups = data.filter(
            (x) => !groupsToFilterOut.some((y) => y === x.id)
          );
          return nonTargetedGroups;
        })
      );

      const getPanels$ = this.suzy.audiencePanelsGet(this.brand_id).pipe(
        catchError(() => {
          return of([] as IDPair[]);
        }),
        map((data) => {
          const nonTargetedGroups = data.filter(
            (x) => !groupsToFilterOut.some((y) => y === x.id)
          );
          return nonTargetedGroups;
        })
      );

      forkJoin([getSegments$, getPanels$]).subscribe(
        ([segmentsData, panelsData]) => {
          this.loading = false;

          const dimensions = [] as Array<IDimension>;
          const nonTargetedGroups = [...segmentsData, ...panelsData];
          for (const item of nonTargetedGroups) {
            dimensions.push({
              type: 'field',
              label: item.name,
              field: {
                source: 'profile',
                field: 'attributed_brand_cluster_group_id',
                action_id: 'primary',
                identifier: item.id,
              },
            });
          }

          dimensions.sort((a, b) =>
            (a.label ?? '').localeCompare(b.label ?? '')
          );
          builder.dragdrop?.prepareNewItems(this.code, dimensions);
          this.dimensions = dimensions;
          this.loaded = true;
        }
      );
    }
    return true;
  }
}
