import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  noop,
  of,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ESortOrder } from '../../../enums/sort-orders.enum';
import { IAction } from '../../../models/suzy/IAction';
import { SuzyDataService } from '../../../services/suzy-data.service';

/* 
* @deprecated
*/
@Component({
  selector: 'cbp-select-tree-field',
  templateUrl: './cbp-select-tree-field.component.html',
  styleUrls: ['./cbp-select-tree-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CbpSelectTreeFieldComponent),
      multi: true,
    },
  ],
})
export class CbpSelectTreeFieldComponent
  implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
  @Input() missions: any[] = [];
  @Input() placeholder!: string;
  @Input() searchingMissions!: boolean | null;

  @Output() readonly surveyQuestionSelected = new EventEmitter();
  @Output() readonly searchMissionsByTerm = new EventEmitter<string>();

  sortedMissions: any[] = [];
  previousMissions: any[] = [];
  searchTerm$ = new Subject<string>();
  unsubscribe = new Subject<void>();

  control = new FormControl();

  constructor(private suzyData: SuzyDataService) {}

  onChange: (value: unknown) => void = noop;
  onTouch: () => void = noop;

  ngOnChanges(changes: SimpleChanges): void {
    const missionChanges = changes['missions'];

    if (missionChanges?.currentValue) {
      const currentMissions = missionChanges.currentValue;
      this.sortedMissions = currentMissions.map((mission) => {
        return {
          item: mission,
          children: [],
          isLoading: false,
          isOpen: false,
        };
      });
      this.sortMissionNames(ESortOrder.Default, 'search_internal_text');
    }
  }

  ngOnInit(): void {
    this.searchTerm$
      .asObservable()
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((value) => {
          this.searchMissionsByTerm.emit(value);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((value) => {
          this.onChange(value);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(value: string): void {
    if (value) {
      this.control.patchValue({ value });
    } else {
      this.control.reset();
    }
  }

  toggleItem(missionId: string): void {
    const foundIdx = this.sortedMissions.findIndex(
      (mission: any) => mission.item.mission_id === missionId
    );

    if (foundIdx !== -1) {
      const item = this.sortedMissions.at(foundIdx);
      item.isOpen = !item.isOpen;

      if (item.children.length === 0) {
        this.fetchChildren(item);
      }
    }
  }

  sortMissionNames(order: ESortOrder, sortKey: string): void {
    const opts: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
    const LOCALE = 'en';
    this.previousMissions = this.sortedMissions.slice();

    for (const item of this.sortedMissions) {
      if (item.brand_folder_name) {
        item.folder_name$ = of(
          item.brand_folder_name[0].name ?? 'Multiple Folders'
        );
      } else {
        item.folder_name$ = of('None');
      }
    }

    for (const item of this.sortedMissions) {
      if (item.brand_tags) {
        item.brand_tags_text ?? 'Multiple Tags';
      } else {
        item.brand_tags_text = 'None';
      }
    }

    if (order === ESortOrder.Default) {
      this.sortedMissions = this.previousMissions.slice();
    }

    if (order === ESortOrder.Desc) {
      this.sortedMissions.sort((prev: any, next: any) =>
        new Intl.Collator(LOCALE, opts).compare(next[sortKey], prev[sortKey])
      );
    }

    if (order === ESortOrder.Asc) {
      this.sortedMissions.sort((prev: any, next: any) =>
        new Intl.Collator(LOCALE, opts).compare(prev[sortKey], next[sortKey])
      );
    }
  }

  fetchChildren(node: any): void {
    node.isLoading = true;

    this.suzyData
      .getMissionActions(node.item.brand_id, node.item.mission_id)
      .pipe(
        tap((actions: any[]) => {
          node.children = actions;
          node.isLoading = false;
        }),
        take(1),
        catchError((err) => {
          node.isLoading = false;
          return err;
        })
      )
      .subscribe();
  }

  displayFn(action: IAction): string {
    return action
      ? `${action.question_number} - ${action.strippedSearchText}`
      : '';
  }

  onSearchInputChanged(evt: any): void {
    this.searchTerm$.next(evt.target.value);
  }

  markAsSelected(evt: any): void {
    //console.log(evt.option.value)
  }

  clearValue(evt: any): void {
    this.searchTerm$.next('');
  }
}
