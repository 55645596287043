<article class="center-column">
  <form>
    <igx-input-group>
      <input
        igxInput
        [(ngModel)]="username"
        name="fullName"
        type="text"
        [disabled]="inprogress"
        autocomplete="off"
      />
      <label igxLabel for="fullName">Username</label>
    </igx-input-group>
    <igx-input-group>
      <input
        igxInput
        [(ngModel)]="password"
        name="password"
        type="password"
        [disabled]="inprogress"
      />
      <label igxLabel for="password">Password</label>
    </igx-input-group>
    <button
      igxButton="outlined"
      igxRipple
      class="suzy"
      (click)="onLoginClick()"
      [disabled]="inprogress || !username || !password"
      [displayDensity]="'comfortable'"
    >
      Login
    </button>
  </form>
  <div class="loading" *ngIf="inprogress">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
    ></igx-circular-bar>
  </div>
</article>

<igx-dialog
  #errorDialog
  title="Error"
  [message]="errorMsg"
  leftButtonLabel="OK"
  (leftButtonSelect)="errorDialog.close()"
>
</igx-dialog>
