import { EDEmographicsFilterNames } from '../data/request/IDataFilterItem';
import { IDPair } from '../suzy/IDPair';

export type DemographicsFilterUI = {
  [key in EDEmographicsFilterNames]: IDemographicsFilterItem;
};

export interface IDemographicsFilterItem {
  enabled?: boolean;
  loaded?: boolean;
  loading?: boolean;
  list: IDPair[];
  includedIds?: string[];
  allIncluded?: boolean;
}

export const DemographicsFiltersUIBase: DemographicsFilterUI = {
  [EDEmographicsFilterNames.ethnicity]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.gender]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.relationship]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.region]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.state]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.age_group]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.education]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.employment]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.household]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.income_level]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.parenting]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.region]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
  [EDEmographicsFilterNames.custom_age_group]: {
    enabled: false,
    list: [],
    includedIds: [],
  },
};
