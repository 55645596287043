/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryFilterKind } from '@asksuzy/typescript-sdk';
import { QueryFilter } from '../models/suzy/QueryFilter.model';
import { QueryFilterGender } from '../models/suzy/QueryFilterGender.model';
import { QueryFilterAge } from '../models/suzy/QueryFilterAge.model';
import { QueryFilterAnswer } from '../models/suzy/QueryFilterAnswer.model';
import { QueryFilterCustom } from '../models/suzy/QueryFilterCustom.model';

export function createQueryFilter(queryFilterJson: any): QueryFilter {
  switch (queryFilterJson.filter_kind) {
    case QueryFilterKind.gender:
      return QueryFilterGender.fromJsonData({
        kind: queryFilterJson.filter_kind,
        ...queryFilterJson,
      });

    case QueryFilterKind.age:
      return QueryFilterAge.fromJsonData({
        kind: queryFilterJson.filter_kind,
        ...queryFilterJson,
      });

    case QueryFilterKind.answer:
      return QueryFilterAnswer.fromJsonData({
        kind: queryFilterJson.filter_kind,
        ...queryFilterJson,
      });

    default:
      return QueryFilterCustom.fromJsonData({
        kind: queryFilterJson.brand_cluster_kind,
        brand_cluster_kind: queryFilterJson.brandClusterKind,
        ...queryFilterJson,
      });
  }
}
