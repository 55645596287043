/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Observable, BehaviorSubject, Subject, filter, map } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { SuzyEndpoints, SuzyRequest } from '@asksuzy/typescript-sdk';
import { Md5 } from 'ts-md5';

//TODO: DO NOT USE THIS FILE AS IS, GET SDK PROPERLY FROM NORMAL NPM
//      AND FOLLOW PARADIGMS OF OTHER CODE BASES
@Injectable()
export class SuzySDK extends SuzyEndpoints implements OnDestroy {
  baseUrl = '';
  tenant!: string;
  apiKeys: { api_key?: string; api_secret?: string } = {}; // TODO: Move to storage
  sdkConnected$ = new BehaviorSubject<boolean>(false);
  unsubscribe$ = new Subject<void>();

  constructor(private http: HttpClient) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // FIXME: this is obsolete, should use oauth token mechanism
  get signature(): string | Int32Array {
    return this.generateSignature();
  }
  // FIXME: this is obsolete, should use oauth token mechanism
  get apiKey(): string {
    return this.apiKeys.api_key!;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override execute(request: SuzyRequest): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const data = request.payload || request.params;

    if (!request.payload) {
      for (const key in data) {
        // Server may expect empty string instead of null value, so send empty string as default.
        if (data[key] === null) {
          data[key] = '';
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promise: Observable<any> = this.http.request(
      new HttpRequest(
        request.method!,
        `${self.baseUrl}/${request.resource}`,
        data,
        {
          withCredentials: true,
          headers: this.generateHeaders(),
          params: new HttpParams({ fromObject: request.params }),
        }
      )
    );

    return promise.pipe(
      filter((result) => result.type === 4),
      map((result) => result.body)
    );
  }

  initialize(baseUrl = '/api'): SuzySDK {
    this.baseUrl = baseUrl === null || baseUrl === '' ? '/api' : baseUrl; // FIXME: this seem unnecessary
    this.constructEndpoints(this);

    return this;
  }

  setApiCredentials(apiKey: string, apiSecret: string): void {
    this.apiKeys = {
      api_key: apiKey,
      api_secret: apiSecret,
    };
  }
  clearApiCredentials(): void {
    this.apiKeys = {};
  }

  getSignature(): { key: string; signature: string | Int32Array } | undefined {
    const apiKeys = this.apiKeys;
    if (this.apiKey !== undefined && this.signature !== undefined) {
      return {
        key: apiKeys.api_key!,
        signature: this.generateSignature(),
      };
    }

    return undefined;
  }

  private generateSignature(): string | Int32Array {
    const credentials = this.apiKeys;

    return Md5.hashStr(
      // tslint:disable-next-line:no-bitwise
      credentials.api_key! + credentials.api_secret! + ((Date.now() / 1000) | 0)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateHeaders(): any {
    const type = 'application/json';
    const platform = this.getPlatform();
    const signature = this.getSignature();

    let headers = new HttpHeaders()
      .set('Content-Type', type)
      .set('x-device-platform', platform);

    if (signature !== undefined) {
      headers = headers
        .append('x-api-key', signature.key)
        .append('x-api-signature', signature.signature.toString())
        .append('x-tenant', this.tenant);
    }

    return headers;
  }

  private getPlatform(): string {
    const platform = 'client';

    return platform;
  }
}
