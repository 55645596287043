<div class="info-banner" *ngIf="isExternalLinkMission">
  <igx-icon fontSet="material">info</igx-icon>
  <span>{{ 'crosstab.externalLinkRespondentIdInfo' | translate }}</span>
</div>

<p class="info">
  {{ 'crosstab.idUploadInfo' | translate }}
</p>

<div class="list-type-toggle">
  <button
    [igxButton]="isExlcude ? 'raised' : 'flat'"
    (click)="onExcludeTypeChanged(true)"
  >
    {{ 'crosstab.idUploadExclude' | translate }}
  </button>
  <button
    [igxButton]="!isExlcude ? 'raised' : 'flat'"
    igxButton="flat"
    (click)="onExcludeTypeChanged(false)"
  >
    {{ 'crosstab.idUploadInclude' | translate }}
  </button>
</div>

<span class="input-label">{{ 'crosstab.idUploadLabel' | translate }}</span>

<igx-input-group>
  <textarea
    igxInput
    [(ngModel)]="userIdsInput"
    (ngModelChange)="onUserIdsChanged()"
    name="userIdsInput"
  ></textarea>
</igx-input-group>
