<div class="h-100" *ngIf="!loading">
  <div class="filter-modal">
    <div class="modal-header">
      <div class="modal-header-left">
        <span class="h2 text-suzy-grey-900">{{ 'filters.filterModalTitle' | translate }}</span>
        <span class="body-copy-small text-suzy-grey-800">{{ 'filters.filterModalTitleSub' | translate }}</span>
      </div>
      <button class="suzy-icon-button" (click)="onClose()">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-body">
      <igx-expansion-panel *ngIf="config?.allowedFilters?.demographics">
        <igx-expansion-panel-header>
          <igx-expansion-panel-title>{{
            'crosstab.demographics' | translate
          }}</igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>
          <app-demographics-filter
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            [data]="demographicsFilterBinding?.data"
            (filterChanged)="onFilterChanged($event, 'demographics')"
            #demographics_filter
          ></app-demographics-filter>
        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <igx-expansion-panel *ngIf="config?.allowedFilters?.segmentsPanels">
        <igx-expansion-panel-header>
          <igx-expansion-panel-title>{{
            'builder.dimensionSegmentsPanels' | translate
          }}</igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>
          <app-cluster-groups-filter
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            [data]="segmentsPanelsFilterBinding?.data"
            (filterChanged)="onFilterChanged($event, 'segments_panels')"
            #segments_panels_filter
          ></app-cluster-groups-filter>
        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <igx-expansion-panel *ngIf="config?.allowedFilters?.profiling">
        <igx-expansion-panel-header>
          <igx-expansion-panel-title>{{
            profilingSectionTitle | translate
          }}</igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>
          <app-profiling-filter
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            [data]="profilingFilterBinding?.data"
            (filterChanged)="onFilterChanged($event, 'profiling')"
            #profiling_filter
          ></app-profiling-filter>
        </igx-expansion-panel-body>
      </igx-expansion-panel>
      <igx-expansion-panel
        *ngIf="
          config?.allowedFilters?.respondents || config?.allowedFilters?.completes
        "
      >
        <igx-expansion-panel-header>
          <igx-expansion-panel-title>{{
            'builder.customizeRespondentFilters' | translate
          }}</igx-expansion-panel-title>
        </igx-expansion-panel-header>
        <igx-expansion-panel-body>
          <app-completes-filter
            *ngIf="config?.allowedFilters?.completes"
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            (filterChanged)="onFilterChanged($event, 'completes')"
            #completes_filter
          ></app-completes-filter>
          <app-trimmed-responses-filter
            *ngIf="
              missionHasTrimmedResponses && config?.allowedFilters?.trimmedResponses
            "
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            (filterChanged)="onFilterChanged($event, 'trimmed_responses')"
            #trimmed_responses_filter
          ></app-trimmed-responses-filter>
          <app-cleaned-responses-filter
            *ngIf="missionHasCleanedResponses"
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            (filterChanged)="onFilterChanged($event, 'cleaned_responses')"
            #cleaned_responses_filter
          ></app-cleaned-responses-filter>
          <app-respondents-filter
            *ngIf="config?.allowedFilters?.respondents"
            [context]="context"
            [crosstabConfig]="crosstabConfig"
            (filterChanged)="onFilterChanged($event, 'respondents')"
            #respondents_filter
          ></app-respondents-filter>
        </igx-expansion-panel-body>
      </igx-expansion-panel>
    </div>
    <div class="modal-footer-right">
      <div class="filter-content">
        <span *ngIf="!hasFilter">&nbsp;</span>
        <div *ngIf="hasFilter" class="applied-filter">
          <span class="bold"
            >{{ 'filters.filtersApplied' | translate }}&nbsp;:&nbsp;&nbsp;</span
          >
          <ng-container *ngFor="let tag of filterTags">
            <ng-container [ngSwitch]="tag.kind">
              <span *ngSwitchCase="filterTagKind.token">{{
                tag.value | translate
              }}</span>
              <span *ngSwitchCase="filterTagKind.async">{{
                tag.asyncParams
                  | asyncFilterTag
                    : {
                        brand_id: context?.brand_id ?? '',
                        mission_id: context?.mission?.mission_id ?? ''
                      }
                  | async
              }}</span>
              <span *ngSwitchDefault>{{ tag.value }}</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="action-buttons">
        <igx-circular-bar
          *ngIf="inprogress"
          [animate]="false"
          [indeterminate]="true"
          [textVisibility]="false"
        ></igx-circular-bar>
        <button
          *ngIf="hasFilter"
          class="suzy-secondary"
          (click)="onResetFilters()"
        >
          <igx-icon fontSet="material">replay</igx-icon>
          {{ 'crosstab.reset' | translate }}
        </button>
        <button class="suzy-secondary" [disabled]="inprogress" (click)="onClose()">
          {{ 'builder.cancel' | translate }}
        </button>
        <button
          class="suzy-primary"
          [disabled]="inprogress"
          (click)="onClose(true)"
        >
          {{ 'crosstab.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="loading" *ngIf="loading">
  <igx-circular-bar
    [animate]="false"
    [indeterminate]="true"
    [textVisibility]="false"
  ></igx-circular-bar>
</div>
