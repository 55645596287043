<div class="combo-item">
    <div>
        <action-icon [mission]="item"></action-icon>
        <span class="region">{{ item.search_internal_text ?? item.originalSearchText }}</span>
    </div>
    <div *ngIf="!item.action_id">
        <span [igxTooltipTarget]="infoTooltip" class="material-symbols-rounded">info</span>
        <div #infoTooltip="tooltip" igxTooltip class="tooltip">
            <span class="tooltip-region">Created on: {{ item.stamp_utc | date : 'mediumDate'
                }}</span>
            <span class="tooltip-region">Folder: {{ item.folder_name$ | async }}</span>
            <span class="tooltip-region">Tags: {{ item.brand_tags_text ?? 'None' }}</span>
        </div>
    </div>
</div>
