<igx-dialog-title>
  <div class="dialog-container">
    <h2 class="h2">{{ 'crosstab.validateTableView' | translate }}</h2>
  </div>
</igx-dialog-title>

<div class="from-container">
  <form [formGroup]="pageForm">
    <div class="content">{{ validation.metadata.table_name }}</div>

    <igx-input-group type="border">
      <textarea
        igxInput
        name="description"
        formControlName="description"
        autocomplete="off"
        [placeholder]="'crosstab.useCase' | translate"
      ></textarea>
    </igx-input-group>

    <igx-checkbox formControlName="isValid">
      {{ 'crosstab.validateOk' | translate }}
    </igx-checkbox>
  </form>
</div>

<div igxDialogActions class="dialog-container dialog-actions">
  <div class="loading" *ngIf="inprogress">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
    ></igx-circular-bar>
  </div>
  <button
    igxButton="flat"
    igxRipple
    (click)="dialog.close()"
    [disabled]="inprogress"
  >
    {{ 'crosstab.cancel' | translate }}
  </button>
  <button
    class="right"
    igxButton="raised"
    igxRipple
    (click)="onSubmitValidation()"
    [disabled]="inprogress"
  >
    {{ 'crosstab.save' | translate }}
  </button>
</div>
