import * as uuid from 'uuid';
import { IDataFilter } from '../models/data/request/IDataFilter';
import { IDataFilterItem } from '../models/data/request/IDataFilterItem';
import { IStatTestingCell } from '../models/ui/i-stat-testing-cell.model';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IMetaGroup<T, M> {
  items: Array<T>;
  meta?: M;
}

export class DataUtility {
  public static readonly bannerSummaryDimensions: string[] = [
    'span_base_size_row',
    'internal_base_size',
    'span_total_responses_row',
    'scale_rank.top',
    'scale_rank.bottom',
    'means_row',
    'means_column',
    'stat_test_column',
  ];
  public static readonly tableSummaryDimensions: string[] = [];
  public static readonly flexOrderedSummaryDimensions: string[] = [
    'scale_rank.top',
    'scale_rank.bottom',
  ];
  public static readonly alwaysFormattedAsNumber: string[] = [
    'means_row',
    'span_base_size_row',
    'internal_base_size',
    'span_total_responses_row',
  ];
  public static readonly totalsColumnDimensions: string[] = [
    'primary_total_responses_column',
    'primary_base_size_column',
  ];

  public static isBannerSummaryDim(s?: string): boolean {
    return DataUtility.bannerSummaryDimensions.some((x) => x === s);
  }

  public static isTableSummaryDim(s?: string): boolean {
    return DataUtility.tableSummaryDimensions.some((x) => x === s);
  }

  public static isFlexOrderedSummaryDim(s?: string): boolean {
    return DataUtility.flexOrderedSummaryDimensions.some((x) => x === s);
  }

  public static isAnchoredAtEndSummaryDim(s?: string): boolean {
    return this.isBannerSummaryDim(s) && !this.isFlexOrderedSummaryDim(s);
  }

  public static isTotalsColumnDim(s?: string): boolean {
    return DataUtility.totalsColumnDimensions.some((x) => x === s);
  }

  public static groupWithMeta = <T, K extends keyof any, M>(
    list: T[],
    getKey: (item: T) => K,
    getMeta: (item: T) => M
  ) =>
    list.reduce((previous, current) => {
      const group = getKey(current);
      if (!previous[group]) {
        previous[group] = { items: [] };
      }
      previous[group].meta = getMeta(current);
      previous[group].items.push(current);
      return previous;
    }, {} as Record<K, IMetaGroup<T, M>>);

  public static sortCompareAnchorTotals(
    obj1: string | number | IStatTestingCell,
    obj2: string | number | IStatTestingCell,
    sortDirection: 'asc' | 'desc' | 'none',
    data1: any,
    data2: any
  ): number {
    const isObj1StatCell = (obj1 as IStatTestingCell).kind !== undefined;
    const isObj2StatCell = (obj2 as IStatTestingCell).kind !== undefined;

    const compareObj1 = isObj1StatCell
      ? (obj1 as IStatTestingCell).kind === 'ref'
        ? ('' as string)
        : (obj1 as IStatTestingCell).value
      : obj1;
    const compareObj2 = isObj2StatCell
      ? (obj2 as IStatTestingCell).kind === 'ref'
        ? ''
        : (obj2 as IStatTestingCell).value
      : obj2;

    const obj1Value = compareObj1?.toLocaleString().toLocaleLowerCase() ?? '';
    const obj2Value = compareObj2?.toLocaleString().toLocaleLowerCase() ?? '';
    const data1Source = data1.length > 0 ? '' : data1.rbd_1__s ?? '' ?? '';
    const data2Source = data2.length > 0 ? '' : data2.rbd_1__s ?? '' ?? '';

    if (
      obj1Value === '' ||
      [
        ...DataUtility.bannerSummaryDimensions,
        ...DataUtility.tableSummaryDimensions,
      ].some((x) => x === data1Source)
    ) {
      return 0;
    }

    if (
      obj2Value === '' ||
      [
        ...DataUtility.bannerSummaryDimensions,
        ...DataUtility.tableSummaryDimensions,
      ].some((x) => x === data2Source)
    ) {
      return 0;
    }

    if (sortDirection === 'none') {
      return 0;
    }

    if (typeof compareObj1 === 'number' && typeof compareObj2 === 'number') {
      return sortDirection === 'desc'
        ? compareObj2 - compareObj1
        : compareObj1 - compareObj2;
    }

    return sortDirection === 'desc'
      ? obj2Value.localeCompare(obj1Value)
      : obj1Value.localeCompare(obj2Value);
  }

  public static generateGUID(): string {
    return uuid.v4();
  }

  public static findFilterItem(
    config: IDataFilter,
    name: string,
    uiArea: string
  ): { ix: number; item?: IDataFilterItem } {
    if (!config.items?.length) {
      return { ix: -1 };
    }

    const foundIx = (config.items ?? []).findIndex(
      (x) => x.name === name && x.ui_area === uiArea && x.enabled
    );

    return {
      ix: foundIx,
      item: foundIx >= 0 ? config.items[foundIx] : undefined,
    };
  }
}
