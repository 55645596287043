/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TranslateService } from '@ngx-translate/core';
import { IDimension } from '../../../../models/data/request/IDimension';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import {
  ActionKind,
  ActionKindVariant,
  ActionStructureGridKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';
import { TableViewService } from '../../../../services/table-view.service';

export class QuestionDimensionProvider implements IDimensionProvider {
  public static CODE = 'questions';
  instructions = 'builder.questionTabInstructions';

  constructor(
    private translate: TranslateService,
    private tableViewService: TableViewService
  ) {
    this.name = this.translate.instant('builder.dimensionQuestion');
  }

  code: string = QuestionDimensionProvider.CODE;
  name: string;
  dimensions: Array<IBuilderDimension> = [];
  loading: boolean = false;
  loaded: boolean = false;
  enabled: boolean = false;
  error?: string;

  initialize(
    context: IBuilderContext,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder: IBuilder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    config: ICrosstabConfigUI
  ): boolean {
    let has_secondary_values: boolean = false;
    let has_monadic: boolean = false;
    let net_not_allowed = true;
    let filter_not_allowed = true;
    let secondary_net_not_allowed = true;
    let secondary_filter_not_allowed = true;

    if (context.action) {
      has_monadic = context.action.has_monadic;
      switch (context.action.action_kind) {
        case ActionKind.multiplechoice:
        case ActionKind.turf:
        case ActionKind.auto_assign:
          net_not_allowed = false;
          filter_not_allowed = false;
          break;
        case ActionKind.maxdiff:
          has_secondary_values = true;
          filter_not_allowed = false;
          secondary_filter_not_allowed = false;
          secondary_net_not_allowed = false;
          break;
        case ActionKind.grid:
          has_secondary_values = true;
          filter_not_allowed = false;
          secondary_filter_not_allowed = false;
          secondary_net_not_allowed =
            context.action.grid?.grid_kind === ActionStructureGridKind.open;
          break;
        default:
          break;
      }

      switch (context.action.action_kind_variant) {
        case ActionKindVariant.grid_rank:
        case ActionKindVariant.grid_scale:
        case ActionKindVariant.grid_open:
          has_secondary_values = true;
          filter_not_allowed = false;
          secondary_filter_not_allowed = false;
          secondary_net_not_allowed =
            context.action.action_kind_variant === ActionKindVariant.grid_open;
          break;
        default:
          break;
      }
    }

    const data: Array<IDimension> = [
      {
        type: 'field',
        label: this.translate.instant(
          this.tableViewService.getLabelForPrimaryResponse(context.action)
        ),
        field: {
          source: 'answer',
          field: 'action_setting_id_root',
          action_id: 'primary',
        },
        net_not_allowed: net_not_allowed,
        filter_not_allowed: filter_not_allowed,
      },
    ];

    if (has_secondary_values) {
      data.push({
        type: 'field',
        label: this.translate.instant(
          this.tableViewService.getLabelForPrimarySelection(context.action)
        ),
        field: {
          source: 'answer',
          field: 'action_setting_id_secondary_root',
          action_id: 'primary',
        },
        net_not_allowed: secondary_net_not_allowed,
        filter_not_allowed: secondary_filter_not_allowed,
      });
    }

    if (
      has_monadic ||
      context.mission?.mission_kind === MissionKind.splittesting ||
      (context.mission?.mission_kind === MissionKind.external_cint &&
        context.mission.monadic_enabled)
    ) {
      data.push({
        type: 'field',
        label: this.translate.instant('builder.fieldAnswerMonadicConcept'),
        field: {
          source: 'answer',
          field: 'monadic_variant_id_root',
          action_id: 'primary',
        },
        net_not_allowed: true,
        filter_not_allowed: true,
      });
    }

    this.loaded = true;
    this.dimensions = data;
    return true;
  }
}
