import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plainText',
})
export class PlainTextPipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined || value === null) {
      return '';
    }

    return value
      .replace(/\\\*/g, '--asterisk--')
      .replace(/\\`/g, '--backtick--')
      .replace(/\*{1,3}/gi, '') // remove all non-escaped asterisks
      .replace(/`/gi, '') // remove all non-escaped backticks
      .replace(/--asterisk--/g, '*')
      .replace(/--backtick--/g, '`')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .trim()
      .replace(/&nbsp;/gs, '\u00A0');
  }
}
