import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Md5 } from 'ts-md5';
import { ICrosstabConfig } from '../models/data/request/ICrosstabConfig';
import { IEngineSettings } from '../models/data/request/IEngineSettings';
import { IQualitySetting } from '../models/data/request/IQualitySetting';
import { ITableViewValidation } from '../models/data/request/ITableViewValidation';
import { ICrosstabData } from '../models/data/response/ICrosstabData';
import { ICrosstabDataSet } from '../models/data/response/ICrosstabDataSet';
import { ICrosstabDimension } from '../models/data/response/ICrosstabDimension';
import { ICrosstabResult } from '../models/data/response/IItemResult';
import { DataUtility } from '../utilities/data-utility';

@Injectable()
export class CrosstabDataService {
  authToken = '';
  apiBaseUrl = '';
  apiValidationUrl = '';
  apiTokenUrl = '';
  preparationEnabled?: boolean;
  qualityEnabled?: boolean;
  outputCacheEnabled?: boolean;

  constructor(private http: HttpClient) {}

  initialize(
    baseURL: string,
    validationURL: string,
    tokenURL: string,
    preparationEnabled: boolean,
    qualityEnabled: boolean
  ): CrosstabDataService {
    this.apiBaseUrl = baseURL;
    this.apiValidationUrl = validationURL;
    this.apiTokenUrl = tokenURL;
    this.preparationEnabled = preparationEnabled;
    this.qualityEnabled = qualityEnabled;
    return this;
  }

  preflightCrosstab(
    config: ICrosstabConfig,
    actionIdsToRefresh?: string[],
    qualitySettings?: IQualitySetting
  ): Observable<any> {
    /* const newConfiguration: ICrosstabConfig = { ...config };
    if (newConfiguration.engine_settings && qualitySettings) {
      newConfiguration.engine_settings.quality = qualitySettings;
    } */

    const newConfiguration: ICrosstabConfig = { ...config };

    newConfiguration.engine_settings = this.configureEngineSettings(
      {
        preparations: this.preparationEnabled,
        quality: this.qualityEnabled,
        de_output_cache_enabled: this.outputCacheEnabled,
      },
      actionIdsToRefresh,
      qualitySettings
    );

    if (newConfiguration.engine_settings['preparations']) {
      delete newConfiguration.engine_settings['preparations'];
    }

    if (Object.keys(newConfiguration.engine_settings).length === 0) {
      delete newConfiguration.engine_settings;
    }

    return this.http.post(
      `${this.apiBaseUrl}/crosstab/preflight`,
      newConfiguration,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authToken}`,
        },
      }
    );
  }

  public fetchData(
    config: ICrosstabConfig,
    tableViewId: string,
    allowStale = false,
    actionIdsToRefresh?: string[],
    qSettings?: any
  ): Observable<ICrosstabData | undefined> {
    const tmpConfig = <ICrosstabConfig>JSON.parse(JSON.stringify(config));
    if (
      tmpConfig.orchestration?.custom_age_group?.age_group_classification_name
    ) {
      delete tmpConfig.orchestration.custom_age_group
        ?.age_group_classification_name;
    }

    // Engine Settings
    tmpConfig.engine_settings = this.configureEngineSettings(
      {
        preparations: this.preparationEnabled,
        quality: this.qualityEnabled,
        de_output_cache_enabled: this.outputCacheEnabled,
      },
      actionIdsToRefresh,
      qSettings
    );

    if (tmpConfig.engine_settings['preparations']) {
      delete tmpConfig.engine_settings['preparations'];
    }

    if (Object.keys(tmpConfig.engine_settings).length === 0) {
      delete tmpConfig.engine_settings;
    }

    const err = (msg: string): Error => {
      const newErr = new Error(msg);
      newErr.name = '';
      return newErr;
    };
    return this.http
      .post<ICrosstabResult>(
        `${this.apiBaseUrl}/data/${config.brand_id}/dataview/${tableViewId}/${allowStale}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      )
      .pipe(
        catchError(() => {
          return throwError(() => err('ServerError'));
        }),
        map((result) => {
          const { success, item, error_code, message, preparation, meta } =
            result;

          if (success) {
            if (meta) {
              return meta;
            }

            const statTestingCIDLabel = this.getStatTestingIntervalLabel(
              (result as ICrosstabResult).config
            );
            const unpackedData = this.unpackData(
              item as ICrosstabData,
              tmpConfig,
              statTestingCIDLabel
            );
            unpackedData.preparation = preparation;
            return unpackedData;
          }

          if (error_code) {
            throw err(`${error_code}`);
          }
          throw err(message ?? 'Error');
        })
      );
  }

  configureEngineSettings(
    settings: any,
    actionIdsToRefresh: string[] | undefined,
    qSettings: any
  ): IEngineSettings {
    const { preparations, ...engineSettings } = settings;

    if (settings.preparations) {
      engineSettings['preparations_async'] = true;
      engineSettings['preparations_disabled'] = false;
      engineSettings['preparations_force_refresh'] = actionIdsToRefresh ?? [];
    }

    if (settings.quality) {
      engineSettings['quality'] = {
        ...qSettings,
      };
    }

    engineSettings['output_cache_disabled'] = !settings.de_output_cache_enabled;
    delete engineSettings.de_output_cache_enabled;

    return engineSettings;
  }

  public getAuthToken(key: string, secret: string): Observable<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const signature = Md5.hashStr(key! + secret! + ((Date.now() / 1000) | 0));
    return this.http
      .get(this.apiTokenUrl, {
        headers: {
          'x-api-key': key,
          'x-api-secret': secret,
          'x-api-signature': signature,
        },
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((resp: any) => {
          return resp?.token ?? '';
        })
      );
  }

  public generateToken(key: string, secret: string): void {
    this.getAuthToken(key, secret).subscribe((token: string) => {
      this.authToken = token ?? '';
    });
  }

  unpackData(
    data: ICrosstabData,
    config: ICrosstabConfig,
    statTestingCID?: string
  ): ICrosstabData {
    this.unpackDataset(data.names, data.primary, config);

    if (data.additions) {
      for (const addition in data.additions) {
        if (Object.prototype.hasOwnProperty.call(data.additions, addition)) {
          const dataset = data.additions[addition];
          this.unpackDataset(data.names, dataset, config, statTestingCID);
        }
      }
    }

    return data;
  }

  unpackDataset(
    names: { [key: string]: string },
    dataset: ICrosstabDataSet,
    config: ICrosstabConfig,
    statTestingCID?: string
  ): void {
    if (dataset) {
      for (const column of dataset.columns) {
        for (const item of column) {
          this.unpackDimension(
            names,
            dataset.map.c,
            item,
            dataset.columns,
            config
          );
        }
      }

      for (const row of dataset.rows) {
        for (const item of row) {
          this.unpackDimension(
            names,
            dataset.map.r,
            item,
            dataset.rows,
            config
          );
          if (statTestingCID && item.s === 'stat_test_column' && item.i) {
            item.name = statTestingCID;
          }
        }
      }
    }
  }

  unpackDimension(
    names: { [key: string]: string },
    map: { [key: string]: string },
    dimension: ICrosstabDimension,
    dimensions: ICrosstabDimension[][],
    config: ICrosstabConfig
  ): void {
    if (!dimension?.b || !dimension?.n || !dimension.k || !map) {
      return;
    }

    if (dimension.id || dimension.bannerId) {
      return;
    }

    if (map[dimension.k] !== undefined) {
      dimension.id = map[dimension.k];
      dimension.name = names[dimension.id];

      if (
        !dimension.s ||
        DataUtility.isTableSummaryDim(dimension.s) ||
        DataUtility.isTotalsColumnDim(dimension.s)
      ) {
        if (map[dimension.n]) {
          dimension.bannerLabel = names[dimension.n] ?? map[dimension.n];
          dimension.bannerId = dimension.b;
        }
        if (map[dimension.b]) {
          dimension.bannerId = map[dimension.b];
        }
      }

      if (dimension.s && DataUtility.isBannerSummaryDim(dimension.s)) {
        const dimensionIx = dimensions.findIndex((items) => {
          return items.some(
            (dim) => dim.b === dimension.b && dim.id === dimension.id
          );
        });

        if (dimensionIx < 0) {
          return;
        }

        let isAtEndOfBanner =
          DataUtility.isAnchoredAtEndSummaryDim(dimension.s) ||
          dimensionIx + 1 === dimensions.length;
        let bannerDataDimStartIx = -1;
        if (DataUtility.isFlexOrderedSummaryDim(dimension.s)) {
          const dimConfig = (config.expansions ?? []).find(
            (x) => x.name === dimension.s
          );
          isAtEndOfBanner = !dimConfig?.start;
          if (!isAtEndOfBanner) {
            let rachedToADataDim = false;

            for (
              let loop = dimensionIx + 1;
              loop < dimensions.length && !rachedToADataDim;
              loop++
            ) {
              if (!dimensions[loop][0].s) {
                rachedToADataDim = true;
                bannerDataDimStartIx = loop;
                continue;
              }
            }
          }
        }

        let nonSummaryDimensionIx = -1;
        if (isAtEndOfBanner) {
          for (
            let loopIx = dimensionIx;
            loopIx >= 0 && nonSummaryDimensionIx < 0;
            loopIx--
          ) {
            if (
              dimensions[loopIx]?.length &&
              dimensions[loopIx][0].i === 'd' &&
              !dimensions[loopIx][0].s
            ) {
              nonSummaryDimensionIx = loopIx;
            }
          }
        } else {
          nonSummaryDimensionIx = bannerDataDimStartIx;
        }

        if (nonSummaryDimensionIx >= 0) {
          const bannerDim = dimensions[nonSummaryDimensionIx][0];
          if (bannerDim && bannerDim.b && map[bannerDim.b]) {
            dimension.bannerId = map[bannerDim.b];
          }

          if (bannerDim && bannerDim.n && map[bannerDim.n]) {
            dimension.bannerLabel = names[bannerDim.n] ?? map[bannerDim.n];
          }
        }
      }
    }
  }

  public postTableValiadtion(
    validation: ITableViewValidation
  ): Observable<ICrosstabResult> {
    return new Observable<ICrosstabResult>((observer) => {
      this.http
        .post(`${this.apiValidationUrl}/store_validation_data`, validation, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`,
          },
          responseType: 'text',
        })
        .subscribe({
          next: (message) => {
            observer.next({ success: true, message } as ICrosstabResult);
          },
          error: (error) => {
            observer.next({
              success: false,
              message: error?.error ?? 'Failed to save the validation!',
            } as ICrosstabResult);
          },
        });
    });
  }

  private getStatTestingIntervalLabel(config?: ICrosstabConfig): string {
    if (!config) {
      return '';
    }
    const hasStatTestAddition = config.additions?.some(
      (x) => x.name === 'stat_test_column'
    );
    let statTestRowKey = '';
    if (hasStatTestAddition) {
      const ref = config.additions?.find(
        (x) => x.name === 'stat_test_column'
      ) ?? { param_1: '', param_2: '' };
      const statTestIntervals = new Array<number>();
      if (ref.param_1 && !isNaN(parseInt(ref.param_1))) {
        statTestIntervals.push(parseInt(ref.param_1));
      }
      if (ref.param_2 && !isNaN(parseInt(ref.param_2))) {
        statTestIntervals.push(parseInt(ref.param_2));
      }

      if (statTestIntervals.length) {
        if (statTestIntervals.length === 1) {
          statTestRowKey = `a = ${statTestIntervals[0]}`;
        } else {
          statTestIntervals.sort((a, b) => b - a);
          statTestRowKey = `(${statTestIntervals
            .map((x, ix) => `${ix === 0 ? 'A' : 'a'} = ${x}`)
            .join('; ')})`;
        }
      }
    }
    return statTestRowKey;
  }
}
