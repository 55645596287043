import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export interface AgeRangeRowControl {
  ageGroupId: FormControl<string | null>;
  ageGroupName: FormControl<string | null>;
  minAge: FormControl<string | null>;
  maxAge: FormControl<string | null>;
}

export function ageRangeValidator(ageMin: number, ageMax: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const group = <FormGroup<AgeRangeRowControl | null>>control;
    if (!group) {
      return null;
    }

    const rangeMinAge = group.controls?.minAge;
    const rangeMaxAge = group.controls?.maxAge;

    if (rangeMinAge?.hasError('required') || rangeMinAge?.hasError('pattern')) {
      return null;
    }

    if (rangeMaxAge?.hasError('required') || rangeMaxAge?.hasError('pattern')) {
      return null;
    }

    const rowMinAge = parseInt(group.controls?.minAge.value ?? '');
    const rowMaxAge = parseInt(group.controls?.maxAge.value ?? '');
    const ageGroupId = group.controls?.ageGroupId.value ?? '';
    if (isNaN(rowMinAge) || isNaN(rowMaxAge)) {
      return null;
    }

    if (
      rowMinAge < ageMin ||
      rowMinAge > ageMax ||
      rowMaxAge < ageMin ||
      rowMaxAge > ageMax
    ) {
      return { rangeMinMax: true };
    }

    if (rowMinAge >= rowMaxAge) {
      return { rangeInvalid: true };
    }

    const rangesArray = <FormArray<FormGroup<AgeRangeRowControl | null>>>(
      group.parent
    );
    if (!rangesArray) {
      return null;
    }
    const isOverlappingRow = checkIsOverlappingRow(rangesArray, {
      ageGroupId,
      rowMinAge,
      rowMaxAge,
    });
    if (isOverlappingRow) {
      return { rangeOverlap: true };
    }

    return null;
  };
}

function checkIsOverlappingRow(
  rangesArray: FormArray<FormGroup<AgeRangeRowControl | null>>,
  sourceRange: {
    ageGroupId: string;
    rowMinAge: number;
    rowMaxAge: number;
  }
): boolean {
  let isOverlappingRow = false;

  rangesArray.controls.forEach((item) => {
    if (isOverlappingRow) {
      return;
    }
    const loopAgeGroupId = item.controls?.ageGroupId.value ?? '';
    if (loopAgeGroupId === sourceRange.ageGroupId) {
      return;
    }

    const loopMinAge = parseInt(item.controls?.minAge.value ?? '');
    const loopMaxAge = parseInt(item.controls?.maxAge.value ?? '');
    if (isNaN(loopMinAge) || isNaN(loopMaxAge)) {
      return;
    }

    if (
      (sourceRange.rowMinAge <= loopMinAge &&
        sourceRange.rowMaxAge >= loopMinAge) ||
      (sourceRange.rowMinAge <= loopMaxAge &&
        sourceRange.rowMaxAge >= loopMaxAge)
    ) {
      isOverlappingRow = true;
      return;
    }

    if (
      (loopMinAge <= sourceRange.rowMinAge &&
        loopMaxAge >= sourceRange.rowMinAge) ||
      (loopMinAge <= sourceRange.rowMaxAge &&
        loopMaxAge >= sourceRange.rowMaxAge)
    ) {
      isOverlappingRow = true;
      return;
    }
  });

  return isOverlappingRow;
}
