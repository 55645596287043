import { TranslateService } from '@ngx-translate/core';
import { IDimensionProvider } from '../i-dimension-provider';
import { SuzyDataService } from '../../../../services/suzy-data.service';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilder } from '../../i-builder';
import { MissionKind } from '@asksuzy/typescript-sdk';

export class ExternalAttributesDimensionProvider implements IDimensionProvider {
  constructor(
    private translate: TranslateService,
    private suzy: SuzyDataService
  ) {
    this.name = this.translate.instant('builder.dimensionExternalAttributes');
  }

  code = 'externalattributes';
  name: string;
  instructions = 'builder.instructionsExternalAttributes';
  noDimensionMessage = 'builder.noExternalAttributesForMission';
  instructionsLink = 'builder.manageDataExternalAttributes';
  instructionsLinkUrl?: string | undefined;
  dimensions: Array<IBuilderDimension> = [];
  loading = false;
  loaded = false;
  enabled = false;
  error?: string;
  brand_id?: string;
  mission_id?: string;

  initialize(
    context: IBuilderContext,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder: IBuilder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    config: ICrosstabConfigUI
  ): boolean {
    if (context.mission?.mission_kind !== MissionKind.external_link) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }

    if (this.brand_id != context.brand_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    if (this.mission_id != context.mission?.mission_id) {
      this.dimensions = [];
      this.loaded = false;
    }

    this.brand_id = context.brand_id;
    this.mission_id = context.mission?.mission_id;
    this.instructionsLinkUrl = context.manageDataUrl;
    this.error = undefined;

    if (!this.loaded && this.brand_id && this.mission_id && context.mission) {
      const { brand_id, mission } = context;
      this.loading = true;
      this.suzy
        .getPublicLinkAttributes(brand_id, mission?.mission_id ?? '')
        .subscribe({
          next: (data) => {
            this.loading = false;
            const newDimensions = data.map((item) => {
              return {
                type: 'field',
                label: item.name,
                field: {
                  source: 'profile',
                  field: 'brand_profile_question_id',
                  identifier: item.id,
                  action_id: 'primary',
                },
              } as IBuilderDimension;
            });
            this.dimensions.push(...newDimensions);
            builder.dragdrop?.prepareNewItems(this.code, this.dimensions);
            this.loaded = true;
          },
          error: (error) => {
            this.loading = false;
            this.loaded = false;
            this.error = error;
          },
        });
    }
    return true;
  }
}
