<igx-checkbox [ngModel]="included" (ngModelChange)="onSelectionChange($event)"
  >{{
    'builder.' + (kind === 'top' ? 'includeTopN' : 'includeBottomN') | translate
  }}
</igx-checkbox>

<div class="config-container" *ngIf="included && expansion">
  <span class="label">{{ 'builder.boxType' | translate }}</span>

  <igx-select
    [(ngModel)]="expansion.param_1"
    (selectionChanging)="onBoxTypeChanged($event)"
  >
    <igx-select-item [value]="'1'">
      {{ 'calculations.' + kind + 'N' | translate: { param: '1' } }}
    </igx-select-item>
    <igx-select-item [value]="'2'">
      {{ 'calculations.' + kind + 'N' | translate: { param: '2' } }}
    </igx-select-item>
    <igx-select-item [value]="'3'">
      {{ 'calculations.' + kind + 'N' | translate: { param: '3' } }}
    </igx-select-item>
  </igx-select>

  <span class="label">{{ 'builder.topBottomLabel' | translate }}</span>

  <igx-input-group>
    <input
      igxInput
      name="topbottom_label"
      type="text"
      [(ngModel)]="expansion.label"
    />
  </igx-input-group>

  <span class="label">{{ 'builder.position' | translate }}</span>
  <div class="start-box">
    <igx-buttongroup
      [multiSelection]="false"
      (selected)="onTopBottomSelected($event)"
      [values]="expansionData"
    >
    </igx-buttongroup>
  </div>
</div>
