import { Component, Input } from '@angular/core';

@Component({
  selector: 'action-dropdown-item',
  templateUrl: './action-dropdown-item.component.html',
  styleUrls: ['./action-dropdown-item.component.scss'],
})
export class ActionDropdownItemComponent {
  @Input() item!: any;
}
