/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TranslateService } from '@ngx-translate/core';
import { IDimension } from '../../../../models/data/request/IDimension';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';
import { MissionKind } from '@asksuzy/typescript-sdk';

export class DemographicDimensionProvider implements IDimensionProvider {
  code: string = 'demographics-standard';
  name: string;
  dimensions: Array<IBuilderDimension> = [];
  loading: boolean = false;
  loaded: boolean = false;
  enabled: boolean = false;
  error?: string;
  instructions = 'builder.demographicsTabInstructions';

  constructor(private translate: TranslateService) {
    this.name = this.translate.instant('builder.dimensionDemographicsStandard');
  }

  public static DIMENSIONS(): Array<IDimension> {
    return [
      {
        type: 'field',
        label: 'Ethnicity',
        field: {
          source: 'demographic',
          field: 'ethnicity_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Gender',
        field: {
          source: 'demographic',
          field: 'gender',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Relationship Status',
        field: {
          source: 'demographic',
          field: 'relationship_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'State',
        field: {
          source: 'demographic',
          field: 'state_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Age Group',
        field: {
          source: 'demographic',
          field: 'age_group_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Education Level',
        field: {
          source: 'demographic',
          field: 'education_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Employment Status',
        field: {
          source: 'demographic',
          field: 'employment_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Household Size',
        field: {
          source: 'demographic',
          field: 'household_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Income Level',
        field: {
          source: 'demographic',
          field: 'income_level_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Parenting Status',
        field: {
          source: 'demographic',
          field: 'parenting_id',
          action_id: 'primary',
        },
      },
      {
        type: 'field',
        label: 'Region',
        field: {
          source: 'demographic',
          field: 'region_id',
          action_id: 'primary',
        },
      },
    ];
  }

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (
      context.global ||
      context.mission?.mission_kind === MissionKind.external_link
    ) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }

    const data = DemographicDimensionProvider.DIMENSIONS();
    this.loaded = true;
    this.dimensions = data;
    return true;
  }
}
