<igx-checkbox
  [disabled]="optionsDisabled || (maxOptionsSelected && !statTesting_95)"
  [checked]="statTesting_95"
  value="statTesting_95"
  (change)="onCheckboxChange($event)"
  >{{ 'builder.statTesting95' | translate }}</igx-checkbox
>
<igx-checkbox
  [disabled]="optionsDisabled || (maxOptionsSelected && !statTesting_90)"
  [checked]="statTesting_90"
  value="statTesting_90"
  (change)="onCheckboxChange($event)"
  >{{ 'builder.statTesting90' | translate }}</igx-checkbox
>
<igx-checkbox
  [disabled]="optionsDisabled || (maxOptionsSelected && !statTesting_85)"
  [checked]="statTesting_85"
  value="statTesting_85"
  (change)="onCheckboxChange($event)"
  >{{ 'builder.statTesting85' | translate }}</igx-checkbox
>
<igx-checkbox
  [disabled]="optionsDisabled || (maxOptionsSelected && !statTesting_80)"
  [checked]="statTesting_80"
  value="statTesting_80"
  (change)="onCheckboxChange($event)"
  >{{ 'builder.statTesting80' | translate }}</igx-checkbox
>
<div class="disabled-overlay" *ngIf="optionsDisabled || maxOptionsSelected">
  <div
    class="disabled-overlay-item"
    *ngFor="let t of disabledTooltipItems"
    [igxTooltipTarget]="disabledTooltip"
    (tooltipShow)="
      disabledTooltip.context = {
        show: optionsDisabled || maxOptionsSelected,
        tooltip: optionsDisabled
          ? optionsDisabledTooltip
          : maxOptionsSelectedTooltip
      }
    "
  ></div>
</div>

<div #disabledTooltip="tooltip" igxTooltip class="toolbar-tooltip">
  <span *ngIf="disabledTooltip.context?.show">{{
    disabledTooltip.context.tooltip | translate
  }}</span>
</div>
