import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { IBuilderContext } from '../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { TableViewService } from '../../../../services/table-view.service';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFiltersTag,
} from '../../../../models/ui/i-advanced-filters-config.model';
import { MeanScoreComponent } from '../../providers/calculations/mean-score/mean-score.component';
import { ICrosstabConfig } from '../../../../models/data/request/ICrosstabConfig';
import { ICalculations } from '../../../../models/ui/i-calculations-ui.model';
import { ScaleRankTopBottomComponent } from '../../providers/calculations/scale-rank-top-bottom/scale-rank-top-bottom.component';
import { StatTestingComponent } from '../../providers/calculations/stat-testing/stat-testing.component';
import { ColumnTotalComponent } from '../../providers/calculations/column-total/column-total.component';
import { SpamResponsesComponent } from '../../providers/calculations/spam-trimmed-responses/spam-responses.component';

@Component({
  selector: 'app-calculations-editor',
  templateUrl: './calculations-editor.component.html',
  styleUrls: ['./calculations-editor.component.scss'],
})
export class CalculationsEditorComponent implements OnInit {
  hasCalculations!: boolean;
  statTestingEnabled!: boolean;
  summaryMetricsEnabled!: boolean;
  advancedEnabled!: boolean;
  calculationsStatus!: ICalculations;
  calculationsTags!: IAdvancedFiltersTag[];
  tagKind = EAdvancedFiltersTagKind;

  @Input()
  public dialog: IgxDialogComponent | undefined;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() configChange = new EventEmitter<ICrosstabConfigUI>();

  @ViewChild('mean_score', {
    read: MeanScoreComponent,
    static: false,
  })
  private mean_score!: MeanScoreComponent;

  @ViewChild('scale_rank_top', {
    read: ScaleRankTopBottomComponent,
    static: false,
  })
  private scale_rank_top!: ScaleRankTopBottomComponent;

  @ViewChild('scale_rank_bottom', {
    read: ScaleRankTopBottomComponent,
    static: false,
  })
  private scale_rank_bottom!: ScaleRankTopBottomComponent;

  @ViewChild('stat_testing', {
    read: StatTestingComponent,
    static: false,
  })
  private stat_testing!: StatTestingComponent;

  @ViewChild('column_total_responses', {
    read: ColumnTotalComponent,
    static: false,
  })
  private column_total_responses!: ColumnTotalComponent;

  @ViewChild('column_total_base', {
    read: ColumnTotalComponent,
    static: false,
  })
  private column_total_base!: ColumnTotalComponent;

  @ViewChild('row_total_responses', {
    read: ColumnTotalComponent,
    static: false,
  })
  private row_total_responses!: ColumnTotalComponent;

  @ViewChild('row_total_base', {
    read: ColumnTotalComponent,
    static: false,
  })
  private row_total_base!: ColumnTotalComponent;

  @ViewChild('include_spam_responses', {
    read: SpamResponsesComponent,
    static: false,
  })
  private include_spam_responses!: SpamResponsesComponent;

  constructor(private tableViewService: TableViewService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    this.statTestingEnabled = this.tableViewService.canCustomizeStatTesting(
      this.context.statTestingEnabled ?? false,
      this.context.action
    );

    this.summaryMetricsEnabled = this.tableViewService.canCustomizeCalculations(
      this.context.mission,
      this.context.action
    );

    this.advancedEnabled = this.context.advancedCalculationsEnabled ?? false;

    this.calculationsStatus =
      this.tableViewService.crosstabConfigHasCalculations(
        this.context.advancedCalculationsEnabled ?? false,
        this.crosstabConfig.crosstab
      );

    this.calculationsTags = this.tableViewService.getCalculationsTags(
      this.calculationsStatus
    );

    this.hasCalculations = this.checkHasCalculations(this.calculationsStatus);
  }

  onResetCalculations(): void {
    this.scale_rank_bottom?.reset();
    this.scale_rank_top?.reset();
    this.mean_score?.reset();
    this.stat_testing?.reset();
    this.column_total_responses?.reset();
    this.column_total_base?.reset();
    this.row_total_responses?.reset();
    this.row_total_base?.reset();
    this.include_spam_responses?.reset();
  }

  onCalculationsChanged(
    updatedConfig: ICrosstabConfig,
    changeKind:
      | 'means'
      | 'scaleRankTop'
      | 'scaleRankBottom'
      | 'statTesting'
      | 'columnTotalBase'
      | 'columnTotalResponses'
      | 'rowTotalBase'
      | 'rowTotalResponses'
      | 'spamResponses'
  ): void {
    const calculationsStatusForChange =
      this.tableViewService.crosstabConfigHasCalculations(
        this.context?.advancedCalculationsEnabled ?? false,
        updatedConfig
      );

    switch (changeKind) {
      case 'means':
        this.calculationsStatus.means = calculationsStatusForChange.means;
        break;
      case 'scaleRankTop':
        this.calculationsStatus.topN = calculationsStatusForChange.topN;
        break;
      case 'scaleRankBottom':
        this.calculationsStatus.bottomN = calculationsStatusForChange.bottomN;
        break;
      case 'statTesting':
        this.calculationsStatus.statTesting =
          calculationsStatusForChange.statTesting;
        break;
      case 'columnTotalBase':
        this.calculationsStatus.baseTotalColumn =
          calculationsStatusForChange.baseTotalColumn;
        break;
      case 'columnTotalResponses':
        this.calculationsStatus.responsesTotalColumn =
          calculationsStatusForChange.responsesTotalColumn;
        break;
      case 'rowTotalBase':
        this.calculationsStatus.baseTotalRow =
          calculationsStatusForChange.baseTotalRow;
        break;
      case 'rowTotalResponses':
        this.calculationsStatus.responsesTotalRow =
          calculationsStatusForChange.responsesTotalRow;
        break;
      case 'spamResponses':
        this.calculationsStatus.spamResponses =
          calculationsStatusForChange.spamResponses;
        break;
      default:
        break;
    }

    this.calculationsTags = this.tableViewService.getCalculationsTags(
      this.calculationsStatus
    );
    this.hasCalculations = this.checkHasCalculations(this.calculationsStatus);
    if (changeKind !== 'statTesting') {
      this.stat_testing?.onConfigChanged(updatedConfig);
    }
  }

  onClose(save?: boolean): void {
    if (!this.dialog || !this.context || !this.crosstabConfig) {
      return;
    }

    if (!save) {
      this.dialog.close();
      return;
    }

    this.scale_rank_bottom?.prepareSave(this.context, this.crosstabConfig);
    this.scale_rank_top?.prepareSave(this.context, this.crosstabConfig);
    this.mean_score?.prepareSave(this.context, this.crosstabConfig);
    this.stat_testing?.prepareSave(this.context, this.crosstabConfig);
    this.column_total_responses?.prepareSave(this.context, this.crosstabConfig);
    this.column_total_base?.prepareSave(this.context, this.crosstabConfig);
    this.row_total_responses?.prepareSave(this.context, this.crosstabConfig);
    this.row_total_base?.prepareSave(this.context, this.crosstabConfig);
    this.include_spam_responses?.prepareSave(this.context, this.crosstabConfig);

    this.configChange.emit(this.crosstabConfig);

    setTimeout(() => {
      this.onClose();
    }, 100);
  }

  private checkHasCalculations(calculations: ICalculations): boolean {
    return Object.keys(calculations).some((x) => calculations[x]?.included);
  }
}
