import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IBuilderContext } from '../../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IExpansion } from '../../../../../models/data/request/IExpansion';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { TranslateService } from '@ngx-translate/core';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import {
  IButtonGroupEventArgs,
  ISelectionEventArgs,
} from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-calculations-scale-rank-top-bottom',
  templateUrl: './scale-rank-top-bottom.component.html',
  styleUrls: ['./scale-rank-top-bottom.component.scss'],
})
export class ScaleRankTopBottomComponent implements OnInit, IAdvancedProvider {
  included!: boolean;
  expansion?: IExpansion;
  expansionData!: Array<{
    label: string;
    selected: boolean;
    togglable: boolean;
  }>;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Input()
  public kind!: 'top' | 'bottom';

  @Output() calculationsChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const foundTop = this.findExpansion(this.kind, this.crosstabConfig);
    this.expansion = foundTop.item;
    this.expansionData = [
      {
        label: this.translate.instant('builder.topBottomStart'),
        selected: this.expansion?.start ?? false,
        togglable: true,
      },
      {
        label: this.translate.instant('builder.topBottomEnd'),
        selected: !this.expansion?.start,
        togglable: true,
      },
    ];

    this.included = foundTop.ix >= 0;
  }

  onTopBottomSelected(event: IButtonGroupEventArgs): void {
    if (!this.expansion || !this.crosstabConfig) {
      return;
    }
    this.expansion.start = event.index === 0;
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  onSelectionChange(included: boolean): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.included = included;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
    setTimeout(() => {
      this.expansion = this.findExpansion(this.kind, this.crosstabConfig)?.item;
    }, 0);
  }

  onBoxTypeChanged(event: ISelectionEventArgs): void {
    setTimeout(() => {
      if (!this.expansion || !this.crosstabConfig) {
        return;
      }
      this.expansion.param_1 = event.newSelection.value;
      const defaultLabel = this.translate.instant(
        `calculations.${this.kind}n`,
        {
          '0': event.oldSelection.value,
        }
      );

      if (this.expansion.label === defaultLabel) {
        this.expansion.label = this.translate.instant(
          `calculations.${this.kind}n`,
          {
            '0': this.expansion.param_1,
          }
        );
      }

      this.calculationsChanged.emit(this.crosstabConfig.crosstab);
    });
  }

  reset(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.included = false;
    this.expansion = undefined;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.included) {
      const found = this.findExpansion(this.kind, crosstabConfig);
      if (found.ix >= 0) {
        crosstabConfig.crosstab.expansions?.splice(found.ix, 1);
      }
      return;
    }

    if (!this.expansion) {
      this.expansion = {
        name: `scale_rank.${this.kind}`,
        label: this.translate.instant(`calculations.${this.kind}n`, { '0': 2 }),
        start: false,
        param_1: '2',
      };
    }

    if ((this.expansion.label ?? '').trim() === '') {
      this.expansion.label = this.translate.instant(
        `calculations.${this.kind}n`,
        {
          '0': this.expansion.param_1 ?? '',
        }
      );
    }

    if (!crosstabConfig.crosstab.expansions?.length) {
      crosstabConfig.crosstab.expansions = [this.expansion];
    }

    const found = this.findExpansion(this.kind, crosstabConfig);
    if (found.item) {
      crosstabConfig.crosstab.expansions[found.ix] = { ...this.expansion };
    } else {
      crosstabConfig.crosstab.expansions.push(this.expansion);
    }
  }

  private findExpansion(
    kind: 'top' | 'bottom',
    crosstabConfig?: ICrosstabConfigUI
  ): {
    ix: number;
    item?: IExpansion;
  } {
    if (!crosstabConfig) {
      return { ix: -1 };
    }
    const ix = (crosstabConfig.crosstab.expansions ?? []).findIndex(
      (x) => x.name === `scale_rank.${kind}`
    );
    if (ix >= 0 && crosstabConfig.crosstab.expansions?.length) {
      return { ix, item: crosstabConfig.crosstab.expansions[ix] };
    }
    return { ix: -1 };
  }
}
