<form class="active-filters" [formGroup]="subpopulationsForm">
    <ng-container formArrayName="subpopulations">
        <div class="filter-box" *ngFor="let item of subpopulations.controls; let i = index;" [formGroupName]="i">
            <div class="filter-name">
                <span class="name">
                    {{ item.getRawValue()!.title }}
                </span>
                <button igxButton="icon" (click)="removeSubpopulation(i)">
                    <igx-icon family="suzy" name="delete_custom"></igx-icon>
                </button>
            </div>
            <div class="filter-actions">

            </div>
        </div>
    </ng-container>
</form>

<div class="field-selector">
    <ng-container *ngIf="selectVisible; else elseButton">
        <igx-select [(ngModel)]="selectedSubpopulationOption" (ngModelChange)="addOption($event)"
            placeholder="{{ 'filters.selectSubpopulation' | translate }}">
            <igx-select-item *ngFor="let option of subpopulationsData; trackBy: trackByFn" [value]="option">
                {{ option.name }}
            </igx-select-item>
        </igx-select>
    </ng-container>
    <ng-template #elseButton>
        <button igxButton="outlined" (click)="setVisibility(true)">
            <igx-icon fontSet="material">add</igx-icon>
            {{ 'filters.addSubpopulation' | translate }}
        </button>
    </ng-template>
</div>