import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'selected-mission-questions-list',
  templateUrl: './selected-mission-questions-list.component.html',
  styleUrls: ['./selected-mission-questions-list.component.scss'],
})
export class SelectedMissionQuestionsListComponent {
  @Input() actions: any[] = [];
  @Input() mission: any;
  @Input() loading = false;

  @Output() readonly actionSelected = new EventEmitter<any>();

  @Input() activeItem = '';


  selectAction(item: any) {
    this.activeItem = item.action_id;
    this.actionSelected.emit(item);
  }
}
