import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  exhaustMap,
  Observable,
  tap,
  timer
} from 'rxjs';
import { ILongPollingBeginResponse } from '../models/ui/long-polling.interface';
import { SignalRResponse } from '../models/ui/signalr-response';
import { CrosstabDataService } from './crosstab-data.service';

@Injectable()
export class LongPollingDataService {
  polledData = new BehaviorSubject<SignalRResponse | null>(null);
  polledData$ = this.polledData.asObservable();

  longPollingConfig = new BehaviorSubject<any | null>(null);
  longPollingConfig$ = this.longPollingConfig.asObservable();

  constructor(
    private http: HttpClient,
    private crosstab: CrosstabDataService
  ) {}

  getLongPollingConfiguration(
    brandId: string,
    screenId: string
  ): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/collector/${screenId}/begin`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  requestDataUpdates(
    configuration: ILongPollingBeginResponse
  ): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${configuration.organization_id}/collector/${configuration.signal_collector_id}/fetch`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }

  startPolling(configuration: ILongPollingBeginResponse): Observable<any> {
    return timer(0, configuration.interval_seconds * 1000).pipe(
      exhaustMap(() => this.requestDataUpdates(configuration)),
      tap((response: any) => this.polledData.next(response)),
      catchError((err: any) => {
        return err;
      })
    );
  }
}
