import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DataExplorerConfig,
  ExplorerComponent,
} from '@data-explorer-elements/data-explorer';
import { ISelectionEventArgs } from '@infragistics/igniteui-angular';
import { IAction, IMission } from '../../data';
import { AuthSessionService } from '../../services/auth-session.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SuzyDataService } from '../../services/suzy-data.service';
import { tap } from 'rxjs';

@Component({
  selector: 'data-explorer-elements-testing-container',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerUIComponent implements OnInit {
  showTestingSidebar = false;
  config!: DataExplorerConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brands!: any[];
  brandId!: string;
  selectedMission!: IMission;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedAction!: IAction | any;
  question!: string;
  builderVisible = false;
  isChartExpanded = false;

  @ViewChild('explorer', { static: false }) explorer!: ExplorerComponent;

  constructor(
    private authSessionService: AuthSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private suzyData: SuzyDataService
  ) {
    this.config = {
      suzyURL: environment.suzyUrl,
      dataExplorerAPI: environment.dataExplorerAPI,
      features: environment.features,
      segmentToken: environment.segmentToken,
    };
  }

  ngOnInit(): void {
    this.brands = this.authSessionService.brands;

    const routeBrandId = this.activatedRoute.snapshot.paramMap.get('brand_id');
    const routeMissionId =
      this.activatedRoute.snapshot.paramMap.get('mission_id');

    const savedBrandId = this.authSessionService.selectedBrandId;

    if (routeBrandId?.length && routeBrandId !== savedBrandId) {
      const routeBrand = this.brands.find((x) => x.brand_id === routeBrandId);
      if (routeBrand) {
        this.config.brandName = routeBrand.name;
        this.authSessionService.setSelectedBrand(routeBrand.brand_id);
        this.brandId = routeBrand.brand_id;
        if (this.config.features.publicLinkManageDataEnabled) {
          this.config.features.manageDataUrl = `${environment.features.manageDataUrl}brand/${this.brandId}/manage-data`;
        }
        return;
      }
    }
    this.brandId = savedBrandId;
    this.config.brandName =
      this.brands.find((x) => x.brand_id === this.brandId)?.name ?? '';
    if (this.config.features.publicLinkManageDataEnabled) {
      this.config.features.manageDataUrl = `${environment.features.manageDataUrl}brand/${this.brandId}/manage-data`;
    }
    if (this.config.brandName && this.brandId !== routeBrandId) {
      this.router.navigate(
        ['/', 'brand', savedBrandId, 'insights', 'data-explorer'],
        {
          // relativeTo: this.activatedRoute,
          /*  queryParams: {
          b: savedBrandId,
        }, */
          // queryParamsHandling: 'merge',
        }
      );
    }

    if (routeMissionId?.length) {
      this.suzyData
        .getMission(this.brandId, routeMissionId)
        .pipe(tap((mission) => (this.selectedMission = mission)))
        .subscribe();
    }
  }

  showTestingSide(): void {
    this.showTestingSidebar = !this.showTestingSidebar;
  }

  onLogoutClick(): void {
    this.authSessionService.logout(true);
  }

  onBrandSelected(event: ISelectionEventArgs): void {
    this.config.brandName =
      this.brands.find((x) => x.brand_id === event.newSelection.value)?.name ??
      '';
    this.authSessionService.setSelectedBrand(event.newSelection.value);
    if (this.config.features.publicLinkManageDataEnabled) {
      this.config.features.manageDataUrl = `${environment.features.manageDataUrl}brand/${this.brandId}/manage-data`;
    }
    this.router.navigate(
      ['/', 'brand', event.newSelection.value, 'insights', 'data-explorer'],
      {
        // relativeTo: this.activatedRoute,
        /* queryParams: {
        b: event.newSelection.value,
      }, */
      }
    );
  }

  onMissionSelected(event: IMission): void {
    this.selectedMission = event;
  }

  onActionSelected(event: IAction): void {
    this.selectedAction = event;
    this.question = event?.search_text ? event.search_text : '';
  }

  onBuilderToggle(visible: boolean): void {
    this.builderVisible = visible;
  }

  onExpandChart(expand: boolean): void {
    this.isChartExpanded = expand;
  }

  onDownloadAsPPT(): void {
    if (!this.explorer) {
      return;
    }
    this.explorer.onDownloadSurvey('ppt');
  }

  onDownloadAsExcel(): void {
    if (!this.explorer) {
      return;
    }
    this.explorer.onDownloadSurvey('excel');
  }

  onManageTableViews(): void {
    if (
      !this.brandId ||
      !this.selectedMission?.mission_id ||
      !this.selectedAction?.action_id
    ) {
      return;
    }
    const url = `${environment.features.manageDataUrl}brand/${this.brandId}/manage-data/table-views/${this.selectedMission.mission_id}/action/${this.selectedAction.action_id}`;
    window.open(url, '_blank');
  }

  onExportSurveyClick(exportKind: string): void {
    if (!this.explorer) {
      return;
    }

    if (exportKind === 'ppt') {
      this.onDownloadAsPPT();
      return;
    }

    this.onDownloadAsExcel();
  }
}
