/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IBuilderContext } from '../../../i-builder-context';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { TranslateService } from '@ngx-translate/core';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { DataUtility } from '../../../../../utilities/data-utility';

@Component({
  selector: 'app-calculations-spam-responses',
  templateUrl: './spam-responses.component.html',
  styleUrls: ['./spam-responses.component.scss'],
})
export class SpamResponsesComponent implements OnInit, IAdvancedProvider {
  private readonly name: string = EDataFilterNames.includeSpam;
  private readonly uiArea: string = EDataFilterUIAreas.customize;

  public isIncluded!: boolean;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() calculationsChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const found = this.findInFilter(this.crosstabConfig);
    this.isIncluded =
      (found.item?.enabled ?? false) && (found.item?.bool_1 ?? false);
  }

  onSelectionChange(value: boolean): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.isIncluded = value;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  reset(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.isIncluded = false;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.isIncluded) {
      const found = this.findInFilter(crosstabConfig);
      if (found.item) {
        found.item.bool_1 = false;
      }
      return;
    }

    const filterItem: IDataFilterItem = {
      ui_area: this.uiArea,
      name: this.name,
      enabled: true,
      bool_1: this.isIncluded,
    };

    if (!crosstabConfig.crosstab.data_filter.items?.length) {
      crosstabConfig.crosstab.data_filter.items = [filterItem];
    }

    const found = this.findInFilter(crosstabConfig);
    if (found.item) {
      crosstabConfig.crosstab.data_filter.items[found.ix] = { ...filterItem };
    } else {
      crosstabConfig.crosstab.data_filter.items.push(filterItem);
    }
  }

  private findInFilter(crosstabConfig?: ICrosstabConfigUI): {
    ix: number;
    item?: IDataFilterItem;
  } {
    if (!crosstabConfig) {
      return { ix: -1 };
    }

    const filterName = this.name;
    const found = DataUtility.findFilterItem(
      crosstabConfig.crosstab.data_filter,
      filterName,
      this.uiArea
    );

    return found;
  }
}
