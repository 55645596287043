import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IBuilderContext } from '../../../i-builder-context';
import { IChangeCheckboxEventArgs } from '@infragistics/igniteui-angular';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { TableViewService } from '../../../../../services/table-view.service';
import { IAddition } from '../../../../../models/data/request/IAddition';

@Component({
  selector: 'app-calculations-stat-testing',
  templateUrl: './stat-testing.component.html',
  styleUrls: ['./stat-testing.component.scss'],
})
export class StatTestingComponent implements OnInit, IAdvancedProvider {
  included!: boolean;
  optionsDisabled!: boolean;
  optionsDisabledTooltip!: string;
  maxOptionsSelected!: boolean;
  maxOptionsSelectedTooltip!: string;
  statTesting_95!: boolean;
  statTesting_90!: boolean;
  statTesting_85!: boolean;
  statTesting_80!: boolean;
  addition?: IAddition;
  disabledTooltipItems: string[] = ['', '', '', ''];

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() calculationsChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private tableViewService: TableViewService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const shouldDisableCheckResult =
      this.tableViewService.isStatTestingDisabledForCrosstabConfig(
        this.context.statTestingEnabled ?? false,
        this.crosstabConfig.crosstab,
        this.context.action
      );

    this.optionsDisabled = shouldDisableCheckResult.disabled;
    this.optionsDisabledTooltip = shouldDisableCheckResult.tooltip ?? '';
    const found = this.findAddition(this.crosstabConfig);
    if (found.item && !this.optionsDisabled) {
      this.addition = found.item;
      this.statTesting_95 =
        this.addition.param_1 === '95' || this.addition.param_2 === '95';
      this.statTesting_90 =
        this.addition.param_1 === '90' || this.addition.param_2 === '90';
      this.statTesting_85 =
        this.addition.param_1 === '85' || this.addition.param_2 === '85';
      this.statTesting_80 =
        this.addition.param_1 === '80' || this.addition.param_2 === '80';

      this.maxOptionsSelected =
        this.addition.param_1 !== '' && this.addition.param_2 !== '';

      this.maxOptionsSelectedTooltip = this.maxOptionsSelected
        ? 'builder.statTestWarningMaxSelected'
        : '';
      this.included = true;
    } else {
      this.maxOptionsSelected = false;
      this.maxOptionsSelectedTooltip = '';
      this.included = false;
    }
  }

  onCheckboxChange(cb: IChangeCheckboxEventArgs): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const cbValue = cb.checkbox.value;
    const isChecked = cb.checked;

    if (cbValue === 'statTesting_95') {
      this.statTesting_95 = isChecked;
    } else if (cbValue === 'statTesting_90') {
      this.statTesting_90 = isChecked;
    } else if (cbValue === 'statTesting_85') {
      this.statTesting_85 = isChecked;
    } else if (cbValue === 'statTesting_80') {
      this.statTesting_80 = isChecked;
    }

    this.included =
      this.statTesting_95 ||
      this.statTesting_90 ||
      this.statTesting_85 ||
      this.statTesting_80;

    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
    setTimeout(() => {
      this.addition = this.findAddition(this.crosstabConfig)?.item;
    }, 0);
  }

  onConfigChanged(newConfig: ICrosstabConfig): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    const disableCheckResult =
      this.tableViewService.isStatTestingDisabledForCrosstabConfig(
        this.context.statTestingEnabled ?? false,
        newConfig,
        this.context?.action
      );

    const shouldDisable = disableCheckResult.disabled;
    const tooltip = disableCheckResult.tooltip ?? '';

    if (!shouldDisable && this.optionsDisabled) {
      this.optionsDisabled = false;
      this.optionsDisabledTooltip = '';
      return;
    }

    if (shouldDisable && !this.optionsDisabled) {
      if (this.included) {
        this.statTesting_95 = false;
        this.statTesting_90 = false;
        this.statTesting_85 = false;
        this.statTesting_80 = false;
        this.maxOptionsSelected = false;
        this.maxOptionsSelectedTooltip = '';
        this.included = false;
        this.addition = undefined;
        setTimeout(() => {
          this.calculationsChanged.emit(newConfig);
        }, 0);
      }
      this.optionsDisabledTooltip = tooltip;
      setTimeout(() => {
        this.optionsDisabled = true;
      }, 0);
    }
  }

  reset(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.included = false;
    this.statTesting_95 = false;
    this.statTesting_90 = false;
    this.statTesting_85 = false;
    this.statTesting_80 = false;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  prepareSave(context: IBuilderContext, crosstabConfig: ICrosstabConfigUI) {
    if (!this.included) {
      const found = this.findAddition(crosstabConfig);
      if (found.ix >= 0) {
        crosstabConfig.crosstab.additions?.splice(found.ix, 1);
      }
      this.addition = undefined;
      this.maxOptionsSelected = false;
      this.maxOptionsSelectedTooltip = '';
      return;
    }

    const statValuesChecked = [] as number[];
    if (this.statTesting_95) {
      statValuesChecked.push(95);
    }
    if (this.statTesting_90) {
      statValuesChecked.push(90);
    }
    if (this.statTesting_85) {
      statValuesChecked.push(85);
    }
    if (this.statTesting_80) {
      statValuesChecked.push(80);
    }

    if (!this.addition) {
      this.addition = {
        name: 'stat_test_column',
        label: 'Stat Letter',
        param_1: '',
        param_2: '',
      };
    }

    this.addition.param_1 =
      statValuesChecked.length > 0 ? `${statValuesChecked[0]}` : '';
    this.addition.param_2 =
      statValuesChecked.length > 1 ? `${statValuesChecked[1]}` : '';
    this.maxOptionsSelected = statValuesChecked.length >= 2;
    if (this.maxOptionsSelected) {
      this.maxOptionsSelectedTooltip = 'builder.statTestWarningMaxSelected';
    } else {
      this.maxOptionsSelectedTooltip = '';
    }

    if (!crosstabConfig.crosstab.additions?.length) {
      crosstabConfig.crosstab.additions = [this.addition];
    }
    const found = this.findAddition(crosstabConfig);
    if (found.item) {
      crosstabConfig.crosstab.additions[found.ix] = { ...this.addition };
    } else {
      crosstabConfig.crosstab.additions.push(this.addition);
    }
  }

  private findAddition(crosstabConfig?: ICrosstabConfigUI): {
    ix: number;
    item?: IAddition;
  } {
    if (!crosstabConfig) {
      return { ix: -1 };
    }
    const ix = (crosstabConfig.crosstab.additions ?? []).findIndex(
      (x) => x.name === 'stat_test_column'
    );
    if (ix >= 0 && crosstabConfig.crosstab.additions?.length) {
      return { ix, item: crosstabConfig.crosstab.additions[ix] };
    }
    return { ix: -1 };
  }
}
