<ng-container *ngIf="!ui.building">
  <section class="data-explorer-container">
    <!-- Table area -->
    <div class="split-container" [ngClass]="{ 'chart-view': ui.show_chart }">
      <!-- Chart -->
      <div class="chart-container vertical" *ngIf="ui.show_chart">
        <div class="chart-btns-container">
          <div class="back-button">
            <button igxButton="flat" class="back" (click)="backToTable()">
              <igx-icon>arrow_back</igx-icon>
              {{ 'crosstab.backToTable' | translate }}
            </button>
          </div>
          <div class="chart-right-btn-container">
            <button *ngIf="!ui.chart_expanded" class="suzy-icon-button" [igxTooltipTarget]="chartExpandButtonTooltip"
              (click)="adjustChartWidth(true)" [showDelay]="0" [hideDelay]="0">
              <igx-icon fontSet="material">open_in_full</igx-icon>
            </button>
            <button *ngIf="ui.chart_expanded" class="suzy-icon-button" [igxTooltipTarget]="closeExpandedChartTooltip"
              (click)="adjustChartWidth(false)" [showDelay]="0" [hideDelay]="0">
              <igx-icon fontSet="material">close</igx-icon>
            </button>
            <div class="download-chart-button">
              <button class="suzy-icon-button" *ngIf="!downloadingImage" [igxTooltipTarget]="chartDownloadTooltip"
                [disabled]="downloadingImage" (click)="downloadChartAsImage()" [showDelay]="0" [hideDelay]="0">
                <igx-icon family="suzy" name="download"></igx-icon>
              </button>
              <div class="loading-spinner" style="margin-right: 0; width: 36px; text-align: center"
                *ngIf="downloadingImage">
                <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
              </div>
            </div>
          </div>
        </div>
        <div #chartContent class="container fill">
          <ng-container *ngIf="
              ui.is_single_chart ||
              ui.action?.action_kind === actionKind.multiplechoice ||
              ui.action?.action_kind === actionKind.auto_assign ||
              ui.action?.action_kind === actionKind.turf ||
              ui.action?.action_kind === actionKind.maxdiff
            ">
            <app-bar-chart [gridData]="grid_data" [question]="ui.action?.strippedSearchText ?? ''"
              [isPercent]="ui.is_percent" [isSingleChart]="ui.is_single_chart ?? false"
              (back)="backToTable()"></app-bar-chart>
          </ng-container>
          <ng-container *ngIf="
              !ui.is_single_chart &&
              (ui.action?.action_kind === actionKind.gridrankscale ||
                ui.action?.action_kind === actionKind.gridcustom ||
                ui.action?.action_kind === actionKind.grid)
            ">
            <data-explorer-elements-stacked-bar-chart [gridData]="grid_data"
              [question]="ui.action?.strippedSearchText ?? ''" [isPercent]="ui.is_percent" [isSingleSelect]="
                ui.action?.grid?.open_row_min === 0 &&
                ui.action?.grid?.open_row_max === 0
              " [canFlip]="canFlipStackedBarChart"></data-explorer-elements-stacked-bar-chart>
          </ng-container>
        </div>
      </div>
      <!-- Table -->
      <div class="grid-container" *ngIf="!ui.show_chart" #gridContainer>
        <!-- Grid -->
        <igx-grid #grid *ngIf="grid_data.visible && grid_data.depth === 1" [data]="grid_data.data"
          [allowFiltering]="false" [showGroupArea]="false" [autoGenerate]="false"
          [emptyGridTemplate]="gridLoadingMessage" [groupingExpressions]="grid_data.grouping" [rowClasses]="rowClasses"
          [sortingOptions]="singleSortOption" [sortingExpressions]="grid_data.defaultSort"
          (sortingDone)="onGridSort($event)" (columnResized)="onGridResize($event)" displayDensity="compact"
          cellSelection="none" class="grid-instance" [ngClass]="{ 'single-line-header': grid_data.isSingleCol }">
          <igx-grid-toolbar *ngIf="ui.mission?.mission_id && ui.action?.action_id">
            <div class="grid-config-bar">
              <div class="table-view-config-row">
                <div class="left">
                  <button class="suzy-primary" [disabled]="ui.loading || !ui.tableViews.loaded || ui.cloning"
                    (click)="toggleBuilder(true)" [igxTooltipTarget]="builderButtonTooltip" [showDelay]="0"
                    [hideDelay]="0">
                    <igx-icon style="font-size: 20px;" fontSet="material">add</igx-icon>
                    {{
                    'crosstab.tableView' | translate
                    }}
                  </button>
                  <igx-select class="table-view-selection" [disabled]="
                      ui.loading || !ui.tableViews.loaded || ui.cloning === true
                    " [ngModel]="ui.activeTableViewId" placeholder="{{ 'crosstab.selectTableView' | translate }}"
                    [type]="'border'" [igxTooltipTarget]="tableViewSelectTooltip" [showDelay]="0" [hideDelay]="0"
                    (selectionChanging)="onSelectTableView($event)">
                    <igx-select-item *ngFor="let item of ui.tableViews.data" [value]="item.table_view_id" [text]="item.display_name">
                      <div class="select-item-container">
                        <span>{{ item.display_name }}</span>
                        <igx-icon fontSet="material" *ngIf="item.requires_processing">schedule</igx-icon>
                      </div>
                    </igx-select-item>
                    <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                  </igx-select>
                </div>
                <div class="right">
                  <ng-container *ngIf="
                      ui.tableViews.loaded &&
                      !ui.loading &&
                      ui.activeTableView &&
                      !ui.activeTableView.isGlobal &&
                      !ui.cloning
                    ">
                    <button class="suzy-icon-button" [igxTooltipTarget]="editTableViewButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="toggleBuilder()">
                      <igx-icon family="suzy" name="edit"></igx-icon>
                    </button>
                    <button class="suzy-icon-button" *ngIf="ui.canDuplicateTableView"
                      [igxTooltipTarget]="duplicateButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onDuplicateTableViewClick()">
                      <igx-icon family="suzy" name="apply-table" class="no-color-fill"></igx-icon>
                    </button>
                    <button class="suzy-icon-button" [igxTooltipTarget]="cloneButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="onCloneTableViewClick()">
                      <igx-icon family="suzy" name="file_copy" class="no-color-fill"></igx-icon>
                    </button>
                    <button class="suzy-icon-button" [igxTooltipTarget]="manageTableViewsButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="onManageTableViewsClick()">
                      <igx-icon family="suzy" name="settings" class="no-color-fill"></igx-icon>
                    </button>
                  </ng-container>
                  <div class="right-align">
                    <div class="loading-spinner" *ngIf="ui.loading || ui.cloning">
                      <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                    </div>
                    <div class="export-btn-container" *ngIf="grid_data.data.length > 0 && !ui.cloning">
                      <button class="export-btn" igxButton="flat" [igxToggleAction]="exportDropdown"
                        [overlaySettings]="dropDownOverlaySettings" [igxDropDownItemNavigation]="exportDropdown"
                        [igxTooltipTarget]="downloadButtonTooltip">
                        <igx-icon family="suzy" name="download"></igx-icon>
                        <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!ui.loading && grid_data.data.length > 0"
                [ngTemplateOutlet]="editTableViewRow"></ng-container>
            </div>
          </igx-grid-toolbar>
          <ng-container *ngIf="grid_data.isSingleCol">
            <igx-column *ngFor="let col of grid_data.singleColColumns" [autosizeHeader]="true"
              [resizable]="col.resizable" [pinned]="col.pinned" [header]="col.header" [field]="col.field"
              [cellStyles]="col.cellStyles" [headerStyles]="col.headerStyles" [sortable]="col.sortable"
              [filterable]="col.filterable" [width]="col.width" [dataType]="col.dataType"
              [cellTemplate]="grid_data.cellTemplate" [sortStrategy]="customSortingStrategy"
              headerGroupClasses="center-justify">
            </igx-column>
          </ng-container>
          <ng-container *ngIf="!grid_data.isSingleCol">
            <igx-column-group *ngFor="let level1 of grid_data.columns" [autosizeHeader]="true"
              [resizable]="level1.resizable" [pinned]="level1.pinned" [header]="level1.header" [field]="level1.field"
              [headerGroupStyles]="level1.headerGroupStyles" [cellStyles]="level1.cellStyles"
              [headerStyles]="level1.headerStyles" [sortable]="level1.sortable" [width]="level1.width"
              [dataType]="level1.dataType" headerGroupClasses="center-justify">
              <igx-column *ngFor="let level2 of level1.columns" [autosizeHeader]="true" [resizable]="level2.resizable"
                [pinned]="level2.pinned" [header]="level2.header" [field]="level2.field"
                [cellStyles]="level2.cellStyles" [headerStyles]="level2.headerStyles" [sortable]="level2.sortable"
                [filterable]="level2.filterable" [width]="level2.width" [dataType]="level2.dataType"
                [cellTemplate]="grid_data.cellTemplate" [sortStrategy]="customSortingStrategy"
                headerGroupClasses="center-justify">
              </igx-column>
            </igx-column-group>
          </ng-container>
          <ng-template igxGroupByRow let-groupRow>
            <span>{{ groupRow.value }}</span>
          </ng-template>
        </igx-grid>
        <!-- Grid -->
        <igx-grid #grid *ngIf="grid_data.visible && grid_data.depth === 2" [data]="grid_data.data"
          [allowFiltering]="false" [showGroupArea]="false" [autoGenerate]="false"
          [emptyGridTemplate]="gridLoadingMessage" [groupingExpressions]="grid_data.grouping" [rowClasses]="rowClasses"
          [sortingOptions]="singleSortOption" [sortingExpressions]="grid_data.defaultSort"
          (sortingDone)="onGridSort($event)" (columnResized)="onGridResize($event)" displayDensity="compact"
          cellSelection="none" class="grid-instance">
          <igx-grid-toolbar *ngIf="ui.mission?.mission_id && ui.action?.action_id">
            <div class="grid-config-bar">
              <div class="table-view-config-row">
                <div class="left">
                  <button igxButton="raised" [disabled]="ui.loading || !ui.tableViews.loaded || ui.cloning"
                    (click)="toggleBuilder(true)" [igxTooltipTarget]="builderButtonTooltip" [showDelay]="0"
                    [hideDelay]="0">
                    <igx-icon fontSet="material">add</igx-icon>
                    <span class="with-icon">{{
                      'crosstab.tableView' | translate
                      }}</span>
                  </button>
                  <igx-select class="table-view-selection" [disabled]="
                      ui.loading || !ui.tableViews.loaded || ui.cloning === true
                    " [ngModel]="ui.activeTableViewId" placeholder="{{ 'crosstab.selectTableView' | translate }}"
                    [type]="'border'" [igxTooltipTarget]="tableViewSelectTooltip" [showDelay]="0" [hideDelay]="0"
                    (selectionChanging)="onSelectTableView($event)">
                    <igx-select-item *ngFor="let item of ui.tableViews.data" [value]="item.table_view_id" [text]="item.display_name">
                      <div class="select-item-container">
                        <span>{{ item.display_name }}</span>
                        <igx-icon fontSet="material" *ngIf="item.requires_processing">schedule</igx-icon>
                      </div>
                    </igx-select-item>
                    <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                  </igx-select>
                </div>
                <div class="right">
                  <ng-container *ngIf="
                      ui.tableViews.loaded &&
                      !ui.loading &&
                      ui.activeTableView &&
                      !ui.activeTableView.isGlobal &&
                      !ui.cloning
                    ">
                    <button igxButton="flat" [igxTooltipTarget]="editTableViewButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="toggleBuilder()">
                      <igx-icon family="suzy" name="edit"></igx-icon>
                    </button>
                    <button igxButton="flat" *ngIf="ui.canDuplicateTableView"
                      [igxTooltipTarget]="duplicateButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onDuplicateTableViewClick()">
                      <igx-icon family="suzy" name="apply-table" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="cloneButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onCloneTableViewClick()">
                      <igx-icon family="suzy" name="file_copy" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="manageTableViewsButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="onManageTableViewsClick()">
                      <igx-icon family="suzy" name="settings" class="no-color-fill"></igx-icon>
                    </button>
                  </ng-container>
                  <div class="right-align">
                    <div class="loading-spinner" *ngIf="ui.loading || ui.cloning">
                      <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                    </div>
                    <div class="export-btn-container" *ngIf="grid_data.data.length > 0 && !ui.cloning">
                      <button class="export-btn" igxButton="flat" [igxToggleAction]="exportDropdown"
                        [overlaySettings]="dropDownOverlaySettings" [igxDropDownItemNavigation]="exportDropdown"
                        [igxTooltipTarget]="downloadButtonTooltip">
                        <igx-icon family="suzy" name="download"></igx-icon>
                        <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!ui.loading && grid_data.data.length > 0"
                [ngTemplateOutlet]="editTableViewRow"></ng-container>
            </div>
          </igx-grid-toolbar>
          <igx-column-group *ngFor="let level1 of grid_data.columns" [autosizeHeader]="true"
            [resizable]="level1.resizable" [pinned]="level1.pinned" [header]="level1.header" [field]="level1.field"
            [headerGroupStyles]="level1.headerGroupStyles" [cellStyles]="level1.cellStyles"
            [headerStyles]="level1.headerStyles" [sortable]="level1.sortable" [width]="level1.width"
            [dataType]="level1.dataType" headerGroupClasses="center-justify">
            <igx-column-group *ngFor="let level2 of level1.columns" [autosizeHeader]="true"
              [resizable]="level2.resizable" [pinned]="level2.pinned" [header]="level2.header" [field]="level2.field"
              [headerGroupStyles]="level2.headerGroupStyles" [cellStyles]="level2.cellStyles"
              [headerStyles]="level2.headerStyles" [sortable]="level2.sortable" [width]="level2.width"
              [dataType]="level2.dataType" headerGroupClasses="center-justify">
              <igx-column *ngFor="let level3 of level2.columns" [autosizeHeader]="true" [resizable]="level3.resizable"
                [pinned]="level3.pinned" [header]="level3.header" [field]="level3.field"
                [cellStyles]="level3.cellStyles" [headerStyles]="level3.headerStyles" [sortable]="level3.sortable"
                [filterable]="level3.filterable" [width]="level3.width" [dataType]="level3.dataType"
                [cellTemplate]="grid_data.cellTemplate" [sortStrategy]="customSortingStrategy"
                headerGroupClasses="center-justify">
              </igx-column>
            </igx-column-group>
          </igx-column-group>
          <ng-template igxGroupByRow let-groupRow>
            <span>{{ groupRow.value }}</span>
          </ng-template>
        </igx-grid>
        <!-- Grid -->
        <igx-grid #grid *ngIf="grid_data.visible && grid_data.depth === 3" [data]="grid_data.data"
          [allowFiltering]="false" [showGroupArea]="false" [autoGenerate]="false"
          [emptyGridTemplate]="gridLoadingMessage" [groupingExpressions]="grid_data.grouping" [rowClasses]="rowClasses"
          [sortingOptions]="singleSortOption" [sortingExpressions]="grid_data.defaultSort"
          (sortingDone)="onGridSort($event)" (columnResized)="onGridResize($event)" displayDensity="compact"
          cellSelection="none" class="grid-instance">
          <igx-grid-toolbar *ngIf="ui.mission?.mission_id && ui.action?.action_id">
            <div class="grid-config-bar">
              <div class="table-view-config-row">
                <div class="left">
                  <button igxButton="raised" [disabled]="ui.loading || !ui.tableViews.loaded || ui.cloning"
                    (click)="toggleBuilder(true)" [igxTooltipTarget]="builderButtonTooltip" [showDelay]="0"
                    [hideDelay]="0">
                    <igx-icon fontSet="material">add</igx-icon>
                    <span class="with-icon">{{
                      'crosstab.tableView' | translate
                      }}</span>
                  </button>
                  <igx-select class="table-view-selection" [disabled]="
                      ui.loading || !ui.tableViews.loaded || ui.cloning === true
                    " [ngModel]="ui.activeTableViewId" placeholder="{{ 'crosstab.selectTableView' | translate }}"
                    [type]="'border'" [igxTooltipTarget]="tableViewSelectTooltip" [showDelay]="0" [hideDelay]="0"
                    (selectionChanging)="onSelectTableView($event)">
                    <igx-select-item *ngFor="let item of ui.tableViews.data" [value]="item.table_view_id" [text]="item.display_name">
                      <div class="select-item-container">
                        <span>{{ item.display_name }}</span>
                        <igx-icon fontSet="material" *ngIf="item.requires_processing">schedule</igx-icon>
                      </div>
                    </igx-select-item>
                    <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                  </igx-select>
                </div>
                <div class="right">
                  <ng-container *ngIf="
                      ui.tableViews.loaded &&
                      !ui.loading &&
                      ui.activeTableView &&
                      !ui.activeTableView.isGlobal &&
                      !ui.cloning
                    ">
                    <button igxButton="flat" [igxTooltipTarget]="editTableViewButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="toggleBuilder()">
                      <igx-icon family="suzy" name="edit"></igx-icon>
                    </button>
                    <button igxButton="flat" *ngIf="ui.canDuplicateTableView"
                      [igxTooltipTarget]="duplicateButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onDuplicateTableViewClick()">
                      <igx-icon family="suzy" name="apply-table" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="cloneButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onCloneTableViewClick()">
                      <igx-icon family="suzy" name="file_copy" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="manageTableViewsButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="onManageTableViewsClick()">
                      <igx-icon family="suzy" name="settings" class="no-color-fill"></igx-icon>
                    </button>
                  </ng-container>
                  <div class="right-align">
                    <div class="loading-spinner" *ngIf="ui.loading || ui.cloning">
                      <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                    </div>
                    <div class="export-btn-container" *ngIf="grid_data.data.length > 0 && !ui.cloning">
                      <button class="export-btn" igxButton="flat" [igxToggleAction]="exportDropdown"
                        [overlaySettings]="dropDownOverlaySettings" [igxDropDownItemNavigation]="exportDropdown"
                        [igxTooltipTarget]="downloadButtonTooltip">
                        <igx-icon family="suzy" name="download"></igx-icon>
                        <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!ui.loading && grid_data.data.length > 0"
                [ngTemplateOutlet]="editTableViewRow"></ng-container>
            </div>
          </igx-grid-toolbar>
          <igx-column-group *ngFor="let level1 of grid_data.columns" [autosizeHeader]="true"
            [resizable]="level1.resizable" [pinned]="level1.pinned" [header]="level1.header" [field]="level1.field"
            [headerGroupStyles]="level1.headerGroupStyles" [cellStyles]="level1.cellStyles"
            [headerStyles]="level1.headerStyles" [sortable]="level1.sortable" [width]="level1.width"
            [dataType]="level1.dataType" headerGroupClasses="center-justify">
            <igx-column-group *ngFor="let level2 of level1.columns" [autosizeHeader]="true"
              [resizable]="level2.resizable" [pinned]="level2.pinned" [header]="level2.header" [field]="level2.field"
              [headerGroupStyles]="level2.headerGroupStyles" [cellStyles]="level2.cellStyles"
              [headerStyles]="level2.headerStyles" [sortable]="level2.sortable" [width]="level2.width"
              [dataType]="level2.dataType" headerGroupClasses="center-justify">
              <igx-column-group *ngFor="let level3 of level2.columns" [autosizeHeader]="true"
                [resizable]="level3.resizable" [pinned]="level3.pinned" [header]="level3.header" [field]="level3.field"
                [headerGroupStyles]="level3.headerGroupStyles" [cellStyles]="level3.cellStyles"
                [headerStyles]="level3.headerStyles" [sortable]="level3.sortable" [width]="level3.width"
                [dataType]="level3.dataType" headerGroupClasses="center-justify">
                <igx-column *ngFor="let level4 of level3.columns" [autosizeHeader]="true" [resizable]="level4.resizable"
                  [pinned]="level4.pinned" [header]="level4.header" [field]="level4.field"
                  [cellStyles]="level4.cellStyles" [headerStyles]="level4.headerStyles" [sortable]="level4.sortable"
                  [filterable]="level4.filterable" [width]="level4.width" [dataType]="level4.dataType"
                  [cellTemplate]="grid_data.cellTemplate" [sortStrategy]="customSortingStrategy"
                  headerGroupClasses="center-justify">
                </igx-column>
              </igx-column-group>
            </igx-column-group>
          </igx-column-group>
          <ng-template igxGroupByRow let-groupRow>
            <span>{{ groupRow.value }}</span>
          </ng-template>
        </igx-grid>
        <!-- Grid -->
        <igx-grid #grid *ngIf="grid_data.visible && grid_data.depth === 4" [data]="grid_data.data"
          [allowFiltering]="false" [showGroupArea]="false" [autoGenerate]="false"
          [emptyGridTemplate]="gridLoadingMessage" [groupingExpressions]="grid_data.grouping" [rowClasses]="rowClasses"
          [sortingOptions]="singleSortOption" [sortingExpressions]="grid_data.defaultSort"
          (sortingDone)="onGridSort($event)" (columnResized)="onGridResize($event)" displayDensity="compact"
          cellSelection="none" class="grid-instance">
          <igx-grid-toolbar *ngIf="ui.mission?.mission_id && ui.action?.action_id">
            <div class="grid-config-bar">
              <div class="table-view-config-row">
                <div class="left">
                  <button igxButton="raised" [disabled]="ui.loading || !ui.tableViews.loaded || ui.cloning"
                    (click)="toggleBuilder(true)" [igxTooltipTarget]="builderButtonTooltip" [showDelay]="0"
                    [hideDelay]="0">
                    <igx-icon fontSet="material">add</igx-icon>
                    <span class="with-icon">{{
                      'crosstab.tableView' | translate
                      }}</span>
                  </button>
                  <igx-select class="table-view-selection" [disabled]="
                      ui.loading || !ui.tableViews.loaded || ui.cloning === true
                    " [ngModel]="ui.activeTableViewId" placeholder="{{ 'crosstab.selectTableView' | translate }}"
                    [type]="'border'" [igxTooltipTarget]="tableViewSelectTooltip" [showDelay]="0" [hideDelay]="0"
                    (selectionChanging)="onSelectTableView($event)">
                    <igx-select-item *ngFor="let item of ui.tableViews.data" [value]="item.table_view_id" [text]="item.display_name">
                      <div class="select-item-container">
                        <span>{{ item.display_name }}</span>
                        <igx-icon fontSet="material" *ngIf="item.requires_processing">schedule</igx-icon>
                      </div>
                    </igx-select-item>
                    <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                  </igx-select>
                </div>
                <div class="right">
                  <ng-container *ngIf="
                      ui.tableViews.loaded &&
                      !ui.loading &&
                      ui.activeTableView &&
                      !ui.activeTableView.isGlobal &&
                      !ui.cloning
                    ">
                    <button igxButton="flat" [igxTooltipTarget]="editTableViewButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="toggleBuilder()">
                      <igx-icon family="suzy" name="edit"></igx-icon>
                    </button>
                    <button igxButton="flat" *ngIf="ui.canDuplicateTableView"
                      [igxTooltipTarget]="duplicateButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onDuplicateTableViewClick()">
                      <igx-icon family="suzy" name="apply-table" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="cloneButtonTooltip" [showDelay]="0" [hideDelay]="0"
                      (click)="onCloneTableViewClick()">
                      <igx-icon family="suzy" name="file_copy" class="no-color-fill"></igx-icon>
                    </button>
                    <button igxButton="flat" [igxTooltipTarget]="manageTableViewsButtonTooltip" [showDelay]="0"
                      [hideDelay]="0" (click)="onManageTableViewsClick()">
                      <igx-icon family="suzy" name="settings" class="no-color-fill"></igx-icon>
                    </button>
                  </ng-container>
                  <div class="right-align">
                    <div class="loading-spinner" *ngIf="ui.loading || ui.cloning">
                      <igx-circular-bar [animate]="false" [indeterminate]="true"
                        [textVisibility]="false"></igx-circular-bar>
                    </div>
                    <div class="export-btn-container" *ngIf="grid_data.data.length > 0 && !ui.cloning">
                      <button class="export-btn" igxButton="flat" [igxToggleAction]="exportDropdown"
                        [overlaySettings]="dropDownOverlaySettings" [igxDropDownItemNavigation]="exportDropdown"
                        [igxTooltipTarget]="downloadButtonTooltip">
                        <igx-icon family="suzy" name="download"></igx-icon>
                        <igx-suffix><igx-icon fontSet="material">expand_more</igx-icon></igx-suffix>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!ui.loading && grid_data.data.length > 0"
                [ngTemplateOutlet]="editTableViewRow"></ng-container>
            </div>
          </igx-grid-toolbar>
          <igx-column-group *ngFor="let level1 of grid_data.columns" [autosizeHeader]="true"
            [resizable]="level1.resizable" [pinned]="level1.pinned" [header]="level1.header" [field]="level1.field"
            [headerGroupStyles]="level1.headerGroupStyles" [cellStyles]="level1.cellStyles"
            [headerStyles]="level1.headerStyles" [sortable]="level1.sortable" [width]="level1.width"
            [dataType]="level1.dataType" headerGroupClasses="center-justify">
            <igx-column-group *ngFor="let level2 of level1.columns" [autosizeHeader]="true"
              [resizable]="level2.resizable" [pinned]="level2.pinned" [header]="level2.header" [field]="level2.field"
              [headerGroupStyles]="level2.headerGroupStyles" [cellStyles]="level2.cellStyles"
              [headerStyles]="level2.headerStyles" [sortable]="level2.sortable" [width]="level2.width"
              [dataType]="level2.dataType" headerGroupClasses="center-justify">
              <igx-column-group *ngFor="let level3 of level2.columns" [autosizeHeader]="true"
                [resizable]="level3.resizable" [pinned]="level3.pinned" [header]="level3.header" [field]="level3.field"
                [headerGroupStyles]="level3.headerGroupStyles" [cellStyles]="level3.cellStyles"
                [headerStyles]="level3.headerStyles" [sortable]="level3.sortable" [width]="level3.width"
                [dataType]="level3.dataType" headerGroupClasses="center-justify">
                <igx-column-group *ngFor="let level4 of level3.columns" [autosizeHeader]="true"
                  [resizable]="level4.resizable" [pinned]="level4.pinned" [header]="level4.header"
                  [field]="level4.field" [headerGroupStyles]="level4.headerGroupStyles" [cellStyles]="level4.cellStyles"
                  [headerStyles]="level4.headerStyles" [sortable]="level4.sortable" [width]="level4.width"
                  [dataType]="level4.dataType" headerGroupClasses="center-justify">
                  <igx-column *ngFor="let level5 of level4.columns" [autosizeHeader]="true"
                    [resizable]="level5.resizable" [pinned]="level5.pinned" [header]="level5.header"
                    [field]="level5.field" [cellStyles]="level5.cellStyles" [headerStyles]="level5.headerStyles"
                    [sortable]="level5.sortable" [filterable]="level5.filterable" [width]="level5.width"
                    [dataType]="level5.dataType" [cellTemplate]="grid_data.cellTemplate"
                    [sortStrategy]="customSortingStrategy" headerGroupClasses="center-justify">
                  </igx-column>
                </igx-column-group>
              </igx-column-group>
            </igx-column-group>
          </igx-column-group>
          <ng-template igxGroupByRow let-groupRow>
            <span>{{ groupRow.value }}</span>
          </ng-template>
        </igx-grid>
      </div>
    </div>
  </section>

  <div #builderButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.builderButtonTooltip' | translate }}
  </div>

  <div #tableViewSelectTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.tableViewSelectTooltip' | translate }}
  </div>

  <div #percentButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.percentButtonTooltip' | translate }}
  </div>

  <div #numberButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.numberButtonTooltip' | translate }}
  </div>

  <div #chartButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.chartButtonTooltip' | translate }}
  </div>

  <div #resetButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.resetButtonTooltip' | translate }}
  </div>

  <div #chartDownloadTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.chartDownloadTooltip' | translate }}
  </div>

  <div #chartExpandButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.chartExpandButtonTooltip' | translate }}
  </div>

  <div #closeExpandedChartTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.closeExpandedChartTooltip' | translate }}
  </div>

  <div #duplicateButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.applyTableViewToOtherQuestions' | translate }}
  </div>

  <div #downloadButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.download' | translate }}
  </div>

  <div #editTableViewButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.editTable' | translate }}
  </div>

  <div #openendDisabledTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.notAvailableOpenend' | translate }}
  </div>

  <div #tableInfoTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'tableInformation.tooltip' | translate }}
  </div>

  <div #cloneButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.cloneTableView' | translate }}
  </div>

  <div #manageTableViewsButtonTooltip="tooltip" igxTooltip class="toolbar-tooltip">
    {{ 'crosstab.manageTableViews' | translate }}
  </div>

  <ng-template #editTableViewRow>
    <div class="tableview-edit-row">
      <div class="table-view-title">
<!--         <span *ngIf="!ui.activeTableView || ui.activeTableView.isGlobal">&nbsp;</span>
 -->
        <span class="h2" *ngIf="ui.activeTableView">{{
          ui.activeTableView.display_name
          }}</span>

        <span class="body-copy-caption text-suzy-grey-700" *ngIf="ui.activeTableView"> {{'crosstab.lastUpdated' | translate}} {{
          ui.activeTableView!.updated_utc | date: 'medium' }}</span>
      </div>


      <div class="buttons">
        <div class="switch-view" [ngClass]="{ disabled: ui.is_openend }">
          <div (click)="
              !ui.is_openend && selectAddition('respondent_percentage_column')
            " [ngClass]="{ active: ui.is_percent }" [igxTooltipTarget]="
              ui.is_openend ? openendDisabledTooltip : percentButtonTooltip
            " [showDelay]="0" [hideDelay]="0">
            %
          </div>
          <div (click)="!ui.is_openend && selectAddition('standard')" [ngClass]="{ active: !ui.is_percent }"
            [igxTooltipTarget]="
              ui.is_openend ? openendDisabledTooltip : numberButtonTooltip
            " [showDelay]="0" [hideDelay]="0">
            #
          </div>
        </div>
        <button class="suzy-icon-button" (click)="!ui.is_openend && showChartSwitch(true)"
          [ngClass]="{ disabled: ui.is_openend }" [igxTooltipTarget]="
            ui.is_openend ? openendDisabledTooltip : chartButtonTooltip
          " [showDelay]="0" [hideDelay]="0">
          <igx-icon family="suzy" name="equalizer"></igx-icon>
        </button>
        <button class="suzy-icon-button" (click)="showTableInformation()" [igxTooltipTarget]="tableInfoTooltip"
          [showDelay]="0" [hideDelay]="0">
          <span class="material-symbols-rounded">info</span>
        </button>
      </div>
      <div class="buttons" *ngIf="ui.loading">
        <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
      </div>
    </div>
  </ng-template>

  <ng-template #comparisonView let-cell="cell">
    <div class="{{ comparisonGenerateClass(cell) }}" (mouseenter)="comparisonHover(cell)"
      (mouseleave)="comparisonHover(cell, true)" [innerHTML]="comparisonExtractValue(cell)"></div>
  </ng-template>

  <ng-template #percentView let-cell="cell">
    <div class="igx-grid__td-text">
      <ng-container *ngTemplateOutlet="
          cell.column.field.startsWith('c') &&
          !(
            cell.column.dataType === 'number' ||
            cell.row.data?.rbd_data_type === 'number'
          )
            ? percentCell
            : cellValue;
          context: { cell }
        ">
      </ng-container>
    </div>
  </ng-template>

  <ng-template #cellValue let-cell="cell">{{ cell.value ?? '' }}</ng-template>
  <ng-template #percentCell let-cell="cell">{{ cell.value | percent }}
  </ng-template>

  <ng-template #gridLoadingMessage>
    <ng-container *ngIf="tableViewIsAsync; else elseDefaultMessage">
      <async-setup-notify [item]="ui.activeTableView" [subscription]="ui.activeTableView?.self_subscription"
        [isSending]="sendingAsyncNotification" (sendNotification)="sendAsyncNotification($event)"
        (cancelNotification)="confirmNotificationCanceling($event)"></async-setup-notify>
    </ng-container>
    <ng-template #elseDefaultMessage>
      <div class="loading-msg-container">
        <span class="body-copy-medium" [innerHTML]="
            ui.emptyDataMessage
              ? ui.emptyDataMessage
              : ('crosstab.selectSurveyToStart' | translate)
          "></span>
        <span class="body-copy-small" *ngIf="ui.emptyDataWarning" [innerHTML]="ui.emptyDataWarning | translate"></span>
        <button class="suzy-tertiary" *ngIf="ui.canCancelDataFetch" (click)="onCancelFetchDataClick()">
          {{ 'crosstab.cancel' | translate }}
        </button>
        <button class="suzy-tertiary" *ngIf="ui.canRetryDataFetch" (click)="onRetryFetchDataClick()">
          {{ 'crosstab.retry' | translate }}
        </button>
      </div>
    </ng-template>



  </ng-template>
</ng-container>

<ng-container *ngIf="ui?.building">
  <div class="builder-container" id="builderContainer">
    <div class="builder-top">
      <form [formGroup]="tableViewFormGroup" #tableViewForm>
        <div class="form-header">
          <igx-icon family="material">playlist_add</igx-icon>
          <span>{{ 'builder.createTableView' | translate }}</span>
        </div>
        <div class="question-info">
          <span>{{ 'crosstab.questionInfo' | translate }}</span>
          <span *ngIf="ui.action?.strippedSearchText" [innerHTML]="ui.action?.strippedSearchText ?? ''">
          </span>
        </div>
        <div class="question-info">
          <div class="input-label">
            <span>{{ 'crosstab.tableViewName' | translate }}</span>
            <span>{{ 'builder.required' | translate }}</span>
          </div>

          <igx-input-group type="border">
            <input igxInput name="TableViewName" type="text" formControlName="TableViewName" required="required"
              autocomplete="off" [placeholder]="'crosstab.nameYourTable' | translate" />
          </igx-input-group>
          <div *ngIf="
              tableViewFormGroup.controls['TableViewName'].hasError(
                'required'
              ) &&
              (tableViewFormGroup.controls['TableViewName'].touched ||
                tableViewFormGroup.controls['TableViewName'].dirty)
            " class="input-warn">
            <igx-icon>warning</igx-icon>
            <span class="warn">{{
              'builder.tableViewNameRequiredWarn' | translate
              }}</span>
          </div>
        </div>
      </form>
    </div>

    <div class="builder-info">
      <span class="title">{{ 'crosstab.addRowsColumns' | translate }}</span>
      <span>{{ 'crosstab.addRowsColumnsSub' | translate }}</span>
    </div>
    <data-explorer-builder #builder [context]="buildContext" [config]="buildConfig"
      (openSubpopulationForm)="openForm($event)" (editTBSubpopulation)="editForm($event)"></data-explorer-builder>
  </div>

  <div class="builder-actions">
    <div class="actions-left">
      <button *ngIf="
          ui.activeTableView &&
          ui.activeTableViewId &&
          !ui.activeTableView.isGlobal
        " class="builderAction" igxButton="outlined" [disabled]="ui.savingTableView"
        (click)="onConfirmTableViewDelete()">
        {{ 'crosstab.delete' | translate }}
      </button>
    </div>
    <div class="actions-right">
      <igx-circular-bar *ngIf="ui.savingTableView" [animate]="false" [indeterminate]="true"
        [textVisibility]="false"></igx-circular-bar>
      <button *ngIf="ui.canValidateTableView" class="builderAction" igxButton="outlined" [disabled]="ui.savingTableView"
        (click)="onValidateTableView()">
        {{ 'crosstab.validateTableView' | translate }}
      </button>
      <button class="builderAction" igxButton="outlined" [disabled]="ui.savingTableView" (click)="toggleBuilder(false)">
        {{ 'builder.cancel' | translate }}
      </button>
      <button class="builderAction" igxButton="raised" [disabled]="ui.savingTableView"
        (click)="toggleBuilder(true); saveTableViewButton.blur()" #saveTableViewButton>
        {{ 'crosstab.saveTable' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<igx-snackbar #grid_snackbar [actionText]="'builder.confirm_close' | translate" (clicked)="snackbar.close()">{{
  ui.snackbar_msg ?? '' }}</igx-snackbar>

<igx-dialog #confirm_dialog [message]="ui.confirm_dialog?.message ?? ''">

  <igx-dialog-title>
    <div class="dialog-container">
      <h2 class="h2">{{ ui.confirm_dialog?.title ?? '' }}</h2>
    </div>
  </igx-dialog-title>
  <div igxDialogActions class="dialog-container dialog-actions">
    <button igxButton="flat" igxRipple (click)="onConfirmCallback(false, confirm_dialog)">
      {{ ui.confirm_dialog?.left }}
    </button>
    <button class="right" igxButton="raised" igxRipple (click)="onConfirmCallback(true, confirm_dialog, $event)">
      {{ ui.confirm_dialog?.right }}
    </button>
  </div>

</igx-dialog>

<igx-drop-down class="export-dropdown" #exportDropdown [allowItemsFocus]="false">
  <igx-drop-down-item #excelExport (click)="onQueueExport('excel')" [disabled]="exportingPPT || exportingExcel">{{
    'crosstab.exportToExcel' | translate }}
    <span class="ppt-loading-spinner" *ngIf="exportingExcel">
      <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
    </span></igx-drop-down-item>
  <igx-drop-down-item #PPTExport (click)="onQueueExport('ppt')" [disabled]="exportingPPT || exportingExcel">
    {{ 'crosstab.exportToPPT' | translate }}
    <span class="ppt-loading-spinner" *ngIf="exportingPPT">
      <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
    </span>
  </igx-drop-down-item>
</igx-drop-down>

<igx-dialog #tableViewValidatorDialog *ngIf="ui.canValidateTableView && validationData">
  <data-explorer-tabelview-validator [dialog]="tableViewValidatorDialog" [validation]="validationData"
    (saved)="onValidationSaved($event)"></data-explorer-tabelview-validator>
</igx-dialog>

<igx-dialog #tableInformationDialog>
  <data-explorer-table-information *ngIf="tableInfo" [dialog]="tableInformationDialog" [data]="tableInfo"
    (refreshData)="onRefreshData($event)"></data-explorer-table-information>
</igx-dialog>

<igx-dialog #crosstabWontProcessDialog>
  <crosstab-wont-process-dialog [dialog]="crosstabWontProcessDialog"></crosstab-wont-process-dialog>
</igx-dialog>

<igx-dialog #crosstabMayProcessDialog>
  <crosstab-may-process-dialog [dialog]="crosstabMayProcessDialog" (tryTableView)="tryTableView($event)"
    (triggerAppcuesEvent)="segmentTrackEvent($event)" (dontTry)="abortTrying($event)"></crosstab-may-process-dialog>
</igx-dialog>

<igx-dialog #builderSPForm>
  <div class="full-screen-dialog" *ngIf="openSPDialog">
    <base-fabrication-form class="full-width" [isDialog]="true" [uiData]="ui"
      [translations]="fabricationInit.translations" [actions]="actions"
      [fabricationType]="fabricationInit.fabricationType" [editValue]="fabricationToEdit"
      [builderDimensions]="builderDimensions" (formValues)="sendSPFormValues($event)"
      (navigateAway)="verifyBeforeClose($event)"></base-fabrication-form>
  </div>
</igx-dialog>