import { Injectable } from '@angular/core';
import { IAction } from '../models/suzy/IAction';
import { ActionKind } from '@asksuzy/typescript-sdk';

@Injectable()
export class CrosstabConfigService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  isPercentDefaultAction(action: IAction): boolean {
    const { action_kind } = action;

    switch (action_kind) {
      case ActionKind.openended:
        return false;
      case ActionKind.multiplechoice:
      case ActionKind.turf:
      case ActionKind.gridcustom:
      case ActionKind.gridrankscale:
      case ActionKind.grid:
      case ActionKind.maxdiff:
        return true;
      default:
        return false;
    }
  }
}
