<div class="cbp-container">
    <div class="cbp-header">
        <span class="h2 cbp-header-title">{{ title }}</span>
        <p class="cbp-header-description body-copy-small text-suzy-grey-800">{{ description }} <a
                class="text-link-small" [href]="learnMoreLink" target="_blank">{{
                'customBannerPoints.learnMore' | translate
                }}</a></p>
    </div>
    <div class="cbp-actions">
       <!--  <button class="suzy-secondary" (click)="refresh()" *ngIf="type === fabricationType.DerivedQuestions"
            [disabled]="refreshing">
            <ng-container *ngIf="refreshing; else elseIcon">
                <igx-circular-bar style="width: 20px; height: 20px;" [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
            </ng-container>
            <ng-template #elseIcon>
                <igx-icon style="font-size: 20px;" fontSet="material">refresh</igx-icon>
            </ng-template>

            {{ 'customBannerPoints.refreshBtn' | translate }}
        </button> -->
        <button class="suzy-primary" (click)="create()">
            <igx-icon style="font-size: 20px;" fontSet="material">add</igx-icon>
            {{ 'customBannerPoints.addBtn' | translate }}
        </button>
    </div>
</div>