import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { appRoutes } from './app.routes';
import {
  IgxButtonModule,
  IgxDialogModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxListModule,
  IgxProgressBarModule,
  IgxRippleModule,
  IgxSelectModule,
  IgxSimpleComboModule,
  IgxComboModule,
} from '@infragistics/igniteui-angular';

import { AppComponent } from './app.component';
import { DataExplorerModule } from '@data-explorer-elements/data-explorer';
import { SuzySdk } from './services/suzy-sdk.service';
import { AuthSessionService } from './services/auth-session.service';
import { SdkAuthInterceptor } from './utilities/sdk-auth-interceptor';
import { SuzyDataService } from './services/suzy-data.service';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { ExplorerSidenavComponent } from './components/explorer/explorer-sidenav/explorer-sidenav.component';
import { ExplorerUIComponent } from './components/explorer/explorer.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/dataexplorer-');
}

export function suzySDKFactory(http: HttpClient): SuzySdk {
  return new SuzySdk(http).initialize(`${environment.suzyUrl}`);
}

export function authSessionServiceFactory(
  sdk: SuzySdk,
  router: Router
): AuthSessionService {
  return new AuthSessionService(sdk, router).initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    ExplorerUIComponent,
    LoginComponent,
    ExplorerSidenavComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    DataExplorerModule,
    IgxProgressBarModule,
    IgxInputGroupModule,
    IgxButtonModule,
    IgxIconModule,
    IgxRippleModule,
    IgxDialogModule,
    IgxSelectModule,
    IgxSimpleComboModule,
    IgxComboModule,
    IgxListModule,
  ],
  providers: [
    {
      provide: SuzySdk,
      useFactory: suzySDKFactory,
      deps: [HttpClient],
    },
    {
      provide: AuthSessionService,
      useFactory: authSessionServiceFactory,
      deps: [SuzySdk, Router],
    },
    { provide: HTTP_INTERCEPTORS, useClass: SdkAuthInterceptor, multi: true },
    {
      provide: SuzyDataService,
      deps: [SuzySdk],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
