import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, map, Subject, take, takeUntil, tap } from 'rxjs';
import {
  EFabricationEndpointType,
  EFabricationTypeNumber,
} from '../../../enums/cbp-enum';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';
import { IDialogData } from '../../../models/fabrication/dialog-data';
import { IFabricationDetail } from '../../../models/fabrication/IFabricationDetail';
import { IFabItem } from '../../../models/fabrication/IFabricationResponse';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { TableViewsRequestsService } from '../../../services/table-views-requests.service';
import { CbpConfirmActionDialogComponent } from '../cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { CbpFabricationDetailDialogComponent } from '../cbp-fabrication-detail-dialog/cbp-fabrication-detail-dialog.component';

@Component({
  selector: 'custom-banner-points-tabs',
  templateUrl: './custom-banner-points-tabs.component.html',
  styleUrls: ['./custom-banner-points-tabs.component.scss'],
})
export class CustomBannerPointsTabsComponent implements OnInit, OnDestroy {
  derivedQuestions!: IFabItem[];
  subpopulations!: IFabItem[];
  brandId = '';
  missionId = '';
  selectedTabIndex = 0;
  unsubscribe = new Subject<void>();

  fabricationType: typeof EFabricationEndpointType = EFabricationEndpointType;
  fabTypeNumber: typeof EFabricationTypeNumber = EFabricationTypeNumber;

  refreshing = false;
  itemRefresh = false;
  idToRefresh: string | null = null;

  constructor(
    private navigationStatus: LibraryNavigationService,
    private cbpRequests: CustomBannerPointsService,
    private cbpActions: CustomBannerPointsActionsService,
    private dialog: Dialog,
    private translate: TranslateService,
    @Inject(DATA_TOKEN) public formInputs: ICBPFormInputs,
    private tableViewService: TableViewsRequestsService
  ) {
    if (this.formInputs && this.formInputs.uiData) {
      this.brandId = formInputs.uiData?.brand_id ?? '';
    }

    if (this.formInputs.selectedTabIndex) {
      this.selectedTabIndex = formInputs.selectedTabIndex;
    }
  }

  ngOnInit(): void {
    this.cbpActions.bannerSubpopulations$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.subpopulations = res;
      });

    this.cbpActions.bannerDerivedQuestions$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.derivedQuestions = res;
      });
  }

  ngOnDestroy(): void {
    this.cbpActions.selectedSavedBanner.next(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getFabricationsList(type: EFabricationEndpointType): void {
    this.cbpRequests
      .getFabricationsByType(this.brandId, type)
      .pipe(
        map((item: any) => item.items),
        tap((res) => {
          type === EFabricationEndpointType.Subpopulation
            ? this.cbpActions.bannerSubpopulations.next(res)
            : this.cbpActions.bannerDerivedQuestions.next(res);
        }),
        take(1)
      )
      .subscribe();
  }

  openDerivedQuestionForm(open: boolean): void {
    this.navigationStatus.DENavigationStatus.next({
      title: 'customBannerPoints.derivedQuestions.tabTitle',
      backTo: ELibraryRoutes.CBPTabs,
      currentRoute: ELibraryRoutes.CBPDerivedQuestion,
    });
  }

  openSubpopulationForm(open: boolean): void {
    this.navigationStatus.DENavigationStatus.next({
      title: 'customBannerPoints.subPopulations.tabTitle',
      backTo: ELibraryRoutes.CBPTabs,
      currentRoute: ELibraryRoutes.CBPSubpopulation,
    });
  }

  confirmDelete(fabId: string, type: EFabricationEndpointType): void {
    const dialogData: IDialogData = {
      title: this.translate.instant(
        type === EFabricationEndpointType.Subpopulation
          ? 'customBannerPoints.formText.validations.confirmDelete.title'
          : 'customBannerPoints.formText.validations.confirmArchive.title'
      ),
      body: this.translate.instant(
        type === EFabricationEndpointType.Subpopulation
          ? 'customBannerPoints.formText.validations.confirmDelete.body'
          : 'customBannerPoints.formText.validations.confirmArchive.body'
      ),
      ctaBtn: this.translate.instant(
        type === EFabricationEndpointType.Subpopulation
          ? 'customBannerPoints.formText.validations.confirmDelete.ctaBtn'
          : 'customBannerPoints.formText.validations.confirmArchive.ctaBtn'
      ),
      cancelBtn: this.translate.instant(
        type === EFabricationEndpointType.Subpopulation
          ? 'customBannerPoints.formText.validations.confirmDelete.cancelBtn'
          : 'customBannerPoints.formText.validations.confirmArchive.cancelBtn'
      ),
      callback: () => this.deleteFabrication(fabId, type),
    };

    if (this.brandId) {
      this.tableViewService
        .verifyFabricationImpact(this.brandId, fabId, { take: 100 })
        .pipe(
          tap((impact) => {
            if (impact.items.length > 0) {
              const listItems = impact.items
                .map((item) => `<li>${item.display_name}</li>`)
                .join('');

              dialogData.customBody = `<ul class="ul-bullet">${listItems}</ul>`;
              dialogData.subtitle = this.translate.instant(
                type === EFabricationEndpointType.Subpopulation
                  ? 'customBannerPoints.subPopulations.archive.subtitle'
                  : 'customBannerPoints.derivedQuestions.archive.subtitle'
              );
              delete dialogData.body;
            }
            this.dialog.open(CbpConfirmActionDialogComponent, {
              data: { ...dialogData },
            });
          }),
          take(1)
        )
        .subscribe();
    }
  }

  deleteFabrication(fabId: string, type: EFabricationEndpointType): void {
    this.cbpRequests
      .deleteFabrication(fabId, this.brandId, type)
      .pipe(
        tap(() => this.getFabricationsList(type)),
        take(1)
      )
      .subscribe();
  }

  viewFabrication(fabId: string, type: EFabricationEndpointType): void {
    const fabDetail = this.cbpRequests.getFabricationDetails(
      fabId,
      this.brandId,
      type
    );
    this.dialog.open(CbpFabricationDetailDialogComponent, {
      data: fabDetail,
    });
  }

  editFabrication(fabId: string, type: EFabricationEndpointType): void {
    this.cbpRequests
      .getFabricationDetails(fabId, this.brandId, type)
      .pipe(take(1), catchError(e => EMPTY))
      .subscribe((fabrication: IFabricationDetail) => {
        if (fabrication) {
          this.cbpActions.selectedSavedBanner.next(fabrication.item);
          this.cbpActions.savedBannerToClone.next(false);
          type === EFabricationEndpointType.Subpopulation
            ? this.openSubpopulationForm(true)
            : this.openDerivedQuestionForm(true);
        }
      });
  }

  cloneFabrication(fabId: string, type: EFabricationEndpointType): void {
    this.cbpRequests
      .getFabricationDetails(fabId, this.brandId, type)
      .pipe(take(1))
      .subscribe((fabrication: IFabricationDetail) => {
        if (fabrication) {
          this.cbpActions.selectedSavedBanner.next(fabrication.item);
          this.cbpActions.savedBannerToClone.next(true);
          type === EFabricationEndpointType.Subpopulation
            ? this.openSubpopulationForm(true)
            : this.openDerivedQuestionForm(true);
        }
      });
  }

  updateTabIndex(index: number): void {
    this.cbpActions.savedBannerSelectedTab.next(index);
  }

  /*   refreshAllDQs(refresh: boolean): void {
    if (refresh) {
      this.refreshing = true;
      this.cbpRequests
        .refreshAllDerivedQuestions(
          this.brandId,
          EFabricationEndpointType.DerivedQuestions
        )
        .pipe(
          tap(() => (this.refreshing = false)),
          take(1),
          catchError(() => {
            this.refreshing = false;
            return EMPTY;
          })
        )
        .subscribe();
    }
  } */

  refreshDerivedQuestion(fabId: string): void {
    this.itemRefresh = true;
    this.idToRefresh = fabId;
    this.cbpRequests
      .refreshDerivedQuestion(
        this.brandId,
        fabId,
        EFabricationEndpointType.DerivedQuestions
      )
      .pipe(
        tap(() => {
          this.itemRefresh = false;
          this.idToRefresh = null;
        }),
        take(1),
        catchError(() => {
          this.itemRefresh = false;
          this.idToRefresh = null;
          return EMPTY;
        })
      )
      .subscribe();
  }
}
