<div class="content">
  <div class="title" *ngIf="exportKind">
    <h2 class="h2">
      {{ 'surveyExporter.' + exportKind + 'ExportTitle' | translate }}
    </h2>
    <p>
      {{ 'surveyExporter.' + exportKind + 'Exportinformation' | translate }}
    </p>
  </div>

  <div class="questions-selector">
    <div class="header">
      <ng-container *ngIf="actionsLoaded">
        <div class="questions-list-header">
          <div>
            <igx-checkbox
              [disabled]="inprogress || !tableViewsLoaded || inprogress"
              [readonly]="true"
              [(ngModel)]="masterCheckbox.checked"
              [indeterminate]="masterCheckbox.indeterminate"
              [ngModelOptions]="{ standalone: true }"
              (click)="onSelectAllClick()"
            >
            </igx-checkbox>
            <span>{{ 'crosstab.selectAll' | translate }}</span>
          </div>
          <div>
            <span>{{ 'crosstab.tableView' | translate }}</span>
          </div>
        </div>
      </ng-container>
      <igx-circular-bar
        *ngIf="loadingActions"
        [animate]="false"
        [indeterminate]="true"
        [textVisibility]="false"
      ></igx-circular-bar>
    </div>
    <div class="questions-list" *ngIf="actionsLoaded">
      <div class="questions-list-row" *ngFor="let item of actionsData">
        <igx-checkbox
          [disabled]="
            item.tableViews.loading || !item.tableViews.loaded || inprogress
          "
          [checked]="item.checked ?? false"
          (change)="onSelectActionChange($event, item.action)"
        ></igx-checkbox>
        <div class="question">
          <div class="action-icon">
            <ng-container
              *ngIf="item.action.action_kind === actionKind.multiplechoice"
            >
              <igx-icon
                fontSet="material"
                *ngIf="
                  item.action.multiple_choice.multiple_choice_kind ===
                  multipleChoiceKind.standard
                "
                >radio_button_checked</igx-icon
              >
              <igx-icon
                fontSet="material"
                *ngIf="
                  item.action.multiple_choice.multiple_choice_kind ===
                  multipleChoiceKind.monadic
                "
                >linear_scale</igx-icon
              >
            </ng-container>
            <igx-icon
              fontSet="material"
              *ngIf="item.action.action_kind === actionKind.openended"
              >sort</igx-icon
            >
            <igx-icon
              fontSet="material"
              *ngIf="item.action.action_kind === actionKind.openended_video"
              >play_circle_filled</igx-icon
            >
            <igx-icon
              fontSet="material"
              *ngIf="item.action.action_kind === actionKind.photoacquisition"
              >photo_camera</igx-icon
            >
            <igx-icon
              family="suzy"
              name="maxdiff"
              *ngIf="item.action.action_kind === actionKind.maxdiff"
            ></igx-icon>
            <igx-icon
              family="material"
              *ngIf="item.action.action_kind === actionKind.auto_assign"
              >people</igx-icon
            >
            <ng-container
              *ngIf="
                item.action.action_kind === actionKind.grid ||
                item.action.action_kind === actionKind.gridcustom ||
                item.action.action_kind === actionKind.gridrankscale
              "
            >
              <igx-icon
                family="suzy"
                name="grid_rank"
                *ngIf="item.action.grid.grid_kind === gridKind.rank"
              ></igx-icon>
              <igx-icon
                family="suzy"
                name="grid_scale"
                *ngIf="item.action.grid.grid_kind === gridKind.scale"
              ></igx-icon>
              <igx-icon
                family="material"
                *ngIf="item.action.grid.grid_kind === gridKind.open"
                >grid_on</igx-icon
              >
            </ng-container>
            <igx-icon
              family="suzy"
              name="turf"
              class="no-color-fill"
              *ngIf="item.action.action_kind === actionKind.turf"
            ></igx-icon>
          </div>

          <span [innerHTML]="item.action.search_text | async"></span>
        </div>
        <igx-select
          [disabled]="
            item.tableViews.loading ||
            !item.tableViews.loaded ||
            !item.checked ||
            inprogress
          "
          [(ngModel)]="item.selectedTableView"
          (selectionChanging)="onSelectTableViewChange($event)"
        >
          <igx-select-item
            *ngFor="let tableView of item.tableViews.data"
            [value]="tableView.table_view_id"
          >
            {{ tableView.display_name }}
          </igx-select-item>
        </igx-select>
      </div>
    </div>
  </div>
</div>

<div class="actions">
  <div class="loading" *ngIf="inprogress">
    <igx-circular-bar
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
    ></igx-circular-bar>
  </div>
  <span class="warning" *ngIf="!inprogress && errorMsg">{{ errorMsg }}</span>
  <button
    class="builderAction suzy-secondary-text"
    [disabled]="inprogress"
    (click)="onCancelClick()"
  >
    {{ 'builder.cancel' | translate }}
  </button>
  <button
    class="builderAction suzy-primary"
    [disabled]="inprogress || !actionsLoaded || !actionsSelected"
    (click)="onApplyClick()"
  >
    {{ 'builder.apply' | translate }}
  </button>
</div>
