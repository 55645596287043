import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EFabricationEndpointType, EFabricationType } from '../../../enums/cbp-enum';

@Component({
  selector: 'cbp-tab-header',
  templateUrl: './cbp-tab-header.component.html',
  styleUrls: ['./cbp-tab-header.component.scss'],
})
export class CbpTabHeaderComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() learnMoreLink!: string;
  @Input() type!: EFabricationEndpointType;
  @Input() refreshing = false;

  @Output() readonly refreshAll = new EventEmitter<boolean>(false);
  @Output() readonly createNew = new EventEmitter<boolean>(false);

  fabricationType: typeof EFabricationEndpointType = EFabricationEndpointType;

  create(): void {
    this.createNew.emit(true);
  }

  refresh(): void {
    this.refreshAll.emit(true);
  }
}
