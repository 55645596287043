<div class="title">
  <h3
    [innerHTML]="
      question.length > 250 ? (question | slice: 0:250) + '...' : question
    "
  ></h3>
</div>

<div style="position: relative">
  <igx-data-chart #chart>
    <igx-category-y-axis #chartYAxis></igx-category-y-axis>
    <igx-numeric-x-axis #chartXAxis></igx-numeric-x-axis>
    <ng-container *ngFor="let col of cols; index as i; trackBy: identify">
      <igx-bar-series
        #barSeries
        [tooltipTemplate]="tooltipBarItem"
      ></igx-bar-series>
      <igx-callout-layer #barValuesLayer></igx-callout-layer>
    </ng-container>
    <ng-container *ngIf="chartType === 'single'">
      <igx-bar-series
        #barSeries
        [tooltipTemplate]="tooltipBarItem"
      ></igx-bar-series>
      <igx-callout-layer #barValuesLayer></igx-callout-layer>
    </ng-container>
  </igx-data-chart>
</div>

<div *ngIf="chartType === 'single'" style="padding: 16px 0px">&nbsp;</div>

<div
  class="custom-legend"
  *ngIf="chartType === 'multiple' && !chartSizeWarning"
>
  <div class="legend-cell" *ngFor="let col of cols; trackBy: identify">
    <span
      class="color"
      [ngStyle]="{ 'background-color': col.brushColor }"
    ></span>
    <div class="legend-rows">
      <div class="legend-row" *ngFor="let row of col.legendData">
        <span
          *ngIf="row.label"
          class="label"
          [innerHTML]="row.label + ' :'"
        ></span>
        <span *ngIf="row.value" class="name" [innerHTML]="row.value"></span>
      </div>
    </div>
  </div>
</div>

<ng-template let-series="series" let-item="item" #tooltipBarItem>
  <div class="tooltip-container">
    <ng-container *ngIf="chartType === 'multiple'">
      <span class="title"> {{ series.title }} </span>
    </ng-container>
    <ng-container *ngIf="chartType === 'single'">
      <span class="title">
        {{ item.name }}
      </span>
    </ng-container>
    <div class="value-container">
      <span
        class="circle"
        [ngStyle]="{ backgroundColor: series.actualBrush }"
      ></span>
      <span class="value" *ngIf="!isPercent">{{
        item[series.valueMemberPath]
      }}</span>
      <span class="value" *ngIf="isPercent">{{
        item[series.valueMemberPath] / 100 | percent: '.0'
      }}</span>
    </div>
  </div>
</ng-template>

<igx-dialog
  #chart_warning_dialog
  [message]="'crosstab.chartSizeWarning' | translate"
>
  <div igxDialogActions class="dialog-container dialog-actions">
    <button class="right" igxButton="flat" igxRipple (click)="onWarningClose()">
      {{ 'builder.confirm_close' | translate }}
    </button>
  </div>
</igx-dialog>
