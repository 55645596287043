/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError, map, switchMap } from 'rxjs';
import {
  MissionKind,
  MissionStatus,
  ActionKind,
} from '@asksuzy/typescript-sdk';
import { IAction, IMission } from '../data';
import { SuzySdk } from './suzy-sdk.service';

@Injectable()
export class SuzyDataService {
  constructor(private suzySDK: SuzySdk) {}

  brandGetPropertyValue(
    brand_id: string,
    property_name: string
  ): Observable<string> {
    return new Observable<string>((observable) => {
      this.suzySDK.Brand.getBrand(brand_id).subscribe({
        next: (data: { success: boolean; item?: any }) => {
          if (!data?.success || !data?.item || !data?.item?.property_values) {
            observable.next(undefined);
            return;
          }
          const { property_values } = data.item;
          if (
            Object.prototype.hasOwnProperty.call(property_values, property_name)
          ) {
            observable.next(data.item.property_values[property_name]);
            return;
          }
          observable.next(undefined);
        },
        error: (error: any) => {
          observable.error(error);
        },
      });
    });
  }

  missionsFind(
    brand_id: string,
    isBrandGlobal: boolean,
    keyword?: string
  ): Observable<Array<IMission>> {
    const take = !keyword ? 10 : 25;
    const isGlobal = isBrandGlobal;
    const search$: Array<
      Observable<{ items: any[]; paging: any; stepping: any; success: boolean }>
    > = [];
    const missionStatus = [
      MissionStatus.complete.toString(),
      MissionStatus.active.toString(),
    ].join(',');
    const missionSearch$ = this.suzySDK.Mission.getForBrand({
      brand_id: brand_id,
      mission_status: missionStatus,
      keyword: keyword,
      take,
    });
    search$.push(missionSearch$);
    if (isGlobal) {
      const globalMissionSearch$ = this.suzySDK.Mission.getForBrand({
        brand_id: brand_id,
        mission_kind: MissionKind.external_cint.toString(),
        mission_status: missionStatus,
        keyword: keyword,
        take,
      });
      search$.push(globalMissionSearch$);
    }

    return forkJoin(search$).pipe(
      map(
        (
          responses: Array<{
            items: any[];
            paging: any;
            stepping: any;
            success: boolean;
          }>
        ) => {
          let missions: IMission[] = [];
          (responses ?? []).forEach((resp) => {
            const items = resp.items
              .filter((mission) => {
                return (
                  mission.mission_kind !== MissionKind.focusgroup &&
                  mission.first_action?.action_kind !==
                    ActionKind.openended_video &&
                  mission.first_action?.action_kind !==
                    ActionKind.photoacquisition
                );
              })
              .sort((a, b) => a.search_text.localeCompare(b.search_text))
              .map((mission) => {
                if (mission.mission_kind === MissionKind.external_cint) {
                  mission.search_text = `[Global] ${mission.search_text}`;
                }
                return mission;
              });

            missions = missions.concat(items);
            if (!keyword) {
              missions.sort((a, b) => {
                if (a.stamp_utc < b.stamp_utc) {
                  return 1;
                }
                if (a.stamp_utc > b.stamp_utc) {
                  return -1;
                }
                return 0;
              });
              missions = missions.slice(0, 10);
            }
          });
          return missions;
        }
      )
    );
  }

  getMission(brandId: string, missionId: string): Observable<IMission> {
    return this.suzySDK.Mission.getMission({
      brand_id: brandId,
      mission_id: missionId,
    }).pipe(
      map((data: any) => {
        if (!data.success) {
          throw data;
        }

        const mission: IMission = data.item;
        return mission;
      })
    );
  }

  getActionsForMission(
    brand_id: string,
    mission_id: string
  ): Observable<Array<IAction>> {
    return this.suzySDK.Action.getActionsForMission({
      brand_id,
      mission_id,
    }).pipe(
      switchMap((response: any) => {
        if (!response?.success) {
          throwError(() => response);
        }

        if (!response?.items?.length) {
          return this.getMission(brand_id, mission_id).pipe(
            map((mission: IMission) => {
              const items = [] as Array<any>;
              if (mission?.first_action) {
                items.push(mission.first_action);
              }
              return items;
            })
          );
        }

        return of(response?.items ?? []);
      })
    );
  }
}
