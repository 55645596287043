import { TranslateService } from '@ngx-translate/core';
import { IDimensionProvider } from '../i-dimension-provider';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilder } from '../../i-builder';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { MissionTargeting } from '../../../../models/suzy/MissionTargeting.model';
import { IQuota } from '../../../../models/suzy/IQuota';
import { QuotaDimensionProvider } from './quota-dimension-provider';
import {
  BrandClusterKind,
  Gender,
  MissionKind,
  QueryFilterKind,
} from '@asksuzy/typescript-sdk';
import { SuzyDataService } from '../../../../services/suzy-data.service';
import { forkJoin } from 'rxjs';
import { DemographicDimensionProvider } from './demographic-dimension-provider';
import { IMission } from '../../../../models/suzy/IMission';
import { QueryFilterAge } from '../../../../models/suzy/QueryFilterAge.model';

export class TargetingDimensionProvider implements IDimensionProvider {
  private targeting?: MissionTargeting;
  private mission?: IMission;
  private quota?: IQuota;
  private hasAgeTargeting!: boolean;
  private hasGenderTargeting!: boolean;
  private hasSegmentTargeting!: boolean;
  private hasPanelTargeting!: boolean;
  private hasNonInterlockedQuotas!: boolean;
  private hasInterlockedQuotas!: boolean;
  private hasDemographicTargets!: boolean;
  private readonly demographicFilterKinds = [
    QueryFilterKind.education,
    QueryFilterKind.employment_status,
    QueryFilterKind.household_size,
    QueryFilterKind.income_level,
    QueryFilterKind.parenting_status,
    QueryFilterKind.relationship_status,
    QueryFilterKind.ethnicity,
    QueryFilterKind.state,
    QueryFilterKind.region,
  ];
  code = 'targeting';
  name!: string;
  instructions = 'builder.targetingTabInstructions';
  dimensions: Array<IBuilderDimension> = [];
  loading = false;
  loaded = false;
  enabled = false;
  error?: string;
  brand_id?: string;
  mission_id?: string;

  constructor(
    private translate: TranslateService,
    private suzy: SuzyDataService
  ) {
    this.name = this.translate.instant('builder.dimensionTargeting');
  }

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (context.mission?.mission_kind === MissionKind.external_link) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }

    if (this.brand_id != context.brand_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    if (this.mission_id != context.mission?.mission_id) {
      this.dimensions = [];
      this.loaded = false;
      this.mission = context.mission;
    }
    this.brand_id = context.brand_id;
    this.mission_id = context.mission?.mission_id;
    this.error = undefined;

    if (!this.loaded && this.brand_id && this.mission_id) {
      const { mission } = context;
      const { targets, targeting, quota } = mission ?? {
        target: undefined,
        targeting: undefined,
        quota: undefined,
      };
      if (!targeting && targets?.length) {
        this.targeting = MissionTargeting.fromJsonData(targets);
      } else {
        this.targeting = targeting;
      }
      this.quota = quota;
      if (quota) {
        config.meta.quota_fields = quota.quota_fields;
      }
      this.checkTargeting();
      if (this.enabled) {
        this.dimensions = this.preapreDimensions(context, builder, config);
      }

      this.loaded = true;
    }

    return this.enabled;
  }

  private preapreDimensions(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): IBuilderDimension[] {
    const dimensions: IBuilderDimension[] = [];
    if (this.hasInterlockedQuotas || this.hasNonInterlockedQuotas) {
      const quotaDimProvider = new QuotaDimensionProvider(this.translate);
      quotaDimProvider.initialize(context, builder, config);
      dimensions.push(...quotaDimProvider.dimensions);
    }
    if (this.hasSegmentTargeting || this.hasPanelTargeting) {
      this.addClusterGroupDimensions(dimensions, builder);
    }
    if (this.hasDemographicTargets) {
      this.addDemographicTargetDimensions(dimensions);
    }
    if (this.hasGenderTargeting) {
      this.addGenderDimension(dimensions);
    }
    if (this.hasAgeTargeting) {
      this.addAgeGroupDimension(dimensions);
    }
    return dimensions;
  }

  private checkEnabled(): void {
    this.enabled =
      this.hasInterlockedQuotas ||
      this.hasNonInterlockedQuotas ||
      this.hasSegmentTargeting ||
      this.hasPanelTargeting ||
      this.hasDemographicTargets ||
      this.hasGenderTargeting;
  }

  private checkTargeting(): void {
    this.hasInterlockedQuotas = this.quota?.is_interlocked ?? false;
    this.hasNonInterlockedQuotas =
      !!this.quota && !(this.quota?.is_interlocked ?? false);
    this.hasSegmentTargeting = (
      this.targeting?.clusterCustomQueryFilters ?? []
    ).some(
      (x) =>
        x.kind === QueryFilterKind.cluster_group &&
        x.brandClusterKind == BrandClusterKind.standard
    );
    this.hasPanelTargeting = (
      this.targeting?.clusterCustomQueryFilters ?? []
    ).some(
      (x) =>
        x.kind === QueryFilterKind.cluster_group &&
        x.brandClusterKind == BrandClusterKind.panel
    );

    this.hasDemographicTargets = (
      this.targeting?.customQueryFilters ?? []
    ).some((x) => this.demographicFilterKinds.some((kind) => kind === x.kind));

    if (this.targeting?.genderQueryFilter) {
      this.hasGenderTargeting =
        this.targeting.genderQueryFilter.gender !== Gender.unknown ||
        (this.targeting.genderQueryFilter.gender === Gender.unknown &&
          (this.mission?.shapes ?? []).length > 0);
    } else {
      this.hasGenderTargeting = false;
    }

    if (
      this.targeting?.ageQueryFilter &&
      (this.targeting.ageQueryFilter.ageRange ?? []).length >= 2
    ) {
      const minAge = this.targeting.ageQueryFilter.ageRange[0];
      const maxAge = this.targeting.ageQueryFilter.ageRange[1];
      const minDefault = QueryFilterAge.AGE_LIMITS.min;
      const maxDefault = QueryFilterAge.AGE_LIMITS.max;
      this.hasAgeTargeting = minAge !== minDefault || maxAge !== maxDefault;
    } else {
      this.hasAgeTargeting = false;
    }

    this.checkEnabled();
  }

  private addClusterGroupDimensions(
    dimensions: IBuilderDimension[],
    builder: IBuilder
  ): void {
    if (!this.brand_id) {
      return;
    }
    if (!this.hasSegmentTargeting && !this.hasPanelTargeting) {
      return;
    }

    const targetIds = this.targeting?.clusterCustomQueryFilters
      .map((x) => x.cluster_group_id ?? '')
      .filter((x) => x !== '');
    if (!targetIds?.length) {
      return;
    }

    const get$ = [
      this.suzy.audienceSegmentsGet(this.brand_id),
      this.suzy.audiencePanelsGet(this.brand_id),
    ];

    forkJoin(get$).subscribe(([segments, panels]) => {
      const targetedSegments = (segments ?? []).filter((x) =>
        targetIds.some((y) => y === x.id)
      );
      const targetedPanels = (panels ?? []).filter((x) =>
        targetIds.some((y) => y === x.id)
      );

      if (!targetedPanels?.length && !targetedSegments?.length) {
        return;
      }

      const newDimensions = [...targetedSegments, ...targetedPanels].map(
        (item) => {
          return {
            type: 'field',
            label: item.name,
            field: {
              source: 'answer',
              field: 'targeted_brand_cluster_id',
              action_id: 'primary',
              identifier: item.id,
            },
          } as IBuilderDimension;
        }
      );

      if (newDimensions?.length) {
        builder.dragdrop?.prepareNewItems(this.code, newDimensions);
        dimensions.push(...newDimensions);
      }
    });
  }

  private addDemographicTargetDimensions(
    dimensions: IBuilderDimension[]
  ): void {
    const demographicDimensions = DemographicDimensionProvider.DIMENSIONS();

    (this.targeting?.customQueryFilters ?? []).forEach((filter) => {
      let dimFound: IBuilderDimension | undefined = undefined;
      switch (filter.kind) {
        case QueryFilterKind.education:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'education_id'
          );
          break;
        case QueryFilterKind.employment_status:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'employment_id'
          );
          break;
        case QueryFilterKind.household_size:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'household_id'
          );
          break;
        case QueryFilterKind.income_level:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'income_level_id'
          );
          break;
        case QueryFilterKind.parenting_status:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'parenting_id'
          );
          break;
        case QueryFilterKind.relationship_status:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'relationship_id'
          );
          break;
        case QueryFilterKind.ethnicity:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'ethnicity_id'
          );
          break;
        case QueryFilterKind.state:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'state_id'
          );
          break;
        case QueryFilterKind.region:
          dimFound = demographicDimensions.find(
            (x) => x.field?.field == 'region_id'
          );
          break;
        default:
          break;
      }

      if (dimFound) {
        dimensions.push(dimFound);
      }
    });
  }

  private addGenderDimension(dimensions: IBuilderDimension[]): void {
    const demographicDimensions = DemographicDimensionProvider.DIMENSIONS();
    const dimFound = demographicDimensions.find(
      (x) => x.field?.field == 'gender'
    );
    if (dimFound) {
      dimensions.push(dimFound);
    }
  }

  private addAgeGroupDimension(dimensions: IBuilderDimension[]): void {
    const demographicDimensions = DemographicDimensionProvider.DIMENSIONS();
    const dimFound = demographicDimensions.find(
      (x) => x.field?.field == 'age_group_id'
    );
    if (dimFound) {
      dimensions.push(dimFound);
    }
  }
}
