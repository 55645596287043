/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SuzyEndpoints, SuzyRequest } from '@asksuzy/typescript-sdk';
import { BehaviorSubject, Observable, Subject, filter, map } from 'rxjs';
import { Md5 } from 'ts-md5';

@Injectable()
export class SuzySdk extends SuzyEndpoints implements OnDestroy {
  baseUrl!: string;
  apiKeys: { api_key: string; api_secret: string } = {
    api_key: '',
    api_secret: '',
  };
  sdkConnected$ = new BehaviorSubject<boolean>(false);
  unsubscribe$ = new Subject<void>();

  get signature(): string | Int32Array {
    return this.generateSignature();
  }

  get apiKey(): string {
    return this.apiKeys.api_key ?? '';
  }

  constructor(private http: HttpClient) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initialize(baseUrl = '/api'): SuzySdk {
    this.baseUrl = baseUrl;
    this.constructEndpoints(this);
    return this;
  }

  setApiCredentials(apiKey: string, apiSecret: string): void {
    this.apiKeys = {
      api_key: apiKey,
      api_secret: apiSecret,
    };
  }

  clearApiCredentials(): void {
    this.apiKeys = {
      api_key: '',
      api_secret: '',
    };
  }

  getSignature(): { key: string; signature: string | Int32Array } | undefined {
    const { api_key, api_secret } = this.apiKeys;
    if (api_key && api_secret && this.signature !== undefined) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        key: api_key,
        signature: this.generateSignature(),
      };
    }

    return undefined;
  }

  private generateSignature(): string | Int32Array {
    const credentials = this.apiKeys;

    return Md5.hashStr(
      // tslint:disable-next-line:no-bitwise
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      credentials.api_key! + credentials.api_secret! + ((Date.now() / 1000) | 0)
    );
  }

  private generateHeaders(): HttpHeaders {
    const type = 'application/json';
    const platform = this.getPlatform();
    const signature = this.getSignature();

    let headers = new HttpHeaders()
      .set('Content-Type', type)
      .set('x-device-platform', platform);

    if (signature !== undefined) {
      headers = headers
        .append('x-api-key', signature.key)
        .append('x-api-signature', signature.signature.toString());
      const brandId = localStorage.getItem('suzy_brand') ?? '';
      if (brandId) {
        headers = headers.append('x-tenant', brandId);
      }
    }

    return headers;
  }

  private getPlatform(): string {
    const platform = 'client';
    return platform;
  }

  override execute(request: SuzyRequest): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    // eslint-disable-next-line no-var
    var data;

    data = request.payload || request.params;

    if (!request.payload) {
      for (const key in data) {
        // Server may expect empty string instead of null value, so send empty string as default.
        if (data[key] === null) {
          data[key] = '';
        }
      }
    }

    const promise: Observable<any> = this.http.request(
      new HttpRequest(
        request.method,
        `${self.baseUrl}/${request.resource}`,
        data,
        {
          withCredentials: true,
          headers: this.generateHeaders(),
          params: new HttpParams({ fromObject: request.params }),
        }
      )
    );

    return promise.pipe(
      filter((result) => result.type === 4),
      map((result) => result.body)
    );
  }
}
