<div class="calculations-modal">
  <div class="modal-header">
    <div class="modal-header-left">
      <span class="h2 text-suzy-grey-900">{{ 'calculations.editorTitle' | translate }}</span>
      <span class="body-copy-small text-suzy-grey-800">{{ 'calculations.editorTitleSub' | translate }}</span>
    </div>
    <button class="suzy-icon-button" (click)="onClose()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>

  <div class="modal-body">
    <igx-expansion-panel
      *ngIf="summaryMetricsEnabled"
      [collapsed]="statTestingEnabled || advancedEnabled"
    >
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>{{
          'calculations.summaryMetrics' | translate
        }}</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-calculations-scale-rank-top-bottom
          kind="top"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'scaleRankTop')"
          #scale_rank_top
        ></app-calculations-scale-rank-top-bottom>
        <app-calculations-scale-rank-top-bottom
          kind="bottom"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'scaleRankBottom')"
          #scale_rank_bottom
        ></app-calculations-scale-rank-top-bottom>
        <app-calculations-mean-score
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'means')"
          #mean_score
        ></app-calculations-mean-score>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
    <igx-expansion-panel
      *ngIf="statTestingEnabled"
      [collapsed]="summaryMetricsEnabled || advancedEnabled"
    >
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>{{
          'calculations.statTesting' | translate
        }}</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-calculations-stat-testing
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'statTesting')"
          #stat_testing
        ></app-calculations-stat-testing>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
    <igx-expansion-panel
      *ngIf="advancedEnabled"
      [collapsed]="summaryMetricsEnabled || statTestingEnabled"
    >
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>{{
          'calculations.advanced' | translate
        }}</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <span class="section-header">{{
          'builder.previewColumn' | translate
        }}</span>
        <app-calculations-column-total
          kind="base_column"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'columnTotalBase')"
          #column_total_base
        ></app-calculations-column-total>
        <app-calculations-column-total
          kind="responses_column"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="
            onCalculationsChanged($event, 'columnTotalResponses')
          "
          #column_total_responses
        ></app-calculations-column-total>
        <span class="section-header">{{ 'builder.previewRow' | translate }}</span>
        <app-calculations-column-total
          kind="base_row"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'rowTotalBase')"
          #row_total_base
        ></app-calculations-column-total>
        <app-calculations-column-total
          kind="responses_row"
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="
            onCalculationsChanged($event, 'rowTotalResponses')
          "
          #row_total_responses
        ></app-calculations-column-total>
        <span class="section-header">{{ 'calculations.spam' | translate }}</span>
        <app-calculations-spam-responses
          #include_spam_responses
          [context]="context"
          [crosstabConfig]="crosstabConfig"
          (calculationsChanged)="onCalculationsChanged($event, 'spamResponses')"
        ></app-calculations-spam-responses>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
  </div>

  <div class="modal-footer-right">
    <div class="filter-content">
      <span *ngIf="!hasCalculations">&nbsp;</span>
      <div *ngIf="hasCalculations" class="applied-filter">
        <span class="bold"
          >{{
            'calculations.calculationsApplied' | translate
          }}&nbsp;:&nbsp;&nbsp;</span
        >
        <ng-container *ngFor="let tag of calculationsTags">
          <ng-container [ngSwitch]="tag.kind">
            <span *ngSwitchCase="tagKind.token">{{
              tag.value | translate
            }}</span>
            <span *ngSwitchDefault>{{ tag.value }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="action-buttons">
      <button
        *ngIf="hasCalculations"
        class="reset suzy-secondary"
        (click)="onResetCalculations()"
      >
        <igx-icon fontSet="material">replay</igx-icon>
        {{ 'crosstab.reset' | translate }}
      </button>
      <button class="suzy-secondary" (click)="onClose()">
        {{ 'builder.cancel' | translate }}
      </button>
      <button class="suzy-primary" (click)="onClose(true)">
        {{ 'crosstab.save' | translate }}
      </button>
    </div>
  </div>
</div>
