import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthSessionService } from './services/auth-session.service';

@Component({
  selector: 'data-explorer-elements-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  sessionReady$: Observable<boolean>;

  constructor(private authService: AuthSessionService) {
    this.sessionReady$ = this.authService.sessionReady$;
  }
}
