/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryFilter } from './QueryFilter.model';
import { QueryFilterKind, Gender } from '@asksuzy/typescript-sdk';

export class QueryFilterGender extends QueryFilter {
  override kind: QueryFilterKind = QueryFilterKind.gender;
  gender: Gender;

  static fromJsonData(filter: any): QueryFilterGender {
    return new QueryFilterGender(filter.gender);
  }

  constructor(gender: Gender = Gender.unknown) {
    super(QueryFilterKind.gender);

    this.gender = gender;
  }

  override toJsonData(): any {
    return {
      ...super.toJsonData(),
      ...{ gender: this.gender === null ? Gender.unknown : this.gender },
    };
  }
}
