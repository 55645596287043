<igx-card>
  <igx-card-header>
    <h2 class="h2">
      {{ 'crosstab.applyTableView' | translate }}
    </h2>
  </igx-card-header>

  <igx-card-content>
    <div class="info-container">
      <span class="header-sub">
        {{ 'crosstab.applyTableViewInfo' | translate }}
      </span>
    </div>
    <form [formGroup]="pageForm">
      <div class="form-section">
        <span class="header">{{ 'crosstab.include' | translate }}</span>
        <igx-checkbox
          *ngIf="hasIncludeDemographics"
          formControlName="includeDemographics"
        >
          {{ 'crosstab.demographics' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeFabrications"
          formControlName="includeFabrications"
        >
          {{ 'customBannerPoints.subPopulations.tabTitle' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeDerivedQuestions"
          formControlName="includeDerivedQuestions"
        >
          {{ 'customBannerPoints.derivedQuestions.tabTitle' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeExternalAttributes"
          formControlName="includeExternalAttributes"
        >
          {{ 'builder.dimensionExternalAttributes' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeSegmentsPanels"
          formControlName="includeSegmentsPanels"
        >
          {{ 'builder.dimensionSegmentsPanels' | translate }}
        </igx-checkbox>
        <igx-checkbox *ngIf="hasIncludeQuotas" formControlName="includeQuotas">
          {{ 'builder.dimensionQuotas' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeCrosstabQuestion"
          formControlName="includeCrosstabQuestion"
        >
          {{ 'builder.crosstabQuestion' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeFilters"
          formControlName="includeFilters"
        >
          {{ 'crosstab.filters' | translate }}
        </igx-checkbox>
        <igx-checkbox
          *ngIf="hasIncludeCalculations"
          formControlName="includeCalculations"
        >
          {{ 'builder.calculations' | translate }}
        </igx-checkbox>
      </div>
      <div class="form-section no-gap">
        <span class="header">{{ 'crosstab.duplicateTo' | translate }}</span>
        <div class="action-form-row header">
          <div class="header-container">
            <div class="select-all-container">
              <igx-checkbox
                [disabled]="inprogress"
                [readonly]="true"
                [(ngModel)]="masterCheckbox.checked"
                [ngModelOptions]="{ standalone: true }"
                [indeterminate]="masterCheckbox.indeterminate"
                (click)="onSelectAllChange()"
              >
              </igx-checkbox>
              <span class="action-caption header">{{
                'crosstab.selectAll' | translate
              }}</span>
            </div>
            <div class="header-info" *ngIf="actionsSelected">
              <span>{{ 'crosstab.customName' | translate }}</span>
              <igx-icon
                fontset="material"
                #target="tooltipTarget"
                [igxTooltipTarget]="customNameInfo"
                >info_outline</igx-icon
              >
            </div>
          </div>

          <div #customNameInfo="tooltip" igxTooltip class="tooltip">
            {{ 'crosstab.customNameTooltip' | translate }}
          </div>
        </div>
        <ng-container *ngIf="actionsLoaded" formArrayName="actions">
          <ng-container
            *ngFor="
              let actionGroup of actionsFormArray.controls;
              let ix = index
            "
          >
            <div class="action-form-row" [formGroup]="actionGroup">
              <div
                class="left"
                [ngClass]="{
                  'full-width': !actionGroup.controls?.Included?.value
                }"
              >
                <igx-checkbox formControlName="Included"> </igx-checkbox>

                <div class="action-icon">
                  <ng-container
                    *ngIf="
                      actions[ix].action.action_kind ===
                      actionKind.multiplechoice
                    "
                  >
                    <igx-icon
                      fontSet="material"
                      *ngIf="
                        actions[ix].action.multiple_choice
                          .multiple_choice_kind === multipleChoiceKind.standard
                      "
                      >radio_button_checked</igx-icon
                    >
                    <igx-icon
                      fontSet="material"
                      *ngIf="
                        actions[ix].action.multiple_choice
                          .multiple_choice_kind === multipleChoiceKind.monadic
                      "
                      >linear_scale</igx-icon
                    >
                  </ng-container>
                  <igx-icon
                    fontSet="material"
                    *ngIf="
                      actions[ix].action.action_kind === actionKind.openended
                    "
                    >sort</igx-icon
                  >
                  <igx-icon
                    fontSet="material"
                    *ngIf="
                      actions[ix].action.action_kind ===
                      actionKind.openended_video
                    "
                    >play_circle_filled</igx-icon
                  >
                  <igx-icon
                    fontSet="material"
                    *ngIf="
                      actions[ix].action.action_kind ===
                      actionKind.photoacquisition
                    "
                    >photo_camera</igx-icon
                  >
                  <igx-icon
                    family="suzy"
                    name="maxdiff"
                    *ngIf="
                      actions[ix].action.action_kind === actionKind.maxdiff
                    "
                  ></igx-icon>
                  <igx-icon
                    family="material"
                    *ngIf="
                      actions[ix].action.action_kind === actionKind.auto_assign
                    "
                    >people</igx-icon
                  >
                  <ng-container
                    *ngIf="
                      actions[ix].action.action_kind === actionKind.grid ||
                      actions[ix].action.action_kind ===
                        actionKind.gridcustom ||
                      actions[ix].action.action_kind ===
                        actionKind.gridrankscale
                    "
                  >
                    <igx-icon
                      family="suzy"
                      name="grid_rank"
                      *ngIf="
                        actions[ix].action.grid.grid_kind === gridKind.rank
                      "
                    ></igx-icon>
                    <igx-icon
                      family="suzy"
                      name="grid_scale"
                      *ngIf="
                        actions[ix].action.grid.grid_kind === gridKind.scale
                      "
                    ></igx-icon>
                    <igx-icon
                      family="material"
                      *ngIf="
                        actions[ix].action.grid.grid_kind === gridKind.open
                      "
                      >grid_on</igx-icon
                    >
                  </ng-container>
                  <igx-icon
                    family="suzy"
                    name="turf"
                    class="no-color-fill"
                    *ngIf="actions[ix].action.action_kind === actionKind.turf"
                  ></igx-icon>
                </div>
                <span
                  class="action-caption"
                  [innerHTML]="actions[ix].action.search_text | async"
                >
                </span>
              </div>

              <div class="right">
                <igx-input-group
                  type="border"
                  *ngIf="actionGroup.controls?.Included?.value"
                >
                  <input
                    igxInput
                    name="TableViewName"
                    formControlName="TableViewName"
                    type="text"
                    autocomplete="off"
                  />
                </igx-input-group>

                <span
                  class="warning"
                  *ngIf="
                    actionGroup.controls?.Included?.value &&
                    actionGroup.controls?.TableViewName?.hasError(
                      'duplicateName'
                    )
                  "
                >
                  {{ 'crosstab.duplicateTableViewNameGeneric' | translate }}
                </span>

                <div class="action-icon progress">
                  <igx-icon *ngIf="actions[ix].duplicate?.failed"
                    >close</igx-icon
                  >
                  <igx-icon *ngIf="actions[ix].duplicate?.success"
                    >done</igx-icon
                  >
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </igx-card-content>

  <igx-card-actions>
    <igx-circular-bar
      *ngIf="inprogress"
      [animate]="false"
      [indeterminate]="true"
      [textVisibility]="false"
    ></igx-circular-bar>
    <button
      class="builderAction"
      igxButton="flat"
      [disabled]="inprogress"
      (click)="cancelClick.emit()"
    >
      {{ 'builder.cancel' | translate }}
    </button>
    <button
      class="builderAction save"
      igxButton="raised"
      [disabled]="
        inprogress || !includesSelected || !actionsSelected || pageForm.invalid
      "
      (click)="onSaveClick()"
    >
      {{ 'builder.apply' | translate }}
    </button>
  </igx-card-actions>
</igx-card>
