<div class="active-filters">
  <ng-container
    class="filter-box"
    *ngFor="let demographic of selectedDemographics"
  >
    <div class="filter-box" *ngIf="data ? data[demographic].enabled : false">
      <div class="filter-name">
        <span class="name">
          <ng-container *ngIf="demographic !== filterNames.custom_age_group">{{
            'filters.' + demographic | translate
          }}</ng-container>
          <ng-container *ngIf="demographic === filterNames.custom_age_group">{{
            'filters.' + demographic | translate: { label: customAgeRangeName }
          }}</ng-container>
        </span>
        <button igxButton="icon" (click)="onRemoveDemographic(demographic)">
          <igx-icon family="suzy" name="delete_custom"></igx-icon>
        </button>
      </div>
      <div class="filter-actions">
        <div class="loading" *ngIf="data && data[demographic].loading">
          <igx-circular-bar
            [animate]="false"
            [indeterminate]="true"
            [textVisibility]="false"
          ></igx-circular-bar>
        </div>
        <igx-checkbox
          *ngIf="
            data && data[demographic].loaded && data[demographic].list?.length
          "
          [readonly]="true"
          [(ngModel)]="data[demographic].allIncluded"
          [indeterminate]="
            !data[demographic].allIncluded &&
            (data[demographic].includedIds?.length ?? 0) > 0
          "
          (click)="onToggleAllOptions(demographic)"
        >
          {{ 'builderFilter.all' | translate }}
        </igx-checkbox>
        <div
          class="warnings"
          *ngIf="
            demographic === filterNames.custom_age_group &&
            data &&
            data[demographic].loaded &&
            !data[demographic].list?.length
          "
        >
          <div class="warning-box">
            <igx-icon>warning</igx-icon>
            <span>{{
              'ageRanges.customAgesNotDefinedWarning' | translate
            }}</span>
          </div>
        </div>
      </div>
      <div class="filter-options">
        <div class="options-grid" *ngIf="data && data[demographic].loaded">
          <div
            class="option-item"
            *ngFor="let option of data[demographic].list"
          >
            <igx-checkbox
              [(ngModel)]="option.include"
              (change)="onChangeOption(demographic)"
              >{{ option.name }}</igx-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="field-selector">
  <igx-select
    *ngIf="selectListVisible"
    [(ngModel)]="selectedDemographicOption"
    (selectionChanging)="onSelectDemographicsChange($event)"
    placeholder="{{ 'filters.selectDemographic' | translate }}"
  >
    <igx-select-item
      *ngFor="let option of demographicsOptions"
      [value]="option.key"
    >
      <ng-container *ngIf="option.key !== filterNames.custom_age_group">{{
        option.label | translate
      }}</ng-container>
      <ng-container *ngIf="option.key === filterNames.custom_age_group">{{
        option.label | translate: { label: customAgeRangeName }
      }}</ng-container>
    </igx-select-item>
  </igx-select>

  <button
    *ngIf="addButtonVisible && demographicsOptions.length"
    igxButton="outlined"
    (click)="onAddButtonClick()"
  >
    <igx-icon fontSet="material">add</igx-icon>
    {{ 'filters.addDemographic' | translate }}
  </button>
</div>
