// Mapping from BB-28 Question Type Table

export const BASE_OPERATORS = [
  { name: 'any of', operator: 'contains' },
  { name: 'none of', operator: 'missing' },
];

const EXTENDED_OPERATORS = [
  ...BASE_OPERATORS,
  { name: 'at least', operator: 'contains' },
  { name: 'exactly', operator: 'strict_match' },
  { name: 'between', operator: 'contains' },
];

export const DEMOGRAPHIC_OPERATORS = BASE_OPERATORS;

export const SEGMENTS_OPERATORS = BASE_OPERATORS;

/* Responses */

export const MULTI_SINGLE_ACTION_OPERATORS = BASE_OPERATORS;

export const MULTI_MULTI_ACTION_OPERATORS = EXTENDED_OPERATORS;

export const RATING_ACTION_OPERATORS = BASE_OPERATORS;

export const SCALE_RANK_ACTION_OPERATORS = BASE_OPERATORS;

export const CUSTOM_SINGLE_ACTION_OPERATORS = [
  { name: 'any of', operator: 'contains' },
];

export const CUSTOM_MULTI_ACTION_OPERATORS = EXTENDED_OPERATORS;

export const AUTO_ACTION_OPERATORS = BASE_OPERATORS;

export const TURF_ACTION_OPERATORS = EXTENDED_OPERATORS;

export const AT_LEAST_CRITERIA = ['at least', 'exactly', 'between'];

export const AT_MOST_CRITERIA = ['exactly', 'between'];

export const RULE_TWO_CRITERIA = ['any of', 'none of', 'at least', 'between'];