
    <igx-dialog-title>
        <div class="flex-container">
            <div class="dialog-header">
                <igx-icon fontSet="material">warning</igx-icon>
                <span class="h2">{{ 'crosstabWontProcessDialog.title' | translate }}</span>
            </div>
            <button class="remove-button" igxButton="icon" (click)="close()">
                <igx-icon>close</igx-icon>
            </button>
        </div>
    </igx-dialog-title>
    <div class="try-queue">
        <div class="try-queue-caption body-copy-regular">
            <p>
                {{ 'crosstabWontProcessDialog.caption' | translate }}
            </p>
        </div>
    </div>
