import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, map } from 'rxjs';
import { SuzyDataService } from '../services/suzy-data.service';

@Pipe({
  name: 'asyncFilterTag',
})
export class TableViewFilterTagPipe implements PipeTransform {
  constructor(private suzyDataService: SuzyDataService) {}

  transform(
    attribute?: {
      key: 'profilingQuestion' | 'externalAttribute';
      value: string;
      count: number;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): Observable<string> {
    if (!attribute) {
      return of('');
    }
    const { key, value, count } = attribute;
    switch (key) {
      case 'profilingQuestion':
        return this.suzyDataService
          .getBrandProfileQuestions(
            args?.length ? args[0]['brand_id'] ?? '' : '',
            args?.length ? args[0]['mission_id'] ?? '' : ''
          )
          .pipe(
            map((items) => {
              const item = (items ?? []).find((x) => x.id === value);
              if (item?.name) {
                return `${item.name} (${count})`;
              } else {
                return `(${count})`;
              }
            })
          );
      case 'externalAttribute':
        return this.suzyDataService
          .getPublicLinkAttributes(
            args?.length ? args[0]['brand_id'] ?? '' : '',
            args?.length ? args[0]['mission_id'] ?? '' : ''
          )
          .pipe(
            map((items) => {
              const item = (items ?? []).find((x) => x.id === value);
              if (item?.name) {
                return `${item.name} (${count})`;
              } else {
                return `(${count})`;
              }
            })
          );
      default:
        return of(`${value} (${count})`);
    }
  }
}
