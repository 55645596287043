import { InjectionToken } from '@angular/core';
import { IExplorerUI } from './i-explorer-ui';
import {
  EFabricationEndpointType,
  EFabricationType,
} from '../../enums/cbp-enum';
import { IFabItemDetail } from '../fabrication/IFabricationDetail';

export const DATA_TOKEN = new InjectionToken<ICBPFormInputs>('form-inputs');

export interface ICBPFormInputs {
  fabricationType?: EFabricationType;
  translations?: ICBPFormTranslation;
  uiData: IExplorerUI;
  actions?: any[];
  type?: EFabricationEndpointType;
  toEdit?: IFabItemDetail | null;
  toClone?: boolean;
  bannerDimensions?: any;
  selectedTabIndex: number;
}

export interface ICBPFormTranslation {
  title: string;
  subtitle: string;
  placeholderDescription: string;
  labelDescription: string;
  labelName: string;
  namePlaceholder: string;
  validations: {
    nameRequired: string;
    nameExists: string;
    cantValidateName: string;
  };
}
