import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IBuilderContext } from '../i-builder-context';
import { ICrosstabConfigUI } from '../../../models/ui/i-crosstab-config-ui';
import { TableViewService } from '../../../services/table-view.service';
import { ICalculations } from '../../../models/ui/i-calculations-ui.model';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFiltersTag,
} from '../../../models/ui/i-advanced-filters-config.model';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { delay, take } from 'rxjs';

@Component({
  selector: 'app-builder-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.scss'],
})
export class CalculationsComponent implements OnInit {
  hasCalculations!: boolean;
  calculationsStatus!: ICalculations;
  calculationsTags!: IAdvancedFiltersTag[];
  tagKind = EAdvancedFiltersTagKind;
  editorVisible!: boolean;
  editorConfig?: ICrosstabConfigUI;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() crosstabConfigChange = new EventEmitter<ICrosstabConfigUI>();

  @ViewChild('calculations_editor_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private calculations_editor_dialog!: IgxDialogComponent;

  constructor(private tableViewService: TableViewService) {
    this.hasCalculations = false;
    this.editorVisible = false;
  }

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    this.calculationsStatus =
      this.tableViewService.crosstabConfigHasCalculations(
        this.context.advancedCalculationsEnabled ?? false,
        this.crosstabConfig.crosstab
      );

    this.calculationsTags = this.tableViewService.getCalculationsTags(
      this.calculationsStatus
    );

    this.hasCalculations = this.checkHasCalculations(this.calculationsStatus);
  }

  onCalculationsClick(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    this.editorConfig = JSON.parse(JSON.stringify(this.crosstabConfig));
    this.editorVisible = true;
    setTimeout(() => {
      this.calculations_editor_dialog.open();
      this.calculations_editor_dialog.closed
        .pipe(take(1), delay(0))
        .subscribe(() => {
          this.editorVisible = false;
          this.editorConfig = undefined;
        });
    });
  }

  onConfigChanged(newConfig: ICrosstabConfigUI): void {
    this.calculationsStatus =
      this.tableViewService.crosstabConfigHasCalculations(
        this.context?.advancedCalculationsEnabled ?? false,
        newConfig.crosstab
      );

    this.calculationsTags = this.tableViewService.getCalculationsTags(
      this.calculationsStatus
    );

    this.hasCalculations = this.checkHasCalculations(this.calculationsStatus);
    this.crosstabConfigChange.emit(newConfig);
  }

  onColumnBannersRemoved(): void {
    if (!this.crosstabConfig) {
      return;
    }
    if (this.calculationsStatus.statTesting?.included) {
      const additionIx = (
        this.crosstabConfig?.crosstab.additions ?? []
      ).findIndex((x) => x.name === 'stat_test_column');
      if (additionIx >= 0) {
        this.crosstabConfig.crosstab.additions?.splice(additionIx, 1);
        setTimeout(() => {
          if (this.crosstabConfig) {
            this.onConfigChanged(this.crosstabConfig);
          }
        });
      }
      return;
    }
  }

  private checkHasCalculations(filter: ICalculations): boolean {
    return Object.keys(filter).some((x) => filter[x]?.included);
  }
}
