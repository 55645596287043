<form [formGroup]="form">
    <div class="cbp-radio-buttons-wrapper">
        <div class="separator"></div>
        <div class="cbp-radio-group">
            <ng-container *ngFor="let option of radioOptions; let i = index;">
                <input class="cbp-radio-button" formControlName="value" type="radio" [value]="option.value"
                    id="{{ id + '-' + i }}" (change)="onTouch()" />
                <label for="{{ id + '-' + i }}">{{ option.label }}</label>
            </ng-container>
        </div>
        <div class="separator"></div>
    </div>
</form>