import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SecurityContext,
} from '@angular/core';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import {
  IChangeCheckboxEventArgs,
  IgxDialogComponent,
  ISelectionEventArgs,
} from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, take, takeUntil } from 'rxjs';
import { IAction } from '../../../models/suzy/IAction';
import { IMission } from '../../../models/suzy/IMission';
import { ITableView } from '../../../models/ui/ITableView';
import { SuzyDataService } from '../../../services/suzy-data.service';
import { TableViewService } from '../../../services/table-view.service';
import { SegmentService } from '../../../services/segment.service';
import {
  ESegmentTrackActionKind,
  ISegmentTrackBulkExportData,
} from '../../../models/ui/i-segment-track-action';
import { DomSanitizer } from '@angular/platform-browser';

interface ISurveyExporterActionData {
  action: IAction;
  tableViews: { loading: boolean; loaded: boolean; data: ITableView[] };
  selectedTableView?: string;
  checked?: boolean;
}

@Component({
  selector: 'data-explorer-survey-exporter',
  templateUrl: './survey-exporter.component.html',
  styleUrls: ['./survey-exporter.component.scss'],
})
export class SurveyExporterComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<void>();

  loadingActions = true;
  actionsLoaded!: boolean;
  actionsSelected!: boolean;
  actionsData!: ISurveyExporterActionData[];
  inprogress!: boolean;
  actionKind = ActionKind;
  gridKind = ActionStructureGridKind;
  missionKind = MissionKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  errorMsg!: string;

  @Input() brandId!: string;
  @Input() brandName?: string;
  @Input() mission?: IMission;
  // @Input() defaultTableViews!: ITableView[];
  @Input() exportKind?: 'excel' | 'ppt';
  @Input() dialog!: IgxDialogComponent;
  @Output() closed: EventEmitter<{ success?: boolean }> = new EventEmitter<{
    success?: boolean;
  }>();

  constructor(
    private tableViewService: TableViewService,
    private suzyData: SuzyDataService,
    private translate: TranslateService,
    private segmentService: SegmentService,
    private sanitizer: DomSanitizer
  ) {}

  public get masterCheckbox() {
    const options = {
      checked: false,
      include: 0,
      indeterminate: false,
    };
    this.actionsData.forEach((option) => {
      if (option.checked) {
        options.include++;
        options.checked = true;
      }
    });
    if (options.include === this.actionsData.length) {
      options.indeterminate = false;
    } else {
      if (options.include > 0) {
        options.indeterminate = true;
      }
    }
    return options;
  }

  get tableViewsLoaded(): boolean {
    return (
      this.actionsLoaded && !this.actionsData.some((x) => !x.tableViews.loaded)
    );
  }

  ngOnInit(): void {
    this.dialog.opened.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.reset();
      if (!this.mission) {
        return;
      }
      this.loadActionsForMission(
        this.mission.mission_id,
        this.mission.mission_kind,
        this.brandId
      );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onCancelClick(): void {
    this.closeDialog();
  }

  onSelectAllClick(): void {
    if (this.masterCheckbox.checked) {
      this.actionsData.forEach((item) => {
        item.checked = false;
      });
    } else {
      this.actionsData.forEach((item) => {
        item.checked = true;
      });
    }

    this.setActionsSelected();
  }

  onSelectActionChange(e: IChangeCheckboxEventArgs, action: IAction): void {
    const item = this.actionsData.find(
      (x) => x.action.action_id === action.action_id
    );

    if (!item) {
      return;
    }

    item.checked = e.checked;
    this.setActionsSelected();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectTableViewChange(e: ISelectionEventArgs): void {
    this.setActionsSelected();
  }

  onApplyClick(): void {
    this.inprogress = true;
    this.errorMsg = '';

    const trackData = <ISegmentTrackBulkExportData>{
      brand_id: this.brandId,
      brand_name: this.brandName ?? '',
      data_export_kind_name: this.exportKind === 'ppt' ? 'powerpoint' : 'excel',
      primary_mission_id: this.mission?.mission_id ?? '',
      primary_mission_kind: this.mission
        ? MissionKind[this.mission.mission_kind]
        : '',
      primary_mission_name: this.mission?.search_text ?? '',
      action_ids_included: [],
      action_kinds_included: [],
      action_text_included: [],
      tableview_ids_included: [],
      tableview_names_included: [],
    };

    const interface_configs = this.actionsData
      .filter((x) => x.checked && !!x.selectedTableView)
      .map((x) => {
        const { selectedTableView, action } = x;
        const tableView = (x.tableViews.data ?? []).find(
          (t) => t.table_view_id === selectedTableView
        );
        if (!tableView) {
          return;
        }

        trackData.action_ids_included.push(action.action_id);
        trackData.action_kinds_included.push(ActionKind[action.action_kind]);
        trackData.action_text_included.push(action.search_text);
        trackData.tableview_ids_included.push(tableView.table_view_id);
        trackData.tableview_names_included.push(tableView.display_name);

        // if (tableView.isGlobal) {
        //   return {
        //     action_id: action.action_id,
        //     interface_config_global_id: tableView.table_view_id,
        //   };
        // }

        return {
          action_id: action.action_id,
          interface_config_brand_id: tableView.table_view_id,
        };
      });

    const requestData = {
      mission_id: this.mission?.mission_id ?? '',
      percentage: true,
      interface_configs,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let queueDownload$!: Observable<any>;
    if (this.exportKind === 'ppt') {
      queueDownload$ = this.suzyData.exportSurveyToPPT(
        this.brandId,
        requestData
      );
    } else {
      queueDownload$ = this.suzyData.exportSurveyToExcel(
        this.brandId,
        requestData
      );
    }

    queueDownload$.subscribe((response) => {
      if (response?.success) {
        this.closeDialog({ success: true });
        return;
      }

      this.errorMsg =
        response?.error?.Message ??
        this.translate.instant('surveyExporter.pptDownloadRequestFailed');

      this.inprogress = false;
    });

    const missionText$ = this.suzyData
      .processMissionText(this.mission)
      .pipe(take(1));
    const textSubs$ = [missionText$];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackData.action_text_included.forEach((value: any) =>
      textSubs$.push(value.pipe(take(1)))
    );

    forkJoin(textSubs$)
      .pipe(take(1))
      .subscribe((values) => {
        trackData.primary_mission_name =
          this.sanitizer.sanitize(SecurityContext.HTML, values[0]) ?? '';
        trackData.action_text_included = values
          .slice(1)
          .map(
            (value) =>
              this.sanitizer.sanitize(SecurityContext.HTML, value) ?? ''
          );
        this.segmentService.trackAction(
          ESegmentTrackActionKind.bulkExport,
          trackData
        );
      });
  }

  private loadActionsForMission(
    missionId: string,
    mission_kind: MissionKind,
    brandId: string
  ): void {
    const loader$ =
      mission_kind === MissionKind.external_link
        ? this.suzyData.getActionsForExternalLinkMission(missionId, brandId)
        : this.suzyData.getActionsForMission(missionId, brandId);

    loader$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe({
      next: (resp) => {
        this.loadingActions = false;
        if (!resp?.length || !this.mission) {
          return;
        }

        const mission = this.mission;
        this.actionsData = ((resp ?? []) as IAction[]).map(
          (action: IAction) => {
            this.tableViewService
              .getTableViewsForAction(
                this.brandId,
                mission,
                action,
                //this.defaultTableViews
              )
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe((items) => {
                this.setTableViewsForAction(items, action);
              });
            return {
              action,
              tableViews: { loading: true, loaded: false, data: [] },
            } as ISurveyExporterActionData;
          }
        );
        this.actionsLoaded = true;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: (err) => {
        this.loadingActions = false;
      },
    });
  }

  private setTableViewsForAction(
    tableViews: ITableView[],
    action: IAction
  ): void {
    const actionData = this.actionsData.find(
      (x) => x.action.action_id === action.action_id
    );
    if (!actionData) {
      return;
    }

    actionData.tableViews.data = tableViews;
    actionData.tableViews.loading = false;
    actionData.tableViews.loaded = true;
    actionData.selectedTableView = this.getDefaultTableViewForAction(
      action,
      tableViews
    );
  }

  private getDefaultTableViewForAction(
    action: IAction,
    tableViews: ITableView[]
  ): string {

    if (!tableViews?.length) {
      return '';
    }

    const savedTableViewPref = this.tableViewService.getSavedTableViewPref(
      action.action_id
    );
    if (
      savedTableViewPref &&
      tableViews.some((x) => x.table_view_id === savedTableViewPref)
    ) {
      return savedTableViewPref;
    }

    const mostRecentTableViews = tableViews.sort(
      (first: ITableView, second: ITableView) => {
        if (!first.table_view_id && second.table_view_id) {
          return 1;
        }

        if (!second.table_view_id && first.table_view_id) {
          return -1;
        }

        return (
          new Date(second.updated_utc as string).getTime() -
          new Date(first.updated_utc as string).getTime()
        );
      }
    );

    return mostRecentTableViews[0].table_view_id;
  }

  private setActionsSelected(): void {
    this.errorMsg = '';
    setTimeout(() => {
      const selected = this.actionsData.filter((x) => x.checked);
      if (!selected?.length) {
        this.actionsSelected = false;
        return;
      }

      this.actionsSelected = !selected.some(
        (x) => (x.selectedTableView ?? '') === ''
      );
    }, 0);
  }

  private reset(): void {
    this.loadingActions = true;
    this.actionsLoaded = false;
    this.actionsSelected = false;
    this.actionsData = [];
    this.inprogress = false;
    this.errorMsg = '';
  }

  private closeDialog(result?: { success?: boolean }): void {
    if (!this.dialog) {
      return;
    }

    this.dialog.close();
    setTimeout(() => {
      this.closed.emit(result);
      this.reset();
    }, 200);
  }
}
