/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryFilter } from './QueryFilter.model';
import {
  QueryFilterKind,
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
} from '@asksuzy/typescript-sdk';

export interface QueryFilterQuestion {
  id: string;
  name: string;
  parent_name?: string;
  parent_id?: string;
  kind?: ActionKind;
  options?: Array<any>;
  rows?: Array<any>;
  columns?: Array<any>;
  monadic_variants?: Array<any>;
  priority?: number;
  priorityShift?: number;
  childActions?: Array<any>;
  mdMostPreferred?: boolean;
  mdHighValue?: string;
  mdLowValue?: string;
  mcKind?: ActionStructureMultipleChoiceKind;
  gridKind?: ActionStructureGridKind;
}

export class QueryFilterAnswer extends QueryFilter {
  filter_kind: QueryFilterKind = QueryFilterKind.answer;
  override question?: QueryFilterQuestion;
  override action_settings!: Array<{
    id: string;
    name: string;
    parent_name?: string;
    parent_id?: string;
  }>;
  action_setting_pair_ids!: Array<{ id: string; name: string }>;
  monadic_settings?: Array<{
    id: string;
    name: string;
    parent_name?: string;
    parent_id?: string;
  }>;
  override action_settings_is_or!: boolean;
  override is_not!: boolean;
  override answered!: boolean;
  group_id?: string;
  group_name?: string;

  static fromJsonData(filter: any): QueryFilterAnswer {
    const result = new QueryFilterAnswer();
    result.question = filter.question && { ...filter.question };
    result.action_settings = filter.action_settings?.map((x) => ({ ...x }));
    result.monadic_settings = filter.monadic_settings?.map((x) => ({ ...x }));
    result.action_settings_is_or =
      filter.action_settings_is_or !== undefined
        ? filter.action_settings_is_or
        : filter.is_or;
    result.is_not = filter.is_not;
    result.answered = filter.answered;

    return result;
  }

  constructor() {
    super(QueryFilterKind.answer);

    this.init();
  }

  init(): void {
    this.question = undefined;
    this.answered = true;
    this.action_settings = [];
    this.monadic_settings = undefined;
    this.action_settings_is_or = true;
    this.is_not = false;
    this.group_id = undefined;
    this.group_name = undefined;
  }

  reset(): void {
    this.init();
  }

  // For filter answers that have parent_name but don't have parent_id
  needsHealing(): boolean {
    return (
      (this.question?.parent_name ?? '').length > 0 &&
      !(this.question?.parent_id ?? '').length
    );
  }

  override isEmpty(): boolean {
    return !this.question;
  }

  override toJsonData(): any {
    return {
      ...super.toJsonData(),
      ...{
        question: this.question,
        action_settings_is_or: this.action_settings_is_or,
        action_settings: this.action_settings,
        monadic_settings: this.monadic_settings,
        answered: this.answered,
        is_not: this.is_not,
      },
    };
  }

  clone(): QueryFilterAnswer {
    return QueryFilterAnswer.fromJsonData(this);
  }
}
