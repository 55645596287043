import { Component, Input, OnInit } from '@angular/core';
import { DragDropManager } from '../../../drag-drop-manager';
import { IBuilder } from '../../../i-builder';
import { IBuilderDimension } from '../../../i-builder-dimension';
import { IOrchestrationAgeGroupClassification } from '../../../../../models/data/request/IOrchestrationAgeGroupClassification';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';

@Component({
  selector: 'app-custom-age-ranges',
  templateUrl: './custom-age-ranges.component.html',
  styleUrls: ['./custom-age-ranges.component.scss'],
})
export class CustomAgeRangesComponent implements OnInit {
  hasCustomAgeGroup!: boolean;
  dimension!: IBuilderDimension;

  @Input()
  public builder?: IBuilder;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  get dragdrop(): DragDropManager | undefined {
    return this.builder?.dragdrop;
  }

  ngOnInit(): void {
    if (!this.crosstabConfig?.crosstab) {
      return;
    }

    const { crosstab } = this.crosstabConfig;
    const { orchestration } = crosstab;
    this.hasCustomAgeGroup = orchestration?.custom_age_group !== undefined;
    if (!orchestration || !this.hasCustomAgeGroup) {
      return;
    }

    const { custom_age_group } = orchestration;
    this.generateDimension(
      custom_age_group?.age_group_classification_name ?? ''
    );
  }

  updateConfig(newConfig: IOrchestrationAgeGroupClassification): void {
    if (!this.dimension) {
      this.generateDimension(newConfig.age_group_classification_name ?? '');
      this.hasCustomAgeGroup = true;
      return;
    }
    this.dimension.label = newConfig.age_group_classification_name ?? '';
  }

  onEditCustomRangesClick(): void {
    this.openDialog();
  }

  onAddCustomRangesClick(): void {
    this.openDialog();
  }

  private generateDimension(label: string): void {
    this.dimension = {
      type: 'field',
      label,
      field: {
        source: 'demographic',
        field: 'custom_age_group_id',
        action_id: 'primary',
      },
      net_not_allowed: true,
      filter_not_allowed: false,
    };

    if (this.builder) {
      this.builder.dragdrop?.prepareNewItems('custom_age_ranges', [
        this.dimension,
      ]);
    }
  }

  private openDialog(): void {
    if (!this.builder) {
      return;
    }

    this.builder.onEditAgeRanges('builder');
  }
}
