import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IBuilderContext } from '../../../i-builder-context';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { DataUtility } from '../../../../../utilities/data-utility';

@Component({
  selector: 'app-cleaned-responses-filter',
  templateUrl: './cleaned-responses-filter.component.html',
  styleUrls: ['./cleaned-responses-filter.component.scss'],
})
export class CleanedResponsesFilterComponent
  implements OnInit, IAdvancedProvider
{
  public enabled = false;
  public include_cleaned = true;
  private readonly name: string = EDataFilterNames.includeCleanedResponses;
  private readonly uiArea: string = EDataFilterUIAreas.customize;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() filterChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const found = DataUtility.findFilterItem(
      this.crosstabConfig.crosstab.data_filter,
      this.name,
      this.uiArea
    );

    const hasCleanedResponses =
      found.ix >= 0 && found.item?.enabled && (found.item.bool_1 ?? false);

    this.include_cleaned = hasCleanedResponses ?? false;
    this.enabled = true;
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    const filterItem: IDataFilterItem = {
      ui_area: this.uiArea,
      name: this.name,
      enabled: true,
      bool_1: this.include_cleaned,
    };

    if (!crosstabConfig.crosstab.data_filter.items?.length) {
      crosstabConfig.crosstab.data_filter.items = [filterItem];
    }

    const found = DataUtility.findFilterItem(
      crosstabConfig.crosstab.data_filter,
      this.name,
      this.uiArea
    );

    if (found.ix >= 0) {
      crosstabConfig.crosstab.data_filter.items[found.ix] = { ...filterItem };
    } else {
      crosstabConfig.crosstab.data_filter.items.push(filterItem);
    }
  }

  onFilterChanged(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const tmpConfig = JSON.parse(
      JSON.stringify(this.crosstabConfig)
    ) as ICrosstabConfigUI;
    this.prepareSave(this.context, tmpConfig);
    this.filterChanged.emit(tmpConfig.crosstab);
  }

  reset(): void {
    this.include_cleaned = false;
    this.onFilterChanged();
  }
}
