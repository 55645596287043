import { IMission } from '../../../../models/suzy/IMission';
import { IDimension } from '../../../../models/data/request/IDimension';
import { IOrchestrationAgeGroupClassification } from '../../../../models/data/request/IOrchestrationAgeGroupClassification';

export interface IDimensionFilterData {
  brand_id: string;
  dimension: IDimension;
  type: string;
  source: IDimension;
  renaming: boolean;
  action_id_primary?: string;
  action_id_crosstabs?: string[];
  age_classification_id?: string;
  region_classification_id?: string;
  custom_age_group?: IOrchestrationAgeGroupClassification;
  is_row?: boolean;
  mission?: IMission;
}

export enum IDimensionFilterKind {
  rename,
  filter,
  net,
}
