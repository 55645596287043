import { IClusterGroupFilterUI } from './i-cluster-group-filters-ui.model';
import { DemographicsFilterUI } from './i-demographics-filter-ui.model';

export interface IAdvancedFiltersConfig {
  loading?: boolean;
  allowedFilters?: {
    demographics?: boolean;
    completes?: boolean;
    respondents?: boolean;
    segmentsPanels?: boolean;
    profiling?: boolean;
    trimmedResponses?: boolean;
    subpopulations?: boolean;
  };
  demographicsFilterData?: DemographicsFilterUI;
  segmentsPanelsFilterData?: IClusterGroupFilterUI;
  profilingFilterData?: IClusterGroupFilterUI;
}

export interface IAdvancedFilters {
  completes?: { included: boolean; count?: number };
  respondents?: { included: boolean; isExclude?: boolean; count?: number };
  gender?: { included: boolean; count?: number };
  ethnicity?: { included: boolean; count?: number };
  relationship?: { included: boolean; count?: number };
  state?: { included: boolean; count?: number };
  age_group?: { included: boolean; count?: number };
  education?: { included: boolean; count?: number };
  employment?: { included: boolean; count?: number };
  household?: { included: boolean; count?: number };
  income_level?: { included: boolean; count?: number };
  parenting?: { included: boolean; count?: number };
  region?: { included: boolean; count?: number };
  custom_age_group?: { included: boolean; count?: number };
  segmentsPanels?: { included: boolean; count?: number };
  profiling?: {
    included: boolean;
    count?: number;
    attributes?: { id: string; name: string; count: number }[];
  };
  trimmed_responses?: { included: boolean; count?: number };
  cleaned_responses?: { included: boolean; count?: number };
}

export interface IAdvancedFiltersTag {
  value: string;
  kind: EAdvancedFiltersTagKind;
  asyncParams?: {
    key: 'profilingQuestion' | 'externalAttribute';
    value: string;
    count: number;
  };
}

export enum EAdvancedFiltersTagKind {
  raw,
  token,
  async,
}
