<div class="async-notification-container">
    <igx-icon fontSet="material" class="custom-icon">schedule</igx-icon>
    <p class="body-copy-medium">{{ 'asyncNotification.title' | translate }}</p>
    <p class="body-copy-medium">{{ 'asyncNotification.subtitle' | translate }}</p>
    <p *ngIf="subscription" class="body-copy-small text-suzy-grey-700">{{ 'asyncNotification.caption' |
        translate: { 'email': subscription.email_address_masked} }}</p>

    <ng-container *ngIf="subscription; else elseNotify">
        <button style="margin: 1.5rem 0 1rem" class="suzy-secondary" [disabled]="true">
            <span class="material-symbols-rounded">notifications</span>
            {{ 'asyncNotification.ctas.notified' | translate }}
        </button>
        <button class="gray-fit-button--no-border" (click)="cancelingNotification()"
            [ngClass]="{'igx-button--outlined': isSending}">
            <ng-container *ngIf="isSending; else elseTemplate">
                <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
            </ng-container>
            <ng-template #elseTemplate>
                {{ 'asyncNotification.ctas.cancel' | translate }}
            </ng-template>
        </button>
    </ng-container>
    <ng-template #elseNotify>
        <button class="suzy-secondary" (click)="sendAsyncNotification()"
            [ngClass]="{'igx-button--outlined': isSending }" style="margin-top: 1.5rem">
            <ng-container *ngIf="isSending; else elseTemplate">
                <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
            </ng-container>
            <ng-template #elseTemplate>
                <span class="material-symbols-rounded">notifications</span>
                {{ 'asyncNotification.ctas.notifyMe' | translate }}
            </ng-template>
        </button>
    </ng-template>
</div>