export interface DataExplorerConfig {
  suzyURL: string;
  dataExplorerAPI: {
    baseURL: string;
    validationURL: string;
    tokenURL: string;
  };
  features: {
    validationEnabled: boolean;
    qualityEnabled: boolean;
    statTestingEnabled: boolean;
    advancedCalculationsEnabled: boolean;
    preparationEnabled: boolean;
    trimmedResponsesEnabled: boolean;
    publicLinkManageDataEnabled: boolean;
    manageDataUrl?: string;
    brandFoldersEnabled: boolean;
    outputCacheEnabled?: boolean;
  };
  segmentToken?: string;
  brandName?: string;
  showManageData?: boolean;
}
