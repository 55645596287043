import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { IBuilderContext } from '../../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import {
  EDEmographicsFilterNames,
  EDataFilterNames,
  EDataFilterUIAreas,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { IDPair } from '../../../../../models/suzy/IDPair';
import {
  IClusterGroupFilterItem,
  IClusterGroupFilterUI,
} from '../../../../../models/ui/i-cluster-group-filters-ui.model';
import { SuzyDataService } from '../../../../../services/suzy-data.service';
import { ISelectionEventArgs } from '@infragistics/igniteui-angular';
import { DataUtility } from '../../../../../utilities/data-utility';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { MissionKind } from '@asksuzy/typescript-sdk';

@Component({
  selector: 'app-profiling-filter',
  templateUrl: './profiling-filter.component.html',
  styleUrls: ['./profiling-filter.component.scss'],
})
export class ProfilingFilterComponent
  implements OnInit, OnDestroy, IAdvancedProvider
{
  private unsubscribeAll = new Subject<void>();
  private fieldValueCache: { [id: string]: Array<IDPair> } = {};
  private _data?: IClusterGroupFilterUI;

  selectedBrandProfileQuestionId?: string;
  enabledBrandProfileQuestionIds!: string[];
  selectListVisible!: boolean;
  addButtonVisible!: boolean;
  selectPlaceholder!: string;
  addButtonLabel!: string;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Input() set data(value: IClusterGroupFilterUI | undefined) {
    this.initialize(value);
    this._data = value;
  }

  get data(): IClusterGroupFilterUI | undefined {
    return this._data;
  }

  get clusterGroupOptions(): IDPair[] | undefined {
    if (!this.data) {
      return;
    }

    const keys = Object.keys(this.data);
    const options: IDPair[] = [];
    keys.forEach((key) => {
      if (this.data) {
        const value = this.data[key];
        if (!value.enabled) {
          options.push({
            id: key,
            name: value.name,
          } as IDPair);
        }
      }
    });

    options.sort((a, b) => a.name.localeCompare(b.name));
    return options;
  }

  @Output() filterChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private suzy: SuzyDataService) {}

  ngOnInit(): void {
    if (!this.context || !this.context.brand_id || !this.crosstabConfig) {
      return;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSelectBrandProfileQuestionChange(e: ISelectionEventArgs): void {
    if (!this.data) {
      return;
    }

    const addToFilter = (
      selectedFilterItem: IClusterGroupFilterItem,
      id: string,
      list?: IDPair[]
    ) => {
      selectedFilterItem.enabled = true;
      if (list?.length) {
        selectedFilterItem.list = list;
      }
      selectedFilterItem.loaded = true;
      selectedFilterItem.list.forEach((x) => (x.include = true));
      selectedFilterItem.allIncluded = true;
      selectedFilterItem.includedIds = selectedFilterItem.list.map((x) => x.id);
      this.enabledBrandProfileQuestionIds?.push(id);
      this.onFilterChanged();
    };

    const selected = e.newSelection.value as string;
    const found = Object.keys(this.data).find((x) => x === selected);

    if (!found || this.data[found].enabled) {
      return;
    }

    const selectedFilterItem = this.data[selected];
    if (!selectedFilterItem.loaded) {
      let load$: Observable<IDPair[]>;

      if (selected === 'gender' || selected === 'age_group') {
        const demographicsKey =
          selected === 'gender'
            ? EDEmographicsFilterNames.gender
            : EDEmographicsFilterNames.age_group;
        load$ = this.loadDemographicsValuesCached(
          demographicsKey,
          this.data[selected]
        );
      } else {
        load$ = this.loadBrandProfileQuestionValuesCached(
          selected,
          this.data[selected],
          this.context?.mission?.mission_kind === MissionKind.external_link,
          this.context?.mission?.mission_id
        );
      }

      load$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe((list) => {
        addToFilter(selectedFilterItem, found, list);
      });
    } else {
      addToFilter(selectedFilterItem, found);
    }

    setTimeout(() => {
      this.selectedBrandProfileQuestionId = undefined;
      this.selectListVisible = false;
      this.addButtonVisible = true;
    });
  }

  onRemoveBrandProfileQuestion(id: string, skipEvent?: boolean): void {
    if (!this.data || !this.data[id]) {
      return;
    }

    this.data[id].enabled = false;
    this.data[id].allIncluded = true;
    this.data[id].list.forEach((x) => (x.include = true));
    this.data[id].includedIds = this.data[id].list.map((x) => x.id);
    this.enabledBrandProfileQuestionIds =
      this.enabledBrandProfileQuestionIds?.filter((x) => x !== id) ?? [];
    if (!this.enabledBrandProfileQuestionIds?.length) {
      setTimeout(() => {
        this.selectedBrandProfileQuestionId = undefined;
        this.selectListVisible = true;
        this.addButtonVisible = false;
      });
    }

    if (!skipEvent) {
      this.onFilterChanged();
    }
  }

  onAddButtonClick(): void {
    this.selectListVisible = true;
    this.addButtonVisible = false;
  }

  onChangeOption(id: string): void {
    if (!this.data) {
      return;
    }

    this.data[id].includedIds = this.data[id].list
      .filter((x) => x.include)
      .map((x) => x.id);
    this.data[id].allIncluded = !this.data[id].list.some((x) => !x.include);
    this.onFilterChanged();
  }

  onToggleAllOptions(id: string): void {
    if (!this.data) {
      return;
    }

    if (this.data[id].allIncluded) {
      this.data[id].list.forEach((x) => (x.include = false));
      this.data[id].includedIds = [];
      this.data[id].allIncluded = false;
      this.onFilterChanged();
      return;
    }

    if (!this.data[id].allIncluded) {
      this.data[id].list.forEach((x) => (x.include = true));
      this.data[id].includedIds = this.data[id].list.map((x) => x.id);
      this.data[id].allIncluded = true;
      this.onFilterChanged();
      return;
    }
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.data) {
      return;
    }

    const keys = Object.keys(this.data);
    const items = this.data;
    keys.forEach((key) => {
      const value = items[key];
      const filterName = this.getFilterNameForBrandProfileQuestionId(key);
      let found: { ix: number; item?: IDataFilterItem };
      if (filterName === 'gender' || filterName === 'age_group') {
        found = DataUtility.findFilterItem(
          crosstabConfig.crosstab.data_filter,
          key,
          EDataFilterUIAreas.demographicFilters
        );
      } else {
        found = DataUtility.findFilterItem(
          crosstabConfig.crosstab.data_filter,
          filterName,
          key
        );
      }

      const isFiltered =
        (value?.enabled ?? false) && (value?.includedIds ?? []).length > 0;

      if (!isFiltered && found.ix >= 0) {
        crosstabConfig.crosstab.data_filter.items?.splice(found.ix, 1);
        return;
      }

      if (isFiltered) {
        const filterItem: IDataFilterItem = {
          ui_area: key,
          name: filterName,
          enabled: true,
          list_1: value?.includedIds ?? [],
          string_1: key,
          as_exclude: false,
        };

        if (filterName === 'gender' || filterName === 'age_group') {
          filterItem.ui_area = EDataFilterUIAreas.demographicFilters;
        }

        if (!crosstabConfig.crosstab.data_filter.items?.length) {
          crosstabConfig.crosstab.data_filter.items = [];
        }

        if (found.ix >= 0) {
          crosstabConfig.crosstab.data_filter.items[found.ix] = {
            ...filterItem,
          };
        } else {
          crosstabConfig.crosstab.data_filter.items.push(filterItem);
        }
      }
    });
  }

  identify(index: number, item: IDPair): string {
    return item.id;
  }

  reset(): void {
    if (!this.enabledBrandProfileQuestionIds?.length) {
      return;
    }

    const enabledIds = [...this.enabledBrandProfileQuestionIds];
    enabledIds.forEach((item) => {
      this.onRemoveBrandProfileQuestion(item, true);
    });
    this.onFilterChanged();
  }

  private initialize(filterData: IClusterGroupFilterUI | undefined): void {
    if (!filterData || !this.context) {
      return;
    }

    this.selectPlaceholder = this.context.global
      ? 'filters.selectProfiling'
      : 'filters.selectExternalAttribute';
    this.addButtonLabel = this.context.global
      ? 'filters.addProfiling'
      : 'filters.addExternalAttribute';
    this.selectedBrandProfileQuestionId = undefined;
    this.enabledBrandProfileQuestionIds = [];

    const keys = Object.keys(filterData);
    keys.forEach((key) => {
      const value = filterData[key];
      if (!value.enabled) {
        return;
      }

      let load$: Observable<IDPair[]>;
      if (key === 'gender' || key === 'age_group') {
        const demographicsKey =
          key === 'gender'
            ? EDEmographicsFilterNames.gender
            : EDEmographicsFilterNames.age_group;
        load$ = this.loadDemographicsValuesCached(
          demographicsKey,
          filterData[key]
        );
      } else {
        load$ = this.loadBrandProfileQuestionValuesCached(
          key,
          filterData[key],
          this.context?.mission?.mission_kind === MissionKind.external_link,
          this.context?.mission?.mission_id
        );
      }

      this.enabledBrandProfileQuestionIds.push(key);
      load$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe((list) => {
        value.list = list;
        value.loaded = true;
        value.list.forEach(
          (x) => (x.include = value.includedIds?.some((y) => y === x.id))
        );
        value.allIncluded = !value.list.some((x) => !x.include);
      });
    });

    this.selectListVisible = !this.enabledBrandProfileQuestionIds.length;
    this.addButtonVisible = this.enabledBrandProfileQuestionIds.length > 0;
    this._data = { ...filterData };
  }

  private onFilterChanged(): void {
    if (!this.context || !this.crosstabConfig || !this.data) {
      return;
    }

    const tmpConfig = JSON.parse(
      JSON.stringify(this.crosstabConfig)
    ) as ICrosstabConfigUI;
    this.prepareSave(this.context, tmpConfig);
    this.filterChanged.emit(tmpConfig.crosstab);
  }

  private loadBrandProfileQuestionValuesCached(
    brand_profile_question_id: string,
    item: IClusterGroupFilterItem,
    is_external_link_mission: boolean,
    mission_id?: string
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      if (this.fieldValueCache[brand_profile_question_id]) {
        item.loaded = true;
        observable.next(this.fieldValueCache[brand_profile_question_id]);
      } else {
        item.loading = true;
        item.loaded = false;
        this.suzy
          .getBrandProfileQuestionValues(
            this.context?.brand_id ?? '',
            brand_profile_question_id,
            is_external_link_mission,
            mission_id
          )
          .pipe(takeUntil(this.unsubscribeAll), take(1))
          .subscribe({
            next: (data) => {
              if (!is_external_link_mission) {
                this.fieldValueCache[brand_profile_question_id] = data;
              }
              item.loading = false;
              observable.next(data);
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            error: (error) => {
              item.loading = false;
            },
          });
      }
    });
  }

  private loadDemographicsValuesCached(
    demographicsKey: EDEmographicsFilterNames,
    item: IClusterGroupFilterItem
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      switch (demographicsKey) {
        case EDEmographicsFilterNames.gender:
          observable.next([
            {
              id: '1',
              name: 'Female',
            },
            {
              id: '2',
              name: 'Male',
            },
          ]);
          break;
        case EDEmographicsFilterNames.age_group:
          if (this.fieldValueCache[demographicsKey]) {
            item.loaded = true;
            observable.next(this.fieldValueCache[demographicsKey]);
          } else {
            item.loading = true;
            item.loaded = false;
            this.suzy
              .ageGroupGetValues()
              .pipe(takeUntil(this.unsubscribeAll), take(1))
              .subscribe({
                next: (data) => {
                  this.fieldValueCache[demographicsKey] = data;
                  item.loading = false;
                  observable.next(data);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (error) => {
                  item.loading = false;
                },
              });
          }
          break;
      }
    });
  }

  private getFilterNameForBrandProfileQuestionId(id: string): EDataFilterNames {
    if (id === 'gender') {
      return EDataFilterNames.gender;
    }
    if (id === 'age_group') {
      return EDataFilterNames.age_group;
    }
    return EDataFilterNames.profilingQuestion;
  }
}
