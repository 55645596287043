import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ELibraryRoutes } from '../enums/de-routes.enum';

export function justOneResponseValidator(
  currentRoute: ELibraryRoutes
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (currentRoute === ELibraryRoutes.CBPDerivedQuestion) {
      if (control.value && control.value.length > 0) {
        if (
          control.value.filter(
            (rule: any) => rule.criteria.field.source === 'answer'
          ).length !== 1
        ) {
          return {
            justOneResponse: true,
          };
        } else {
          return null;
        }
      }
    }
    return null;
  };
}
