<ng-container *ngIf="mission.mission_kind !== undefined">
  <ng-container *ngIf="
      mission.mission_kind !== missionKind.screening &&
      mission.mission_kind !== missionKind.survey &&
      mission.mission_kind !== missionKind.splittesting &&
      mission.mission_kind !== missionKind.focusgroup &&
      mission.mission_kind !== missionKind.maxdiff &&
      mission.mission_kind !== missionKind.external_cint &&
      mission.mission_kind !== missionKind.external_link
    ">
    <ng-container *ngIf="mission.first_action?.action_kind === actionKind.multiplechoice">
      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.multiple_choice?.multiple_choice_kind === multipleChoiceKind.standard">
        radio_button_checked</span>
      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.multiple_choice?.multiple_choice_kind === multipleChoiceKind.rating">
        more_horiz</span>
    </ng-container>
    <span class="material-symbols-rounded"
      *ngIf="mission.first_action?.action_kind === actionKind.openended">sort</span>
    <span class="material-symbols-rounded"
      *ngIf="!hidePhoto && mission.first_action?.action_kind === actionKind.photoacquisition">
      photo_camera</span>
    <ng-container *ngIf="
        mission.first_action?.action_kind === actionKind.grid ||
        mission.first_action?.action_kind === actionKind.gridcustom ||
        mission.first_action?.action_kind === actionKind.gridrankscale
      ">

      <igx-icon *ngIf="mission.first_action?.grid?.grid_kind === gridKind.rank" name="grid_rank"
        family="suzy"></igx-icon>
      <igx-icon *ngIf="mission.first_action?.grid?.grid_kind === gridKind.scale" name="grid_scale"
        family="suzy"></igx-icon>

      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.grid?.grid_kind === gridKind.open">grid_on</span>
    </ng-container>

    <igx-icon *ngIf="mission.first_action?.action_kind === actionKind.turf" name="turf" family="suzy"></igx-icon>
  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.screening">filter_list</span>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.survey">thumbs_up_down</span>

  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.splittesting">
    arrow_split
  </span>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.focusgroup">forum</span>

  <igx-icon *ngIf="mission.mission_kind === missionKind.maxdiff" name="maxdiff" family="suzy"></igx-icon>
  <igx-icon *ngIf="mission.mission_kind === missionKind.external_cint" name="global_mission" family="suzy"></igx-icon>


  <span *ngIf="mission.mission_kind === missionKind.external_link" class="suzy-icon"
    aria-label="Public link">public-link</span>

  <svg *ngIf="mission.mission_kind === 'derived_question'" width="20" height="20" viewBox="0 0 20 20" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5138 6.96782C11.5138 6.54872 11.3621 6.21246 11.0589 5.95905C10.7556 5.70564 10.3539 5.57893 9.85379 5.57893C9.54521 5.57893 9.26589 5.63985 9.01583 5.76168C8.76577 5.88351 8.5503 6.06139 8.3694 6.29531C8.19915 6.51948 7.96771 6.64862 7.67509 6.68273C7.38247 6.71685 7.12975 6.64618 6.91694 6.47074C6.76796 6.34404 6.6855 6.18809 6.66954 6.00291C6.65358 5.81772 6.70412 5.64228 6.82117 5.47659C7.16167 5.00876 7.59529 4.6457 8.122 4.38741C8.64872 4.12913 9.22599 3.99998 9.85379 3.99998C10.8859 3.99998 11.7239 4.26802 12.3677 4.80408C13.0114 5.34014 13.3333 6.04189 13.3333 6.90934C13.3333 7.34794 13.2322 7.74267 13.0301 8.09355C12.8279 8.44443 12.4555 8.85866 11.9128 9.33624C11.5191 9.67737 11.2531 9.94297 11.1147 10.133C10.9764 10.3231 10.8859 10.5399 10.8434 10.7836C10.8008 11.0175 10.6918 11.2125 10.5162 11.3684C10.3406 11.5244 10.1305 11.6023 9.88572 11.6023C9.64098 11.6023 9.43082 11.5268 9.25525 11.3757C9.07968 11.2246 8.99189 11.037 8.99189 10.8128C8.99189 10.4327 9.08234 10.0843 9.26323 9.76753C9.44412 9.45076 9.74739 9.11694 10.173 8.76607C10.7157 8.32747 11.0748 7.98878 11.2504 7.74998C11.426 7.51119 11.5138 7.25047 11.5138 6.96782ZM9.85379 15.1111C9.50265 15.1111 9.20204 14.9966 8.95199 14.7675C8.70193 14.5385 8.5769 14.2631 8.5769 13.9415C8.5769 13.6199 8.70193 13.3445 8.95199 13.1155C9.20204 12.8864 9.50265 12.7719 9.85379 12.7719C10.2049 12.7719 10.5055 12.8864 10.7556 13.1155C11.0057 13.3445 11.1307 13.6199 11.1307 13.9415C11.1307 14.2631 11.0057 14.5385 10.7556 14.7675C10.5055 14.9966 10.2049 15.1111 9.85379 15.1111Z"
      fill="#27292C" />
    <path
      d="M2.22222 20C1.61111 20 1.08796 19.7824 0.652778 19.3472C0.217593 18.912 0 18.3889 0 17.7778V14.4444C0 14.1296 0.106481 13.8657 0.319444 13.6528C0.532407 13.4398 0.796296 13.3333 1.11111 13.3333C1.42593 13.3333 1.68981 13.4398 1.90278 13.6528C2.11574 13.8657 2.22222 14.1296 2.22222 14.4444V17.7778H5.55556C5.87037 17.7778 6.13426 17.8843 6.34722 18.0972C6.56019 18.3102 6.66667 18.5741 6.66667 18.8889C6.66667 19.2037 6.56019 19.4676 6.34722 19.6806C6.13426 19.8935 5.87037 20 5.55556 20H2.22222ZM17.7778 20H14.4444C14.1296 20 13.8657 19.8935 13.6528 19.6806C13.4398 19.4676 13.3333 19.2037 13.3333 18.8889C13.3333 18.5741 13.4398 18.3102 13.6528 18.0972C13.8657 17.8843 14.1296 17.7778 14.4444 17.7778H17.7778V14.4444C17.7778 14.1296 17.8843 13.8657 18.0972 13.6528C18.3102 13.4398 18.5741 13.3333 18.8889 13.3333C19.2037 13.3333 19.4676 13.4398 19.6806 13.6528C19.8935 13.8657 20 14.1296 20 14.4444V17.7778C20 18.3889 19.7824 18.912 19.3472 19.3472C18.912 19.7824 18.3889 20 17.7778 20ZM0 5.55556V2.22222C0 1.61111 0.217593 1.08796 0.652778 0.652778C1.08796 0.217593 1.61111 0 2.22222 0H5.55556C5.87037 0 6.13426 0.106481 6.34722 0.319444C6.56019 0.532407 6.66667 0.796296 6.66667 1.11111C6.66667 1.42593 6.56019 1.68981 6.34722 1.90278C6.13426 2.11574 5.87037 2.22222 5.55556 2.22222H2.22222V5.55556C2.22222 5.87037 2.11574 6.13426 1.90278 6.34722C1.68981 6.56019 1.42593 6.66667 1.11111 6.66667C0.796296 6.66667 0.532407 6.56019 0.319444 6.34722C0.106481 6.13426 0 5.87037 0 5.55556ZM17.7778 5.55556V2.22222H14.4444C14.1296 2.22222 13.8657 2.11574 13.6528 1.90278C13.4398 1.68981 13.3333 1.42593 13.3333 1.11111C13.3333 0.796296 13.4398 0.532407 13.6528 0.319444C13.8657 0.106481 14.1296 0 14.4444 0H17.7778C18.3889 0 18.912 0.217593 19.3472 0.652778C19.7824 1.08796 20 1.61111 20 2.22222V5.55556C20 5.87037 19.8935 6.13426 19.6806 6.34722C19.4676 6.56019 19.2037 6.66667 18.8889 6.66667C18.5741 6.66667 18.3102 6.56019 18.0972 6.34722C17.8843 6.13426 17.7778 5.87037 17.7778 5.55556Z"
      fill="#27292C" />
  </svg>
</ng-container>

<ng-container *ngIf="mission && mission.mission_kind === undefined">
  <ng-container *ngIf="mission.action_kind === actionKind.multiplechoice">
    <span class="material-symbols-rounded"
      *ngIf="mission.multiple_choice.multiple_choice_kind === multipleChoiceKind.standard">
      radio_button_checked</span>
    <span class="material-symbols-rounded"
      *ngIf="mission.multiple_choice.multiple_choice_kind === multipleChoiceKind.rating">more_horiz
    </span>
  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.action_kind === actionKind.openended">sort</span>
  <span class="material-symbols-rounded" *ngIf="!hidePhoto && mission.action_kind === actionKind.photoacquisition">
    photo_camera</span>
  <ng-container *ngIf="
      mission.action_kind === actionKind.grid ||
      mission.action_kind === actionKind.gridcustom ||
      mission.action_kind === actionKind.gridrankscale
    ">
    <igx-icon *ngIf="mission.grid?.grid_kind === gridKind.rank" name="grid_rank" family="suzy"></igx-icon>
    <igx-icon *ngIf="mission.grid?.grid_kind === gridKind.scale" name="grid_scale" family="suzy"></igx-icon>

    <span class="material-symbols-rounded" *ngIf="mission.grid?.grid_kind === gridKind.open">grid_on</span>

  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.action_kind === actionKind.auto_assign">people</span>
  <igx-icon *ngIf="mission.action_kind === actionKind.maxdiff" name="maxdiff" family="suzy"></igx-icon>
  <igx-icon *ngIf="mission.action_kind === actionKind.turf" name="turf" family="suzy"></igx-icon>
</ng-container>