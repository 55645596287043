<div class="suzy-modal mx-width">
    <div class="modal-header">
        <div class="modal-header-content">
            <div class="dialog-header">
                <span class="h2 text-suzy-grey-900">{{ data.title }}</span>
                <span *ngIf="data.subtitle" class="pt body-copy-small text-suzy-grey-800">{{ data.subtitle }}</span>
            </div>
            <button class="suzy-icon-button" (click)="close()">
                <span class="material-symbols-rounded">close</span>
            </button>
        </div>
    </div>

    <div class="modal-body">
        <p class="body-copy-small text-suzy-grey-900" *ngIf="data.body">{{ data.body }}</p>
        <span *ngIf="data.customBody" class="body-copy-small text-suzy-grey-900" [innerHTML]="data.customBody"></span>
    </div>

    <div class="modal-footer-right">
        <button class="suzy-secondary" (click)="close()">
            {{ data.cancelBtn }}
        </button>
        <button class="suzy-primary" (click)="confirmAction()">
            {{ data.ctaBtn }}
        </button>
    </div>
</div>