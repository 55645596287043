import { Component, Input } from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'crosstab-wont-process-dialog',
  templateUrl: './crosstab-wont-process-dialog.component.html',
  styleUrls: ['./crosstab-wont-process-dialog.component.scss'],
})
export class CrosstabWontProcessDialogComponent {
  @Input() dialog: IgxDialogComponent | undefined;

  close(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }
}
