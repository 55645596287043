import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandClusterKind } from '@asksuzy/typescript-sdk';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { EFabricationEndpointType } from '../enums/cbp-enum';
import { IFabricationDetail } from '../models/fabrication/IFabricationDetail';
import { IFabricationResponse } from '../models/fabrication/IFabricationResponse';
import { CrosstabDataService } from './crosstab-data.service';
import { SuzySDK } from './suzy-sdk';

@Injectable({
  providedIn: 'root',
})
export class CustomBannerPointsService {
  derivedQuestions = new BehaviorSubject<any[]>([]);
  derivedQuestions$ = this.derivedQuestions.asObservable();

  constructor(
    private http: HttpClient,
    private crosstab: CrosstabDataService,
    private suzySDK: SuzySDK
  ) {}

  getFabricationsByType(
    brandId: string,
    type: EFabricationEndpointType,
    missionId?: string,
    keyword?: string,
    has_hydrated?: boolean
  ): Observable<IFabricationResponse> {
    let params = new HttpParams();

    if (missionId) {
      params = params.append('mission_id', missionId);
    }

    if (keyword) {
      params = params.append('keyword', keyword);
    }

    if (has_hydrated) {
      params = params.append('has_hydrated', has_hydrated);
    }

    params = params.append('take', 1000).append('order_by', 'fabrication_name');

    return this.http.get<IFabricationResponse>(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  createFabrication(
    data: any,
    brandId: string,
    type: EFabricationEndpointType,
    missionId?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (missionId) {
      params = params.append('mission_id', missionId);
    }
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  deleteFabrication(
    fabId: string,
    brandId: string,
    type: EFabricationEndpointType,
    missionId?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (missionId) {
      params = params.append('mission_id', missionId);
    }
    return this.http.delete(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}/${fabId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  getFabricationDetails(
    fabId: string,
    brandId: string,
    type: EFabricationEndpointType,
    missionId?: string
  ): Observable<IFabricationDetail> {
    let params = new HttpParams();

    if (missionId) {
      params = params.append('mission_id', missionId);
    }
    return this.http.get<IFabricationDetail>(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}/${fabId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  getSegmentsPanelsForSavedBanners(brandId: string): Observable<any> {
    return this.suzySDK.BrandClusterGroup.getActiveByBrandAsync(
      brandId,
      0,
      400,
      '',
      'cluster_group_name.sort',
      false
    ).pipe(
      map((items: any) =>
        items.items
          .filter(
            (item: any) =>
              item.cluster_kind === BrandClusterKind.panel ||
              item.cluster_kind === BrandClusterKind.standard
          )
          .map((segmentPanel) => {
            return {
              type: 'field',
              label: segmentPanel.cluster_group_name,
              field: {
                source: 'profile',
                field: 'attributed_brand_cluster_group_id',
                action_id: 'primary',
                identifier: segmentPanel.brand_cluster_group_id,
              },
              _drag_id: segmentPanel.brand_cluster_group_id,
            };
          })
      )
    );
  }

  updateFabrication(
    brandId: string,
    type: EFabricationEndpointType,
    data: any,
    missionId?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (missionId) {
      params = params.append('mission_id', missionId);
    }
    return this.http.put(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}/${data.fabrication_id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
        params,
      }
    );
  }

  refreshDerivedQuestion(
    brandId: string,
    fabId: string,
    type: EFabricationEndpointType
  ): Observable<any> {
    return this.http.post(
      `${this.crosstab.apiBaseUrl}/data/${brandId}/${type}/${fabId}/refresh`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.crosstab.authToken}`,
        },
      }
    );
  }
}
