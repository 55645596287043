
    <igx-dialog-title>
        <div class="flex-container">
            <div class="dialog-header">
                <igx-icon fontSet="material">warning</igx-icon>
                <span class="h2">{{ 'crosstabMayProcessDialog.title' | translate }}</span>
            </div>
            <button class="remove-button" igxButton="icon" (click)="close()">
                <igx-icon>close</igx-icon>
            </button>
        </div>

    </igx-dialog-title>
    <div class="try-queue">
        <div class="try-queue-caption body-copy-regular">
            <p>
                {{ 'crosstabMayProcessDialog.caption' | translate }}
            </p>
        </div>
        <div class="try-queue-message body-copy-small">
            <a (click)="triggerEvent()">{{ 'crosstabMayProcessDialog.notifyLink' | translate }}</a>
            <p>{{ 'crosstabMayProcessDialog.notify' | translate }}</p>
        </div>
        <p class="body-copy-regular">{{ 'crosstabMayProcessDialog.confirm' | translate }}</p>
    </div>
    <div igxDialogActions class="dialog-container-actions">
        <button igxButton="flat" (click)="close()">{{ 'crosstabMayProcessDialog.cancelBtn' | translate }}</button>
        <button igxButton="raised" (click)="try()">{{ 'crosstabMayProcessDialog.ctaBtn' | translate }}</button>
    </div>
