/* eslint-disable @typescript-eslint/no-explicit-any */

import { MissionTargetKind, QueryFilterKind } from '@asksuzy/typescript-sdk';
import { QueryFilterCustom } from './QueryFilterCustom.model';
import { MissionTarget } from './MissionTarget.model';
import { QueryFilterGender } from './QueryFilterGender.model';
import { QueryFilterAge } from './QueryFilterAge.model';
import { GenderSplit } from './GenderSplit.model';
import { QueryFilter } from './QueryFilter.model';

export class MissionTargeting {
  customQueryFilters: Array<QueryFilterCustom> = [];
  clusterCustomQueryFilters: Array<QueryFilterCustom> = [];
  answerMissionTargets: Array<MissionTarget> = [];

  genderQueryFilter: QueryFilterGender;
  ageQueryFilter: QueryFilterAge;
  genderSplitFilter!: GenderSplit;
  answerQueryFilterIsOr = false;

  static fromJsonData(targets: Array<any>): MissionTargeting {
    return new MissionTargeting(targets.map(MissionTarget.fromJsonData));
  }

  constructor(targets: Array<MissionTarget> = []) {
    this.ageQueryFilter = new QueryFilterAge();
    this.genderQueryFilter = new QueryFilterGender();
    const generatedUID = this.guid();
    targets.forEach((missionTarget) => {
      if (missionTarget.kind === MissionTargetKind.answer) {
        missionTarget.queryFilters.forEach((queryFilter) => {
          if (queryFilter.question) {
            queryFilter.question.hasParent = queryFilter.question.parent_id
              ? true
              : false;
          }
        });

        this.answerMissionTargets.push(missionTarget);

        if (!missionTarget.groupId || !missionTarget.groupName) {
          missionTarget.groupId = generatedUID;
          missionTarget.groupName = '1';
        }
      } else {
        missionTarget.queryFilters.forEach((queryFilter) => {
          switch (queryFilter.kind) {
            case QueryFilterKind.gender:
              this.genderQueryFilter = queryFilter as QueryFilterGender;
              break;

            case QueryFilterKind.age:
              this.ageQueryFilter = queryFilter as QueryFilterAge;
              break;

            default:
              if (queryFilter.cluster_group_id) {
                this.clusterCustomQueryFilters.push(
                  queryFilter as QueryFilterCustom
                );
              } else {
                this.customQueryFilters.push(queryFilter as QueryFilterCustom);
              }
          }
        });
      }
    });
  }

  guid(): string {
    const s4 = () => {
      return Math.floor((Math.random() + 1) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
  }

  getCustomQueryFilters(): Array<QueryFilter> {
    const mapClusterCustom: QueryFilterCustom[] = [];

    this.clusterCustomQueryFilters.forEach((cluster) => {
      if (cluster.values && cluster.values.length > 0) {
        cluster.kind = QueryFilterKind.cluster_group;
        mapClusterCustom.push(cluster);
      }
    });

    this.customQueryFilters.forEach((cluster) => {
      if (cluster.values && cluster.values.length > 0) {
        mapClusterCustom.push(cluster);
      }
    });

    return (mapClusterCustom as Array<QueryFilter>).concat([
      this.ageQueryFilter,
      this.genderQueryFilter,
    ]);
  }

  toJsonData(): Array<any> {
    const groupId = this.guid();

    const profileMissionTargets = this.getCustomQueryFilters().map(
      (queryFilter) =>
        new MissionTarget({
          queryFilters: [queryFilter],
          groupName: '0',
          groupIsOr: false,
          groupId,
        })
    );
    const missionTargets = this.answerMissionTargets.concat(
      profileMissionTargets
    );

    return missionTargets.map((missionTarget) => missionTarget.toJsonData());
  }
}
