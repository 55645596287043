/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { IBuilderContext } from '../../../i-builder-context';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { IExpansion } from '../../../../../models/data/request/IExpansion';
import { TranslateService } from '@ngx-translate/core';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';

@Component({
  selector: 'app-calculations-mean-score',
  templateUrl: './mean-score.component.html',
  styleUrls: ['./mean-score.component.scss'],
})
export class MeanScoreComponent implements OnInit, IAdvancedProvider {
  public addMeanScore!: boolean;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() calculationsChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const found = this.findMeanExpansion(this.crosstabConfig);
    this.addMeanScore = found.ix >= 0;
  }

  onSelectionChange(value: boolean): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.addMeanScore = value;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  reset(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    this.addMeanScore = false;
    this.prepareSave(this.context, this.crosstabConfig);
    this.calculationsChanged.emit(this.crosstabConfig.crosstab);
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.addMeanScore) {
      const found = this.findMeanExpansion(crosstabConfig);
      if (found.ix >= 0) {
        crosstabConfig.crosstab.expansions?.splice(found.ix, 1);
      }
      return;
    }

    const expansionItem: IExpansion = {
      name: 'means',
      label: this.translate.instant('builder.meanScore'),
      start: false,
    };

    if (!crosstabConfig.crosstab.expansions?.length) {
      crosstabConfig.crosstab.expansions = [expansionItem];
    }

    const found = this.findMeanExpansion(crosstabConfig);
    if (found.item) {
      crosstabConfig.crosstab.expansions[found.ix] = { ...expansionItem };
    } else {
      crosstabConfig.crosstab.expansions.push(expansionItem);
    }
  }

  private findMeanExpansion(crosstabConfig?: ICrosstabConfigUI): {
    ix: number;
    item?: IExpansion;
  } {
    if (!crosstabConfig) {
      return { ix: -1 };
    }
    const ix = (crosstabConfig.crosstab.expansions ?? []).findIndex(
      (x) => x.name === 'means'
    );
    if (ix >= 0 && crosstabConfig.crosstab.expansions?.length) {
      return { ix, item: crosstabConfig.crosstab.expansions[ix] };
    }
    return { ix: -1 };
  }
}
