/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { TranslateService } from '@ngx-translate/core';
import { IDimension } from '../../../../models/data/request/IDimension';
import { ICrosstabConfigMeta } from '../../../../models/ui/i-crosstab-config-meta';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import {
  MissionKind,
  QuotaEntity,
  QuotaProfileField,
} from '@asksuzy/typescript-sdk';
import { IBuilder } from '../../i-builder';
import { IBuilderContext } from '../../i-builder-context';
import { IBuilderDimension } from '../../i-builder-dimension';
import { IDimensionProvider } from '../i-dimension-provider';
import { DemographicDimensionProvider } from './demographic-dimension-provider';
import { IQuotaField } from '../../../../models/suzy/IQuotaField';

export class QuotaDimensionProvider implements IDimensionProvider {
  constructor(private translate: TranslateService) {
    this.name = this.translate.instant('builder.dimensionQuotas');
  }

  code: string = 'quotas';
  name: string;
  dimensions: Array<IBuilderDimension> = [];
  loading: boolean = false;
  loaded: boolean = false;
  enabled: boolean = false;
  error?: string;

  brand_id?: string;
  mission_id?: string;

  initialize(
    context: IBuilderContext,
    builder: IBuilder,
    config: ICrosstabConfigUI
  ): boolean {
    if (context.mission?.mission_kind === MissionKind.external_link) {
      this.dimensions = [];
      this.loaded = false;
      return false;
    }

    if (this.brand_id != context.brand_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    if (this.mission_id != context.mission?.mission_id) {
      this.dimensions = [];
      this.loaded = false;
    }
    this.brand_id = context.brand_id;
    this.mission_id = context.mission?.mission_id;
    this.error = undefined;

    if (!this.loaded && this.brand_id && this.mission_id) {
      const dimensions: Array<IBuilderDimension> = [];

      if (context.mission?.quota) {
        config.meta.quota_fields = context.mission.quota.quota_fields;
        if (context.mission.quota.is_interlocked) {
          this.addInterlockedQuotaDim(dimensions, config.meta);
        } else {
          this.addNonInterlockedQuotaDim(
            config.meta.quota_fields ?? [],
            dimensions
          );
        }
      }
      this.dimensions = dimensions;
      this.loaded = true;
    }
    return false;
  }

  createInterlockedDropInstances(
    builderDimension: IBuilderDimension,
    meta?: ICrosstabConfigMeta
  ): IDimension[] | undefined {
    if (!meta || !meta.quota_fields || !builderDimension) {
      return undefined;
    }
    const result: Array<IDimension> = [];
    for (const quotaField of meta.quota_fields) {
      const profileDimensions = DemographicDimensionProvider.DIMENSIONS();
      let profileQuotaFieldFound: IDimension | undefined = undefined;
      switch (quotaField.quota_entity) {
        case QuotaEntity.profile:
          switch (quotaField.quota_profile_field) {
            case QuotaProfileField.age_group:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'age_group_id'
              );
              break;
            case QuotaProfileField.gender:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'gender'
              );
              break;
            case QuotaProfileField.physical_ethnicity:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'ethnicity_id'
              );
              break;
            case QuotaProfileField.cultural_ethnicity:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'ethnicity_cultural_id'
              );
              break;
            case QuotaProfileField.region:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'region_id'
              );
              break;
            case QuotaProfileField.income:
              profileQuotaFieldFound = profileDimensions.find(
                (x) => x.field?.field == 'income_level_id'
              );
              break;
            default:
              break;
          }
          if (profileQuotaFieldFound) {
            if (profileQuotaFieldFound?.label) {
              profileQuotaFieldFound.label = `Quota: ${profileQuotaFieldFound?.label}`;
            }
            result.push(profileQuotaFieldFound);
          }
          break;
        case QuotaEntity.cluster_group:
          result.push({
            type: 'field',
            label: quotaField.field_description,
            field: {
              source: 'answer',
              field: 'targeted_brand_cluster_id',
              action_id: 'primary',
              identifier: quotaField.quota_custom_field,
            },
          });
          break;
        default:
          break;
      }
    }
    return JSON.parse(JSON.stringify(result));
  }

  private addInterlockedQuotaDim(
    dimensions: IBuilderDimension[],
    meta?: ICrosstabConfigMeta
  ): void {
    dimensions.push({
      type: 'field',
      label: this.translate.instant('builder.fieldQuotaFlat'),
      field: {
        source: 'answer',
        field: 'interlocked_quota_entry',
        action_id: 'primary',
      },
      net_not_allowed: true,
      filter_not_allowed: true,
    });
    const nestedQuotaDim: IBuilderDimension = {
      type: 'field',
      label: this.translate.instant('builder.fieldQuotaExpanded'),
      field: {
        source: 'answer',
        field: 'interlocked_nested_quotas',
        action_id: 'primary',
      },
      createDropInstances: this.createInterlockedDropInstances.bind(this),
    };
    const nestedQuotaDimFields = this.createInterlockedDropInstances(
      nestedQuotaDim,
      meta
    );
    nestedQuotaDim.interlocked_nested_quota_fields = nestedQuotaDimFields;
    dimensions.push(nestedQuotaDim);
  }

  private addNonInterlockedQuotaDim(
    quotaFields: IQuotaField[],
    dimensions: IBuilderDimension[]
  ): void {
    quotaFields.forEach((quotaField, ix) => {
      if (ix > 3) {
        return;
      }
      dimensions.push({
        type: 'field',
        label: `Quota: ${quotaField.field_description}`,
        field: {
          source: 'answer',
          field: `quota_field_${ix + 1}`,
          action_id: 'primary',
        },
      } as IDimension);
    });
  }
}
