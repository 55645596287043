<div class="header-container">
    <div class="flex-row-center-space-btw spacing-margin-b-24">
        <span class="material-symbols-rounded">settings</span>
        <span class="h2 spacing-margin-l-8">{{ 'manageData.manageTableViews' | translate }}</span>
    </div>
    <div class="flex-column-start-start">
        <span class="label">{{ 'manageData.selectedQuestionInfo' | translate }}</span>
        <div class="question-container">
            <action-icon [mission]="action"></action-icon>
            <span class="body-copy-regular"> {{action.originalSearchText}}</span>
        </div>
    </div>
</div>

<manage-tables-grid [data]="tableView" [emptyText]="'customBannerPoints.subPopulations.emptyState' | translate"
    (delete)="confirmDelete($event)"></manage-tables-grid>

    