<mat-tab-group class="suzy-mat-tab-left-aligned" [animationDuration]="0" [(selectedIndex)]="selectedTabIndex"
    (selectedIndexChange)="updateTabIndex($event)">
    <mat-tab label="{{ 'customBannerPoints.derivedQuestions.tabTitle' |
                translate
                }}">
        <ng-template matTabContent>
            <cbp-tab-header [title]="'customBannerPoints.derivedQuestions.headerTitle' | translate"
                [description]="'customBannerPoints.derivedQuestions.headerDescription' | translate"
                [learnMoreLink]="'https://academy.suzy.com/student/path/1491386/activity/3182955#/page/66758a94660bf461919f71f1'"
                [type]="fabricationType.DerivedQuestions" [refreshing]="refreshing"
                (createNew)="openDerivedQuestionForm($event)">
            </cbp-tab-header>
            <custom-banner-points-grid [data]="derivedQuestions"
                [emptyText]="'customBannerPoints.derivedQuestions.emptyState' | translate" [refreshing]="itemRefresh"
                [idToRefresh]="idToRefresh" (delete)="confirmDelete($event, fabricationType.DerivedQuestions)"
                [type]="fabTypeNumber.DerivedQuestions"
                (openDetail)="viewFabrication($event, fabricationType.DerivedQuestions)"
                (edit)="editFabrication($event, fabricationType.DerivedQuestions)"
                (clone)="cloneFabrication($event, fabricationType.DerivedQuestions)"
                (refresh)="refreshDerivedQuestion($event)"></custom-banner-points-grid>
        </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'customBannerPoints.subPopulations.tabTitle' |
    translate
    }}">

        <ng-template matTabContent>
            <cbp-tab-header [title]="'customBannerPoints.subPopulations.headerTitle' | translate"
                [description]="'customBannerPoints.subPopulations.headerDescription' | translate"
                [learnMoreLink]="'https://academy.suzy.com/student/path/1491386/activity/2962316#/page/668ff485dba5653a8a244077'"
                [type]="fabricationType.Subpopulation" (createNew)="openSubpopulationForm($event)">
            </cbp-tab-header>
            <custom-banner-points-grid [data]="subpopulations"
                [emptyText]="'customBannerPoints.subPopulations.emptyState' | translate"
                [type]="fabTypeNumber.Subpopulation"
                (delete)="confirmDelete($event, fabricationType.Subpopulation)"
                (openDetail)="viewFabrication($event, fabricationType.Subpopulation)"
                (edit)="editFabrication($event, fabricationType.Subpopulation)"
                (clone)="cloneFabrication($event, fabricationType.Subpopulation)"></custom-banner-points-grid>
        </ng-template>
    </mat-tab>
</mat-tab-group>