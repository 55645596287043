import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'mission-remove-dialog',
  templateUrl: './mission-remove-dialog.component.html',
  styleUrls: ['./mission-remove-dialog.component.scss'],
})
export class MissionRemoveDialogComponent {
  @Input() dialog!: IgxDialogComponent;

  @Output() remove = new EventEmitter<boolean>(false);

  close(): void {
    this.dialog?.close();
  }

  confirm(evt: boolean) {
    this.remove.emit(evt);
  }
}
