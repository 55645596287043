import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import { CustomBannerPointsActionsService } from '../../../../../services/custom-banner-points-actions.service';
import { catchError, of, Subject, take, takeUntil, tap } from 'rxjs';
import { EFabricationEndpointType } from '../../../../../enums/cbp-enum';
import { IFabItem } from '../../../../../models/fabrication/IFabricationResponse';
import { IAction } from '../../../../../models/suzy/IAction';
import { IMission } from '../../../../../models/suzy/IMission';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import { CustomBannerPointsService } from '../../../../../services/custom-banner-points.service';
import { SuzyDataService } from '../../../../../services/suzy-data.service';
import { DragDropManager } from '../../../drag-drop-manager';
import { IBuilder } from '../../../i-builder';
import { IBuilderContext } from '../../../i-builder-context';
import { IBuilderDimension } from '../../../i-builder-dimension';
import { IAdvancedProvider } from '../../i-advanced-provider';

interface ICrosstabQuestionActionsCache {
  missionId: string;
  cahce: Array<{ action: IAction; dimension: IBuilderDimension }>;
}
@Component({
  selector: 'app-crosstab-question',
  templateUrl: './crosstab-question.component.html',
  styleUrls: ['./crosstab-question.component.scss'],
})
export class CrosstabQuestionComponent
  implements OnInit, OnDestroy, IAdvancedProvider
{
  private unsubscribeAll = new Subject<void>();
  private missionChanged = new Subject<void>();
  private loadActions = new Subject<{
    missionId: string;
    missionKind?: MissionKind;
  }>();
  private cachedActions: Array<ICrosstabQuestionActionsCache> = [];

  loading!: boolean;
  dimensions: Array<{ action: IAction; dimension: IBuilderDimension }> = [];
  actionKind = ActionKind;
  gridKind = ActionStructureGridKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  showBrandFolderInTooltip!: boolean;

  questionTypes = [
    { label: 'Actions', id: 1 },
    { label: 'Derived Questions', id: 2 },
  ];

  questionSelector = false;
  selectedType = 1;
  derivedQuestions: IFabItem[] = [];

  @Input()
  public builder?: IBuilder;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  constructor(
    private suzy: SuzyDataService,
    private sanitizer: DomSanitizer,
    private cbpService: CustomBannerPointsService,
    private cbpActions: CustomBannerPointsActionsService
  ) {}

  get mission(): IMission | undefined {
    if (!this.context?.mission?.mission_id) {
      return undefined;
    }

    return this.context.mission;
  }

  get brandId(): string {
    return this.context?.brand_id ?? '';
  }

  get global(): boolean {
    return this.context?.global ?? false;
  }

  get dragdrop(): DragDropManager | undefined {
    return this.builder?.dragdrop;
  }

  get externalLink(): boolean {
    return (
      this.context?.mission?.mission_kind === MissionKind.external_link ?? false
    );
  }

  ngOnInit(): void {
    this.showBrandFolderInTooltip = this.context?.brandFoldersEnabled ?? false;
    this.loadActions.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
      this.dimensions = [];
      const { missionId, missionKind } = data;
      if (!missionId) {
        return;
      }

      this.loadActionsForMission(missionId, missionKind);
    });

    this.cbpService
      .getFabricationsByType(
        this.brandId,
        EFabricationEndpointType.DerivedQuestions
      )
      .pipe(
        tap((value) => {
          this.derivedQuestions = value.items.filter((item) => item.has_hydrated);
          this.cbpActions.bannerDerivedQuestions.next(this.derivedQuestions);
        }),
        take(1)
      )
      .subscribe();

    this.selectedType = this.questionTypes[0].id;
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.missionChanged.complete();
    this.loadActions.complete();
  }

  onMissionSelected(data: {
    missionId: string;
    missionKind?: MissionKind;
  }): void {
    this.missionChanged.next();
    setTimeout(() => {
      this.loadActions.next(data);
    });
  }

  private loadActionsForMission(
    missionId: string,
    missionKind?: MissionKind
  ): void {
    if (!this.context?.brand_id) {
      return;
    }
    const cachedActionsForMission = this.cachedActions.find(
      (x) => x.missionId === missionId
    );
    if (cachedActionsForMission) {
      this.dimensions = cachedActionsForMission.cahce ?? [];
      return;
    }

    this.loading = true;
    const contextActionId = this.context?.action?.action_id ?? '';
    const loader$ =
      missionKind === MissionKind.external_link
        ? this.suzy.getActionsForExternalLinkMission(
            missionId,
            this.context?.brand_id
          )
        : this.suzy.getActionsForMission(
            missionId,
            this.context?.brand_id,
            missionKind
          );

    loader$
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.missionChanged),
        take(1),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError((err) => {
          return of([]);
        })
      )
      .subscribe((actions) => {
        const actionsToCache: Array<{
          action: IAction;
          dimension: IBuilderDimension;
        }> = [];
        actions.forEach((action) => {
          if (action.action_id === contextActionId) {
            return;
          }
          const dimension = this.addCrosstabDimension(action);
          if (dimension) {
            this.dimensions.push({ action, dimension });
            actionsToCache.push({ action, dimension });
          }
        });

        if (this.builder) {
          this.builder.dragdrop?.prepareNewItems(
            'crosstab_question',
            this.dimensions.map((x) => x.dimension)
          );
        }

        const newCacheItem: ICrosstabQuestionActionsCache = {
          missionId,
          cahce: actionsToCache,
        };
        this.cachedActions.push(newCacheItem);
        this.loading = false;
      });
  }

  private addCrosstabDimension(action: IAction): IBuilderDimension | undefined {
    if (!this.builder?.config) {
      return;
    }
    action.search_text = this.suzy.processMissionText(action);

    const actionText = action.search_text.getValue();
    const { action_kind } = action;
    const netNotAllowed =
      [
        ActionKind.multiplechoice,
        ActionKind.auto_assign,
        ActionKind.turf,
      ].findIndex((x) => x === action_kind) < 0;

    const dimension = {
      type: 'field',
      label: this.getActionCaption(actionText),
      field: {
        source: 'answer',
        field: 'action_setting_id_cross',
        action_id: action.action_id,
      },
      net_not_allowed: netNotAllowed,
      filter_not_allowed: netNotAllowed,
    };

    for (const banner of this.builder.config.crosstab.columns) {
      const found = banner.dimensions.find(
        (x) =>
          x.field?.field == 'action_setting_id_cross' &&
          x.field?.source === 'answer'
      );
      if (!found) {
        continue;
      }
      found.net_not_allowed = netNotAllowed;
      found.filter_not_allowed = netNotAllowed;
    }

    for (const banner of this.builder.config.crosstab.rows) {
      const found = banner.dimensions.find(
        (x) =>
          x.field?.field == 'action_setting_id_cross' &&
          x.field?.source === 'answer'
      );
      if (!found) {
        continue;
      }
      found.net_not_allowed = netNotAllowed;
      found.filter_not_allowed = netNotAllowed;
    }

    return dimension;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getActionCaption(caption: any): string {
    const actionText = this.sanitizer
      .sanitize(SecurityContext.HTML, caption)
      ?.toString();
    const parsed = new DOMParser().parseFromString(
      actionText ?? '',
      'text/html'
    );
    return parsed.body.textContent ?? '';
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }
    const crosstabActionIds: string[] = [];
    const { columns, rows } = crosstabConfig.crosstab;

    if (this.context.action && !crosstabConfig.crosstab.action_id_primary) {
      crosstabConfig.crosstab.action_id_primary =
        this.context.action?.action_id;
    }
    if (this.context.brand_id && !crosstabConfig.crosstab.brand_id) {
      crosstabConfig.crosstab.brand_id = this.context.brand_id;
    }

    columns.forEach((cols) => {
      cols.dimensions
        .filter((col) => col.field?.field === 'action_setting_id_cross')
        .forEach((col) => {
          if (
            col.field?.action_id &&
            !crosstabActionIds.some((id) => id === col.field?.action_id)
          ) {
            crosstabActionIds.push(col.field.action_id);
          }
        });
    });

    rows.forEach((items) => {
      items.dimensions
        .filter((row) => row.field?.field === 'action_setting_id_cross')
        .forEach((row) => {
          if (
            row.field?.action_id &&
            !crosstabActionIds.some((id) => id === row.field?.action_id)
          ) {
            crosstabActionIds.push(row.field.action_id);
          }
        });
    });

    if (crosstabActionIds.length) {
      crosstabConfig.crosstab.action_id_crosstabs = crosstabActionIds;
    } else {
      delete crosstabConfig.crosstab.action_id_crosstabs;
    }
  }
}
