/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  throwError,
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { SuzySdk } from './suzy-sdk.service';

@Injectable()
export class AuthSessionService implements OnDestroy {
  private sessionReady = new BehaviorSubject<boolean>(false);
  sessionReady$: Observable<boolean> = this.sessionReady.asObservable();
  unsubscribe$ = new Subject<void>();
  hasAuthSession!: boolean;
  brands: Array<any> = [];
  selectedBrandId!: string;

  constructor(private sdk: SuzySdk, private router: Router) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initialize(): AuthSessionService {
    const found = localStorage.getItem('suzy_login');
    if (!found) {
      setTimeout(() => {
        this.hasAuthSession = false;
        this.sessionReady.next(true);
      }, 0);
      return this;
    }

    const keys = JSON.parse(found);
    this.sdk.setApiCredentials(keys.api_key, keys.api_secret);
    this.sdk.Brand.findSelf(0, 5000, '', 'name.sort')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          const httpError: HttpErrorResponse = error as HttpErrorResponse;
          if (httpError.status === 401) {
            return throwError(() => error);
          }
          return of({ success: false, items: [] });
        }),
        take(1)
      )
      .subscribe({
        next: (resp: any) => {
          this.hasAuthSession = true;
          if (!resp.success) {
            const forceCheckBrandId =
              localStorage.getItem('suzy_brand_force') ?? '';
            if (!forceCheckBrandId) {
              this.setSelectedBrand('');
              this.sessionReady.next(true);
              return;
            }

            this.sdk.Brand.getBrand(forceCheckBrandId)
              .pipe(
                takeUntil(this.unsubscribe$),
                catchError((error) => {
                  const httpError: HttpErrorResponse =
                    error as HttpErrorResponse;
                  if (httpError.status === 401) {
                    return throwError(() => error);
                  }
                  return of({ success: false, item: {} });
                }),
                take(1)
              )
              .subscribe((brandResp: any) => {
                if (brandResp?.success && brandResp?.item?.brand_id) {
                  this.brands = [brandResp.item];
                  this.setSelectedBrand(brandResp.item.brand_id);
                }
                this.sessionReady.next(true);
              });
            return;
          }
          const savedBrandId = localStorage.getItem('suzy_brand') ?? '';
          this.brands = resp?.items ?? [];
          if (
            savedBrandId &&
            this.brands.findIndex((x) => x.brand_id === savedBrandId) > 0
          ) {
            this.selectedBrandId = savedBrandId;
          }
          this.setSelectedBrand(this.selectedBrandId ?? '');
          this.sessionReady.next(true);
        },
        error: () => {
          this.logout();
          this.hasAuthSession = false;
          this.sessionReady.next(true);
        },
      });
    return this;
  }

  login(
    user: string,
    password: string
  ): Observable<{ success: boolean; message?: string }> {
    return this.sdk.ProtocolAuthorize.login({
      user,
      password,
      brands: true,
    }).pipe(
      switchMap((loginResp: any) => {
        if (loginResp?.success && loginResp?.item) {
          const { api_key, api_secret } = loginResp.item;
          localStorage.setItem(
            'suzy_login',
            JSON.stringify({ api_key, api_secret })
          );

          setTimeout(() => {
            this.initialize();
          }, 0);

          this.hasAuthSession = true;
          this.sessionReady.next(false);
          return this.sessionReady$.pipe(
            filter((x) => x),
            take(1),
            map(() => {
              return { success: true };
            })
          );
        }

        return of({
          success: loginResp?.success,
          message: loginResp?.message ?? '',
        });
      })
    );
  }

  logout(redirectToLogin?: boolean): void {
    localStorage.removeItem('suzy_login');
    localStorage.removeItem('suzy_brand');
    this.selectedBrandId = '';
    if (redirectToLogin) {
      setTimeout(() => {
        this.router.navigate(['/', 'login']);
      }, 100);
    }
  }

  setSelectedBrand(brandId: string): void {
    this.selectedBrandId = brandId;
    if (brandId) {
      localStorage.setItem('suzy_brand', brandId);
    } else {
      localStorage.removeItem('suzy_brand');
    }
  }
}
