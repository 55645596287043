import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConnectedPositioningStrategy,
  HorizontalAlignment,
  NoOpScrollStrategy,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';
import {
  EFabricationTypeNumber,
  EHydrateStatus,
} from '../../../enums/cbp-enum';
import { IFabItem } from '../../../models/fabrication/IFabricationResponse';

@Component({
  selector: 'custom-banner-points-grid',
  templateUrl: './custom-banner-points-grid.component.html',
  styleUrls: ['./custom-banner-points-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerPointsGridComponent implements OnChanges {
  @Input() data: IFabItem[] | null = null;
  @Input() emptyText!: string;
  @Input() refreshing = false;
  @Input() idToRefresh: string | null = null;
  @Input() type?: EFabricationTypeNumber;

  @Output() delete = new EventEmitter<string>();
  @Output() openDetail = new EventEmitter<string>();
  @Output() edit = new EventEmitter<string>();
  @Output() clone = new EventEmitter<string>();
  @Output() refresh = new EventEmitter<string>();

  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource(undefined);
  displayedColumns = [
    'fabrication_name',
    'known_items',
    'updated_utc',
    'edited_by',
    'actions',
  ];
  fabricationType: typeof EFabricationTypeNumber = EFabricationTypeNumber;

  showMore = false;
  showMoreState: boolean[] = [];

  status: typeof EHydrateStatus = EHydrateStatus;

  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
    scrollStrategy: new NoOpScrollStrategy(),
  };

  ngOnChanges(changes: SimpleChanges): void {
    const dataChanges = changes['data'];
    const typeChanges = changes['type'];

    if (dataChanges?.currentValue) {
      this.dataSource = new MatTableDataSource(dataChanges.currentValue);
      this.dataSource.sort = this.sort;
      this.showMoreState = Array(this.dataSource.data.length).fill(false);
    }

    if (typeChanges?.currentValue) {
      if (typeChanges?.currentValue === this.fabricationType.DerivedQuestions) {
        this.displayedColumns.splice(1, 0, 'hydrate_status');
      }
    }
  }

  filterItems(searchTerm: string): void {
    this.dataSource.filter = searchTerm;
  }

  deleteItem(item: string): void {
    this.delete.emit(item);
  }

  openItem(item: string): void {
    this.openDetail.emit(item);
  }

  editItem(item: string): void {
    this.edit.emit(item);
  }

  cloneItem(item: string): void {
    this.clone.emit(item);
  }

  refreshItem(item: string): void {
    this.refresh.emit(item);
  }

  toggleShowMore(index: number): void {
    this.showMoreState[index] = !this.showMoreState[index];
  }
}
