import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IAdvancedProvider } from '../../i-advanced-provider';
import { IBuilderContext } from '../../../i-builder-context';
import { ICrosstabConfigUI } from '../../../../../models/ui/i-crosstab-config-ui';
import {
  EDataFilterNames,
  IDataFilterItem,
} from '../../../../../models/data/request/IDataFilterItem';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { IDPair } from '../../../../../models/suzy/IDPair';
import {
  IClusterGroupFilterItem,
  IClusterGroupFilterUI,
} from '../../../../../models/ui/i-cluster-group-filters-ui.model';
import { SuzyDataService } from '../../../../../services/suzy-data.service';
import { ISelectionEventArgs } from '@infragistics/igniteui-angular';
import { DataUtility } from '../../../../../utilities/data-utility';
import { BrandClusterKind, QuotaEntity } from '@asksuzy/typescript-sdk';
import { ICrosstabConfig } from '../../../../../models/data/request/ICrosstabConfig';
import { MissionTargeting } from '../../../../../models/suzy/MissionTargeting.model';

@Component({
  selector: 'app-cluster-groups-filter',
  templateUrl: './cluster-groups-filter.component.html',
  styleUrls: ['./cluster-groups-filter.component.scss'],
})
export class ClusterGroupsFilterComponent
  implements OnInit, OnDestroy, IAdvancedProvider
{
  private unsubscribeAll = new Subject<void>();
  private fieldValueCache: { [id: string]: Array<IDPair> } = {};
  private _data?: IClusterGroupFilterUI;
  private targeting?: MissionTargeting;
  private targetedClusterGroupIds!: string[];
  private quotedClusterGroupIds!: string[];

  selectedClusterGroupId?: string;
  enabledClusterGroupIds!: string[];
  selectListVisible!: boolean;
  addButtonVisible!: boolean;

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Input() set data(value: IClusterGroupFilterUI | undefined) {
    this.initialize(value);
    this._data = value;
  }

  get data(): IClusterGroupFilterUI | undefined {
    return this._data;
  }

  get clusterGroupOptions(): IDPair[] | undefined {
    if (!this.data) {
      return;
    }

    const keys = Object.keys(this.data);
    const options: IDPair[] = [];
    keys.forEach((key) => {
      if (this.data) {
        const value = this.data[key];
        if (!value.enabled) {
          options.push({
            id: key,
            name: value.name,
          } as IDPair);
        }
      }
    });

    options.sort((a, b) => a.name.localeCompare(b.name));
    return options;
  }

  @Output() filterChanged: EventEmitter<ICrosstabConfig> =
    new EventEmitter<ICrosstabConfig>();

  constructor(private suzy: SuzyDataService) {}

  ngOnInit(): void {
    if (!this.context || !this.context.brand_id || !this.crosstabConfig) {
      return;
    }

    const { mission } = this.context;
    const { targets, targeting, quota } = mission ?? {
      target: undefined,
      targeting: undefined,
      quota: undefined,
    };

    if (!targeting && targets?.length) {
      this.targeting = MissionTargeting.fromJsonData(targets);
    } else {
      this.targeting = targeting;
    }

    this.targetedClusterGroupIds = (
      this.targeting?.clusterCustomQueryFilters ?? []
    )
      .map((x) => x.cluster_group_id ?? '')
      .filter((x) => x !== '');

    const clusterGroupQuotaFields = (quota?.quota_fields ?? []).filter(
      (quotaField) => quotaField.quota_entity === QuotaEntity.cluster_group
    );
    this.quotedClusterGroupIds = (clusterGroupQuotaFields ?? []).map(
      (quotaField) => quotaField.quota_custom_field
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSelectClusterGroupChange(e: ISelectionEventArgs): void {
    if (!this.data) {
      return;
    }

    const selected = e.newSelection.value as string;
    const found = Object.keys(this.data).find((x) => x === selected);

    if (!found || this.data[found].enabled) {
      return;
    }

    const addToFilter = (
      selectedFilterItem: IClusterGroupFilterItem,
      list?: IDPair[]
    ) => {
      selectedFilterItem.enabled = true;
      if (list?.length) {
        selectedFilterItem.list = list;
      }
      selectedFilterItem.loaded = true;
      selectedFilterItem.list.forEach((x) => (x.include = true));
      selectedFilterItem.allIncluded = true;
      selectedFilterItem.includedIds = selectedFilterItem.list.map((x) => x.id);
      this.enabledClusterGroupIds?.push(found);
      this.onFilterChanged();
    };

    const selectedFilterItem = this.data[selected];
    if (!selectedFilterItem.loaded) {
      this.loadClusterGroupValuesCached(selected, this.data[selected])
        .pipe(takeUntil(this.unsubscribeAll), take(1))
        .subscribe((list) => {
          addToFilter(selectedFilterItem, list);
        });
    } else {
      addToFilter(selectedFilterItem);
    }

    setTimeout(() => {
      this.selectedClusterGroupId = undefined;
      this.selectListVisible = false;
      this.addButtonVisible = true;
    });
  }

  onRemoveClusterGroup(id: string, skipEvent?: boolean): void {
    if (!this.data || !this.data[id]) {
      return;
    }

    this.data[id].enabled = false;
    this.data[id].allIncluded = true;
    this.data[id].list.forEach((x) => (x.include = true));
    this.data[id].includedIds = this.data[id].list.map((x) => x.id);
    this.enabledClusterGroupIds =
      this.enabledClusterGroupIds?.filter((x) => x !== id) ?? [];
    if (!this.enabledClusterGroupIds?.length) {
      setTimeout(() => {
        this.selectedClusterGroupId = undefined;
        this.selectListVisible = true;
        this.addButtonVisible = false;
      });
    }

    if (!skipEvent) {
      this.onFilterChanged();
    }
  }

  onAddButtonClick(): void {
    this.selectListVisible = true;
    this.addButtonVisible = false;
  }

  onChangeOption(id: string): void {
    if (!this.data) {
      return;
    }

    this.data[id].includedIds = this.data[id].list
      .filter((x) => x.include)
      .map((x) => x.id);
    this.data[id].allIncluded = !this.data[id].list.some((x) => !x.include);
    this.onFilterChanged();
  }

  onToggleAllOptions(id: string): void {
    if (!this.data) {
      return;
    }

    if (this.data[id].allIncluded) {
      this.data[id].list.forEach((x) => (x.include = false));
      this.data[id].includedIds = [];
      this.data[id].allIncluded = false;
      this.onFilterChanged();
      return;
    }

    if (!this.data[id].allIncluded) {
      this.data[id].list.forEach((x) => (x.include = true));
      this.data[id].includedIds = this.data[id].list.map((x) => x.id);
      this.data[id].allIncluded = true;
      this.onFilterChanged();
      return;
    }
  }

  prepareSave(
    context: IBuilderContext,
    crosstabConfig: ICrosstabConfigUI
  ): void {
    if (!this.data) {
      return;
    }

    const keys = Object.keys(this.data);
    const items = this.data;
    keys.forEach((key) => {
      const value = items[key];
      const filterName = this.getFilterNameForClusterGroupId(key);
      const found = DataUtility.findFilterItem(
        crosstabConfig.crosstab.data_filter,
        filterName,
        key
      );

      const isFiltered =
        (value?.enabled ?? false) && (value?.includedIds ?? []).length > 0;

      if (!isFiltered && found.ix >= 0) {
        crosstabConfig.crosstab.data_filter.items?.splice(found.ix, 1);
        return;
      }

      if (isFiltered) {
        const filterItem: IDataFilterItem = {
          ui_area: key,
          name: filterName,
          enabled: true,
          list_1: value?.includedIds ?? [],
          as_exclude: false,
        };

        if (!crosstabConfig.crosstab.data_filter.items?.length) {
          crosstabConfig.crosstab.data_filter.items = [];
        }

        if (found.ix >= 0) {
          crosstabConfig.crosstab.data_filter.items[found.ix] = {
            ...filterItem,
          };
        } else {
          crosstabConfig.crosstab.data_filter.items.push(filterItem);
        }
      }
    });
  }

  identify(index: number, item: IDPair): string {
    return item.id;
  }

  reset(): void {
    if (!this.enabledClusterGroupIds?.length) {
      return;
    }

    const enabledIds = [...this.enabledClusterGroupIds];
    enabledIds.forEach((item) => {
      this.onRemoveClusterGroup(item, true);
    });
    this.onFilterChanged();
  }

  private initialize(filterData: IClusterGroupFilterUI | undefined): void {
    if (!filterData || !this.context) {
      return;
    }

    this.selectedClusterGroupId = undefined;
    this.enabledClusterGroupIds = [];

    const keys = Object.keys(filterData);
    keys.forEach((key) => {
      const value = filterData[key];
      if (!value.enabled) {
        return;
      }

      this.enabledClusterGroupIds.push(key);
      this.loadClusterGroupValuesCached(key, filterData[key])
        .pipe(takeUntil(this.unsubscribeAll), take(1))
        .subscribe((list) => {
          value.list = list;
          value.loaded = true;
          value.list.forEach(
            (x) => (x.include = value.includedIds?.some((y) => y === x.id))
          );
          value.allIncluded = !value.list.some((x) => !x.include);
        });
    });

    this.selectListVisible = !this.enabledClusterGroupIds.length;
    this.addButtonVisible = this.enabledClusterGroupIds.length > 0;

    this._data = { ...filterData };
  }

  private onFilterChanged(): void {
    if (!this.context || !this.crosstabConfig || !this.data) {
      return;
    }

    const tmpConfig = JSON.parse(
      JSON.stringify(this.crosstabConfig)
    ) as ICrosstabConfigUI;
    this.prepareSave(this.context, tmpConfig);
    this.filterChanged.emit(tmpConfig.crosstab);
  }

  private loadClusterGroupValuesCached(
    clusterGroupId: string,
    item: IClusterGroupFilterItem
  ): Observable<Array<IDPair>> {
    return new Observable<Array<IDPair>>((observable) => {
      if (this.fieldValueCache[clusterGroupId]) {
        item.loaded = true;
        observable.next(this.fieldValueCache[clusterGroupId]);
      } else {
        item.loading = true;
        item.loaded = false;
        this.suzy
          .brandClusterGroupGetValues(
            this.context?.brand_id ?? '',
            clusterGroupId
          )
          .pipe(takeUntil(this.unsubscribeAll), take(1))
          .subscribe({
            next: (data) => {
              this.fieldValueCache[clusterGroupId] = data;
              item.loading = false;
              observable.next(data);
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            error: (error) => {
              item.loading = false;
            },
          });
      }
    });
  }

  private getFilterNameForClusterGroupId(id: string): EDataFilterNames {
    if (
      this.targetedClusterGroupIds.findIndex((x) => x === id) >= 0 ||
      this.quotedClusterGroupIds.findIndex((x) => x === id) >= 0
    ) {
      const brandClusterKind = this.suzy.getClusterKindByClusterGroupId(id);
      if (brandClusterKind === BrandClusterKind.standard) {
        return EDataFilterNames.segmentTarget;
      }

      if (brandClusterKind === BrandClusterKind.panel) {
        return EDataFilterNames.panelTarget;
      }
    }

    return EDataFilterNames.userAttribution;
  }
}
