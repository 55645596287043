import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'cbp-confirm-action-dialog',
  templateUrl: './cbp-confirm-action-dialog.component.html',
  styleUrls: ['./cbp-confirm-action-dialog.component.scss'],
})
export class CbpConfirmActionDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      title: string;
      subtitle: string;
      body: string;
      customBody: any;
      ctaBtn: string;
      cancelBtn: string;
      callback?: () => unknown;
    }
  ) {}

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  confirmAction(): void {
    if (this.data.callback) {
      this.data.callback();
      this.close();
    }
  }
}
