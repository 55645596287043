/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryFilterKind } from '@asksuzy/typescript-sdk';
import { QueryFilter } from './QueryFilter.model';

export class QueryFilterAge extends QueryFilter {
  static AGE_LIMITS = { min: 13, max: 99 };

  override kind: QueryFilterKind = QueryFilterKind.age;
  ageRange: [number, number];

  static fromJsonData(filter: any): QueryFilterAge {
    return new QueryFilterAge(filter.age_min, filter.age_max);
  }

  constructor(ageMin = 13, ageMax = 99) {
    super(QueryFilterKind.age);

    this.ageRange = [ageMin, ageMax];
  }

  isDefault(): boolean {
    return (
      this.ageRange[0] === QueryFilterAge.AGE_LIMITS.min &&
      this.ageRange[1] === QueryFilterAge.AGE_LIMITS.max
    );
  }

  override toJsonData(): any {
    return {
      ...super.toJsonData(),
      ...{
        age_min: this.ageRange[0],
        age_max: this.ageRange[1],
      },
    };
  }
}
