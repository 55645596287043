import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obscureEmail'
})
export class ObscureEmailPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    const [name, domain] = value.split('@');

    if (!domain) return value; 

    const maskedName = name.length > 1
      ? `${name[0]}${'*'.repeat(name.length - 1)}`
      : name;

    return `${maskedName}@${domain}`;
  }

}
