import { Injectable } from '@angular/core';
import { ActionKind } from '@asksuzy/typescript-sdk';
import {
  BehaviorSubject,
  exhaustMap,
  forkJoin,
  map,
  Observable,
  of,
  take,
  tap,
} from 'rxjs';
import { DemographicDimensionProvider } from '../components/builder/providers/dimensions/demographic-dimension-provider';
import {
  AUTO_ACTION_OPERATORS,
  BASE_OPERATORS,
  MULTI_MULTI_ACTION_OPERATORS,
  MULTI_SINGLE_ACTION_OPERATORS,
  SCALE_RANK_ACTION_OPERATORS,
  TURF_ACTION_OPERATORS,
} from '../constants/cbp.constant';
import { ECBPCategory, EFabricationEndpointType } from '../enums/cbp-enum';
import { IFabricationOutcome } from '../models/fabrication/IFabrication';
import { IFabItemDetail } from '../models/fabrication/IFabricationDetail';
import { IFabricationPayload } from '../models/fabrication/IFabricationPayload';
import { IFabItem } from '../models/fabrication/IFabricationResponse';
import { ITableViewFabrication } from '../models/fabrication/ITableViewFabrication';
import { IAction } from '../models/suzy/IAction';
import { CustomBannerPointsService } from './custom-banner-points.service';

@Injectable({
  providedIn: 'root',
})
export class CustomBannerPointsActionsService {
  formHasChanges = new BehaviorSubject<boolean>(false);
  formHasChanges$ = this.formHasChanges.asObservable();

  builderSubpopulations = new BehaviorSubject<IFabItem[]>([]);
  builderSubpopulations$ = this.builderSubpopulations.asObservable();

  selectedSavedBanner = new BehaviorSubject<IFabItemDetail | null>(null);
  selectedSavedBanner$ = this.selectedSavedBanner.asObservable();

  bannerDimensions = new BehaviorSubject<any | null>(null);
  bannerDimensions$ = this.bannerDimensions.asObservable();

  bannerSubpopulations = new BehaviorSubject<IFabItem[]>([]);
  bannerSubpopulations$ = this.bannerSubpopulations.asObservable();

  bannerDerivedQuestions = new BehaviorSubject<IFabItem[]>([]);
  bannerDerivedQuestions$ = this.bannerDerivedQuestions.asObservable();

  savedBannerToClone = new BehaviorSubject<boolean>(false);
  savedBannerToClone$ = this.savedBannerToClone.asObservable();

  activeTableViewFabrications = new BehaviorSubject<ITableViewFabrication[]>(
    []
  );
  activeTableViewFabrications$ =
    this.activeTableViewFabrications.asObservable();

  savedBannerSelectedTab = new BehaviorSubject<number>(0);
  savedBannerSelectedTab$ = this.savedBannerSelectedTab.asObservable();

  constructor(private cbpService: CustomBannerPointsService) {}

  getOperatorsByActionKind(action: IAction): any[] {
    let operators: any[] = [];
    switch (action.action_kind) {
      case ActionKind.multiplechoice:
        if (action.multiple_choice?.max_choices > 1) {
          operators = MULTI_MULTI_ACTION_OPERATORS;
        } else {
          operators = MULTI_SINGLE_ACTION_OPERATORS;
        }
        break;
      case ActionKind.auto_assign:
        operators = AUTO_ACTION_OPERATORS;
        break;
      case ActionKind.gridrankscale:
        //case ActionKindVariant.grid_rank:
        operators = SCALE_RANK_ACTION_OPERATORS;
        break;
      case ActionKind.grid:
      case ActionKind.gridcustom:
        if (action.grid?.open_row_max > 1) {
          operators = MULTI_MULTI_ACTION_OPERATORS;
        } else {
          operators = MULTI_SINGLE_ACTION_OPERATORS;
        }
        break;
      case ActionKind.turf:
        operators = TURF_ACTION_OPERATORS;
        break;
      case ActionKind.maxdiff:
        operators = MULTI_MULTI_ACTION_OPERATORS;
        break;
      default:
        operators = BASE_OPERATORS;
        break;
    }

    return operators;
  }

  getFabricationOutcomes(fabrication: any): any[] {
    const outcomes = fabrication.items[0].outcomes.map(
      (outcome: IFabricationOutcome) => {
        return { id: outcome.outcome_id, name: outcome.outcome_text };
      }
    );

    if (fabrication.items[0].remainder.include) {
      const leftoverGroup = fabrication.items[0].remainder.outcome;
      outcomes.push({
        id: leftoverGroup.outcome_id,
        name: leftoverGroup.outcome_text,
      });
    }
    return outcomes;
  }

  extractDimensions(dimensionProviders: any, action?: any): any {
    const segments = dimensionProviders
      .filter((dimension: any) => dimension.code === 'segments_panels')
      .map((dim) => dim.dimensions)
      .flat();

    const demographics = dimensionProviders
      .filter((dimension: any) => dimension.code === 'demographics-standard')
      .map((dim) => dim.dimensions)
      .flat();

    const questions = dimensionProviders
      .filter((dimension: any) => dimension.code === 'questions')
      .map((dim) =>
        dim.dimensions.map((dim) => {
          return {
            ...dim,
            field: {
              ...dim.field,
              field:
                dim.field.field === 'action_setting_id_root'
                  ? 'action_setting_id'
                  : dim.field.field,
            },
            actionId: action.action_id,
            actionKind: action.action_kind,
          };
        })
      )
      .flat();

    return { questions, demographics, segments };
  }

  getDimensionFieldValue(dimensions: any, id: string): any {
    const dimensionsList = Object.values(dimensions).flat();
    return dimensionsList.find((item: any) => item._drag_id === id);
  }

  getValueFromDimensions(dimensions: any, fieldObject: any): any {
    const dimensionsList = Object.values(dimensions).flat();

    return dimensionsList.find((item: any) => {
      if (
        fieldObject.identifier &&
        fieldObject.source !== ECBPCategory.Demographics
      ) {
        return item.field.identifier === fieldObject.identifier;
      } else {
        return item.field.field === fieldObject.field;
      }
    });
  }

  createSavedBannerDimensions(brandId): Observable<any> {
    const demographics = DemographicDimensionProvider.DIMENSIONS().map(
      (item: any, idx: number) => {
        return {
          ...item,
          _drag_id: 'n_' + idx,
        };
      }
    );

    return forkJoin({
      segments: this.cbpService.getSegmentsPanelsForSavedBanners(brandId),
      demographics: of(demographics),
    });
  }

  createOrEditFabrication(
    brandId: string,
    type: EFabricationEndpointType,
    fab: any,
    isEdit: boolean,
    missionId?: string
  ): void {
    const fabrication: IFabricationPayload = {
      fabrication_id: fab.fabrication_id,
      fabrication_name: fab.items[0].title,
      fabrication_type: fab.fabrication_type,
      organization_id: brandId,
      mission_scoped: !!missionId,
    };

    if (missionId) {
      fabrication.mission_id = missionId;
    }

    if (fab.fabrication_description) {
      fabrication.fabrication_description = fab.fabrication_description;
    }

    fabrication.json_payload = JSON.stringify({
      fabrication_id: fabrication.fabrication_id,
      fabrication_name: fabrication.fabrication_name,
      fabrication_type: fabrication.fabrication_type,
      items: fab.items,
    });

    if (isEdit) {
      this.cbpService
        .updateFabrication(brandId, type, fabrication, missionId)
        .pipe(
          exhaustMap(() =>
            this.cbpService.getFabricationsByType(brandId, type, missionId)
          ),
          tap((res) =>
            this.updateFabricationSubjects(type, res.items, !!missionId)
          ),
          take(1)
        )
        .subscribe();
    } else {
      this.cbpService
        .createFabrication(fabrication, brandId, type, missionId)
        .pipe(
          exhaustMap(() =>
            this.cbpService.getFabricationsByType(brandId, type, missionId)
          ),
          tap((res) =>
            this.updateFabricationSubjects(type, res.items, !!missionId)
          ),
          take(1)
        )
        .subscribe();
    }
  }

  updateFabricationSubjects(
    type: EFabricationEndpointType,
    items: IFabItem[],
    isBuilder: boolean
  ): void {
    if (type === EFabricationEndpointType.Subpopulation) {
      isBuilder
        ? this.builderSubpopulations.next(items)
        : this.bannerSubpopulations.next(items);
    } else {
      this.bannerDerivedQuestions.next(items);
    }
  }

  getAllFabricationsByType(
    fabType: EFabricationEndpointType,
    brandId: string,
    missionId: string
  ): Observable<any> {
    return forkJoin({
      savedBanners: this.cbpService
        .getFabricationsByType(brandId, fabType)
        .pipe(map((results) => results.items)),
      missionSPs: this.cbpService
        .getFabricationsByType(brandId, fabType, missionId)
        .pipe(map((results) => results.items)),
    }).pipe(
      map((res) => {
        return res.savedBanners.concat(res.missionSPs);
      })
    );
  }
}
