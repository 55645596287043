<div class="active-filters">
  <ng-container
    class="filter-box"
    *ngFor="let groupId of enabledClusterGroupIds"
  >
    <div
      class="filter-box"
      *ngIf="data && data[groupId] && data[groupId].enabled"
    >
      <div class="filter-name">
        <span class="name">{{ data[groupId].name }}</span>
        <button igxButton="icon" (click)="onRemoveClusterGroup(groupId)">
          <igx-icon family="suzy" name="delete_custom"></igx-icon>
        </button>
      </div>
      <div class="filter-actions">
        <div class="loading" *ngIf="data && data[groupId].loading">
          <igx-circular-bar
            [animate]="false"
            [indeterminate]="true"
            [textVisibility]="false"
          ></igx-circular-bar>
        </div>
        <igx-checkbox
          *ngIf="data && data[groupId].loaded"
          [readonly]="true"
          [(ngModel)]="data[groupId].allIncluded"
          [indeterminate]="
            !data[groupId].allIncluded &&
            (data[groupId].includedIds?.length ?? 0) > 0
          "
          (click)="onToggleAllOptions(groupId)"
        >
          {{ 'builderFilter.all' | translate }}
        </igx-checkbox>
      </div>
      <div class="filter-options">
        <div class="options-grid" *ngIf="data && data[groupId].loaded">
          <div class="option-item" *ngFor="let option of data[groupId].list">
            <igx-checkbox
              [(ngModel)]="option.include"
              (change)="onChangeOption(groupId)"
              >{{ option.name }}</igx-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="field-selector">
  <igx-select
    *ngIf="selectListVisible"
    [(ngModel)]="selectedClusterGroupId"
    (selectionChanging)="onSelectClusterGroupChange($event)"
    [placeholder]="'filters.selectSegmentPanel' | translate"
  >
    <igx-select-item
      *ngFor="let option of clusterGroupOptions; trackBy: identify"
      [value]="option.id"
    >
      {{ option.name }}
    </igx-select-item>
  </igx-select>

  <button
    *ngIf="addButtonVisible && clusterGroupOptions?.length"
    igxButton="outlined"
    (click)="onAddButtonClick()"
  >
    <igx-icon fontSet="material">add</igx-icon>
    {{ 'filters.addSegmentPanel' | translate }}
  </button>
</div>
