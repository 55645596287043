import { Route } from '@angular/router';
import { ExplorerUIComponent } from './components/explorer/explorer.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'login',
    component: LoginComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand/:brand_id/insights/data-explorer',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand/:brand_id/insights/data-explorer/derived-question/:question_id',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand/:brand_id/insights/data-explorer/:mission_id',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand/:brand_id/insights/data-explorer/:mission_id/action/:action_id',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: 'brand/:brand_id/insights/data-explorer/:mission_id/action/:action_id/tableview/:tableview_id',
    canActivate: [AuthGuard],
    component: ExplorerUIComponent,
    data: { text: 'Suzy Data Explorer' },
  },
  {
    path: '**',
    redirectTo: 'brand',
  },
];
