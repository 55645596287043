import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { IBuilderContext } from '../i-builder-context';
import { ICrosstabConfigUI } from '../../../models/ui/i-crosstab-config-ui';
import { TableViewService } from '../../../services/table-view.service';
import {
  EAdvancedFiltersTagKind,
  IAdvancedFilters,
  IAdvancedFiltersConfig,
  IAdvancedFiltersTag,
} from '../../../models/ui/i-advanced-filters-config.model';
import { DataUtility } from '../../../utilities/data-utility';
import {
  EDataFilterNames,
  EDataFilterUIAreas,
} from '../../../models/data/request/IDataFilterItem';

@Component({
  selector: 'app-builder-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  hasFilter!: boolean;
  filterStatus!: IAdvancedFilters;
  filterTags!: IAdvancedFiltersTag[];
  filterTagKind = EAdvancedFiltersTagKind;
  advancedFiltersBinding?: {
    data?: IAdvancedFiltersConfig;
  } = {
    data: undefined,
  };

  @Input()
  public context?: IBuilderContext;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  @Output() crosstabConfigChange = new EventEmitter<ICrosstabConfigUI>();

  @Input()
  public allowedFilters?: {
    demographics?: boolean;
    completes?: boolean;
    respondents?: boolean;
    segmentsPanels?: boolean;
    profiling?: boolean;
    subpopulations?: boolean;
  };

  @ViewChild('advanced_filter_dialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private advanced_filter_dialog!: IgxDialogComponent;

  constructor(private tableViewService: TableViewService) {
    this.hasFilter = false;
  }

  ngOnInit(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    this.filterStatus = this.tableViewService.crosstabConfigHasAdvancedFilters(
      this.crosstabConfig.crosstab
    );

    this.filterTags = this.tableViewService.getAdvancedFiltersTags(
      this.filterStatus,
      this.context?.global
    );

    this.hasFilter = this.checkHasFilters(this.filterStatus);
  }

  onFiltersClick(): void {
    if (!this.context || !this.crosstabConfig) {
      return;
    }

    const demographicsFilterData = this.allowedFilters?.demographics
      ? {
          ...this.tableViewService.getDemographicsFilterFromConfig(
            this.crosstabConfig.crosstab
          ),
        }
      : undefined;

    if (this.allowedFilters?.segmentsPanels) {
      this.advancedFiltersBinding = {
        data: { loading: true },
      };

      this.tableViewService
        .getClusterGroupsFilterFromConfig(this.crosstabConfig.crosstab)
        .subscribe((response) => {
          this.advancedFiltersBinding = {
            data: {
              loading: false,
              allowedFilters: this.allowedFilters,
              demographicsFilterData,
              segmentsPanelsFilterData: response,
            },
          };
        });
    } else if (this.allowedFilters?.profiling) {
      this.tableViewService
        .getProfilingFilterFromConfig(
          this.crosstabConfig.crosstab,
          this.context?.brand_id,
          this.context?.mission?.mission_id,
          this.context?.global
        )
        .subscribe((response) => {
          this.advancedFiltersBinding = {
            data: {
              loading: false,
              allowedFilters: this.allowedFilters,
              demographicsFilterData,
              profilingFilterData: response,
            },
          };
        });
    } else {
      this.advancedFiltersBinding = {
        data: {
          allowedFilters: this.allowedFilters,
          demographicsFilterData,
        },
      };
    }

    this.advanced_filter_dialog.open();
  }

  onConfigChanged(newConfig: ICrosstabConfigUI): void {
    this.filterStatus = this.tableViewService.crosstabConfigHasAdvancedFilters(
      newConfig.crosstab
    );

    this.filterTags = this.tableViewService.getAdvancedFiltersTags(
      this.filterStatus,
      this.context?.global
    );

    this.hasFilter = this.checkHasFilters(this.filterStatus);

    this.crosstabConfigChange.emit(newConfig);
  }

  private checkHasFilters(filter: IAdvancedFilters): boolean {
    return Object.keys(filter).some((x) => filter[x]?.included);
  }

  customAgeRangesUpdated(): void {
    if (!this.crosstabConfig || !this.allowedFilters?.demographics) {
      return;
    }
    const newConfig = <ICrosstabConfigUI>(
      JSON.parse(JSON.stringify(this.crosstabConfig))
    );

    if (!newConfig.crosstab?.data_filter?.items?.length) {
      return;
    }
    const customAgeGroupFilter = DataUtility.findFilterItem(
      newConfig.crosstab.data_filter,
      EDataFilterNames.custom_age_group,
      EDataFilterUIAreas.demographicFilters
    );

    if (customAgeGroupFilter.ix < 0) {
      return;
    }

    newConfig.crosstab.data_filter.items.splice(customAgeGroupFilter.ix, 1);
    this.onConfigChanged(newConfig);

    const dataBinding = this.advancedFiltersBinding?.data;
    if (!dataBinding) {
      return;
    }

    const demographicsFilterData = {
      ...this.tableViewService.getDemographicsFilterFromConfig(
        newConfig.crosstab
      ),
    };

    dataBinding.demographicsFilterData = demographicsFilterData;
    this.advancedFiltersBinding = {
      data: { ...dataBinding },
    };
  }
}
